import React from 'react';
import {
    TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    disabled: {
        backgroundColor: '#eeeeee'
    }
}));

const onChangeValue = (value, dataName, name, setValue, e) => {
    if (!value) setValue(null, name);
    else setValue(value[dataName], name, e)
}

const Select = (props) => {
    const classes = useStyles();
    return (
        <Autocomplete
            getOptionLabel={
                option =>
                    (typeof option === 'object') ?
                        option[props.dataname + 'Name'] :
                        (props.data?.find(x => x[props.dataobjectname] === option)?.[props.dataname + 'Name'] ?? "")
            }
            renderOption={option => option[props.dataname + 'Name']}
            onChange={
                (e, value) =>
                    onChangeValue(
                        value,
                        props.dataobjectname,
                        props.name,
                        props.setvalue,
                        (props.getIndex) ? e.target.getAttribute("data-option-index") : undefined
                    )
            }
            options={props.data ?? []}
            getOptionSelected={(option, value) => option[props.dataobjectname] === value}
            value={props.value || null}
            noOptionsText='ไม่มีผลการค้นหา...'
            disabled={props.disabled ?? false}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        label={props.label}
                        placeholder={props.placeholder ?? props.label}
                        variant="outlined"
                        size="small"
                        color={props.color}
                        error={props.error}
                        required={props.required}
                        disabled={props.disabled ?? false}
                        className={(props.className ?? "") + (props.disabled ? classes.disabled : "")}
                        fullWidth
                    />
                )
            }
            }
        />
    )
}

export default React.memo(Select
    , (prevProps, nextProps) =>
    (
        prevProps.data === nextProps.data &&
        prevProps.value === nextProps.value &&
        prevProps.error === nextProps.error &&
        prevProps.disabled === nextProps.disabled
    )
);