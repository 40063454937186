import React from "react";
import {
	Box,
	// Hidden,
	MenuItem,
	MenuList,
	Paper,
	Popover,
	Typography,
	Avatar
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
// import SettingsIcon from "@material-ui/icons/Settings";
import { setAccountLogout, setMasterData } from '../../../../redux/action';
import AccountService from '../../../../Service/AccountService';
// import { removeAllUsersCookies } from '../../../../Service/_CookieService';
import { removeLocalStorage, removeUserToken } from '../../../../Service/_LocalStorageService';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utilities from "../../../../Util/Utilities";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "30px 16px 12px 16px",
		borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`
	},
	userInfo: {
		paddingLeft: 10,
		transition: "all .4s ease-out",
		height: 50,
		// overflowY: 'hidden',
		flex: "1 1",
		opacity: 1,
		".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
			height: 0,
			paddingTop: 0,
			opacity: 0,
			transition: "all 0.1s ease"
		}
	},
	userTitle: {
		color: theme.palette.sidebar.textDarkColor,
		marginBottom: 5,
		maxWidth: "180px",
		whiteSpace: "nowrap",
		overflow: "hidden !important",
		textOverflow: "ellipsis",
	},
	userSubTitle: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		letterSpacing: 0.25,
		maxWidth: "180px",
		whiteSpace: "nowrap",
		overflow: "hidden !important",
		textOverflow: "ellipsis",
	}
}));

const SidebarHeader = ({ account, trainingPlatform }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const state = useSelector(
		(state) => state,
		(state, prevState) => state.account === prevState.account && state.profilePictureURL === prevState.profilePictureURL
	);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onLogoutClick = () => {
		removeLocalStorage('currentSignoff');
		dispatch(setMasterData());
		handlePopoverClose();
		// removeAllUsersCookies();
		removeUserToken();
		dispatch(setAccountLogout());
		removeLocalStorage("masterData");
		Utilities.clearNavigationMenu(trainingPlatform, account);
		AccountService.Logout();
		history.push("/landingpage");
		// dispatch(AuhMethods[CurrentAuthMethod].onLogout());
	};

	return (
		<Box className={classes.root}>
			<Box
				display="flex"
				justifyContent="flex-start"
			>
				<Avatar
					src={state.profilePictureURL}
					style={{
						marginBottom: '3px',
						minWidth: 48,
						minHeight: 48
					}}
					onClick={() => {
						history.push("/settings/profile")
					}}
				/>
				<Box className={classes.userInfo} onClick={handlePopoverOpen}>
					<Box
						className="pointer"
						display="flex"
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Box mr={2}>
							<Typography
								className={classes.userTitle}
								component="h3"
								variant="h6"
							>
								{state?.account?.Username ?? "admin"}
							</Typography>
							<Typography className={classes.userSubTitle}>
								{state?.account?.Email ?? "admin@twp.com"}
							</Typography>
						</Box>
						<ArrowDropDownIcon className="ml-auto" />
					</Box>
				</Box >
			</Box>
			{open && (
				<Popover
					open={open}
					anchorEl={anchorEl}
					container={anchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: "center",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "center",
						horizontal: "right"
					}}
				>
					<Paper elevation={8}>
						<MenuList>
							<NavLink to="/settings/profile">
								<MenuItem onClick={handlePopoverClose}>
									<PersonIcon />
									<Box ml={2}>Profile</Box>
								</MenuItem>
							</NavLink>
							<MenuItem onClick={onLogoutClick}>
								<ExitToAppIcon />
								<Box ml={2}>Logout</Box>
							</MenuItem>
						</MenuList>
					</Paper>
				</Popover>
			)}
		</Box >
	);
};

export default SidebarHeader;
