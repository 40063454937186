import React from "react";
import Highcharts from 'highcharts/highstock';
import Utilities from './Utilities';
Highcharts.setOptions({
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

class Chart extends React.Component {
    chartInstance = undefined;

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.data !== this.props.data
        if (nextProps.data && this.chartInstance === undefined) {
            this.renderChart(nextProps.data, nextProps.onClick);
            return true;
        }
        else if (nextProps.data !== this.props.data && this.chartInstance !== undefined) {
            this.chartInstance.destroy();
            this.renderChart(nextProps.data, nextProps.onClick);
            return true;
        }
        // return (this.chartInstance === undefined);
        return false;
    }

    componentDidMount() {
        if (this.props.data && this.chartInstance === undefined) {
            this.renderChart(this.props.data, this.props.onClick);
        }
    }

    renderChart(data, onClick) {
        let max;
        let dataLength = data[0]?.data?.length;
        if (dataLength) {
            max = 0;
            for (let i = 0; i < data[0].data.length; i++) {
                const element = data[0]?.data[i];
                if (element[1] > max || element.y > max) max = element[1] ?? element.y;
            }
            max = (10 * Math.ceil(max / 10));
        }
        let unit = this.props.unit;
        this.chartInstance = Highcharts.chart(this.chart, {
            chart: {
                type: 'column',
                height: this.props.height ?? undefined
            },
            title: {
                text: this.props.title ?? 'สรุปงบประมาณแผนงานโครงการ'
            },
            xAxis: {
                type: 'category',
                title: {
                    text: this.props.typeName ?? 'ชื่อ'
                },
                min: 0,
                max: dataLength > 5 ? 5 : ((dataLength || 1) - 1),
                scrollbar: {
                    enabled: dataLength > 5
                },
                tickLength: 0
            },
            yAxis: {
                min: 0,
                max: max,
                title: {
                    text: this.props.yText ?? 'งบประมาณ (ล้านบาท)',
                    align: 'high'
                },
                labels: {
                    formatter: function () {
                        const formatter = Intl.NumberFormat('en', {
                            notation: 'compact',
                        })
                    
                        return formatter.format(this.value);
                    },
                }
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.point?.options?.tooltipLabel ?? this.key}</b>: ${Utilities.toCommas(this.y)} ${unit ?? ""}`;
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                },
                column: {
                    maxPointWidth: 200
                },
                series: {
                    dataLabels: {
                        enabled: true
                    },
                    cursor: onClick ? 'pointer' : undefined,
                    events: {
                        click: onClick
                    }
                }
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            series: data
        });
    }

    render() {
        return (
            <div ref={el => this.chart = el} style={{ width: "100%", height: "100%" }} />
        )
    }
}

export default Chart;