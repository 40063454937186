import Axios from "./_BaseService";

const ProjectImportantService = {
    searchProjectImportant: (data) => {
        data = {
            ShowNotPass: (data.ShowNotPass === 2 ? null : data.ShowNotPass) || undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID,
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            ProjectImportantCode: data.ProjectImportantCode ? data.ProjectImportantCode.trim() : undefined,
            OperationStartYear: data.OperationStartYear || undefined,
            OperationEndYear: data.OperationEndYear || undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
            MasterPlan: data.MasterPlan ?? undefined,
            ProjectImportantPrepareID: data.ProjectImportantPrepareID || undefined,
            ProjectImportantPrepareChildID: data.ProjectImportantPrepareChildID || undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID || undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID || undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined
        }

        const SEARCHPROJECTIMPORTANT = `
            query SearchProjectImportant (
                $ShowNotPass: Int,
                $ProjectImportantStatusID: Int,
                $ProjectImportantName: String,
                $ProjectImportantCode: String,
                $OperationStartYear: [Int],
                $OperationEndYear: [Int],
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int,
                $MasterPlan: Boolean,
                $ProjectImportantPrepareID: Int,
                $ProjectImportantPrepareChildID: Int,
                $ProjectImportantGroupSubID: Int,
                $ProjectImportantGroupID: Int,
                $IsCanceled: Int
            ) {
                SearchProjectImportant (
                    ShowNotPass: $ShowNotPass
                    ProjectImportantStatusID: $ProjectImportantStatusID
                    ProjectImportantName: $ProjectImportantName
                    ProjectImportantCode: $ProjectImportantCode
                    OperationStartYear: $OperationStartYear
                    OperationEndYear: $OperationEndYear
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    Offset: $Offset
                    Limit: $Limit
                    MasterPlan: $MasterPlan
                    ProjectImportantPrepareID: $ProjectImportantPrepareID
                    ProjectImportantPrepareChildID: $ProjectImportantPrepareChildID
                    ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                    ProjectImportantGroupID: $ProjectImportantGroupID,
                    IsCanceled: $IsCanceled
                ) {
                ProjectImportant {
                        TProjectImportantID
                        ProjectImportantCode,
                        ProjectImportantName,
                        BudgetYear,
                        ProjectTypeName,
                        OrganizationName,
                        ProjectStatusName,
                        ProjectStatusID,
                        MasterPlanID,
                        MasterPlanName,
                        RequestAmount,
                        RequestAmountAll,
                        DraftAmount,
                        AllocateAmount,
                        BudgetDimensionName,
                        IsLock,
                        OperationStartYear,
                        OperationEndYear,
                        MYProject,
                        OrderProject,
                        OrderProvince,
                        OrderBasin,
                        OrderCountry,
                        OrderGroup,
                        ProjectImportantStatusID,
                        ProjectImportantStatusName,
                        ProjectImportantPrepareName,
                        Score,
                        Lat,
                        Lng,
                        IsPolicyProject,
                        AreaBaseID,
                        RequestAmount,
                        ProjectImportantStatusID
                    }
                    Count
                }
            }
        `;


        return Axios.post("/twpservice", {
            query: SEARCHPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    searchProjectImportantGroup: (data) => {
        data = {
            ProjectImportantGroupName: data.ProjectImportantGroupName ? data.ProjectImportantGroupName.trim() : undefined,
            ProjectImportantGroupLevel: data.ProjectImportantGroupLevel || undefined
        }
        const GETPROJECTIMPORTANTPREPARE = `
            query SearchProjectImportantGroup (
                $ProjectImportantGroupName: String,
                $ProjectImportantGroupLevel: Int,
            ) { 
                SearchProjectImportantGroup (
                    ProjectImportantGroupName: $ProjectImportantGroupName,
                    ProjectImportantGroupLevel: $ProjectImportantGroupLevel,
                ) {
                    ProjectImportantGroupID,
                    ProjectImportantGroupName,
                    ProjectImportantGroupLevel,
                    ProjectImportantGroupParentID
                }
            }
        `;

        return Axios.post('/twpservice', {
            query: GETPROJECTIMPORTANTPREPARE,
            variables: data,
            errorPolicy: "all",
        })
    },

    getProjectImportantDetail: (TProjectImportantID) => {
        const GETPROJECTIMPORTANTDETAIL = `
            query GetProjectImportantDetail (
                $TProjectImportantID: Int!
            ) {
                GetProjectImportantDetail (
                    TProjectImportantID: $TProjectImportantID 
                ) {
                    TProjectImportantID,
                    ProjectImportantName,
                    ProjectImportantCode,
                    Moo,
                    Village,
                    SubdistrictID,
                    SubdistrictName,
                    DistrictID,
                    DistrictName,
                    ProvinceID,
                    ProvinceName,
                    BasinID,
                    BasinName,
                    BasinCode,
                    SubbasinID,
                    SubbasinName,
                    SubbasinCode,
                    Lat,
                    Lng,
                    OrganizationID,
                    OrganizationName,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetSourceName,
                    BudgetTypeID,
                    BudgetTypeName,
                    ActivityID,
                    ActivityName,
                    ProjectTypeID,
                    ProjectTypeName,
                    AreaBaseID,
                    AreaBaseName,
                    AreaBaseCode,
                    BudgetDimensionID,
                    BudgetDimensionName,
                    RequestAmountAll,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    TProjectImportantPrepare {
                        ProjectImportantPrepareID,
                        ProjectImportantPrepareName,
                        ParentID,
                        Level,
                        ProjectImportantPrepareChild {
                            ProjectImportantPrepareID,
                            ProjectImportantPrepareName,
                            ParentID,
                            Level,
                            StartYear,
                            EndYear,
                            Value,
                        }
                    }
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    OrderGroup,
                    ProjectStatusID,
                    ProjectStatusName,
                    IsLock,
                    ContractSignDate,
                    ContractStartDate,
                    ContractEndDate,
                    ReadinessStatusID,
                    ReadinessStatusName,
                    MYProject,
                    IRR,
                    DC,
                    BC,
                    NPV,
                    ROI,
                    MasterPlanID,
                    MasterPlanName,
                    Strategy,
    				KPI,
                    Cashflow {
                        Year1,
                        Year2,
                        Year3,
                        Year4,
                        Year5,
                        Year6,
                        YearLast
                    },
                    CashflowAll {
                        TProjectImportantID,
                        CashflowYear,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        GFMISCode
                    }
                    Roadmap {
                        start,
                        end,
                        date,
                        y,
                        color,
                        name,
                        ParentID,
                        ProjectImportantMilestoneID,
                        IsPublic,
                    }
                    ProjectImportantMilestone {
                        ProjectImportantMilestoneID,
                        ProjectImportantMilestoneName,
                        ProjectImportantMilestoneDate,
                        TProjectImportantID,
                        IsPublic
                    }
                    IsPolicyProject,
                    PolicyTypeID,
                    PolicyTypeName,
                    PolicyDate,
                    Description,
                    Files {
                        TProjectImportantID,
                        ProjectImportantPrepareID,
                        ProjectImportantPrepareName,
                        FileName,
                        GenFileName,
                        Description,
                        Path,
                        FileID
                    },
                    ProjectImportantStatusID,
                    ProjectImportantStatusName,
                    ProjectImportantGroupSubID,
                    ProjectImportantGroupID,
                    ProjectImportantGroupName,
                    ProjectImportantGroupSubName,
                    ProjectImportantPrepareID,
                    ProjectImportantPrepareName,
                    Score,
                    ScoreDetail,

                    ImpactEnvironment,
                    ImpactPeople,
                    ImpactSociety,

                    IsCanceled,
                    CanceledDescription,
                    CanceledDate,
                    CanceledAccountID,
                    CanceledUsername
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTIMPORTANTDETAIL,
            variables: {
                TProjectImportantID: TProjectImportantID
            },
            errorPolicy: "all",
        });
    },

    getProjectImportantPrepare: () => {
        const GETPROJECTIMPORTANTPREPARE = `
            query { 
                GetTProjectImportantPrepare {
                ProjectImportantPrepareID,
                ProjectImportantPrepareName,
                ParentID,
                Level,
                StartYear,
                EndYear,
                Value,
                show,
                ProjectImportantPrepareChild {
                ProjectImportantPrepareID,
                ProjectImportantPrepareName,
                ParentID,
                Level,
                StartYear,
                EndYear,
                Value
                }
            }
        }
        `;

        return Axios.post('/twpservice', {
            query: GETPROJECTIMPORTANTPREPARE,
            variables: {},
            errorPolicy: "all",
        })
    },

    createProjectImportant: (data) => {

        data = {
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            ProjectImportantCode: data.ProjectImportantCode ? data.ProjectImportantCode.trim() : undefined,
            Moo: data.Moo ? data.Moo.trim() : undefined,
            Village: data.Village ? data.Village.trim() : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            BasinID: data.BasinID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lat ? data.Lng : undefined,
            OrganizationID: data.OrganizationID,
            BudgetYear: data.BudgetYear || undefined,
            OperationStartYear: data.OperationStartYear,
            OperationEndYear: data.OperationEndYear,
            OperationStartMonth: data.OperationStartMonth || undefined,
            OperationEndMonth: data.OperationEndMonth || undefined,
            Duration: data.Duration || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            RequestAmount: data.RequestAmount || undefined,
            DraftAmount: data.DraftAmount || undefined,
            ApproveAmount: data.ApproveAmount || undefined,
            AllocateAmount: data.AllocateAmount || undefined,
            /* TProjectImportantPrepare */
            TProjectImportantPrepare: data.TProjectImportantPrepare.length ? data.TProjectImportantPrepare : undefined,
            Remark: data.Remark ? data.Remark.trim() : undefined,
            CreatedDateTime: data.CreatedDateTime || undefined,
            ModifiedDateTime: data.ModifiedDateTime || undefined,
            CreatedUsername: data.CreatedUsername || undefined,
            ModifiedUsername: data.ModifiedUsername || undefined,
            OrderProject: data.OrderProject || undefined,
            OrderProvince: data.OrderProvince || undefined,
            OrderBasin: data.OrderBasin || undefined,
            OrderCountry: data.OrderCountry || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            IsLock: data.IsLock || undefined,
            ContractSignDate: data.ContractSignDate || undefined,
            ContractStartDate: data.ContractStartDate || undefined,
            ContractEndDate: data.ContractEndDate || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            MYProject: data.MYProject ?? undefined,
            IRR: data.IRR ?? undefined,
            DC: data.DC ?? undefined,
            BC: data.BC ?? undefined,
            NPV: data.NPV ?? undefined,
            ROI: data.ROI ?? undefined,
            Strategy: data.Strategy ?? undefined,
            KPI: data.KPI ?? undefined,
            Cashflow: data.Cashflow ?? undefined,
            CashflowAll: data.CashflowAll.length ? data.CashflowAll : undefined,
            IsPolicyProject: data.IsPolicyProject,
            PolicyTypeID: data.PolicyTypeID ?? undefined,
            PolicyDate: data.PolicyDate ?? undefined,
            Description: data.Description ? data.Description.trim() : undefined,
            Files: data.Files?.length ? data.Files : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID || undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID || undefined,
            ImpactEnvironment: data.ImpactEnvironment || undefined,
            ImpactPeople: data.ImpactPeople || undefined,
            ImpactSociety: data.ImpactSociety || undefined,
        }


        const CREATEPROJECTIMPORTANT = `
        mutation CreateProjectImportant (
            $ProjectImportantName: String,
            $ProjectImportantCode: String,
            $Moo: String,
            $Village: String,
            $SubdistrictID: Int,
            $DistrictID: Int,
            $ProvinceID: Int,
            $BasinID: Int,
            $SubbasinID: Int,
            $Lat: Float,
            $Lng: Float,
            $OrganizationID: Int,
            $BudgetYear: Int,
            $OperationStartYear: Int,
            $OperationEndYear: Int,
            $OperationStartMonth: Int,
            $OperationEndMonth: Int,
            $Duration: Int,
            $BudgetSourceID: Int,
            $BudgetTypeID: Int,
            $ActivityID: Int,
            $ProjectTypeID: Int,
            $AreaBaseID: Int,
            $BudgetDimensionID: Int,
            $RequestAmount: Float,
            $DraftAmount: Float,
            $ApproveAmount: Float,
            $AllocateAmount: Float,

            ############ Prepare ############
            $TProjectImportantPrepare: [InputPrepare]
            #################################

            $Remark: String,
            $CreatedDateTime: Date,
            $ModifiedDateTime: Date,
            $CreatedUsername: String,
            $ModifiedUsername: String,
            $OrderProject: Int,
            $OrderProvince: Int,
            $OrderBasin: Int,
            $OrderCountry: Int,
            $ProjectStatusID: Int,
            $IsLock: Int,
            $ContractSignDate: BEDate,
            $ContractStartDate: BEDate,
            $ContractEndDate: BEDate,
            $ReadinessStatusID: Int,
            $MYProject: Boolean,
            $IRR: Float,
            $DC: Float,
            $BC: Float,
            $NPV: Float,
            $ROI: Float,
            $Strategy: [StrategyData]!,
            $KPI: [KPIData]!,
            $Cashflow: InputCashflow,
            $CashflowAll: [InputCashflowAll],
            $IsPolicyProject: Int,
            $PolicyTypeID: Int,
            $PolicyDate: Date,
            $Description: String,
            $Files: [InputProjectImportantFile],
            $ProjectImportantGroupSubID: Int,
            $ProjectImportantGroupID: Int,

            ############ Impact ############
            $ImpactEnvironment: Boolean,
            $ImpactPeople: Boolean,
            $ImpactSociety: Boolean
        ) {
            CreateProjectImportant (
                ProjectImportantName: $ProjectImportantName,
                ProjectImportantCode: $ProjectImportantCode,
                Moo: $Moo,
                Village: $Village,
                SubdistrictID: $SubdistrictID,
                DistrictID: $DistrictID,
                ProvinceID: $ProvinceID,
                BasinID: $BasinID,
                SubbasinID: $SubbasinID,
                Lat: $Lat,
                Lng: $Lng,
                OrganizationID: $OrganizationID,
                BudgetYear: $BudgetYear,
                OperationStartYear: $OperationStartYear,
                OperationEndYear: $OperationEndYear,
                OperationStartMonth: $OperationStartMonth,
                OperationEndMonth: $OperationEndMonth,
                Duration: $Duration,
                BudgetSourceID: $BudgetSourceID,
                BudgetTypeID: $BudgetTypeID,
                ActivityID: $ActivityID,
                ProjectTypeID: $ProjectTypeID,
                AreaBaseID: $AreaBaseID,
                BudgetDimensionID: $BudgetDimensionID,
                RequestAmount: $RequestAmount,
                DraftAmount: $DraftAmount,
                ApproveAmount: $ApproveAmount,
                AllocateAmount: $AllocateAmount,
                TProjectImportantPrepare: $TProjectImportantPrepare,
                Remark: $Remark,
                CreatedDateTime: $CreatedDateTime,
                ModifiedDateTime: $ModifiedDateTime,
                CreatedUsername: $CreatedUsername,
                ModifiedUsername: $ModifiedUsername,
                OrderProject: $OrderProject,
                OrderProvince: $OrderProvince,
                OrderBasin: $OrderBasin,
                OrderCountry: $OrderCountry,
                ProjectStatusID: $ProjectStatusID,
                IsLock: $IsLock,
                ContractSignDate: $ContractSignDate,
                ContractStartDate: $ContractStartDate,
                ContractEndDate: $ContractEndDate,
                ReadinessStatusID: $ReadinessStatusID,
                MYProject: $MYProject,
                IRR: $IRR,
                DC: $DC,
                BC: $BC,
                NPV: $NPV,
                ROI: $ROI,
                Strategy: $Strategy,
                KPI: $KPI,
                Cashflow: $Cashflow,
                CashflowAll: $CashflowAll,
                IsPolicyProject: $IsPolicyProject,
                PolicyTypeID: $PolicyTypeID,
                PolicyDate: $PolicyDate,
                Description: $Description,
                Files: $Files,
                ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                ProjectImportantGroupID: $ProjectImportantGroupID,
                ImpactEnvironment: $ImpactEnvironment,
                ImpactPeople: $ImpactPeople,
                ImpactSociety: $ImpactSociety
            ) {
                TProjectImportantID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: CREATEPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    updateProjectImportant: (data) => {
        data = {
            TProjectImportantID: data.TProjectImportantID || undefined,
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            ProjectImportantCode: data.ProjectImportantCode ? data.ProjectImportantCode.trim() : undefined,
            Moo: data.Moo ? data.Moo.trim() : undefined,
            Village: data.Village ? data.Village.trim() : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            BasinID: data.BasinID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lat ? data.Lng : undefined,
            OrganizationID: data.OrganizationID,
            BudgetYear: data.BudgetYear || undefined,
            OperationStartYear: data.OperationStartYear,
            OperationEndYear: data.OperationEndYear,
            OperationStartMonth: data.OperationStartMonth || undefined,
            OperationEndMonth: data.OperationEndMonth || undefined,
            Duration: data.Duration || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            RequestAmount: data.RequestAmount || undefined,
            DraftAmount: data.DraftAmount || undefined,
            ApproveAmount: data.ApproveAmount || undefined,
            AllocateAmount: data.AllocateAmount || undefined,
            /* TProjectImportantPrepare */
            TProjectImportantPrepare: data.TProjectImportantPrepare.length ? data.TProjectImportantPrepare : undefined,
            Remark: data.Remark ? data.Remark.trim() : undefined,
            CreatedDateTime: data.CreatedDateTime || undefined,
            ModifiedDateTime: data.ModifiedDateTime || undefined,
            CreatedUsername: data.CreatedUsername || undefined,
            ModifiedUsername: data.ModifiedUsername || undefined,
            OrderProject: data.OrderProject || undefined,
            OrderProvince: data.OrderProvince || undefined,
            OrderBasin: data.OrderBasin || undefined,
            OrderCountry: data.OrderCountry || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            //IsLock: data.IsLock || undefined,
            ContractSignDate: data.ContractSignDate || undefined,
            ContractStartDate: data.ContractStartDate || undefined,
            ContractEndDate: data.ContractEndDate || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            MYProject: data.MYProject ?? undefined,
            MasterPlanID: data.MasterPlanID ?? undefined,
            IRR: data.IRR ?? undefined,
            DC: data.DC ?? undefined,
            BC: data.BC ?? undefined,
            NPV: data.NPV ?? undefined,
            ROI: data.ROI ?? undefined,
            Strategy: data.Strategy ?? undefined,
            KPI: data.KPI ?? undefined,
            Cashflow: data.Cashflow ?? undefined,
            CashflowAll: data.CashflowAll.length ? data.CashflowAll : undefined,
            DraftAmountYear: data.DraftAmountYear ?? undefined,
            AllocateAmountYear: data.AllocateAmountYear ?? undefined,
            PolicyTypeID: data.PolicyTypeID ?? undefined,
            //PolicyTypeName: data.PolicyTypeName ? data.PolicyTypeName.trim() : undefined,
            IsPolicyProject: data.IsPolicyProject,
            PolicyDate: data.PolicyDate ? data.PolicyDate.trim() : undefined,
            Files: data.Files?.length ? data.Files : undefined,
            Description: data.Description ? data.Description.trim() : undefined,
            DelFiles: data.DelFiles?.length ? data.DelFiles : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID || undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID || undefined,
            /* Impact */
            ImpactEnvironment: data.ImpactEnvironment || undefined,
            ImpactPeople: data.ImpactPeople || undefined,
            ImpactSociety: data.ImpactSociety || undefined,
            /* Cancel Status */
            ProjectImportantStatusID: data.ProjectImportantStatusID,
            IsCanceled: data.IsCanceled || 0,
            CanceledDescription: data.CanceledDescription || undefined
        }

        const UPDATEPROJECTIMPORTANT = `
        mutation UpdateProjectImportant(
            $TProjectImportantID: Int,
            $ProjectImportantName: String,
            $ProjectImportantCode: String,
            $Moo: String,
            $Village: String,
            $SubdistrictID: Int,
            $DistrictID: Int,
            $ProvinceID: Int,
            $BasinID: Int,
            $SubbasinID: Int,
            $Lat: Float,
            $Lng: Float,
            $OrganizationID: Int,
            $BudgetYear: Int,
            $OperationStartYear: Int,
            $OperationEndYear: Int,
            $OperationStartMonth: Int,
            $OperationEndMonth: Int,
            $Duration: Int,
            $BudgetSourceID: Int,
            $BudgetTypeID: Int,
            $ActivityID: Int,
            $ProjectTypeID: Int,
            $AreaBaseID: Int,
            $BudgetDimensionID: Int,
            $RequestAmount: Float,
            $DraftAmount: Float,
            $ApproveAmount: Float,
            $AllocateAmount: Float,

            ############ Prepare ############
            $TProjectImportantPrepare: [InputPrepare]
            #################################


            $Remark: String,
            $CreatedDateTime: Date,
            $ModifiedDateTime: Date,
            $CreatedUsername: String,
            $ModifiedUsername: String,
            $OrderProject: Int,
            $OrderProvince: Int,
            $OrderBasin: Int,
            $OrderCountry: Int,
            $ProjectStatusID: Int,
            $ContractSignDate: BEDate,
            $ContractStartDate: BEDate,
            $ContractEndDate: BEDate,
            $ReadinessStatusID: Int,
            $MYProject: Boolean,
            $MasterPlanID: Int,
            $IRR: Float,
            $DC: Float,
            $BC: Float,
            $NPV: Float,
            $ROI: Float,
            $Strategy: [StrategyData]!,
            $KPI: [KPIData]!,
            $Cashflow: InputCashflow,
            $CashflowAll: [InputCashflowAll],
            $IsPolicyProject: Int,
            $PolicyTypeID: Int,
            $PolicyDate: Date,
            $Description: String,
            $Files: [InputProjectImportantFile],
            $DelFiles: [InputProjectImportantFile],
            $ProjectImportantGroupID: Int,
            $ProjectImportantGroupSubID: Int,

            ############ Impact ############
            $ImpactEnvironment: Boolean,
            $ImpactPeople: Boolean,
            $ImpactSociety: Boolean,

            ############ Cancel Status ############
            $ProjectImportantStatusID: Int
            $IsCanceled: Int
            $CanceledDescription: String
        ) {
            UpdateProjectImportant (
                TProjectImportantID: $TProjectImportantID,
                ProjectImportantName: $ProjectImportantName,
                ProjectImportantCode: $ProjectImportantCode,
                Moo: $Moo,
                Village: $Village,
                SubdistrictID: $SubdistrictID,
                DistrictID: $DistrictID,
                ProvinceID: $ProvinceID,
                BasinID: $BasinID,
                SubbasinID: $SubbasinID,
                Lat: $Lat,
                Lng: $Lng,
                OrganizationID: $OrganizationID,
                BudgetYear: $BudgetYear,
                OperationStartYear: $OperationStartYear,
                OperationEndYear: $OperationEndYear,
                OperationStartMonth: $OperationStartMonth,
                OperationEndMonth: $OperationEndMonth,
                Duration: $Duration,
                BudgetSourceID: $BudgetSourceID,
                BudgetTypeID: $BudgetTypeID,
                ActivityID: $ActivityID,
                ProjectTypeID: $ProjectTypeID,
                AreaBaseID: $AreaBaseID,
                BudgetDimensionID: $BudgetDimensionID,
                RequestAmount: $RequestAmount,
                DraftAmount: $DraftAmount,
                ApproveAmount: $ApproveAmount,
                AllocateAmount: $AllocateAmount,
                TProjectImportantPrepare: $TProjectImportantPrepare,
                Remark: $Remark,
                CreatedDateTime: $CreatedDateTime,
                ModifiedDateTime: $ModifiedDateTime,
                CreatedUsername: $CreatedUsername,
                ModifiedUsername: $ModifiedUsername,
                OrderProject: $OrderProject,
                OrderProvince: $OrderProvince,
                OrderBasin: $OrderBasin,
                OrderCountry: $OrderCountry,
                ProjectStatusID: $ProjectStatusID,
                ContractSignDate: $ContractSignDate,
                ContractStartDate: $ContractStartDate,
                ContractEndDate: $ContractEndDate,
                ReadinessStatusID: $ReadinessStatusID,
                MYProject: $MYProject,
                MasterPlanID: $MasterPlanID,
                IRR: $IRR,
                DC: $DC,
                BC: $BC,
                NPV: $NPV,
                ROI: $ROI,
                Strategy: $Strategy,
                KPI: $KPI,
                Cashflow: $Cashflow,
                CashflowAll: $CashflowAll,
                IsPolicyProject: $IsPolicyProject,
                PolicyTypeID: $PolicyTypeID,
                PolicyDate: $PolicyDate,
                Description: $Description,
                Files: $Files,
                DelFiles: $DelFiles,
                ProjectImportantGroupID: $ProjectImportantGroupID,
                ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                ImpactEnvironment: $ImpactEnvironment,
                ImpactPeople: $ImpactPeople,
                ImpactSociety: $ImpactSociety,
                ProjectImportantStatusID: $ProjectImportantStatusID
                IsCanceled: $IsCanceled
                CanceledDescription: $CanceledDescription
            ) {
                TProjectImportantID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    uploadFileProjectImportant: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : [],
            TProjectImportantID: data.TProjectImportantID
        }


        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
            formData.append("Description", element.Description ?? "");
            formData.append("ProjectImportantPrepareID", element.ProjectImportantPrepareID ?? "");
        }
        formData.append("TProjectImportantID", data.TProjectImportantID);

        return Axios({
            method: "post",
            url: "/upload/projectimportant",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000
        });
    },

    UpdateIsLockProjectImportant: (TProjectImportantID) => {
        const UPDATEPROJECTISLOCKPROJECTIMPORTANT = `
            mutation UpdateIsLockProjectImportant($TProjectImportantID: Int!) {
                UpdateIsLockProjectImportant(TProjectImportantID: $TProjectImportantID)
            }
        `;
        return Axios.post("/twpservice", {
            query: UPDATEPROJECTISLOCKPROJECTIMPORTANT,
            variables: {
                TProjectImportantID: TProjectImportantID
            },
            errorPolicy: "all",
        });
    },

    deleteProjectImportant: (TProjectImportantID) => {

        const DELETEPROJECTIMPORTANT = `
            mutation DeleteProjectImportant (
                $TProjectImportantID: Int!
            ) {
                DeleteProjectImportant (
                    TProjectImportantID: $TProjectImportantID
                ) {
                    TProjectImportantID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEPROJECTIMPORTANT,
            variables: {
                TProjectImportantID: TProjectImportantID
            },
            errorPolicy: "all",
        });
    },

    ///////////////////// Milestone /////////////////////

    searchProjectImportantMilestone: (data) => {
        data = {
            ProjectImportantMilestoneID: data.ProjectImportantMilestoneID || undefined,
            ProjectImportantMilestoneName: data.ProjectImportantMilestoneName || undefined,
            TProjectImportantID: data.TProjectImportantID || undefined,
            IsPublic: data.IsPublic ?? undefined
        }

        const SEARCHPROJECTIMPORTANTMILESTONE = `
            query SearchProjectImportantMilestone (
                $ProjectImportantMilestoneID: Int,
                $ProjectImportantMilestoneName: String,
                $TProjectImportantID: Int,
                $IsPublic: Int
            ) {
                SearchProjectImportantMilestone (
                    ProjectImportantMilestoneID: $ProjectImportantMilestoneID,
                    ProjectImportantMilestoneName: $ProjectImportantMilestoneName,
                    TProjectImportantID: $TProjectImportantID,
                    IsPublic: $IsPublic
                ) {
                    ProjectImportantMilestoneID,
                    ProjectImportantMilestoneName,
                    ProjectImportantMilestoneDate,
                    TProjectImportantID,
                    IsPublic
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHPROJECTIMPORTANTMILESTONE,
            variables: data,
            errorPolicy: "all",
        });
    },

    createProjectImportantMilestone: (data) => {
        data = {
            ProjectImportantMilestoneName: data.ProjectImportantMilestoneName || undefined,
            ProjectImportantMilestoneDate: data.ProjectImportantMilestoneDate || undefined,
            TProjectImportantID: data.TProjectImportantID || undefined,
            IsPublic: data.IsPublic ?? undefined
        }

        const CREATEPROJECTIMPORTANTMILESTONE = `
            mutation CreateProjectImportantMilestone (
                $ProjectImportantMilestoneName: String,
                $ProjectImportantMilestoneDate: Date,
                $TProjectImportantID: Int,
                $IsPublic: Int
            ) {
                CreateProjectImportantMilestone (
                    ProjectImportantMilestoneName: $ProjectImportantMilestoneName,
                    ProjectImportantMilestoneDate: $ProjectImportantMilestoneDate,
                    TProjectImportantID: $TProjectImportantID,
                    IsPublic: $IsPublic
                ) {
                    ProjectImportantMilestoneID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: CREATEPROJECTIMPORTANTMILESTONE,
            variables: data,
            errorPolicy: "all",
        });
    },

    updateProjectImportantMilestone: (data) => {
        data = {
            ProjectImportantMilestoneID: data.ProjectImportantMilestoneID || undefined,
            ProjectImportantMilestoneName: data.ProjectImportantMilestoneName || undefined,
            ProjectImportantMilestoneDate: data.ProjectImportantMilestoneDate || undefined,
            TProjectImportantID: data.TProjectImportantID || undefined,
            IsPublic: data.IsPublic ?? undefined
        }

        const UPDATEPROJECTIMPORTANTMILESTONE = `
            mutation UpdateProjectImportantMilestone (
                $ProjectImportantMilestoneID: Int!,
                $ProjectImportantMilestoneName: String,
                $ProjectImportantMilestoneDate: Date,
                $TProjectImportantID: Int,
                $IsPublic: Int
            ) {
                UpdateProjectImportantMilestone (
                    ProjectImportantMilestoneID: $ProjectImportantMilestoneID,
                    ProjectImportantMilestoneName: $ProjectImportantMilestoneName,
                    ProjectImportantMilestoneDate: $ProjectImportantMilestoneDate,
                    TProjectImportantID: $TProjectImportantID,
                    IsPublic: $IsPublic
                ) {
                    ProjectImportantMilestoneID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEPROJECTIMPORTANTMILESTONE,
            variables: data,
            errorPolicy: "all",
        });
    },

    deleteProjectImportantMilestone: (ProjectImportantMilestoneID) => {
        let data = {
            ProjectImportantMilestoneID: ProjectImportantMilestoneID || undefined
        }

        const DELETEPROJECTIMPORTANTMILESTONE = `
            mutation DeleteProjectImportantMilestone (
                $ProjectImportantMilestoneID: Int!
            ) {
                DeleteProjectImportantMilestone (
                    ProjectImportantMilestoneID: $ProjectImportantMilestoneID
                ) {
                    ProjectImportantMilestoneID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEPROJECTIMPORTANTMILESTONE,
            variables: data,
            errorPolicy: "all",
        });
    },

    /////////////////// COMMENT //////////////////////

    getProjectImportantComment: (ProjectID) => {
        let data = {
            ProjectID: ProjectID
        }
        const GETPROJECTCOMMENT = `
            query GetProjectImportantComment(
                $ProjectID: Int!,
            ) {
                GetProjectImportantComment(
                    ProjectID: $ProjectID
                ) {
                    CommentID
                    ProjectID
                    Comment
                    CreatedDateTime
                    CreatedUsername
                    CreatedAccountID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },

    addProjectImportantComment: (data) => {
        data = {
            ProjectID: data.ProjectID || undefined,
            Comment: data.Comment || undefined,
        }
        const GETPROJECTCOMMENT = `
            mutation AddProjectImportantComment(
                $ProjectID: Int!,
                $Comment: String,
            ) {
                AddProjectImportantComment(
                    ProjectID: $ProjectID,
                    Comment: $Comment
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },

    updateProjectImportantComment: (data) => {
        data = {
            CommentID: data.CommentID || undefined,
            Comment: data.Comment || undefined,
        }
        const GETPROJECTCOMMENT = `
            mutation UpdateProjectImportantComment(
                $CommentID: Int!,
                $Comment: String,
            ) {
                UpdateProjectImportantComment(
                    CommentID: $CommentID,
                    Comment: $Comment
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },

    deleteProjectImportantComment: (data) => {
        data = {
            CommentID: data.CommentID || undefined,
        }
        const GETPROJECTCOMMENT = `
            mutation DeleteProjectImportantComment(
                $CommentID: Int!
            ) {
                DeleteProjectImportantComment(
                    CommentID: $CommentID,
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },
    ///////////////////// TAG /////////////////////
    getProjectImportantTag: (TProjectImportantID) => {
        let data = {
            TProjectImportantID: TProjectImportantID
        }
        const GETPROJECTCOMMENT = `
            query GetProjectImportantTag(
                $TProjectImportantID: Int!,
            ) {
                GetProjectImportantTag(
                    TProjectImportantID: $TProjectImportantID
                ) {
                    TagID
                    TagName
                    TProjectImportantID
                    CreatedDateTime
                    CreatedUsername
                    ModifiedUsername
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },
    addProjectImportantTag: (data) => {
        data = {
            TProjectImportantID: data.TProjectImportantID || undefined,
            TagID: data.TagID || undefined,
        }
        const GETPROJECTCOMMENT = `
            mutation AddProjectImportantTag(
                $TProjectImportantID: Int!,
                $TagID: Int!,
            ) {
                AddProjectImportantTag(
                    TProjectImportantID: $TProjectImportantID,
                    TagID: $TagID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },
    updateProjectImportantTag: (data) => {
        data = {
            TProjectImportantID: data.TProjectImportantID || undefined,
            TagID: data.TagID || undefined,
            OldTagID: data.OldTagID || undefined
        }
        const GETPROJECTCOMMENT = `
            mutation UpdateProjectImportantTag(
                $TProjectImportantID: Int!,
                $TagID:  Int!,
                $OldTagID:  Int!,
            ) {
                UpdateProjectImportantTag(
                    TProjectImportantID: $TProjectImportantID,
                    TagID: $TagID,
                    OldTagID: $OldTagID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTCOMMENT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },
    deleteProjectImportantTag: (data) => {
        data = {
            TProjectImportantID: data.TProjectImportantID || undefined,
            TagID: data.TagID || undefined,
        }
        const DELETEPROJECTIMPORTANTTAG = `
            mutation DeleteProjectImportantTag(
                $TProjectImportantID: Int!,
                $TagID: Int!
            ) {
                DeleteProjectImportantTag(
                    TProjectImportantID: $TProjectImportantID,
                    TagID: $TagID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEPROJECTIMPORTANTTAG,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    },

    ///////////////////// Dashboard /////////////////////

    getTProjectImportantRoadmap: (data) => {
        data = {
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            ProjectImportantCode: data.ProjectImportantCode ? data.ProjectImportantCode.trim() : undefined,
            OperationStartYear: data.OperationStartYear || undefined,
            OperationEndYear: data.OperationEndYear || undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            IsCanceled: data.IsCanceled,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
        }

        const GETTPROJECTIMPORTANTROADMAP = `
            query GetTProjectImportantRoadmap (
                $ProjectImportantName: String,
                $ProjectImportantCode: String,
                $OperationStartYear: [Int],
                $OperationEndYear: [Int],
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $IsCanceled: Int,
                $Offset: Int,
                $Limit: Int
            ) {
                GetTProjectImportantRoadmap (
                    ProjectImportantName: $ProjectImportantName
                    ProjectImportantCode: $ProjectImportantCode
                    OperationStartYear: $OperationStartYear
                    OperationEndYear: $OperationEndYear
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    IsCanceled: $IsCanceled
                    Offset: $Offset
                    Limit: $Limit
                ) {
                    Roadmap {
                        TProjectImportantID,
                        ProjectImportantName,
                        OrganizationID,
                        OrganizationName,
                        OperationStartYear,
                        OperationEndYear,
                        RequestAmount,
                        ProjectImportantPrepareName,
                        Remark,
                        Roadmap {
                            start,
                            end,
                            date,
                            y,
                            color,
                            name,
                            ParentID,
                            ProjectImportantMilestoneID,
                            IsPublic,
                        },
                        KPI,
                        Strategy
                    }
                    Count
                }
            }
        `;


        return Axios.post("/twpservice", {
            query: GETTPROJECTIMPORTANTROADMAP,
            variables: data,
            errorPolicy: "all",
        });
    },

    updateBudgetImportantYear: (data) => {

        data = {
            TProjectImportantID: data.TProjectImportantID ?? undefined,
            UpdateNextYear: data.UpdateNextYear ?? undefined
        }

        const UPDATEBUDGETIMPORTANTYEAR = `
            mutation UpdateBudgetImportantYear(
                $TProjectImportantID: Int!
                $UpdateNextYear: Boolean!
            ) {
                UpdateBudgetImportantYear(
                    TProjectImportantID: $TProjectImportantID,
                    UpdateNextYear: $UpdateNextYear
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEBUDGETIMPORTANTYEAR,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 1000 * 60
        });
    }
}

export default ProjectImportantService;