import Axios from './_BaseService';

const VersionService = {
    GetVersion: () => ({
        Version: "2.8.2",
        Build: "2023062501",
        BuildData: "2023062501",
    }),
    GetReleaseNote: () => {
        const GETRELEASENOTE = `
            query GetReleaseNote{
                GetReleaseNote {
                    Version,
                    Build,
                    Date,
                    Note
                }
            } 
        `;
        return Axios.post("/twpservice", {
            query: GETRELEASENOTE,
            errorPolicy: "all",
        })
    }

}

export default VersionService;