import React, { useState } from "react";
import { Box, Collapse, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CmtCardContent from "../CmtCard/CmtCardContent";
import CmtCardActions from "../CmtCard/CmtCardActions";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        zIndex: 1
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        padding: "0.6rem",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    wrapper: {
        display: "flex",
        width: '100%',
        userSelect: "none"
    },
    actionsComponentWrapper: {
        display: "flex",
        flex: "1 1",
        cursor: "pointer"
    }
}));

const CmtCardExpendableContent = ({
    actionsComponent,
    className,
    actionsClasses,
    contentClass,
    children,
    defaultExpanded = false,
    ...rest
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            <CmtCardActions className={actionsClasses} disableSpacing>
                <span className={classes.wrapper}>
                    <span
                        className={classes.actionsComponentWrapper}
                        onClick={handleExpandClick}
                    >
                        {actionsComponent}
                    </span>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </span>
            </CmtCardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CmtCardContent className={contentClass}>{children}</CmtCardContent>
            </Collapse>
        </Box>
    );
};

CmtCardExpendableContent.propTypes = {
    actionsComponent: PropTypes.node,
    // actionsClasses: PropTypes.string,
    contentClass: PropTypes.string
};

CmtCardExpendableContent.defaultProps = {
    // actionsClasses: {},
    contentClass: ""
};

export default CmtCardExpendableContent;
