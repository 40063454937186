import Axios from "./_BaseService";

const ReportService = {
    getBudgetSummary: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            Type: data.Type ?? undefined,
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            SubdistrictID: data.SubdistrictID?.length ? data.SubdistrictID : undefined,
            DistrictID: data.DistrictID?.length ? data.DistrictID : undefined,
            ProvinceID: data.ProvinceID?.length ? data.ProvinceID : undefined,
            SubbasinID: data.SubbasinID?.length ? data.SubbasinID : undefined,
            BasinID: data.BasinID?.length ? data.BasinID : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            DLAOrganizationID: data.DLAOrganizationID?.length ? data.DLAOrganizationID : undefined,
            OperatorOrganizationID: data.OperatorOrganizationID?.length ? data.OperatorOrganizationID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetTypeID: data.BudgetTypeID?.length ? data.BudgetTypeID : undefined,
            ActivityID: data.ActivityID?.length ? data.ActivityID : undefined,
            ProjectTypeID: data.ProjectTypeID?.length ? data.ProjectTypeID : undefined,
            AreaBaseID: data.AreaBaseID?.length ? data.AreaBaseID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            StrategyID: data.StrategyID?.length ? data.StrategyID : undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            ProjectStatusID: data.ProjectStatusID?.length ? data.ProjectStatusID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            SignoffID: data.SignoffID ?? undefined,
            SignoffStepID: data.SignoffStepID ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID?.length ? data.ReadinessStatusID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject || undefined,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined,
            ProjectCode: ProjectCode || undefined
        }
        const GetBudgetSummary = `
            query GetBudgetSummary(
                $Type: Int,
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ParentStrategyID: Int,
                $ProjectStatusID: [Int],
                $TagID: [Int],
                $SignoffID: [Int],
                $SignoffStepID: [Int],
                $ReadinessStatusID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $ProjectTypeAmount: Int,
                $ProjectCode: [String]
            ) {
                GetBudgetSummary(
                    Type: $Type,
                    BudgetYear: $BudgetYear,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    StrategyID: $StrategyID,
                    ParentStrategyID: $ParentStrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    TagID: $TagID,
                    SignoffID: $SignoffID,
                    SignoffStepID: $SignoffStepID,
                    ReadinessStatusID: $ReadinessStatusID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    MYProject: $MYProject,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    ProjectCode: $ProjectCode
                ){
                    Name
                    ID
                    ProjectCount
                    SumRequestAmount
                    SumDraftAmount
                    SumApproveAmount
                    SumAllocateAmount
                    BudgetYear
                    StrategyID
                    OrganizationID
                    SubGroup
                    BudgetDimensionID
                    ReadinessStatusID
                    Highlight
                    subHighlight
                    ParentID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GetBudgetSummary,
            variables: data,
            errorPolicy: "all",
        });
    },
    getBudgetSummaryMinistry: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            Type: data.Type ?? undefined,
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            SubdistrictID: data.SubdistrictID?.length ? data.SubdistrictID : undefined,
            DistrictID: data.DistrictID?.length ? data.DistrictID : undefined,
            ProvinceID: data.ProvinceID?.length ? data.ProvinceID : undefined,
            SubbasinID: data.SubbasinID?.length ? data.SubbasinID : undefined,
            BasinID: data.BasinID?.length ? data.BasinID : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            DLAOrganizationID: data.DLAOrganizationID?.length ? data.DLAOrganizationID : undefined,
            OperatorOrganizationID: data.OperatorOrganizationID?.length ? data.OperatorOrganizationID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetTypeID: data.BudgetTypeID?.length ? data.BudgetTypeID : undefined,
            ActivityID: data.ActivityID?.length ? data.ActivityID : undefined,
            ProjectTypeID: data.ProjectTypeID?.length ? data.ProjectTypeID : undefined,
            AreaBaseID: data.AreaBaseID?.length ? data.AreaBaseID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            StrategyID: data.StrategyID?.length ? data.StrategyID : undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            ProjectStatusID: data.ProjectStatusID?.length ? data.ProjectStatusID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            SignoffID: data.SignoffID ?? undefined,
            SignoffStepID: data.SignoffStepID ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID?.length ? data.ReadinessStatusID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject || undefined,
            IsMinistry: true,
            ReportID: data.ReportID ?? 1,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined,
            ProjectCode: ProjectCode || undefined
        }
        const GetBudgetSummary = `
            query GetBudgetSummary(
                $Type: Int
                $BudgetYear: [Int]
                $SubdistrictID: [Int]
                $DistrictID: [Int]
                $ProvinceID: [Int]
                $SubbasinID: [Int]
                $BasinID: [Int]
                $OrganizationID: [Int]
                $DLAOrganizationID: [Int]
                $OperatorOrganizationID: [Int]
                $BudgetSourceID: [Int]
                $BudgetTypeID: [Int]
                $ActivityID: [Int]
                $ProjectTypeID: [Int]
                $AreaBaseID: [Int]
                $BudgetDimensionID: [Int]
                $StrategyID: [Int]
                $ParentStrategyID: Int
                $ProjectStatusID: [Int]
                $TagID: [Int]
                $SignoffID: [Int],
                $SignoffStepID: [Int],
                $ReadinessStatusID: [Int],
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $MYProject: Boolean
                $IsMinistry: Boolean
                $ReportID: Int
                $ProjectTypeAmount: Int
                $ProjectCode: [String]
            ) {
                GetBudgetSummary(
                    Type: $Type
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    DLAOrganizationID: $DLAOrganizationID
                    OperatorOrganizationID: $OperatorOrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ParentStrategyID: $ParentStrategyID,
                    ProjectStatusID: $ProjectStatusID
                    TagID: $TagID
                    SignoffID: $SignoffID
                    SignoffStepID: $SignoffStepID
                    ReadinessStatusID: $ReadinessStatusID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsMinistry: $IsMinistry
                    ReportID: $ReportID
                    ProjectTypeAmount: $ProjectTypeAmount
                    ProjectCode: $ProjectCode
                ){
                    Name
                    ID
                    ProjectCount
                    SumRequestAmount
                    SumDraftAmount
                    SumApproveAmount
                    SumAllocateAmount
                    BudgetYear
                    StrategyID
                    OrganizationID
                    SubGroup
                    BudgetDimensionID
                    ReadinessStatusID
                    Highlight
                    subHighlight
                    ParentID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GetBudgetSummary,
            variables: data,
            errorPolicy: "all",
        });
    },
    getDefaultFilterReportMinistry: (ReportID) => {
        const GetDefaultFilterReportMinistry = `
            query GetDefaultFilterReportMinistry (
                $ReportID: Int!
            ) {
                GetDefaultFilterReportMinistry(
                    ReportID: $ReportID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: GetDefaultFilterReportMinistry,
            variables: {
                ReportID: ReportID
            },
            errorPolicy: "all",
        });
    }
}
export default ReportService;