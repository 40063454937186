import cookie from 'js-cookie';

export const setUsersCookies = (Type, Data) => {
    cookie.set(Type, Data, { expires: 14 });
}

export const getUsersCookies = (type) => {
    return cookie.get(type);
}

export const removeAllUsersCookies = () => {
    Object.keys(cookie.get()).forEach(function (obj) {
        cookie.remove(obj);
    });
}