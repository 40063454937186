import React from "react";
import { AppBar, Toolbar, Box, Container, Button, Grid, Typography } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import SplitButton from '../../../Util/SplitButton';
import LoginTrainingModal from './LoginTrainingModal';
import RegisterTrainingModal from './RegisterTrainingModal';
import ImportantModal from '../ImportantModal';
import VideoModal from '../VideoModal';
import { connect } from "react-redux";
import { hideMenu, showMenu, showLoading, hideLoading } from "../../../redux/action";
import { setLocalStorage } from "../../../Service/_LocalStorageService";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

class LandingPageTraining extends React.Component {
    state = {
        showModal: false,
        showVideoModal: false,
        showModalImportant: false,
        showModalRegister: false
    }
    timeoutModal = null;

    componentDidMount() {
        this.props.dispatch(hideMenu());
        this.timeoutModal = setTimeout(() => {
            this.setState({ showModalImportant: false });
            setLocalStorage("showImportantMessage", "");
        }, 30000);
    }

    componentWillUnmount() {
        if (this.timeoutModal) {
            clearTimeout(this.timeoutModal);
            setLocalStorage("showImportantMessage", "");
        }
        this.props.dispatch(showMenu());
    }

    setShowModalLogin = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    setShowModalRegister = () => {
        this.setState({ showModalRegister: !this.state.showModalRegister });
    }

    setShowModalImportant = () => {
        this.setState({ showModalImportant: false });
        setLocalStorage("showImportantMessage", "");
    }

    setShowVideoModal = () => {
        this.setState({ showVideoModal: !this.state.showVideoModal });
    }

    buttonOptions = [
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                คู่มือการจัดทำแผนปฏิบัติการด้านทรัพยากรน้ำ ผ่าน TWP
            </Box>,
            onClick: () => window.open("/twp/v2/files/twp_manual_full.pdf", '_blank')
        },
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                คู่มือการใช้งาน TWP Dashboard
            </Box>,
            onClick: () => window.open("/twp/v2/files/twp_manual_dashboard.pdf", '_blank')
        },
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                แบบฟอร์ม สทนช 001
            </Box>,
            onClick: () => window.open("/twp/v2/files/แบบฟอร์ม_สทนช_001.xlsx", '_blank')
        }
    ];

    render() {
        return (
            <div className="page-wrapper">
                <AppBar className="header" position="static" color="transparent">
                    <Container maxWidth="lg" style={{ position: "relative" }}>
                        <Toolbar>
                            <Box flexGrow={1}>
                                <img className="logo" src="/images/logo-black.png" style={{ maxWidth: "initial" }} alt="logo black" />
                            </Box>
                            <Box display={"flex"} alignItems="center" flexWrap={"wrap"}>
                                <Button
                                    onClick={() => window.open("/twp/v2/files/twp_budget_calendar.pdf", '_blank')}
                                >
                                    ปฎิทินการจัดทำงบประมาณ
                                </Button>
                                <a href="/about/">
                                    <Button>
                                        เกี่ยวกับ TWP
                                    </Button>
                                </a>
                                <SplitButton
                                    className="custom-button-navbar"
                                    btnStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                                    style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                                    text='คู่มือใช้งาน/แบบฟอร์ม'
                                    options={this.buttonOptions}
                                />
                                <Button
                                    onClick={() => {
                                        document.body.click();
                                        this.setShowVideoModal();
                                    }}
                                >
                                    VDO การใช้งาน
                                </Button>
                                <Button onClick={() => window.open("https://twp.onwr.go.th/public_dashboard.php", '_blank')}>
                                    TWP Dashboard
                                </Button>
                                {
                                    this.props.isAuth ?
                                        <Button
                                            onClick={() => this.props.history.push("/home")}
                                            size="large"
                                            color="primary"
                                            className="ml-05"
                                            variant="contained"
                                        >
                                            <Typography style={{ fontWeight: "bolder" }}>
                                                Home
                                            </Typography>
                                        </Button>
                                        :
                                        <Button
                                            onClick={this.setShowModalLogin}
                                            size="large"
                                            color="primary"
                                            className="ml-05"
                                            variant="contained"
                                        >
                                            <Typography style={{ fontWeight: "bolder" }}>
                                                เข้าสู่ระบบ
                                            </Typography>
                                        </Button>
                                }
                                <Button
                                    onClick={this.setShowModalRegister}
                                    size="large"
                                    className="ml-05"
                                    variant="contained"
                                    style={{ backgroundColor: "#66bb6a", color: "white" }}
                                >
                                    <Typography style={{ fontWeight: "bolder" }}>
                                        ลงทะเบียน
                                    </Typography>
                                </Button>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Container className="content" maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid className="box-title" item xs={12}>
                            <Typography className="title-main" variant="h1">
                                TWP
                            </Typography>
                            <Typography className="title-main" variant="h2" gutterBottom style={{ fontSize: "2rem" }}>
                                Training Platform
                            </Typography>
                        </Grid>
                        <Grid className="box-title my-20" item xs={12} md={6} style={{ height: "340px" }}>
                            <div style={{ backgroundColor: "rgb(245, 245, 245)" }}>
                                <Typography className="title-second pt-10 pl-10" variant="h2" gutterBottom style={{ fontWeight: 'normal' }}>
                                    ข้อกำหนดการใช้งานระบบ TWP Training
                                </Typography>
                                <Typography variant="h4" className="pl-10">
                                    <div className="training-spec">
                                        <ArrowRightIcon /> ระบบเพื่อใช้สำหรับการ Training ข้อมูลการสร้างแผนจะไม่ถูกบันทึกในระบบใช้งานจริง
                                    </div>
                                    <div className="training-spec">
                                        <ArrowRightIcon />เฉพาะวันที่ลงทะเบียนไว้เท่านั้น ข้อมูลการสร้างแผนจะถูกบันทึกตามระยะการจอง
                                    </div>
                                    <div className="training-spec">
                                        <ArrowRightIcon />เฉพาะผู้ที่ได้รับอนุมัติการลงทะเบียนแล้วเท่านั้น
                                    </div>
                                    <div className="training-spec pb-10">
                                        <ArrowRightIcon /><span style={{ color: "red" }}>ห้ามใช้ประโยชน์ในเชิงพาณิชย์</span>
                                    </div>
                                </Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display={{ xs: "none", md: "block" }}>
                                <img style={{ maxHeight: '638px', maxWidth: 'initial', marginLeft: '-218px', marginTop: '-100px' }} src="/images/hero-2-img_training.png" alt="twp-platform" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={3}
                    >
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Box className="box-footer">
                                <div>© 2017-2022 สำนักงานทรัพยากรน้ำแห่งชาติ</div>
                                <div>อาคารจุฑามาศ เลขที 89/168-170 ถนนวิภาวดีรังสิต</div>
                                <div>แขวงตลาดบางเขน เขตหลักสี่ กรุงเทพฯ 10210</div>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                {
                    this.props.isAuth ?
                        null
                        :
                        <LoginTrainingModal
                            show={this.state.showModal}
                            setShow={this.setShowModalLogin}
                            history={this.props.history}
                            dispatch={this.props.dispatch}
                            setMasterData={this.props.setMasterData}
                            masterData={this.props.masterData}
                            showLoading={() => this.props.dispatch(showLoading())}
                            hideLoading={() => this.props.dispatch(hideLoading())}
                        />
                }
                <RegisterTrainingModal
                    show={this.state.showModalRegister}
                    setShow={this.setShowModalRegister}
                    trainingDays={this.props.trainingDays}
                />
                <ImportantModal
                    show={this.state.showModalImportant}
                    setShow={this.setShowModalImportant}
                />
                <VideoModal
                    show={this.state.showVideoModal}
                    setShow={this.setShowVideoModal}
                />
            </div>
        );
    }
};

const mapStatetoProps = (state) => ({
    isAuth: state.isAuth,
    masterData: state.masterData,
    trainingDays: state.trainingDays
});

export default connect(mapStatetoProps)(LandingPageTraining);
