import Axios from './_BaseService';

const HeadlinesService = {
    insertHeadlines: (data) => {
        data = {
            Content: data.content || "",
            BackgroundColor: data.backgroundColor || "",
            TagColor: data.tagColor || ""
        };

        const INSERTHEADLINES = `
        mutation InsertHeadlines (
            $Content: String!,
            $BackgroundColor: String!,
            $TagColor: String!
        ) {
            InsertHeadlines (
                Content: $Content,
                BackgroundColor: $BackgroundColor,
                TagColor: $TagColor     
            ){
                ID,
                Content,
                BackgroundColor,
                TagColor
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: INSERTHEADLINES,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    updateHeadlines: (data) => {
        data = {
            ID: data.id || undefined,
            Content: data.content || "",
            BackgroundColor: data.backgroundColor || "",
            TagColor: data.tagColor || ""
        };

        const UPDATEHEADLINES = `
        mutation UpdateHeadlines (
            $ID: Int!,
            $Content: String!,
            $BackgroundColor: String!,
            $TagColor: String!
        ) {
            UpdateHeadlines (
                ID: $ID,
                Content: $Content,
                BackgroundColor: $BackgroundColor,
                TagColor: $TagColor     
            ){
                ID,
                Content,
                BackgroundColor,
                TagColor
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEHEADLINES,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    deleteHeadlines: (HeadlinesID) => {
        const DELETEHEADLINES = `
        mutation DeleteHeadlines (
            $ID: Int!
        ) {
            DeleteHeadlines (
                ID: $ID    
            ){
                ID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: DELETEHEADLINES,
            variables: {
                ID: HeadlinesID
            },
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    }

}

export default HeadlinesService;