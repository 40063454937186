import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';

const SplitButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                style={props.style}
                className={props.className}
            >
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    color={props.color}
                    className={props.btnClassName}
                    disabled={props.disabled}
                    style={props.btnStyle}
                >
                    {props.icon ? props.icon : null}
                    {props.text}
                    {props.dropdownIcon ?? <ArrowDropDownIcon />}
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                        option.name === 'Divider' ?
                                            <Divider /> :
                                            <MenuItem
                                                key={'option' + index}
                                                onClick={
                                                    props.data ?
                                                        () => option.onClick(props.data)
                                                        : option.onClick
                                                }
                                            >
                                                {option.name}
                                            </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default React.memo(
    SplitButton,
    (prevProps, nextProps) =>
    (
        prevProps.options === nextProps.options &&
        prevProps.disabled === nextProps.disabled
    )
);