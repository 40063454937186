import axios from 'axios';
import store from '../redux';
import { setAccountLogin, setAccountLogout, setMasterData, showLoading, hideLoading } from '../redux/action';
import AccountService from './AccountService';
// import { removeAllUsersCookies, setUsersCookies, getUsersCookies } from './_CookieService';
import { setLocalStorage, removeLocalStorage, getLocalStorage, removeUserToken } from './_LocalStorageService';
import Swal from 'sweetalert2';
import VersionService from './_VersionService';
import CoreService from './CoreService';
import Utilities from '../Util/Utilities';

const version = VersionService.GetVersion();

const baseURL = ((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin) + "/twp/v2/";
// const baseURL = (process.env.NODE_ENV === 'development') ? 'http://test1.klickerlab.com:9211' : window.location.origin;
let AlertTimeout = null;
let forcedReloaded = false;

const setMasterDataApp = async () => {
    let Promises = [];
    let masterData = getLocalStorage("masterData") || null;

    if (!masterData) Promises.push(CoreService.getAllMasterData());
    else masterData = JSON.parse(masterData);

    Promises.push(CoreService.getPermissionMasterData());
    // let data = {};
    store.dispatch(showLoading());
    return Promise.all(Promises)
        .then(res => {
            let data = {};
            for (let i = 0; i < res.length; i++) {
                const element = res[i];
                if (!element.data || element.errors) {
                    store.dispatch(setAccountLogout());
                    AccountService.Logout();
                    removeLocalStorage("masterData");
                    removeUserToken();
                    // removeAllUsersCookies();
                    Utilities.clearNavigationMenu();
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'ไม่สามารถทำรายการได้',
                        icon: 'error'
                    });
                    return;
                }
                data = { ...data, ...element.data };
            }
            if (!masterData) {
                let element = res[0];
                if (!element.data || element.errors) {
                    store.dispatch(setAccountLogout());
                    AccountService.Logout();
                    removeLocalStorage("masterData");
                    removeUserToken();
                    // removeAllUsersCookies();
                    Utilities.clearNavigationMenu();
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'ไม่สามารถทำรายการได้',
                        icon: 'error'
                    });
                    return;
                }
                removeLocalStorage("masterData");
                try {
                    setLocalStorage("masterData", element.data);
                }
                catch (e) {
                    console.error(e);
                }
            }
            else data = { ...data, ...masterData };
            store.dispatch(setMasterData(data));
        })
        .catch(e => {
            console.error(e);
            store.dispatch(setAccountLogout());
            AccountService.Logout();
            removeLocalStorage("masterData");
            removeUserToken();
            // removeAllUsersCookies();
            Utilities.clearNavigationMenu();
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถทำรายการได้',
                icon: 'error'
            });
        })
        .finally(() => {
            store.dispatch(hideLoading());
        })
}

const Axios = axios.create({
    baseURL: baseURL
});

Axios.defaults.timeout = 1000 * 60;

Axios.interceptors.request.use((request) => {
    // console.log(store.getState().sessionToken);
    request.headers.Authorization = store.getState().sessionToken;
    if (process.env.NODE_ENV !== 'development') {
        request.headers.version = version.Version;
        request.headers.build = version.Build;
    }
    return request;
});

Axios.interceptors.response.use(
    (response) => {
        if (
            (response.headers.version && response.headers.build) &&
            (
                response.headers.version !== version.Version ||
                response.headers.build !== version.Build
            )
        ) {
            if (forcedReloaded) return;
            forcedReloaded = true;
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                Swal.fire({
                    icon: 'warning',
                    text: 'เวอร์ชันของระบบไม่ตรง จะทำการ reload ใหม่',
                    title: 'เวอร์ชันของระบบไม่ตรง'
                })
                    .then(() => {
                        window.location.reload();
                    })
            }, 500);
            // else if (response.headers.builddata && response.headers.builddata !== version.BuildData) {
            //     AccountService.getAccountToken(getUsersCookies('sessionToken'))
            //         .then(res => {
            //             if (!res?.data?.GetAccountByToken || res.errors) {
            //                 store.dispatch(setAccountLogout());
            //                 AccountService.Logout();
            //                 removeLocalStorage("masterData");
            //                 removeUserToken();
            //                 removeAllUsersCookies();
            //                 Utilities.clearNavigationMenu();
            //                 return;
            //             }
            //             setUsersCookies('sessionToken', res.data.GetAccountByToken.SessionToken);
            //             store.dispatch(setAccountLogin(res.data.GetAccountByToken));
            //             setMasterDataApp();
            //         })
            //         .catch(e => {
            //             console.log(e);
            //             store.dispatch(setAccountLogout());
            //             AccountService.Logout();
            //             removeLocalStorage("masterData");
            //             removeUserToken();
            //             removeAllUsersCookies();
            //             Utilities.clearNavigationMenu();
            //         })
            // }
        }
        else if (response.data?.errors?.[0]?.Code === -4100) {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                Swal.fire({
                    icon: 'error',
                    text: response.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                    title: 'กรุณาล็อคอินใหม่'
                })
                    .then(() => {
                        store.dispatch(setAccountLogout());
                        AccountService.Logout();
                        removeLocalStorage("masterData");
                        removeUserToken();
                        // removeAllUsersCookies();
                        Utilities.clearNavigationMenu();
                    })
            }, 500);
        }
        else if (response.data?.errors?.[0]?.Code === -4101) {
            clearTimeout(AlertTimeout);
            Swal.close();
            AlertTimeout = setTimeout(function () {
                store.dispatch(setAccountLogout());
                AccountService.Logout();
                removeLocalStorage("masterData");
                removeUserToken();
                // Swal.fire({
                //     icon: 'error',
                //     text: response.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                //     title: 'กรุณาล็อคอินใหม่'
                // })
                //     .then(() => {
                //         store.dispatch(setAccountLogout());
                //         AccountService.Logout();
                //         removeLocalStorage("masterData");
                //         removeUserToken();
                //     })
            }, 500);
        }
        return response.data;
    },
    (err) => {
        if (err.response) {
            if (
                (err.response.headers?.version && err.response.headers?.build) &&
                (
                    err.response.headers?.version !== version.Version ||
                    err.response.headers?.build !== version.Build
                )
            ) {
                if (forcedReloaded) return;
                forcedReloaded = true;
                clearTimeout(AlertTimeout);
                AlertTimeout = setTimeout(function () {
                    Swal.fire({
                        icon: 'warning',
                        text: 'เวอร์ชันของระบบไม่ตรง จะทำการ reload ใหม่',
                        title: 'เวอร์ชันของระบบไม่ตรง'
                    })
                        .then(() => {
                            window.location.reload();
                        })
                }, 500);
                // else if (err.response.headers?.builddata && err.response.headers?.builddata !== version.BuildData) {
                //     AccountService.getAccountToken(getUsersCookies('sessionToken'))
                //         .then(res => {
                //             if (!res?.data?.GetAccountByToken || res.errors) {
                //                 store.dispatch(setAccountLogout());
                //                 AccountService.Logout();
                //                 removeLocalStorage("masterData");
                //                 removeUserToken();
                //                 removeAllUsersCookies();
                //                 Utilities.clearNavigationMenu();
                //                 return;
                //             }
                //             setUsersCookies('sessionToken', res.data.GetAccountByToken.SessionToken);
                //             store.dispatch(setAccountLogin(res.data.GetAccountByToken));
                //             setMasterDataApp();
                //         })
                //         .catch(e => {
                //             console.log(e);
                //             store.dispatch(setAccountLogout());
                //             AccountService.Logout();
                //             removeLocalStorage("masterData");
                //             removeUserToken();
                //         })
                // }
            }
            else if (err.response.status === 404) {
                return Promise.reject(err);
            }
            else if (err?.response?.data?.errors?.[0]?.Code === -4100) {
                clearTimeout(AlertTimeout);
                AlertTimeout = setTimeout(function () {
                    Swal.fire({
                        icon: 'error',
                        title: err?.response?.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                        text: 'กรุณาล็อคอินใหม่'
                    })
                        .then(() => {
                            store.dispatch(setAccountLogout());
                            AccountService.Logout();
                            removeLocalStorage("masterData");
                            removeUserToken();
                            // removeAllUsersCookies();
                            Utilities.clearNavigationMenu();
                        })
                }, 500);
            }
            else if (err?.response?.data?.errors?.[0]?.Code === -4101) {
                clearTimeout(AlertTimeout);
                Swal.close();
                AlertTimeout = setTimeout(function () {
                    store.dispatch(setAccountLogout());
                    AccountService.Logout();
                    removeLocalStorage("masterData");
                    removeUserToken();
                    // Swal.fire({
                    //     icon: 'error',
                    //     text: err?.response?.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                    //     title: 'กรุณาล็อคอินใหม่'
                    // })
                    //     .then(() => {
                    //         store.dispatch(setAccountLogout());
                    //         AccountService.Logout();
                    //         removeLocalStorage("masterData");
                    //         removeUserToken();
                    //     })
                }, 500);
            }
        }
        else if (err.message === 'Network Error') {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                if (Swal.isVisible()) {
                    Swal.showLoading();
                    Swal.update({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    Swal.hideLoading();
                }
                else
                    Swal.fire({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    })
            }, 500);
        }
        else {
            // Swal.fire({
            //     type: 'error',
            //     title: 'เกิดข้อผิดพลาด',
            //     text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
            // })
        }
        return Promise.reject(err);
    }
);

export default Axios;