import React from "react";
import useStyles from "./BaseItem.style";
import Box from "@material-ui/core/Box";
import BaseItem from "./BaseItem";

const DefaultItem = ({ item }) => {
	const classes = useStyles();

	const getTitle = () => {
		return (
			<Box className={classes.titleRoot}>
				<Box component="span" color="primary.main">
					{item.Title}
				</Box>
			</Box>
		);
	};

	return (
		<React.Fragment>
			<BaseItem item={item} title={getTitle()}/>
		</React.Fragment>
	);
};

export default DefaultItem;
