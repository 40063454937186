import React, { useState, useCallback } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import store from '../redux';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 13.673811,
    lng: 101.069368
};

function MapComponent(props) {
    const apiKeys = store?.getState?.()?.account?.APIKeys?.GoogleMaps ?? undefined;
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        language: "th",
        googleMapsApiKey: process.env.NODE_ENV === "development" ? undefined : apiKeys,
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => {
        setMap(map);
        map.setZoom(7);
        if (props.setMap) props.setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null)
    }, []);

    let option = {
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: false,
    };
    if (props.option) {
        option = { ...option, ...props.option };
    }
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            options={option}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {props.children}
        </GoogleMap>
    ) :
        <></>
}

export default React.memo(MapComponent)