import Axios from "./_BaseService";

const ReportProjectImportantService = {
    GetProjectImportantGroupReport: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined
        }
        const GETPROJECTIMPORTANTGROUPREPORT = `
            query 
                GetProjectImportantGroupReport(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int]
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int
                ) {
                    GetProjectImportantGroupReport(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTIMPORTANTGROUPREPORT,
            variables: data,
            errorPolicy: "all",
        });
    },
    GetProjectImportantPrepare: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined
        }
        const GETPROJECTIMPORTANTPREARE = `
            query 
            GetProjectImportantPrepare(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int
                ) {
                    GetProjectImportantPrepare(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID
                        ProjectImportantStatusID: $ProjectImportantStatusID
                        IsCanceled: $IsCanceled
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTIMPORTANTPREARE,
            variables: data,
            errorPolicy: "all",
        });
    },
    SearchProjectImportantGroupReportData: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined,
            Offset: data.Offset,
            Limit: data.Limit,
        }
        const SEARCHPROJECTIMPORTANTPREPAREDATA = `
            query 
            SearchProjectImportantGroupReportData(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int,
                    $Offset: Int,
                    $Limit: Int
                ) {
                    SearchProjectImportantGroupReportData(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled,
                        Offset: $Offset,
                        Limit: $Limit
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHPROJECTIMPORTANTPREPAREDATA,
            variables: data,
            errorPolicy: "all",
        });
    },
    SearchProjectImportantPrepareData: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined,
            Offset: data.OffsetPrepare,
            Limit: data.Limitprepare,
        }
        const SEARCHPROJECTIMPORTANTPREPAREDATA = `
            query 
            SearchProjectImportantPrepareData(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int,
                    $Offset: Int,
                    $Limit: Int
                ) {
                    SearchProjectImportantPrepareData(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled,
                        Offset: $Offset,
                        Limit: $Limit
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHPROJECTIMPORTANTPREPAREDATA,
            variables: data,
            errorPolicy: "all",
        });
    },
    GetProjectImportantGroupTagReport: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined
        }
        const GETPROJECTIMPORTANTGROUPTAGREPORT = `
            query 
            GetProjectImportantGroupTagReport(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $TagID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int
                ) {
                    GetProjectImportantGroupTagReport(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        TagID: $TagID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTIMPORTANTGROUPTAGREPORT,
            variables: data,
            errorPolicy: "all",
        });
    },
    SearchProjectImportantGroupTagReportData: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined,
            Offset: data.Offset,
            Limit: data.Limit,
        }
        const SearchProjectImportantGroupTagReportData = `
            query 
            SearchProjectImportantGroupTagReportData(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $TagID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int,
                    $Offset: Int,
                    $Limit: Int
                ) {
                    SearchProjectImportantGroupTagReportData(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        TagID: $TagID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled,
                        Offset: $Offset,
                        Limit: $Limit
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: SearchProjectImportantGroupTagReportData,
            variables: data,
            errorPolicy: "all",
        });
    },
    GetProjectImportantGroupTagReportTree: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined
        }
        const GETPROJECTIMPORTANTGROUPTAGREPORTTREE = `
            query 
            GetProjectImportantGroupTagReportTree(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $TagID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int
                ) {
                    GetProjectImportantGroupTagReportTree(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        TagID: $TagID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTIMPORTANTGROUPTAGREPORTTREE,
            variables: data,
            errorPolicy: "all",
        });
    },
    SearchProjectImportantGroupTagReportTreeDataMapping: (data) => {
        data = {
            StartYear: data.StartYear?.length ? data.StartYear : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID?.length ? data.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID?.length ? data.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            IsCanceled: data.IsCanceled === 0 ? 0 : data.IsCanceled === 1 ? 1 : undefined,
            Offset: data.Offset,
            Limit: data.Limit,
        }
        const SEARCHPROJECTIMPORTANTGROUPREPORTTREEDATA = `
            query 
            SearchProjectImportantGroupTagReportTreeData(
                    $StartYear: [Int],
                    $OrganizationID: [Int],
                    $ProjectImportantGroupID: [Int],
                    $ProjectImportantGroupSubID: [Int],
                    $BudgetSourceID: [Int],
                    $BudgetDimensionID: [Int],
                    $TagID: [Int],
                    $ProjectImportantStatusID: [Int],
                    $IsCanceled: Int,
                    $Offset: Int,
                    $Limit: Int
                ) {
                    SearchProjectImportantGroupTagReportTreeData(
                        StartYear: $StartYear,
                        OrganizationID: $OrganizationID,
                        ProjectImportantGroupID: $ProjectImportantGroupID,
                        ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                        BudgetSourceID: $BudgetSourceID,
                        BudgetDimensionID: $BudgetDimensionID,
                        TagID: $TagID,
                        ProjectImportantStatusID: $ProjectImportantStatusID,
                        IsCanceled: $IsCanceled,
                        Offset: $Offset,
                        Limit: $Limit
                    )
                }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHPROJECTIMPORTANTGROUPREPORTTREEDATA,
            variables: data,
            errorPolicy: "all",
        });
    },
}

export default ReportProjectImportantService;