
import React, { useState } from 'react';
import {
    Box,
    Grow,
    IconButton,
    Slide,
    Tabs,
    Tab,
    Grid,
    Tooltip,
} from '@material-ui/core';
import {
    Close,
    Equalizer,
    FiberManualRecord
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import BarChart from '../../Util/ChartBarScroll';
import PieChart from '../../Util/ChartPie';
import Select from '../../Util/Select';
import Utilities from '../../Util/Utilities';

const StrategyColor = [
    "#7cb5ec",
    "#434348",
    "#90ed7d",
    "#f7a35c",
    "#8085e9",
    "#f15c80",
    "#e4d354",
    "#2b908f",
    "#f45b5b",
    "#91e8e1"
];

const strategyDataName = [
    "",
    "SumRequestAmount",
    "SumDraftAmount",
    "SumApproveAmount"
]

const MapReport = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedProjectStatus, setSelectedProjectStatus] = useState(1);
    const {
        showReport,
        setShowReport,
        reportData,
        masterData
    } = props;

    let barData = [];
    let chartData = [];

    if (reportData?.StrategySummaryData) {
        chartData = [
            {
                "type": "pie",
                "name": "ลักษณะงาน",
                "data": reportData.StrategySummaryData.filter(x => x.ID !== 9999).map((element) => [element.Name, element[strategyDataName[selectedProjectStatus]]]) ?? [],
                "color": "#1776c3"
            }
        ];
    }

    if (reportData?.ProjectTypeSummaryData) {
        barData = [
            {
                "type": "column",
                "name": "ลักษณะงาน",
                "data": reportData.ProjectTypeSummaryData.filter(x => x.ID !== 9999).map((element) => [element.Name, element[strategyDataName[selectedProjectStatus]]]) ?? [],
                "color": "#1776c3"
            }
        ];
    }

    // const ProjectTypeSummaryData = [
    //     {
    //         "type": "column",
    //         "name": "ลักษณะงาน",
    //         "data": reportData?.ProjectTypeSummaryData?.map((element) => [element.Name, element.ProjectCount]) ?? [],
    //         "color": "#1776c3"
    //     }
    // ];

    const StrategySummaryData = [
        {
            "type": "pie",
            "name": "ลักษณะงาน",
            "data": reportData?.StrategySummaryData?.map((element) => [element.Name, element[strategyDataName[selectedProjectStatus]]]) ?? [],
            "color": "#1776c3"
        }
    ];

    return (
        <Grow in={showReport} className="map-filter">
            <CmtCard style={{ maxWidth: "650px" }}>
                <div className="section-header main d-flex align-items-center py-05 px-10 mb-02">
                    <Equalizer className="mr-05" />
                    <h4>สรุปรายงาน</h4>
                    <IconButton className="ml-auto p-02 text-default" onClick={setShowReport}>
                        <Close />
                    </IconButton>
                </div>
                <hr className="hr-light" />
                <CmtCardContent>
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, index) => setSelectedTab(index)}
                        centered
                    >
                        <Tab
                            label={
                                <Box display="flex" alignContent="center">
                                    {/* <TrackChanges className="mr-02" /> */}
                                    ตามลักษณะงาน
                                </Box>
                            }
                        />
                        <Tab
                            label={
                                <Box display="flex" alignContent="center">
                                    {/* <Layers className="mr-02" /> */}
                                    ตามแผนแม่บทฯน้ำ 20 ปี
                                </Box>
                            }
                        />
                    </Tabs>
                    <Slide in={(selectedTab === 0)} direction="left">
                        <div
                            className="overflowY-auto overflowX-hidden"
                            style={{
                                height: "400px",
                                maxHeight: "400px",
                                width: "100%",
                                display: (selectedTab === 0) ? "" : "none"
                            }}
                        >
                            <BarChart
                                data={barData ?? []}
                                typeName={"ลักษณะงาน"}
                                unit="ลบ."
                                title=""
                            />
                        </div>
                    </Slide>
                    <Slide in={(selectedTab === 1)} direction="right">
                        <div
                            className="overflowY-auto overflowX-hidden"
                            style={{
                                height: "400px",
                                maxHeight: "400px",
                                width: "100%",
                                display: (selectedTab === 1) ? "" : "none"
                            }}
                        >
                            {/* <Box display="flex" marginTop={4} marginBottom={2}>
                                <Grid item xs={6} />
                                <Grid item xs={6}>
                                    <Select
                                        label="สถานะงบประมาณ"
                                        value={selectedProjectStatus ?? []}
                                        dataname='ProjectStatus'
                                        name='ProjectStatusID'
                                        dataobjectname="ProjectStatusID"
                                        setvalue={(value) => setSelectedProjectStatus(value)}
                                        data={masterData.GetProjectStatus}
                                        noDefault
                                    />
                                </Grid>
                            </Box> */}
                            <PieChart
                                data={chartData}
                                typeName={"ลักษณะงาน"}
                                unit="ลบ."
                                title="สรุปงบประมาณแผนงานโครงการ"
                            />
                            <Box marginTop={4} paddingLeft={2} paddingRight={3}>
                                {
                                    StrategySummaryData?.[0]?.data?.length ?
                                        StrategySummaryData?.[0]?.data?.map((obj, index) => (
                                            <Grid container spacing={4} key={"reportstrat" + index}>
                                                <Grid item xs={12} sm={4}>
                                                    <Tooltip title={obj[0] ?? ""}>
                                                        <Box display="flex" alignItems="center" height="100%">
                                                            <div className="mr-03">
                                                                <FiberManualRecord style={{ fill: StrategyColor[index] }} />
                                                            </div>
                                                            <div className="text-ellipsis">
                                                                {obj[0]?.substr(0, 10) ?? ""}
                                                            </div>
                                                        </Box>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <Box display="flex" alignItems="center" height="100%">
                                                        <span>{Utilities.toCommas(Utilities.toPrecision(obj[1] || "-"))}</span>
                                                        <span className="ml-auto">ล้านบาท</span>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))
                                        : null
                                }
                            </Box>
                        </div>
                    </Slide>
                </CmtCardContent>
            </CmtCard>
        </Grow>
    );
}

export default React.memo(MapReport);