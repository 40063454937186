import axios from 'axios';
import store from '../redux';
import { setAccountLogout } from '../redux/action';
import AccountService from './AccountService';
import { setLocalStorage, removeLocalStorage, removeUserToken } from './_LocalStorageService';
import Swal from 'sweetalert2';
import Utilities from '../Util/Utilities';

let AlertTimeout = null;

const baseURL = ((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin) + "/twp/v2/";

const DirectAxios = axios.create({
    baseURL: baseURL
});

DirectAxios.defaults.timeout = 1000 * 30;

DirectAxios.interceptors.request.use((request) => {
    request.headers.Authorization = store.getState().sessionToken;
    
    return request;
});

DirectAxios.interceptors.response.use(
    (response) => {
        if (response.data?.errors?.[0]?.Code === -4100) {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                Swal.fire({
                    icon: 'error',
                    text: response.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                    title: 'กรุณาล็อคอินใหม่'
                })
                    .then(() => {
                        store.dispatch(setAccountLogout());
                        AccountService.Logout();
                        removeLocalStorage("masterData");
                        removeUserToken();
                        Utilities.clearNavigationMenu();
                    })
            }, 500);
        }
        else if (response.data?.errors?.[0]?.Code === -4101) {
            clearTimeout(AlertTimeout);
            Swal.close();
            AlertTimeout = setTimeout(function () {
                store.dispatch(setAccountLogout());
                AccountService.Logout();
                removeLocalStorage("masterData");
                removeUserToken();
            }, 500);
        }

        if (!response.headers.builddata) return response.data;

        setLocalStorage("buildData", response.headers.builddata)

        return response.data;
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 404) {
                return Promise.reject(err);
            }
            else if (err?.response?.data?.errors?.[0]?.Code === -4100) {
                clearTimeout(AlertTimeout);
                AlertTimeout = setTimeout(function () {
                    Swal.fire({
                        icon: 'error',
                        title: err?.response?.data?.errors?.[0]?.Message || 'เกิดข้อผิดพลาด',
                        text: 'กรุณาล็อคอินใหม่'
                    })
                        .then(() => {
                            store.dispatch(setAccountLogout());
                            AccountService.Logout();
                            removeLocalStorage("masterData");
                            removeUserToken();
                            Utilities.clearNavigationMenu();
                        })
                }, 500);
            }
            else if (err?.response?.data?.errors?.[0]?.Code === -4101) {
                clearTimeout(AlertTimeout);
                Swal.close();
                AlertTimeout = setTimeout(function () {
                    store.dispatch(setAccountLogout());
                    AccountService.Logout();
                    removeLocalStorage("masterData");
                    removeUserToken();
                }, 500);
            }
        }
        else if (err.message === 'Network Error') {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                if (Swal.isVisible()) {
                    Swal.showLoading();
                    Swal.update({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    Swal.hideLoading();
                }
                else
                    Swal.fire({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    })
            }, 500);
        }
        return Promise.reject(err);
    }
);


export default DirectAxios;