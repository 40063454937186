import React from 'react';
import {
    Grow,
    IconButton,
    Grid,
    Box,
    Chip,
    Tooltip
} from '@material-ui/core';
import {
    Close,
    Description,
    Room,
    Star
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';

class MapDescription extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.showDescription === false && this.props.showDescription === false)
            return false;
        return !(
            nextProps.showDescription === this.props.showDescription &&
            nextProps.description === this.props.description &&
            nextProps.descriptionList === this.props.descriptionList
        )
    }

    renderLatLng = (lat, lng) => {
        if (!lat && !lng) return "-";
        let latlng = ""

        if (lat) latlng += `${lat}, `;
        else latlng += "-, ";

        if (lng) latlng += `${lng}`;
        else latlng += "-";

        return (
            <Box display="flex" alignItems="center" height="100%">
                <span>{latlng}</span>
                <span>
                    <IconButton
                        size="small"
                        title="ไปยังพิกัด"
                        className="ml-03"
                        color="primary"
                        onClick={() => this.props.onClickProjectLocationLatLng(lat, lng)}
                    >
                        <Room />
                    </IconButton>
                </span>
            </Box>
        );
    }

    render() {
        const {
            showDescription,
            setDescription,
            setShowDescription,
            description,
            descriptionList
        } = this.props;
        return (
            <Grow in={showDescription} className="map-info overflow-auto">
                <Box display="flex" flexDirection="column" >
                    {
                        descriptionList?.length > 1 ?
                            <CmtCard style={{ height: "150px", maxHeight: "150px", flex: "0 0", overflow: "hidden" }}>
                                <Box overflow="hidden" display="flex" flexDirection="column" height="100%">
                                    <div className="d-flex align-items-center py-02 px-05 mb-02">
                                        <h4 className="mr-05">โครงการในพื้นที่</h4>
                                        <Chip label={descriptionList.length} size="small" color="primary" />
                                    </div>
                                    <Box flex="1 1" overflow="auto" paddingLeft={2}>
                                        {
                                            descriptionList.map((obj, index) => (
                                                <div
                                                    className="text-ellipsis mt-03"
                                                    key={'desclist' + index}
                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                    onClick={() => setDescription(obj.ProjectID)}
                                                >
                                                    {(index + 1) + ". " + obj.ProjectName}
                                                </div>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </CmtCard>
                            : null
                    }
                    <CmtCard
                        style={{
                            height: "10000px",
                            maxHeight: (descriptionList?.length > 1) ? "calc(100% - 150px)" : "100%",
                            flex: "1 1 auto",
                            overflow: "hidden",
                            marginTop: "12px"
                        }}
                    >
                        <Box overflow="hidden" display="flex" flexDirection="column" height="100%">
                            <div className="section-header main d-flex align-items-center py-05 px-10 mb-02">
                                <Description className="mr-05" />
                                <h4>รายละเอียดโครงการ</h4>
                                <IconButton className="ml-auto p-02 text-default" onClick={setShowDescription}>
                                    <Close />
                                </IconButton>
                            </div>
                            <hr className="hr-light" />
                            <CmtCardContent className="flex-fill overflowY-auto overflowX-hidden">
                                <Grid container spacing={6}>
                                    {/* <Grid item xs={12}>
                                <div className="section-header sub mb-05">
                                    <div className="d-flex align-items-center">
                                        <Description className="mr-05" />
                                        รายละเอียดโครงการ
                                    </div>
                                    <hr />
                                </div>
                            </Grid> */}
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ชื่อโครงการ</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>
                                            <a
                                                href={"/twp/v2/project/projectdetail/" + description.ProjectID}
                                                target="_blank"
                                                rel='noopener noreferrer'
                                            >
                                                <Box display="flex">
                                                    {
                                                        description.MYProject ?
                                                            <Box paddingRight={1}>
                                                                <Tooltip title="โครงการของฉัน">
                                                                    <Star className="star-icon" />
                                                                </Tooltip>
                                                            </Box>
                                                            : null
                                                    }
                                                    <span>
                                                        {
                                                            description.ProjectName && description.ProjectID ?
                                                                description.ProjectName : "-"
                                                        }
                                                    </span>
                                                </Box>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>หน่วยรับงบประมาณ</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.OrganizationName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>กิจกรรม</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.ActivityName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ลักษณะงาน</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.ProjectTypeName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ปีงบประมาณ</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.BudgetYear ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="section-header sub mb-0">
                                            <div className="d-flex align-items-center">
                                                <Room className="mr-05" />
                                                ที่ตั้งโครงการ
                                            </div>
                                            <hr />
                                        </div>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Box display="flex" alignItems="center" height="100%">
                                                <h4>Lat Long</h4>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={8} md={9}>{this.renderLatLng(description.Lat, description.Lng)}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>พื้นที่สำคัญ</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.AreaBaseName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>จังหวัด</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.ProvinceName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>อำเภอ</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.DistrictName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ตำบล</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.SubdistrictName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>หมู่บ้าน</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.Village ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>หมู่ที่</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.Moo ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ลุ่มน้ำหลัก</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.BasinName ?? "-"}</Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={12} sm={4} md={3}><h4>ลุ่มน้ำสาขา</h4></Grid>
                                        <Grid item xs={12} sm={8} md={9}>{description.SubbasinName ?? "-"}</Grid>
                                    </Grid>
                                </Grid>
                            </CmtCardContent>
                        </Box>
                    </CmtCard>
                </Box>
            </Grow>
        );
    }
}

export default MapDescription;