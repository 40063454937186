import React, { useState } from "react";
import { TextField, Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Grow } from "@material-ui/core";
import AccountService from "../../../Service/AccountService";
import { setAccountLogin } from "../../../redux/action";
import { ArrowBack } from "@material-ui/icons";
import { setLocalStorage } from "../../../Service/_LocalStorageService";
import Swal from "sweetalert2";

const checkLogin = (username, password) => {
    if (!username && !password) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Username และ Password", "error");
        return false;
    } else if (!username) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Username", "error");
        return false;
    } else if (!password) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Password", "error");
        return false;
    } else return true;
};

const login = (username, password, dispatch, history, setMasterData, masterData) => {
    if (!checkLogin(username, password)) return;
    AccountService.Login({ Username: username, Password: password })
        .then((res) => {
            if (res.errors || !res.data) {
                let error = res.errors && res.errors[0] ? res.errors[0].Message : "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล";
                return Swal.fire("กรุณาตรวจสอบข้อมูล", error, "error");
            }
            setLocalStorage('SessionToken', res.data.Login.SessionToken);
            dispatch(setAccountLogin(res.data.Login));
            setMasterData()
                .then(() => {
                    if (!res.data?.Login?.PDPAConsentID) {
                        history.push("/privacy");
                    } else {
                        history.push("/home");
                    }
                });
        })
        .catch((e) => {
            console.log(e);
            Swal.fire("เกิดข้อผิดพลาด", "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล", "error");
        });
};

const LoginTrainingModal = ({ show, setShow, dispatch, history, setMasterData, masterData, showLoading, hideLoading }) => {
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [usernameReset, setUsernameReset] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setError] = useState({});

    const checkDataResetPassword = () => {
        var error = {};
        if (!usernameReset) error.usernameReset = true;
        if (!email) error.Email = true;
        setError(error);

        if (Object.keys(error).length) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                icon: "warning",
            });
            return false;
        } else return true;
    };

    const onEnter = (
        e,
        username,
        password,
        dispatch,
        history,
        setMasterData,
        masterData,
        showResetPassword,
        setShowResetPassword,
        email
    ) => {
        if (e.keyCode === 13) {
            if (!showResetPassword) login(username, password, dispatch, history, setMasterData, masterData);
            else if (showResetPassword) {
                if (!checkDataResetPassword()) return;
                showLoading();
                return AccountService.forgetpassword(email, usernameReset)
                    .then((res) => {
                        if (res.errors || !res.data) {
                            let error =
                                res.errors && res.errors[0]
                                    ? res.errors[0].Message
                                    : "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล";
                            return Swal.fire("เกิดข้อผิดพลาด", error, "error");
                        }
                        Swal.fire({
                            title: "ทำรายการสำเร็จ",
                            text: "ระบบได้ทำการส่ง Email เรียบร้อยแล้ว กรุณาตรวจสอบกล่องข้อความของท่าน",
                            icon: "success",
                        });
                        setUsernameReset("");
                        setEmail("");
                        setShowResetPassword(false);
                    })
                    .catch((e) => {
                        console.log(e);
                        Swal.fire({
                            title: "เกิดข้อผิดพลาด",
                            icon: "error",
                        });
                    })
                    .then(() => {
                        hideLoading()
                    })
            }
            else return false;
        }
    };

    return (
        <Dialog
            onClose={setShow}
            TransitionProps={{
                onExit: () =>
                    setTimeout(() => {
                        setEmail("");
                        setShowResetPassword(false);
                    }, 300)
            }}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle style={{ position: "relative" }}>
                {!showResetPassword ? "เข้าสู่ระบบ" : "ลืมรหัสผ่าน"}
            </DialogTitle>
            <DialogContent
                style={{ paddingTop: !showResetPassword ? "1rem" : "0", paddingBottom: "1rem", overflowX: "hidden" }}
                onKeyDown={(e) =>
                    onEnter(
                        e,
                        username,
                        password,
                        dispatch,
                        history,
                        setMasterData,
                        masterData,
                        showResetPassword,
                        setShowResetPassword,
                        email
                    )
                }
            >
                <Grow in={!showResetPassword} timeout={400}>
                    <div style={{ display: !showResetPassword ? "" : "none" }}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <TextField
                                    label="ชื่อผู้ใช้งาน (Username)"
                                    variant="outlined"
                                    size="small"
                                    value={username ?? ""}
                                    onChange={(e) => setUsername(e.target.value)}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="รหัสผ่าน"
                                    variant="outlined"
                                    size="small"
                                    value={password ?? ""}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grow>
                <Grow in={showResetPassword} timeout={400}>
                    <div style={{ display: showResetPassword ? "" : "none" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <IconButton
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    onClick={() => {
                                        setUsernameReset("");
                                        setEmail("");
                                        setShowResetPassword(false);
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    value={email ?? ""}
                                    onChange={(e) => {
                                        if (errors.Email) setError((prevErr) => ({ ...prevErr, Email: false }));
                                        setEmail(e.target.value);
                                    }}
                                    error={errors.Email}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid className="mt-10" item xs={12}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    size="small"
                                    value={usernameReset ?? ""}
                                    onChange={(e) => {
                                        if (errors.Username) setError((prevErr) => ({ ...prevErr, Username: false }));
                                        setUsernameReset(e.target.value);
                                    }}
                                    error={errors.usernameReset}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grow>
            </DialogContent>
            <DialogActions style={{ display: "block", textAlign: "center", paddingBottom: "1.2rem" }}>
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => login(username, password, dispatch, history, setMasterData, masterData)}
                    >
                        เข้าสู่ระบบ
                    </Button>
                    <div className="d-flex mt-05" style={{ justifyContent: "center", alignItems: "center" }}>
                        <small
                            style={{ cursor: "pointer", textAlign: "center", marginTop: "0.4rem" }}
                            onClick={() => {
                                setUsernameReset("");
                                setEmail("");
                                setShowResetPassword(true);
                            }}
                        >
                            ลืมรหัสผ่าน
                        </small>
                    </div>
                </>
            </DialogActions>
        </Dialog>
    );
};

export default LoginTrainingModal;
