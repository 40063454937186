import Axios from "./_BaseService";

const WresourceService = {
    searchWaterResource: (data) => {
        data = {
            WaterResourceName: data.WaterResourceName ? data.WaterResourceName.trim() : undefined,
            WaterResourceSize: data.WaterResourceSize ?? undefined,
            WaterResourceTypeID: data.WaterResourceTypeID?.length  ? data.WaterResourceTypeID : undefined,
            BasinID: data.BasinID ?? undefined,
            ProvinceID: data.ProvinceID ?? undefined,
            DistrictID: data.DistrictID ?? undefined,
            SubdistrictID: data.SubdistrictID ?? undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lng ? data.Lng : undefined,
            RadiusKM: data.RadiusKM ? data.RadiusKM : undefined,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
            StatusID: data?.StatusID ?? undefined,
            StatusTWRC_ID: data?.StatusTWRC_ID ?? undefined,
            ID: data?.ID ? data?.ID.trim() : undefined,
            TWRC_ID: data?.TWRC_ID ?? undefined
        }

        const SEARCHWATERRESOURCE = `
            query SearchWaterResource (
                $WaterResourceName: String
                $WaterResourceSize: Int
                $WaterResourceTypeID: [Int]
                $BasinID: Int
                $ProvinceID: Int
                $DistrictID: Int
                $SubdistrictID: Int
                $Lat: Float
                $Lng: Float
                $RadiusKM: Float
                $StatusID: Int
                $ID: String
                $TWRC_ID: Int
                $StatusTWRC_ID: Int
                $Limit: Int
                $Offset: Int
            ) {
                SearchWaterResource (
                    WaterResourceName: $WaterResourceName
                    WaterResourceSize: $WaterResourceSize
                    WaterResourceTypeID: $WaterResourceTypeID
                    BasinID: $BasinID
                    ProvinceID: $ProvinceID
                    DistrictID: $DistrictID
                    SubdistrictID: $SubdistrictID
                    Lat: $Lat
                    Lng: $Lng
                    RadiusKM: $RadiusKM
                    StatusID: $StatusID
                    ID: $ID
                    TWRC_ID: $TWRC_ID
                    StatusTWRC_ID: $StatusTWRC_ID
                    Limit: $Limit
                    Offset: $Offset
                ) {
                    WaterResource,
                    Count,
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHWATERRESOURCE,
            variables:data,
            errorPolicy: "all"
        }, {
            timeout: 60000
        });
    },

    searchSyncWaterResource:(data) => {
        data = {
            WaterResourceID: data.WaterResourceID ?? undefined,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
        }

        const SEARCHSYNCWATERRESOURCE = `
            query SearchSyncWaterResource (
                $WaterResourceID: Int!
                $Offset: Int
                $Limit: Int
            ) {
                SearchSyncWaterResource (
                    WaterResourceID: $WaterResourceID
                    Offset: $Offset
                    Limit: $Limit
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSYNCWATERRESOURCE,
            variables:data,
            errorPolicy: "all"
        }, {
            timeout: 60000
        });
    },
    
    getWaterResourceDetail: (WaterResourceID) => {

        const GETWATERRESOURCEDETAIL = `
            query GetWaterResourceDetail (
                $WaterResourceID: Int!
            ) {
                GetWaterResourceDetail (
                    WaterResourceID: $WaterResourceID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: GETWATERRESOURCEDETAIL,
            variables: {
                WaterResourceID: WaterResourceID
            },
            errorPolicy: "all"
        }, {
            timeout: 1000 * 60
        });
    },

    //mutation
    changeStatusWaterResource: (data) => {
        data = {
            WaterResourceName: data.WaterResourceName ? data.WaterResourceName.trim() : undefined,
            WaterResourceSize: data.WaterResourceSize ?? undefined,
            WaterResourceType: data.WaterResourceType ?? undefined,
            BasinID: data.BasinID ?? undefined,
            ProvinceID: data.ProvinceID ?? undefined,
            DistrictID: data.DistrictID ?? undefined,
            SubdistrictID: data.SubdistrictID ?? undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lng ? data.Lng : undefined,
            RadiusKM: data.RadiusKM ? data.RadiusKM : undefined,
            StatusID: data?.StatusID ?? undefined,
            OldStatusID: data?.OldStatusID ?? undefined
        }
        const CHANGESTATUSWATERRESOURCE = `
            mutation ChangeStatusWaterResource(
                $WaterResourceName: String
                $WaterResourceSize: Int
                $WaterResourceType: Int
                $BasinID: Int
                $ProvinceID: Int
                $DistrictID: Int
                $SubdistrictID: Int
                $Lat: Float
                $Lng: Float
                $RadiusKM: Float
                $StatusID: Int!
                $OldStatusID: Int!
            ) {
                ChangeStatusWaterResource(
                    WaterResourceName: $WaterResourceName
                    WaterResourceSize: $WaterResourceSize
                    WaterResourceType: $WaterResourceType
                    BasinID: $BasinID
                    ProvinceID: $ProvinceID
                    DistrictID: $DistrictID
                    SubdistrictID: $SubdistrictID
                    Lat: $Lat
                    Lng: $Lng
                    RadiusKM: $RadiusKM
                    StatusID: $StatusID
                    OldStatusID: $OldStatusID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: CHANGESTATUSWATERRESOURCE,
            variables: data,
            errorPolicy: "all",
        });
    },

    ChangeStatusWaterResourceID: (data) => {
        data = {
            StatusID: data.StatusID ?? undefined,
            WaterResourceID: data.WaterResourceID.length ? data.WaterResourceID : undefined
        }
        const CHANGESTATUSWATERRESOURCEID = `
            mutation ChangeStatusWaterResourceID(
                $StatusID: Int!,
                $WaterResourceID: [Int]
            ) {
                ChangeStatusWaterResourceID(
                    StatusID: $StatusID,
                    WaterResourceID: $WaterResourceID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: CHANGESTATUSWATERRESOURCEID,
            variables: data,
            errorPolicy: "all",
        });
    },

    deleteWaterResourceID: (WaterResourceID) => {
        const DELETEWATERRESOURCEID = `
            mutation DeleteWaterResourceID(
                $WaterResourceID: Int!
            ) {
                DeleteWaterResourceID(
                    WaterResourceID: $WaterResourceID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEWATERRESOURCEID,
            variables: {
                WaterResourceID:WaterResourceID
            },
            errorPolicy: "all",
        });
    },

    deleteWaterResourceMultiple: (data) => {
        data = {
            WaterResourceID: data.WaterResourceID.length ? data.WaterResourceID : undefined
        }
        const DELETEWATERRESOURCEMULTIPLE = `
            mutation DeleteWaterResourceMultiple(
                $WaterResourceID: [Int]
            ) {
                DeleteWaterResourceMultiple(
                    WaterResourceID: $WaterResourceID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEWATERRESOURCEMULTIPLE,
            variables: data,
            errorPolicy: "all",
        });
    },

    importWaterSource: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : []
        };

        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
        }
        return Axios({
            method: "post",
            url: "/service/waterresource",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },

    importWaterSourceShapeFile: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : []
        };

        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
        }
        return Axios({
            method: "post",
            url: "/service/waterresource",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },
}

export default WresourceService;