import {
    Home as HomeIcon,
    Dashboard as DashboardIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Map as MapIcon,
    PieChart as PieChartIcon,
    Settings as SettingsIcon,
    Person as PersonIcon,
    OpenInBrowser as OpenInBrowserIcon,
    History as HistoryIcon,
    LocalOffer as LocalOfferIcon,
    People as PeopleIcon,
    Class as ClassIcon,
    Layers as LayersIcon,
    AccountTree as AccountTreeIcon,
    CardMembership as CardMembershipIcon,
    LocalActivity as LocalActivityIcon,
    Alarm,
    AssignmentTurnedIn,
    PermMedia,
    Group as GroupIcon,
    GroupWork as GroupWorkIcon,
    Email,
    Description,
    SettingsBackupRestore
} from "@material-ui/icons";

let navigationMenus = [
    {
        id: "menu-1",
        name: "Landing Page",
        icon: <OpenInBrowserIcon />,
        type: "item",
        link: "/landingpage",
        windowLocation: true
    },
    {
        id: "menu-2",
        name: "หน้าหลัก",
        icon: <HomeIcon />,
        type: "item",
        link: "/home",
        windowLocation: true
    },
    // {
    //     id: "menu-2",
    //     name: "Release Versions",
    //     icon: <SettingsBackupRestore />,
    //     type: "item",
    //     link: "/releasedversions",
    //     windowLocation: true
    // },
    // {
    //     id: "menu-3",
    //     name: "โครงการ",
    //     icon: <FormatListBulletedIcon />,
    //     type: "item",
    //     link: "/project/projectlist",
    //     permission: 'ProjectData'
    // },
    // {
    // 	id: "menu-4",
    // 	name: "Master Plan",
    // 	icon: <AssistantIcon />,
    // 	type: "item",
    // 	link: "/masterplan/masterplanlist",
    // 	permission: 'Project'
    // },
    // {
    //     id: "menu-5",
    //     name: "แผนที่",
    //     icon: <MapIcon />,
    //     type: "item",
    //     link: "/map",
    //     permission: 'Map'
    // },
    // {
    //     id: "menu-6",
    //     name: "รายงานสรุป / ส่งออก",
    //     icon: <PieChartIcon />,
    //     type: "item",
    //     link: "/report",
    //     permission: 'Export'
    // },
    // {
    // 	id: "menu-6",
    // 	name: "แผนปฏิบัติการด้านทรัพยากรน้ำ 2566",
    // 	icon: <Timeline />,
    // 	type: "item",
    // 	link: "/reportministry",
    // 	permission: 'ReportMinistry'
    // },
    // {
    // 	id: "menu-7",
    // 	name: "เหตุการณ์",
    // 	icon: <DescriptionIcon />,
    // 	type: "item",
    // 	link: "/incident",
    // 	permission: 'MasterData'
    // },
    // {
    //     id: "menu-8",
    //     name: "Dashboard",
    //     icon: <DashboardIcon />,
    //     type: "item",
    //     link: "/dashboard",
    //     externalLink: "https://twp.onwr.go.th/public_dashboard.php",
    //     permission: 'Dashboard'
    // },
    {
        id: "menu-9",
        name: "Settings",
        icon: <SettingsIcon />,
        type: "collapse",
        link: "/settings",
        children: [
            {
                id: "menu-9.1",
                name: "ผู้ใช้งาน",
                icon: <PersonIcon />,
                type: "item",
                link: "/settings/user",
                permission: 'Account'
            },
            {
                id: "menu-9.2",
                name: "หน่วยงาน",
                icon: <PeopleIcon />,
                type: "item",
                link: "/settings/organization",
                permission: 'Organization'
            },
            {
                id: "menu-9.3",
                name: "กิจกรรม",
                icon: <LocalActivityIcon />,
                type: "item",
                link: "/settings/activity",
                permission: 'MasterData'
            },
            {
                id: "menu-9.4",
                name: "ลักษณะงาน",
                icon: <CardMembershipIcon />,
                type: "item",
                link: "/settings/projecttype",
                permission: 'MasterData'
            },
            {
                id: "menu-9.5",
                name: "แผนแม่บทฯน้ำ 20 ปี",
                icon: <AccountTreeIcon />,
                type: "item",
                link: "/settings/strategy",
                permission: 'MasterData'
            },
            {
                id: "menu-9.6",
                name: "ตัวชี้วัด",
                icon: <AccountTreeIcon />,
                type: "item",
                link: "/settings/kpi",
                permission: 'MasterData'
            },
            {
                id: "menu-9.7",
                name: "หน่วยตัวชี้วัด",
                icon: <LocalActivityIcon />,
                type: "item",
                link: "/settings/kpiunit",
                permission: 'MasterData'
            },
            {
                id: "menu-9.8",
                name: "กลุ่มจังหวัด",
                icon: <LayersIcon />,
                type: "item",
                link: "/settings/provincegroup",
                permission: 'MasterData'
            },
            {
                id: "menu-9.9",
                name: "ประเภทเหตุการณ์",
                icon: <ClassIcon />,
                type: "item",
                link: "/settings/incidenttype",
                permission: 'MasterData'
            },
            {
                id: "menu-9.10",
                name: "หมวดหมู่เหตุการณ์",
                icon: <LocalActivityIcon />,
                type: "item",
                link: "/settings/incidentcategory",
                permission: 'MasterData'
            },
            {
                id: "menu-9.11",
                name: "Tag",
                icon: <LocalOfferIcon />,
                type: "item",
                link: "/settings/tag",
                permission: 'MasterData'
            },
            {
                id: "menu-9.12",
                name: "จัดการใช้งานระบบ",
                icon: <Alarm />,
                type: "item",
                link: "/settings/permissiondate",
                permission: 'MasterData'
            },
            {
                id: "menu-9.13",
                name: "จัดการ Sign-off",
                icon: <AssignmentTurnedIn />,
                type: "item",
                link: "/signoffmanage",
                permission: 'MasterData'
            },
            {
                id: "menu-9.14",
                name: "จัดการผู้รับผิดชอบ กผง 001",
                icon: <GroupIcon />,
                type: "item",
                link: "/settings/projectrequestperson",
                permission: 'MasterDataPerson'
            },
            {
                id: "menu-9.15",
                name: "สำนัก / กอง",
                icon: <PeopleIcon />,
                type: "item",
                link: "/settings/organizationinternal",
                permission: 'MasterData'
            },
            {
                id: "menu-9.16",
                name: "จัดกลุ่มหลัก, กลุ่มย่อย",
                icon: <GroupWorkIcon />,
                type: "item",
                link: "/settings/importantgroup",
                permission: 'MasterData'
            },
            {
                id: "menu-9.17",
                name: "ประวัติการใช้งาน",
                icon: <HistoryIcon />,
                type: "item",
                link: "/settings/history",
                permission: 'Core'
            },
            {
                id: "menu-9.18",
                name: "File Recovery Tool",
                icon: <PermMedia />,
                type: "item",
                link: "/settings/filerecovery",
                permission: 'FileRecovery'
            },
            {
                id: "menu-9.19",
                name: "ตรวจสอบ Email",
                icon: <Email />,
                type: "item",
                link: "/settings/emailcheck",
                permission: 'EmailCheck'
            },
            {
                id: "menu-9.20",
                name: "บันทึกการแก้ไขบัญชี",
                icon: <Description />,
                type: "item",
                link: "/settings/accountlog",
                permission: 'AccountLog'
            },
            // {
            // 	id: "menu-9.16",
            // 	name: "ย้ายข้อมูลโครงการ",
            // 	icon: <SyncAlt />,
            // 	type: "item",
            // 	link: "/settings/projecttransfer",
            // 	permission: 'ProjectTransfer'
            // },
        ]
    }
];

let navigationTrainingMenus = [
    {
        id: "menu-1",
        name: "Landing Page",
        icon: <OpenInBrowserIcon />,
        type: "item",
        link: "/landingpage",
        windowLocation: true
    },
    {
        id: "menu-2",
        name: "หน้าหลัก",
        icon: <HomeIcon />,
        type: "item",
        link: "/home",
        windowLocation: true
    }
];

const getNavigationMenu =  (account) => {
    let data = [];
    if (!account?.Permissions) return data;
    for (let i = 0; i < navigationMenus.length; i++) {
        const element = navigationMenus[i];
        if (element.children?.length) {
            let hasPermission = false;
            let newData = { ...element, children: [] };
            for (let i = 0; i < element.children.length; i++) {
                const childEl = element.children[i];
                if (account.Permissions[childEl.permission]) {
                    newData.children.push(childEl);
                    hasPermission = true;
                }
            }
            if (hasPermission) data.push(newData);
        }
        else if (
            (!element.permission && !element.children) ||
            account.Permissions[element.permission]
        ) data.push(element);
    }
    return data;
};

export default {
    navigationMenus,
    navigationTrainingMenus,
    getNavigationMenu
}