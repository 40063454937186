import axios from 'axios';
import Axios from './_BaseService';
import DirectAxios from './_DirectBaseService';
import { getLocalStorage } from './_LocalStorageService';

const CoreService = {

    getProjectMenu: () => {
        const GETPROJECTMENU = `
            query GetProjectMenu {
                GetProjectMenu
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTMENU,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getAllMasterData: () => {
        const getAllMasterData = `
            query{
                GetRegion {
                    RegionID
                    RegionName
                }
                GetAllBasin {
                    BasinID
                    BasinCode
                    BasinName
                }
                GetAllSubbasin {
                    SubbasinID
                    SubbasinCode
                    SubbasinName
                }
                GetAllProvince {
                    ProvinceID
                    ProvinceCode
                    ProvinceName
                    ProvinceNameEN
                    RegionID
                    Lat
                    Lng
                }
                GetAllDistrict {
                    DistrictID
                    DistrictCode
                    DistrictName
                    DistrictNameEN
                    ProvinceID
                    Lat
                    Lng
                }
                GetAllSubdistrict {
                    SubdistrictID
                    SubdistrictCode
                    SubdistrictName
                    SubdistrictNameEN
                    DistrictID
                    Lat
                    Lng
                }
                GetAllOrganization {
                    OrganizationID
                    OrganizationCode
                    OrganizationName
                    ParentOrganizationID
                    OrganizationShortName
                }
                GetBudgetSource {
                    BudgetSourceID
                    BudgetSourceCode
                    BudgetSourceName
                }
                GetBudgetType {
                    BudgetTypeID
                    BudgetTypeCode
                    BudgetTypeName
                }
                GetProjectStatus {
                    ProjectStatusID
                    ProjectStatusName
                }
                GetRole {
                    RoleID
                    RoleName
                }
                GetReadinessStatus {
                    ReadinessStatusID
                    ReadinessStatusName
                }
                GetRoleProjectImportant {
                    RoleProjectImportantID,
                    RoleProjectImportantName
                }
                GetRoleProjectRequest {
                    RoleProjectRequestID,
                    RoleProjectRequestName
                }
                GetProjectImportantSignoffStatus {
                    ProjectImportantStatusID
                    ProjectImportantStatusName
                }
                GetProjectRequestSignoffStatus{
                    ProjectRequestSignoffStepID
                    ProjectRequestSignoffStepName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: getAllMasterData,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getPermissionMasterData: () => {
        const sql = `
            query{
                GetBudgetDimension {
                    BudgetDimensionID
                    BudgetDimensionName
                }
                GetBasin {
                    BasinID
                    BasinCode
                    BasinName
                }
                GetProvince {
                    ProvinceID
                    ProvinceCode
                    ProvinceName
                    ProvinceNameEN
                    RegionID
                    Lat
                    Lng
                }
                SearchOrganization {
                    OrganizationID
                    OrganizationCode
                    OrganizationName
                    ParentOrganizationID
                    OrganizationShortName
                    DLAPatternID
                }
                GetDLAPatternOrganization
                SearchOrganizationAll
                GetSubbasin {
                    SubbasinID
                    SubbasinCode
                    SubbasinName
                    BasinID
                }
                GetSubbasinObject
                GetSignoffPrepareRequireFile
            }
        `;

        return Axios.post("/twpservice", {
            query: sql,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getSignoffPrepareRequireFile: () => {
        const sql = `
            query{
                GetSignoffPrepareRequireFile
            }
        `;

        return Axios.post("/twpservice", {
            query: sql,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getDistrictMasterData: () => {
        const getAllMasterData = `
            query{
                GetDistrict {
                    DistrictID
                    DistrictCode
                    DistrictName
                    DistrictNameEN
                    ProvinceID
                    Lat
                    Lng
                }
                GetDistrictObject
                GetSubdistrict {
                    SubdistrictID
                    SubdistrictCode
                    SubdistrictName
                    SubdistrictNameEN
                    DistrictID
                    Lat
                    Lng
                }
                GetSubdistrictObject
            }
        `;

        return Axios.post("/twpservice", {
            query: getAllMasterData,
            errorPolicy: "all",
        }, {
            timeout: 60000
        })
    },

    getMasterProject: () => {
        const getAllMasterData = `
            query {
                GetOrganizationRelationObject
                GetOrganizationRelationObjectData
                GetOrganizationRelationObjectName
                GetDistrictObjectName
                GetSubdistrictObjectName
                GetActivityObject
                GetProjectTypeObject
                GetActivityObjectName
                GetProjectTypeObjectName
                GetAllStrategyKPIMapping
                GetStrategyObject
                GetStrategyObjectCode
                GetSubbasinObjectName
                GetActivity {
                    ActivityID
                    ActivityName
                }
                SearchProjectType {
                    ProjectTypeID
                    ProjectTypeCode
                    ProjectTypeName
                }
                GetAreaBase {
                    AreaBaseID
                    AreaBaseCode
                    AreaBaseName
                }
                GetPrepareStatus {
                  PrepareStatusID
                  PrepareStatusName
                }
                GetProjectImportant {
                    ProjectImportantID
                    ProjectImportantName
                }
                GetStrategy {
                    StrategyName
                    StrategyID
                    StrategyCode
                    Level
                    ParentID
                }
                GetAllStrategyKPI {
                    StrategyID
                    StrategyName
                    StrategyCode
                    Policy {
                        StrategyID
                        StrategyName
                        StrategyCode
                        WorkPlan {
                            StrategyID
                            StrategyName
                            StrategyCode
                            KPI {
                                KPIID
                                KPICode
                                KPIName
                                KPIUnitName
                            }
                        }
                    }
                }
                GetWorkplanGroup{
                    WorkplanGroupID
                    WorkplanGroupName
                }
                GetBudgetTypeRequest{
                    BudgetTypeRequestID
                    BudgetTypeRequestName
                }
                GetProjectTypeRequest{
                    ProjectTypeID
                    ProjectTypeName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: getAllMasterData,
            errorPolicy: "all",
        }, {
            timeout: 60000
        })
    },

    searchPlace: (placeName) => {
        placeName = placeName ? placeName.trim() : undefined;
        const SEARCHPLACE = `
            query SearchPlace(
                $PlaceName: String
            ){
                SearchPlace(
                    PlaceName: $PlaceName
                ) {
                    Value
                    Label
                    Type
                    ProvinceID
                    ProvinceName
                    DistrictID
                    DistrictName
                    SubdistrictID
                    SubdistrictName
                    BasinID
                    BasinName
                    SubbasinID
                    SubbasinName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHPLACE,
            variables: {
                PlaceName: placeName
            },
            errorPolicy: "all",
        });
    },

    getPresetCollection: () => {
        const GETPRESETCOLLECTION = `
            query {
                GetPresetCollection {
                    PresetCategoryID
                    PresetCollectionID
                    PresetCategoryName
                    PresetCollectionName
                    PresetCollectionJSON
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPRESETCOLLECTION,
            errorPolicy: "all",
        });
    },

    getMasterPlanMaster: () => {
        const GETMASTERPLANMASTER = `
            query {
                GetMasterPlanCategory {
                    MasterPlanCategoryID
                    MasterPlanCategoryName
                    MasterPlanCategoryCode
                    ParentID
                    Level
                    Children
                }
                GetMasterPlanPlan {
                    MasterPlanPlanID
                    MasterPlanPlanCode
                    MasterPlanPlanName
                }
                GetMasterPlanProduct {
                    MasterPlanProductID
                    MasterPlanProductCode
                    MasterPlanProductName
                }
                GetMasterPlanPlanChild
            }
        `;

        return Axios.post("/twpservice", {
            query: GETMASTERPLANMASTER,
            errorPolicy: "all",
        });
    },

    getOrganizationInternal: () => {
        const GETORGANIZATIONINTERNAL = `
            query {
                GetOrganizationInternal {
                    OrganizationInternalID,
                    OrganizationInternalName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETORGANIZATIONINTERNAL,
            errorPolicy: "all",
        });
    },

    getPolicyType: () => {
        const GETPOLICYTYPE = `
            query {
                GetPolicyType {
                    PolicyTypeID,
                    PolicyTypeName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPOLICYTYPE,
            errorPolicy: "all",
        });
    },

    getPolicyTypeProject: () => {
        const GETPOLICYTYPEPROJECT = `
            query {
                GetPolicyTypeProject {
                    PolicyTypeProjectID,
                    PolicyTypeProjectName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPOLICYTYPEPROJECT,
            errorPolicy: "all",
        });
    },

    getWaterResourceType: () => {
        const GETWATERRESOURCETYPE = `
            query {
                GetWaterResourceType {
                    WaterResourceTypeID
                    WaterResourceTypeName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETWATERRESOURCETYPE,
            errorPolicy: "all",
        });
    },

    chackBuildData: () => {
        const CHECKBUILDDATA = `
            query CheckBuildData($BuildData: String) {
                CheckBuildData(BuildData: $BuildData)
            }
        `;

        const buildData = getLocalStorage("buildData") ?? null

        return Axios.post("/twpservice", {
            query: CHECKBUILDDATA,
            errorPolicy: "all",
            variables: { BuildData: buildData },
        });
    },

    getAllMasterDataDirect: async () => {
        const getAllMasterData = `
            query{
                GetRegion {
                    RegionID
                    RegionName
                }
                GetAllBasin {
                    BasinID
                    BasinCode
                    BasinName
                }
                GetAllSubbasin {
                    SubbasinID
                    SubbasinCode
                    SubbasinName
                }
                GetAllProvince {
                    ProvinceID
                    ProvinceCode
                    ProvinceName
                    ProvinceNameEN
                    RegionID
                    Lat
                    Lng
                }
                GetAllDistrict {
                    DistrictID
                    DistrictCode
                    DistrictName
                    DistrictNameEN
                    ProvinceID
                    Lat
                    Lng
                }
                GetAllSubdistrict {
                    SubdistrictID
                    SubdistrictCode
                    SubdistrictName
                    SubdistrictNameEN
                    DistrictID
                    Lat
                    Lng
                }
                GetAllOrganization {
                    OrganizationID
                    OrganizationCode
                    OrganizationName
                    ParentOrganizationID
                    OrganizationShortName
                }
                GetBudgetSource {
                    BudgetSourceID
                    BudgetSourceCode
                    BudgetSourceName
                }
                GetBudgetType {
                    BudgetTypeID
                    BudgetTypeCode
                    BudgetTypeName
                }
                GetProjectStatus {
                    ProjectStatusID
                    ProjectStatusName
                }
                GetRole {
                    RoleID
                    RoleName
                }
                GetReadinessStatus {
                    ReadinessStatusID
                    ReadinessStatusName
                }
                GetRoleProjectImportant {
                    RoleProjectImportantID,
                    RoleProjectImportantName
                }
                GetRoleProjectRequest {
                    RoleProjectRequestID,
                    RoleProjectRequestName
                }
                GetProjectImportantSignoffStatus {
                    ProjectImportantStatusID
                    ProjectImportantStatusName
                }
                GetProjectRequestSignoffStatus{
                    ProjectRequestSignoffStepID
                    ProjectRequestSignoffStepName
                }
            }
        `;

        return DirectAxios.post("/twpservice", {
            query: getAllMasterData,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },
}

export default CoreService;