import version from './_VersionService';
import jsonpack from "jsonpack";

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const getLocalStorageMaster = (key) => {
    let getMaster = localStorage.getItem(key);

    if (!getMaster) return null;

    try {
        getMaster = jsonpack.unpack(getMaster);
        return getMaster;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export const setLocalStorage = (key, data) => {
    if (data && typeof data === "object") {
        data.Version = version;
        data = JSON.stringify(data);
    }
    localStorage.setItem(key, data);
}

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}

export const clearLocalStorage = () => {
    localStorage.clear();
}

export const removeUserToken = () => {
    removeLocalStorage("SessionToken");
    removeLocalStorage("showImportantMessage");
}