import React from 'react';
import {
    Button,
    Grow,
    IconButton,
    Grid,
    FormControlLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import {
    Close,
    Info,
    Search,
    Map,
    Refresh,
    Launch
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import CmtImage from '../../@coremat/CmtImage';
import ExpandableContent from '../../@coremat/CmtCardExpendableContent';
import TextFieldMemo from '../../Util/TextFieldMemo';

const renderLayerMap = (
    searchLayerFilter,
    GetLayerPath,
    showLayer,
    setShowLayer,
    showProjectLayer,
    setShowProjectLayer,
    searchLayer
) => {
    if (!GetLayerPath && !searchLayerFilter) return null;
    let currentGroupID = null;
    let elements = [];
    let renderData = searchLayerFilter ?? GetLayerPath;
    let projectGroupName = "แผนงานด้านทรัพยากรน้ำ";
    let projectName = "ตำแหน่งแผนงาน/โครงการ";
    if (
        !searchLayer ||
        (projectGroupName.includes(searchLayer) || projectName.includes(searchLayer))
    ) {
        elements.push(
            <div key={"layergroup-project"} className="section-header sub mt-02">
                {projectGroupName}
                <hr />
            </div>
        );
        elements.push(
            <div key={"layer-project"} className="d-flex align-items-center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showProjectLayer ?? false}
                            onChange={() => setShowProjectLayer(!showProjectLayer)}
                        />
                    }
                    label={projectName}
                    className="mr-03"
                />
            </div>
        );
    }
    for (let i = 0; i < renderData.length; i++) {
        const obj = renderData[i];
        if (obj.LayerGroupID !== currentGroupID) {
            currentGroupID = obj.LayerGroupID;
            elements.push(
                <div key={"layergroup-" + i} className="section-header sub mt-02">
                    {obj.LayerGroupName}
                    <hr />
                </div>
            );
        }
        elements.push(
            <div key={"layer-" + i} className="d-flex align-items-center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showLayer?.[obj.LayerID] ?? false}
                            onChange={setShowLayer}
                            name={obj.LayerID + ""}
                        />
                    }
                    label={obj.LayerName}
                    className="mr-03"
                />
                {
                    obj.Description ?
                        <Tooltip title={
                            <div>
                                <div>{obj.Description}</div>
                                {
                                    obj.SourceURL ?
                                        <small>(คลิกเพื่อไปยังแหล่งที่มา)</small>
                                        : null
                                }
                            </div>
                        }>
                            {
                                obj.SourceURL ?
                                    <a style={{ height: 24 }} target='_blank' href={obj.SourceURL}>
                                        <Info className="text-secondary layer-path" />
                                    </a>
                                    :
                                    <Info className="text-secondary mr-03" />
                            }
                        </Tooltip>
                        : null
                }
                {/* {
                    obj.SourceURL ? (
                        <a style={{ height: 24 }} target='_blank' href={obj.SourceURL}>
                            <Launch className="text-primary layer-path" />
                        </a>
                    ) : null
                } */}
            </div>
        );
    }
    return elements;
}

const renderNumberLayer = (showLayer, GetLayerPath, searchLayerFilter) => {
    if (!GetLayerPath) return "(0/0)";
    let renderData = GetLayerPath;
    let num = 0;
    for (const key in showLayer) {
        if (Object.hasOwnProperty.call(showLayer, key) && showLayer[key]) {
            num++;
        }
    }
    return `(${num}/${renderData.length})`;
}

class MapFilter extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.showLayerWindow === false && this.props.showLayerWindow === false)
            return false;
        return !(
            nextProps.showLayerWindow === this.props.showLayerWindow &&
            nextProps.showLayer === this.props.showLayer &&
            nextProps.mapType === this.props.mapType &&
            nextProps.searchLayer === this.props.searchLayer &&
            nextProps.searchLayerFilter === this.props.searchLayerFilter &&
            nextProps.showProjectLayer === this.props.showProjectLayer
        )
    }
    searchLayerLabel = (
        <div className="d-flex align-items-center" style={{ marginTop: "-4px" }}>
            <Search className="mr-03" /> ค้นหาชั้นข้อมูล
        </div>
    )

    render() {
        const {
            mapType,
            setMapType,
            showLayerWindow,
            setShowLayerWindow,
            setShowLayer,
            clearShowLayer,
            masterData,
            LayerActionComponent,
            showLayer,
            searchLayer,
            setSearchLayer,
            searchLayerFilter,
            showProjectLayer,
            setShowProjectLayer
        } = this.props;
        const layerComponent = renderLayerMap(searchLayerFilter, masterData?.GetLayerPath, showLayer, setShowLayer, showProjectLayer, setShowProjectLayer, searchLayer);
        return (
            <Grow in={showLayerWindow} className="map-filter d-flex flex-column">
                <CmtCard>
                    <div className="section-header main d-flex align-items-center py-05 px-10 mb-0">
                        <Map className="mr-05" />
                        <h4>รูปแบบแผนที่</h4>
                        <Button
                            size="small"
                            variant="contained"
                            color="default"
                            className="ml-auto btn-muted"
                            onClick={() => {
                                setMapType(0);
                                clearShowLayer();
                            }}
                        >
                            <Refresh className="mr-03" />
                            Clear
                        </Button>
                        <IconButton className="ml-03 p-02 text-default" onClick={setShowLayerWindow}>
                            <Close />
                        </IconButton>
                    </div>
                    <hr className="hr-light" />
                    <CmtCardContent className="flex-fill overflowY-auto overflowX-hidden">
                        <Grid
                            container
                            spacing={6}
                        >
                            <Grid container item xs={12} spacing={0}>
                                <Grid
                                    item xs={4}
                                    onClick={() => setMapType(0)}
                                    className={"map-maptype-wrapper " + ((mapType === 0) ? "active" : "")}
                                >
                                    <span className="map-maptype">
                                        <CmtImage
                                            src={"/images/map/map-type-roadmap.jpg"}
                                            alt={"map-roadmap"}
                                        />
                                    </span>
                                    <div className="mt-10">Default</div>
                                </Grid>
                                <Grid
                                    item xs={4}
                                    onClick={() => setMapType(1)}
                                    className={"map-maptype-wrapper " + ((mapType === 1) ? "active" : "")}
                                >
                                    <span className="map-maptype">
                                        <CmtImage
                                            src={"/images/map/map-type-satellite.jpg"}
                                            alt={"map-satellite"}
                                        />
                                    </span>
                                    <div className="mt-10">Satellite</div>
                                </Grid>
                                <Grid
                                    item xs={4}
                                    onClick={() => setMapType(2)}
                                    className={"map-maptype-wrapper " + ((mapType === 2) ? "active" : "")}
                                >
                                    <span className="map-maptype">
                                        <CmtImage
                                            src={"/images/map/map-type-terrain.jpg"}
                                            alt={"map-terrain"}
                                        />
                                    </span>
                                    <div className="mt-10">Terrain</div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpandableContent
                                    actionsComponent={
                                        <LayerActionComponent
                                            number={renderNumberLayer(showLayer, masterData.GetLayerPath, searchLayerFilter)}
                                        />
                                    }
                                    actionsClasses="map-collapse-header"
                                    contentClass="p-10"
                                    className="section-border"
                                >
                                    <div>
                                        <TextFieldMemo
                                            label={this.searchLayerLabel}
                                            placeholder="ค้นหาชั้นข้อมูล"
                                            variant="outlined"
                                            size="small"
                                            value={searchLayer ?? ''}
                                            name='searchLayer'
                                            onChange={setSearchLayer}
                                            fullWidth
                                        />
                                    </div>
                                    {layerComponent}
                                </ExpandableContent>
                            </Grid>
                        </Grid>
                    </CmtCardContent>
                </CmtCard>
            </Grow >
        );
    }
}

export default MapFilter;