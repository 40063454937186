import { getLocalStorageMaster } from '../Service/_LocalStorageService';

let getMasterData = getLocalStorageMaster("masterData");
let getDistrictMasterData = getLocalStorageMaster("districtMasterData");

getMasterData = !getMasterData ? {} : getMasterData;
getDistrictMasterData = !getDistrictMasterData ? {} : getDistrictMasterData;

const initialState = {
    trigger: 0,
    profilePictureURL: null,
    isAuth: false,
    consentID: null,
    account: null,
    masterData: { ...getMasterData, ...getDistrictMasterData },
    filterData: null,
    showMenu: (window.location.pathname !== "/landingpage"),
    mapMode: (window.location.pathname === "/map"),
    showLoading: false,
    sessionToken: null,
    trainingPlatform: false,
    trainingDays: undefined
}

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOWMENU': {
            return {
                ...state,
                showMenu: true
            };
        }
        case 'HIDEMENU': {
            return {
                ...state,
                showMenu: false
            };
        }
        case 'OPENMAPMODE': {
            return {
                ...state,
                mapMode: true
            };
        }
        case 'CLOSEMAPMODE': {
            return {
                ...state,
                mapMode: false
            };
        }
        case 'SHOWLOADING': {
            return {
                ...state,
                showLoading: true
            };
        }
        case 'HIDELOADING': {
            return {
                ...state,
                showLoading: false
            };
        }
        case 'LOGIN': {
            // if(!action.payload?.Account?.PDPAConsentID) {
            //     return {
            //         ...state,
            //         isAuth: true,
            //         sessionToken: action.payload.Account.SessionToken
            //     };
            // }

            return {
                ...state,
                isAuth: true,
                consentID: action.payload.Account.PDPAConsentID,
                account: action.payload.Account,
                sessionToken: action.payload.Account.SessionToken,
                trigger: 1,
                profilePictureURL: !action.payload?.Account ? null :
                    ((process.env.NODE_ENV !== 'development' ? "" : "http://localhost:9000") +
                        `/twp/v2/upload/profile/${action.payload?.Account.AccountID}/${action.payload?.Account.AccountID}.png?0`)
            };
        }
        case 'IMAGE_CHANGE': {
            return {
                ...state,
                trigger: state.trigger + 1,
                profilePictureURL: !state.account ? null :
                    ((process.env.NODE_ENV !== 'development' ? "" : "http://localhost:9000") +
                        `/twp/v2/upload/profile/${state.account.AccountID}/${state.account.AccountID}.png?${state.trigger}`)
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuth: false,
                consentID: null,
                account: null,
                showMenu: false
            };
        }
        case 'SETMASTERDATA': {
            if (action.payload.MasterData)
                return {
                    ...state,
                    masterData: {
                        ...state.masterData,
                        ...action.payload.MasterData
                    }
                }
            else
                return {
                    ...state,
                    masterData: {}
                }
        }
        case 'SETFILTERDATA': {
            return {
                ...state,
                filterData: action.payload.FilterData ? {
                    ...action.payload.FilterData
                } : null
            }
        }
        case 'SETTRAINING': {
            return {
                ...state,
                trainingPlatform: action.payload.training,
                trainingDays: action.payload.trainingDays
            }
        }
        default: return state;
    }
}

export default mainReducer;