import React from 'react'
import TreeHorizontal from './TreeHorizontal';
import {
    Button,
    Box,
    Typography,
    Grid,
    Dialog,
    Divider,
} from '@material-ui/core';
import {
    Print,
} from '@material-ui/icons';


const PrintPieChart = ({ data = [], total, show, hide, type }) => {
    React.useEffect(() => {
    }, [show, data, type])
    return (
        <Dialog
            onClose={hide}
            aria-labelledby="year-dialog"
            open={show}
            id="projectimportant-print-modal"
            maxWidth="xl"
            fullWidth
            style={{ maxWidth: "100%" }}
            PaperProps={{
                style: { margin: 0 }
            }}
        >
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => window.print()}
                className="no-print-component my-10 mx-10"
            >
                <Print className="mr-03" />
                Print
            </Button>
            <Grid container>
                <Grid item xs={3} className="d-flex align-items-center justify-content-center">
                    <img
                        /* ref={el => ref.current = el} */
                        src="/images/twp-header.png"
                        alt="logo"
                        style={{ maxHeight: "100px" }}
                    />
                </Grid>
                <Grid item xs={9} className="pr-15">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="100%"
                        flexWrap="wrap"
                    >
                        {
                            data.ProjectImportantGroupName || data.ProjectImportantGroupSubName ?
                                <Box textAlign="right" width="100%">
                                    {
                                        ("การจัดกลุ่ม: " +
                                            data.ProjectImportantGroupName +
                                            (
                                                data.ProjectImportantGroupSubName ?
                                                    ("- " + data.ProjectImportantGroupSubName) : ""
                                            )
                                        )
                                    }
                                </Box>
                                : null
                        }
                        {
                            data.ProjectImportantGroupName || data.ProjectImportantGroupSubName ?
                                <Box textAlign="right" width="70%">
                                    <Divider />
                                </Box>
                                : null
                        }
                        <Box display="flex" width="100%">
                            <Box textAlign="left" width="50%" height="100%">
                                <Box>
                                    <Typography variant="h3" className="text-ellipsis">
                                        โครงการสำคัญจำแนกตามประเภทโครงการ
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {/*  */}
            <div className='print-component-projectimportant-tree'>
                {
                    data ? <TreeHorizontal ProjectImportantTree={data} total={total} type={type} /> : null
                }
            </div>

        </Dialog>
    )
}

export default PrintPieChart
