import Axios from './_BaseService';

const TrainingRequestService = {
    searchTrainingRequest: (data) => {
        data = {
            TrainingRequestID: data.TrainingRequestID || undefined,
            FirstName: data.FirstName || undefined,
            LastName: data.LastName || undefined,
            Type: data.Type || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            Email: data.Email || undefined,
            Date: data.Date || undefined,
            RemarkID: data.RemarkID || undefined,
            TrainingApprove: data.TrainingApprove?.length ? data.TrainingApprove : [],
            Offset: data.Offset || undefined,
            Limit: data.Limit || undefined
        };

        const SEARCHTRAININGREQUEST = `
        query SearchTrainingRequest (
            $TrainingRequestID: Int,
            $FirstName: String,
            $LastName: String,
            $Type: Int,
            $OrganizationID: [Int],
            $DLAOrganizationID: [Int],
            $ProvinceID: [Int],
            $Email: String,
            $Date: Date,
            $RemarkID: Int,
            $TrainingApprove: [Int],
            $Offset: Int,
            $Limit: Int
        ) {
            SearchTrainingRequest (
                TrainingRequestID: $TrainingRequestID,
                FirstName: $FirstName,
                LastName: $LastName,
                Type: $Type,
                OrganizationID: $OrganizationID,
                DLAOrganizationID: $DLAOrganizationID,
                ProvinceID: $ProvinceID,
                Email: $Email,
                Date: $Date,
                RemarkID: $RemarkID,
                TrainingApprove: $TrainingApprove,
                Offset: $Offset,
                Limit: $Limit
            ){
                TrainingRequest {
                    TrainingRequestID,
                    Type,
                    TypeName,
                    CardID,
                    FirstName,
                    LastName,
                    OrganizationID,
                    OrganizationName,
                    ProvinceID,
                    ProvinceName,
                    Position,
                    PhoneNumber,
                    Email,
                    LineID,
                    UserNumber,
                    StartDate,
                    EndDate,
                    TrainingApprove,
                    AccountID,
                    TrainingSignoffID,
                    RemarkID,
                    RemarkName,
                    Description,
                    RemarkNote
                }
                Count
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHTRAININGREQUEST,
            variables: data,
            errorPolicy: "all",
        });
    },

    getTrainingRequestDetail: (TrainingRequestID) => {
        const GETTRAININGREQUESTDETAIL = `
            query GetTrainingRequestDetail(
                $TrainingRequestID: Int
            ) {
                GetTrainingRequestDetail (
                    TrainingRequestID: $TrainingRequestID
                ){
                    TrainingRequestID,
                    Type,
                    CardID,
                    FirstName,
                    LastName,
                    OrganizationID,
                    OrganizationName,
                    ProvinceName,
                    Position,
                    PhoneNumber,
                    Email,
                    LineID,
                    UserNumber,
                    StartDate,
                    EndDate,
                    TrainingApprove,
                    AccountID,
                    TrainingSignoffID,
                    RemarkID,
                    RemarkName,
                    Description,
                    RemarkNote
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETTRAININGREQUESTDETAIL,
            variables: { TrainingRequestID },
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getTrainingRequestReport: (data) => {
        data = {
            Type: data.Type || undefined,
            ReportType: data.ReportType || undefined,
            TrainingApprove: data.TrainingApprove?.length ? data.TrainingApprove : [],
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            RemarkID: data.RemarkID || undefined,
            StartDate: data.StartDate || undefined,
            EndDate: data.EndDate || undefined,
        };

        const GETTRAININGREQUESTREPORT = `
        query GetTrainingRequestReport (
            $Type: Int,
            $ReportType: Int,
            $TrainingApprove: [Int],
            $OrganizationID: [Int],
            $DLAOrganizationID: [Int],
            $ProvinceID: [Int],
            $RemarkID: Int,
            $StartDate: Date,
            $EndDate: Date
        ) {
            GetTrainingRequestReport (
                Type: $Type,
                ReportType: $ReportType,
                TrainingApprove: $TrainingApprove,
                OrganizationID: $OrganizationID,
                DLAOrganizationID: $DLAOrganizationID,
                ProvinceID: $ProvinceID,
                RemarkID: $RemarkID,
                StartDate: $StartDate,
                EndDate: $EndDate
            ){
                TrainingReportList {
                    Count,
                    ApprovedCount,
                    RejectedCount,
                    PendingCount,
                    UserCount,
                    ApprovedUserCount,
                    RejectedUserCount,
                    PendingUserCount,
                    Label,
                    SubLabel
                }
                Count
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: GETTRAININGREQUESTREPORT,
            variables: data,
            errorPolicy: "all",
        });
    },

    getOrganization: () => {
        const GETORGANIZATION = `
            query GetOrganization {
                GetOrganization {
                    OrganizationID,
                    OrganizationName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETORGANIZATION,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getOrganizationDLA: () => {
        const GETORGANIZATIONDLA = `
            query GetOrganizationDLA {
                GetOrganizationDLA {
                    OrganizationID,
                    OrganizationName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETORGANIZATIONDLA,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    getProvinceTrainingRequest: () => {
        const GETPROVINCETRAININGREQUEST = `
            query GetProvinceTrainingRequest {
                GetProvinceTrainingRequest {
                    ProvinceID,
                    ProvinceName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROVINCETRAININGREQUEST,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    checkTrainingAvailability: (data) => {
        data = {
            StartDate: data.StartDate || undefined,
            EndDate: data.EndDate || undefined
        };

        const CHECKTRAININGAVAILABILITY = `
        query CheckTrainingAvailability (
            $StartDate: Date,
            $EndDate: Date
        ) {
            CheckTrainingAvailability(
                StartDate: $StartDate,
                EndDate: $EndDate
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: CHECKTRAININGAVAILABILITY,
            variables: data,
            errorPolicy: "all",
        });
    },

    createTrainingRequest: (data, startDate, endDate) => {
        data = {
            Type: data.Type || undefined,
            CardID: data.CardID || undefined,
            FirstName: data.FirstName || undefined,
            LastName: data.LastName || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            Position: data.Position || undefined,
            PhoneNumber: data.PhoneNumber || undefined,
            Email: data.Email || undefined,
            LineID: data.LineID || undefined,
            UserNumber: parseInt(data.UserNumber) || undefined,
            StartDate: startDate || undefined,
            EndDate: endDate || undefined
        };

        const CREATETRAININGREQUEST = `
        mutation CreateTrainingRequest (
            $Type: Int!,
            $CardID: String!,
            $FirstName: String!,
            $LastName: String!,
            $OrganizationID: Int,
            $DLAOrganizationID: Int,
            $ProvinceID: Int,
            $Position: String!,
            $PhoneNumber: String!,
            $Email: String!,
            $LineID: String,
            $UserNumber: Int!,
            $StartDate: Date!,
            $EndDate: Date!
        ) {
            CreateTrainingRequest (
                Type: $Type,
                CardID: $CardID,
                FirstName: $FirstName,
                LastName: $LastName,
                OrganizationID: $OrganizationID,
                DLAOrganizationID: $DLAOrganizationID,
                ProvinceID: $ProvinceID,
                Position: $Position,
                PhoneNumber: $PhoneNumber,
                Email: $Email,
                LineID: $LineID,
                UserNumber: $UserNumber,
                StartDate: $StartDate,
                EndDate: $EndDate
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: CREATETRAININGREQUEST,
            variables: data,
            errorPolicy: "all",
        });
    },

    updateTrainingRequest: (data) => {
        data = {
            TrainingRequestID: data.TrainingRequestID || undefined,
            TrainingApprove: data.TrainingApprove || 0,
            RemarkID: data.RemarkID || undefined,
            Description: data.Description || undefined,
            RemarkNote: data.RemarkNote || undefined,
            AccountID: data.AccountID || undefined,
            UserNumber: data.UserNumber || undefined
        };

        const UPDATETRAININGREQUEST = `
        mutation UpdateTrainingRequest (
            $TrainingRequestID: Int!,
            $TrainingApprove: Int!,
            $RemarkID: Int,
            $Description: String,
            $RemarkNote: String,
            $AccountID: Int,
            $UserNumber: Int
        ) {
            UpdateTrainingRequest (
                TrainingRequestID: $TrainingRequestID,
                TrainingApprove: $TrainingApprove,
                RemarkID: $RemarkID,
                Description: $Description,
                RemarkNote: $RemarkNote,
                AccountID: $AccountID,
                UserNumber: $UserNumber
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATETRAININGREQUEST,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportTrainingRequest: (data, exportType) => {
        data = {
            TrainingRequestID: data.TrainingRequestID || undefined,
            FirstName: data.FirstName || undefined,
            LastName: data.LastName || undefined,
            Type: data.Type || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            Email: data.Email || undefined,
            Date: data.Date || undefined,
            RemarkID: data.RemarkID || undefined,
            TrainingApprove: data.TrainingApprove || undefined,
            ExportType: exportType
        };

        const EXPORTTRAININGREQUEST = `
        query ExportTrainingRequest (
            $TrainingRequestID: [Int],
            $FirstName: String,
            $LastName: String,
            $Type: Int,
            $OrganizationID: [Int],
            $DLAOrganizationID: [Int],
            $ProvinceID: [Int],
            $Email: String,
            $Date: Date,
            $RemarkID: Int,
            $TrainingApprove: [Int],
            $ExportType: Int
        ) {
            ExportTrainingRequest (
                TrainingRequestID: $TrainingRequestID,
                FirstName: $FirstName,
                LastName: $LastName,
                Type: $Type,
                OrganizationID: $OrganizationID,
                DLAOrganizationID: $DLAOrganizationID,
                ProvinceID: $ProvinceID,
                Email: $Email,
                Date: $Date,
                RemarkID: $RemarkID,
                TrainingApprove: $TrainingApprove,
                ExportType: $ExportType
            ) {
                FileName
                Link
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTTRAININGREQUEST,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportTrainingRequestReport: (data) => {
        data = {
            ReportType: data.ReportType || undefined,
            ExportType: data.ExportType || undefined
        };
        const EXPORTTRAININGREQUESTREPORT = `
        query ExportTrainingRequestReport (
            $ReportType: Int
            $ExportType: Int
        ) {
            ExportTrainingRequestReport (
                ReportType: $ReportType
                ExportType: $ExportType
            ) {
                FileName
                Link
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTTRAININGREQUESTREPORT,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportTrainingRequestReportUser: (data) => {
        data = {
            ReportType: data.ReportType || undefined,
            ExportType: data.ExportType || undefined
        };
        const EXPORTTRAININGREQUESTREPORTUSER = `
        query ExportTrainingRequestReportUser (
            $ReportType: Int
            $ExportType: Int
        ) {
            ExportTrainingRequestReportUser (
                ReportType: $ReportType
                ExportType: $ExportType
            ) {
                FileName
                Link
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTTRAININGREQUESTREPORTUSER,
            variables: data,
            errorPolicy: "all",
        });
    },

    getTrainingRequestCount: () => {
        const SEARCHTRAININGREQUEST = `
        query SearchTrainingRequest (
            $TrainingApprove: [Int]
        ) {
            SearchTrainingRequest (
                TrainingApprove: $TrainingApprove
            ){
                Count
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHTRAININGREQUEST,
            variables: { TrainingApprove: [99] },
            errorPolicy: "all",
        });
    },
}

export default TrainingRequestService;