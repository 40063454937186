import React, { Component } from "react";
import {
  Grid,
} from "@material-ui/core";
import "./test.css";



class ReportProjectImportant extends Component {

  setDataDillDownTree = this.props.setDataDillDownTree ?? null;

  handleClickTree = (parent, child, year, tag) => {
    if(this.setDataDillDownTree) this.setDataDillDownTree(parent?.ProjectImportantGroupID, child?.ProjectImportantGroupSubID, year, this.props.type, tag?.TagID);
  }

  renderTree = (data, type) => {
    let dataAll = [];
    let el = [];
    let sizeData = data.reduce((total, value) => value?.ProjectCount > 0 ? total + 1 : total + 0, 0);
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      let elLevel2 = [];

      if(element.ProjectCount <= 0) {
        continue;
      }

      if(element.SubGroupChild?.length) {
        let elementLevel3 = element.SubGroupChild;
        let elLevel3 = []
        let sizeLevel3 = element.SubGroupChild.length;
        for (let k = 0; k < element.SubGroupChild.length; k++) {
          const Level3 = elementLevel3[k];
          let elLevel4 = []
          sizeLevel3 = Level3?.ProjectCount <= 0 ? (sizeLevel3 - 1) : sizeLevel3;
          

          if(Level3.MasterPlanCategory && !Level3?.SubGroupChild?.length) { //group tree
            const Level4 = Level3.MasterPlanCategory
            let size = Object.values(Level4).reduce((t, va) => va > 0 ? t + 1 : t + 0 , 0);
            if(this.props.type === 'tag') {
              element.TagID = element?.ProjectImportantGroupID;
            }
            for (const [index, [key, value]] of Object.entries(Object.entries(Level4))) {
                if(value === 0) continue;
                let year = Number(key) + 543;
                elLevel4.push(
                  <div  className={`entry lv4 ${size > 1 ? "" : "sole"}`} key={Level3.ProjectImportantGroupID  + i + '-' + k + '-' + index}>
                  <div>
                      <span className="label" onClick={ () => this.handleClickTree(element, Level3, year, element)}>
                        <span className="circle-label color-lv4">ปี {year.toString().substr(2, 4)}</span> 
                        {value} โครงการ
                      </span>
                  </div>
                 </div>
                )
            }
          }

          if(Level3?.SubGroupChild?.length) { // tag or  mapping group and tag
            let sizeElLevel4 = Level3?.SubGroupChild.length;
            for (let index = 0; index < Level3?.SubGroupChild.length; index++) {
              const tag = Level3?.SubGroupChild[index];
              sizeElLevel4 = tag.ProjectCount <= 0 ? (sizeElLevel4 - 1) : sizeElLevel4;
              if(tag.ProjectCount <= 0) continue;
              let elementTag = [];

              if(tag.MasterPlanCategory) {
                const tagCategory = tag.MasterPlanCategory
                let size = Object.values(tagCategory).reduce((t, va) => va > 0 ? t + 1 : t + 0 , 0);
                for (const [index, [key, value]] of Object.entries(Object.entries(tagCategory))) {
                    if(value === 0) continue;
                    let year = Number(key) + 543;
                    elementTag.push(
                      <div  className={`entry lv4 ${size > 1 ? "" : "sole"}`} key={tag.tag  + i + '-' + k + '-' + index + 'tag' + index}>
                      <div>
                          <span className="label" onClick={ () => this.handleClickTree(element, Level3, year, tag)}>
                            <span className="circle-label color-lv4">ปี {year.toString().substr(2, 4)}</span> 
                            {value} โครงการ
                          </span>
                      </div>
                     </div>
                    )
                }
              }

              
              elLevel4.push(
                <div  className={`entry level-2 ${sizeElLevel4 >= 2 ? "" : "sole"} `} key={Level3.ProjectImportantGroupID  + i + '-' + k + '-' + index}>
                <div>
                    <span className={`label 'level-2top' : ''}`} onClick={ () => this.handleClickTree(element, Level3, null, tag)}>
                      {type === 'tag' ? '' : tag.ProjectImportantGroupName} <br /> {tag.ProjectCount} โครงการ
                    </span>
                    {elementTag?.length > 0 ? 
                    <div className="branch lv3">
                          {elementTag}
                    </div> 
                    : null}
                </div>
               </div>
              )
            }
          }
          if(Level3?.ProjectCount > 0) {
              elLevel3.push(
                <div className={`entry ${sizeLevel3 <= 1 ? "sole" : ""}`} key={Level3.ProjectImportantGroupID  + i + '-' + k}>
                  <span className={`label `}  onClick={ () => this.handleClickTree(element, Level3)}> 
                    <span className="circle-label color-lv2"> {`${i + 1}.${k + 1}`}  </span> 
                    <span className='break-line'>
                      {type === 'tag' ? '' : Level3.ProjectImportantGroupName}
                      {/* {type === 'group' ?  <br /> : ''} */}
                      {Level3.ProjectCount} โครงการ
                    </span>
                    </span>
                  {elLevel4?.length > 0 ? 
                  <div className="branch lv3">
                        {elLevel4}
                  </div> 
                  : null}
                </div>
              )
            }
          }
          
        elLevel2.push(elLevel3)
      }
      if(element.ProjectCount > 0) {
        el.push(
          <div className={`entry ${sizeData <= 1 ? "sole" : ""}`} /* className="entry" */ key={`level 2`  + i}>
              <div>
                  <span className={`label level-2`} onClick={ () => this.handleClickTree(element)}> 
                    <span className="circle-label color-lv2"> {i + 1} </span>
                    <span className='break-line '>
                      {element.ProjectImportantGroupName}
                      {/* {type === 'tag' ? '' : <br /> }  */}
                      {type === 'tag' ? '' : ` ${element.ProjectCount} โครงการ`}
                    </span>
                  </span>
              </div>
              {
                  element.SubGroupChild?.length && element.ProjectCount > 0 ?
                  <div className="branch lv2">
                      {elLevel2}
                  </div>
                  : null
              }
          </div>
        );
      }
    }
    dataAll.push(el);
    return dataAll;
  };

  shouldComponentUpdate(nextProps) {
    if(nextProps.ProjectImportantTree === this.props?.ProjectImportantTree) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {
              <div className="tree-horizontal-container">
              {this.props.ProjectImportantTree && this.props?.total.Total > 0  ? (
                <div className="tree-horizontal-warpper">
                  <span className="header-label">
                    {this.props?.total.Total} โครงการ
                  </span>
                  <div className="branch lv1">
                    {/*  */}
                    {this.props.ProjectImportantTree ? this.renderTree(this.props.ProjectImportantTree, this.props.type) : null}
                  </div>
                </div>
              ) : <div>ไม่มีข้อมูล...</div>}
             </div>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default ReportProjectImportant;
