import Axios from "./_BaseService";
// import axios from 'axios';

const ProjectService = {
    searchProject: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        let GFMISCode = data.GFMISCode || undefined;
        if (GFMISCode) {
            GFMISCode = String(GFMISCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }

        data = {
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: GFMISCode,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            StrategyID: data.StrategyID || undefined,
            Keyword: data.Keyword || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            HasMasterPlan: data.HasMasterPlan ?? undefined,
            IsLock: data.IsLock ?? undefined,
            MYProject: data.MYProject ?? undefined,
            HasComment: data.HasComment ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            SignoffID: data.SignoffID || undefined,
            Offset: data.Offset,
            Limit: data.Limit,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined,
            GetMap: data.GetMap ?? undefined
        };

        const SEARCHPROJECT = `
            query SearchProject(
                $ProjectName: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $StrategyID: [Int],
                $Keyword: String,
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusID: [Int],
                $HasMasterPlan: Boolean,
                $IsLock: Int,
                $MYProject: Boolean,
                $HasComment: Boolean,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $SignoffID: Int,
                $Offset: Int,
                $Limit: Int,
                $ProjectTypeAmount: Int,
                $GetMap: Boolean
            ) {
                SearchProject(
                    ProjectName: $ProjectName,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    StrategyID: $StrategyID,
                    Keyword: $Keyword,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusID: $ReadinessStatusID,
                    HasMasterPlan: $HasMasterPlan,
                    IsLock: $IsLock,
                    MYProject: $MYProject,
                    HasComment: $HasComment,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    SignoffID: $SignoffID,
                    Offset: $Offset,
                    Limit: $Limit,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    GetMap: $GetMap
                ){
                    Projects {
                        ProjectID
                        ProjectCode,
                        ProjectName,
                        BudgetYear,
                        ProjectTypeName,
                        OrganizationName,
                        ProjectStatusName,
                        ProjectStatusID,
                        MasterPlanID,
                        MasterPlanName,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        BudgetDimensionName,
                        IsLock,
                        OperationStartYear,
                        OperationEndYear,
                        MYProject,
                        OrderProject,
                        OrderProvince,
                        OrderBasin,
                        OrderCountry,
                        OrderGroup,
                        CommentCount,
                        SignoffID
                    }
                    Count
                    CQLFilter
                    LayerStyle {
                        LayerID
                        CQLFilter
                        Style
                    }
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: SEARCHPROJECT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 90000,
            }
        );
    },

    searchProjectID: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        let GFMISCode = data.GFMISCode || undefined;
        if (GFMISCode) {
            GFMISCode = String(GFMISCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }

        data = {
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: GFMISCode,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            StrategyID: data.StrategyID || undefined,
            Keyword: data.Keyword || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            HasMasterPlan: data.HasMasterPlan ?? undefined,
            IsLock: data.IsLock ?? undefined,
            MYProject: data.MYProject ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            SignoffID: data.SignoffID || undefined,
            Offset: data.Offset,
            Limit: 50000,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined
        };
        const SEARCHPROJECT = `
            query SearchProject(
                $ProjectName: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $StrategyID: [Int],
                $Keyword: String,
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusID: [Int],
                $HasMasterPlan: Boolean,
                $IsLock: Int,
                $MYProject: Boolean,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $SignoffID: Int,
                $Offset: Int,
                $Limit: Int,
                $ProjectTypeAmount: Int
            ) {
                SearchProject(
                    ProjectName: $ProjectName,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    StrategyID: $StrategyID,
                    Keyword: $Keyword,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusID: $ReadinessStatusID,
                    HasMasterPlan: $HasMasterPlan,
                    IsLock: $IsLock,
                    MYProject: $MYProject,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    SignoffID: $SignoffID,
                    Offset: $Offset,
                    Limit: $Limit,
                    ProjectTypeAmount: $ProjectTypeAmount
                ){
                    Projects {
                        ProjectID
                    }
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: SEARCHPROJECT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 90000,
            }
        );
    },

    checkDiffBudgetYear: (ProjectIDs, GroupOrder, GroupOrderDesc, IsspreadSheetMode, onlyBudgetSource) => {
        const CHECKDIFFBUDGETYEAR = `
            query CheckDiffBudgetYear (
                $ProjectIDs: [Int!]!
                $GroupOrder: Int
                $GroupOrderDesc: Int
                $IsspreadSheetMode: Boolean
            ) {
                CheckDiffBudgetYear (
                    ProjectIDs: $ProjectIDs
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    IsspreadSheetMode: $IsspreadSheetMode
                ){
                    ${onlyBudgetSource ?
                ""
                :
                `
                ProjectIDs
                BudgetYear
                SignoffID
                ProjectLongtermCount
                BudgetDimensionID
                `
            }
                    BudgetSourceID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: CHECKDIFFBUDGETYEAR,
            variables: {
                ProjectIDs: ProjectIDs,
                GroupOrder: GroupOrder,
                GroupOrderDesc: GroupOrderDesc,
                IsspreadSheetMode: IsspreadSheetMode
            },
            errorPolicy: "all",
        });
    },

    getProjectDetail: (ProjectID) => {
        const GETPROJECTDETAIL = `
            query GetProjectDetail (
                $ProjectID: Int!
            ){
                GetProjectDetail (
                    ProjectID: $ProjectID
                ) {
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    GFMIS,
                    Moo,
                    Village,
                    SubdistrictID,
                    SubdistrictName,
                    DistrictID,
                    DistrictName,
                    ProvinceID,
                    ProvinceName,
                    SubbasinID,
                    SubbasinName,
                    BasinID,
                    BasinName,
                    Lat,
                    Lng,
                    OrganizationID,
                    DLAOrganizationID,
                    OperatorOrganizationID,
                    OrganizationName,
                    DLAOrganizationName,
                    OperatorOrganizationName,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetSourceName,
                    BudgetTypeID,
                    BudgetTypeName,
                    ActivityID,
                    ActivityName,
                    ProjectTypeID,
                    ProjectTypeCode,
                    ProjectTypeName,
                    AreaBaseID,
                    AreaBaseName,
                    BudgetDimensionID,
                    BudgetDimensionName,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    StrategyID,
                    StrategyName,
                    KPIPlan {
                        KPIID,
                        KPIName,
                        KPIUnitName,
                        QuantitativeValue
                    },
                    TProjectImportantID,
                    ProjectImportantName,
                    EducationPrepareID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    EducationPrepareName,
                    LandPrepareName,
                    ListPatternPrepareName,
                    EstimatePrepareName,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    ProjectStatusID,
                    ProjectStatusName,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    Cashflow {
                        Year1,
                        Year2,
                        Year3,
                        Year4,
                        Year5,
                        Year6,
                        YearLast
                    },
                    CashflowAll {
                        ProjectID,
                        CashflowYear,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        GFMISCode,
                        GFMIS
                    },
                    MasterPlanID,
                    MasterPlanName,
                    Files {
                        FileID,
                        FileName,
                        GenFileName,
                        ProjectPrepareStatusID,
                        ProjectPrepareStatusName,
                        Description,
                        Path
                    },
                    IsLock,
                    MYProject,
                    SignoffName,
                    SignoffID,
                    SignoffStep {
                        SignoffID
                        SignoffStepID
                        SignoffStepName
                        ReadinessID
                        ReadinessStatusID
                        ReadinessStatusName
                        Remark
                        VerifiedName
                    }
                    ReadinessStatusID,
                    ReadinessStatusName,
                    WrongPrepare,
                    WaterResource,
                    ScoreCategory1,
                    ScoreCategory1_1,
                    ScoreCategory1_2,
                    ScoreCategory2,
                    ScoreCategory3,
                    ScoreCategory4,
                    ScoreCategory1_1String,
                    ScoreCategory1_2String,
                    WMIScore,
                    IsPolicyProject,
                    Description,
                    PolicyTypeID,
                    PolicyTypeName,
                    PolicyDate
                }
            }
        `;
        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTDETAIL,
                variables: {
                    ProjectID: ProjectID,
                },
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },

    getProjectDetailByCode: (ProjectCode) => {
        ProjectCode = ProjectCode ? ProjectCode.trim() : undefined;
        const GETPROJECTDETAILBYCODE = `
            query GetProjectDetailByCode (
                $ProjectCode: String!
            ){
                GetProjectDetailByCode (
                    ProjectCode: $ProjectCode
                ) {
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    GFMIS,
                    Moo,
                    Village,
                    SubdistrictID,
                    SubdistrictName,
                    DistrictID,
                    DistrictName,
                    ProvinceID,
                    ProvinceName,
                    SubbasinID,
                    SubbasinName,
                    BasinID,
                    BasinName,
                    Lat,
                    Lng,
                    OrganizationID,
                    DLAOrganizationID,
                    OperatorOrganizationID,
                    OrganizationName,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetSourceName,
                    BudgetTypeID,
                    BudgetTypeName,
                    ActivityID,
                    ActivityName,
                    ProjectTypeID,
                    ProjectTypeCode,
                    ProjectTypeName,
                    AreaBaseID,
                    AreaBaseName,
                    BudgetDimensionID,
                    BudgetDimensionName,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    StrategyID,
                    StrategyName,
                    KPIPlan {
                        KPIID,
                        KPIName,
                        KPIUnitName,
                        QuantitativeValue
                    },
                    TProjectImportantID,
                    EducationPrepareID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    EducationPrepareName,
                    LandPrepareName,
                    ListPatternPrepareName,
                    EstimatePrepareName,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    ProjectStatusID,
                    ProjectStatusName,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    Cashflow {
                        Year1,
                        Year2,
                        Year3,
                        Year4,
                        Year5,
                        Year6,
                        YearLast
                    },
                    MasterPlanID,
                    MasterPlanName,
                    IsLock,
                    MYProject,
                    SignoffID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTDETAILBYCODE,
            variables: {
                ProjectCode: ProjectCode,
            },
            errorPolicy: "all",
        });
    },

    getProjectDetail001: (ProjectID) => {
        const GETPROJECTDETAIL = `
            query GetProjectDetail (
                $ProjectID: Int!
            ){
                GetProjectDetail (
                    ProjectID: $ProjectID
                ) {
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    GFMIS,
                    Moo,
                    Village,
                    SubdistrictID,
                    DistrictID,
                    ProvinceID,
                    SubbasinID,
                    BasinID,
                    Lat,
                    Lng,
                    OrganizationID,
                    DLAOrganizationID,
                    OperatorOrganizationID,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetTypeID,
                    ActivityID,
                    ProjectTypeID,
                    ProjectTypeCode,
                    AreaBaseID,
                    BudgetDimensionID,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    StrategyID,
                    KPIPlan {
                        KPIID
                        KPIName
                        QuantitativeValue
                        KPIUnitName
                    },
                    TProjectImportantID,
                    EducationPrepareID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    ProjectStatusID,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    Cashflow {
                        Year1,
                        Year2,
                        Year3,
                        Year4,
                        Year5,
                        Year6,
                        YearLast
                    },
                    CashflowAll {
                        ProjectID,
                        CashflowYear,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        GFMISCode,
                        GFMIS
                    },
                    MasterPlanID,
                    MasterPlanName,
                    Files {
                        FileID,
                        FileName,
                        GenFileName,
                        ProjectPrepareStatusID,
                        ProjectPrepareStatusName,
                        Description,
                        Path
                    },
                    IsLock,
                    MYProject,
                    SignoffID,
                    WaterResource,
                    IsPolicyProject,
                    Description,
                    PolicyTypeID,
                    PolicyTypeName,
                    PolicyDate
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTDETAIL,
            variables: {
                ProjectID: ProjectID,
            },
            errorPolicy: "all",
        });
    },

    getProjectDetailByCode001: (ProjectCode) => {
        ProjectCode = ProjectCode ? ProjectCode.trim() : undefined;
        const GETPROJECTDETAILBYCODE = `
            query GetProjectDetailByCode (
                $ProjectCode: String!
            ){
                GetProjectDetailByCode (
                    ProjectCode: $ProjectCode
                ) {
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    GFMIS,
                    Moo,
                    Village,
                    SubdistrictID,
                    DistrictID,
                    ProvinceID,
                    SubbasinID,
                    BasinID,
                    Lat,
                    Lng,
                    OrganizationID,
                    DLAOrganizationID,
                    OperatorOrganizationID,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetTypeID,
                    ActivityID,
                    ProjectTypeID,
                    ProjectTypeCode,
                    AreaBaseID,
                    BudgetDimensionID,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    StrategyID,
                    KPIPlan {
                        KPIID
                        KPIName
                        QuantitativeValue
                        KPIUnitName
                    },
                    TProjectImportantID,
                    EducationPrepareID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    ProjectStatusID,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    Cashflow {
                        Year1,
                        Year2,
                        Year3,
                        Year4,
                        Year5,
                        Year6,
                        YearLast
                    },
                    CashflowAll {
                        ProjectID,
                        CashflowYear,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        GFMISCode,
                        GFMIS
                    },
                    MasterPlanID,
                    MasterPlanName,
                    IsLock,
                    MYProject,
                    SignoffID,
                    WaterResource
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTDETAILBYCODE,
            variables: {
                ProjectCode: ProjectCode,
            },
            errorPolicy: "all",
        });
    },

    getProjectDetail004: (ProjectID) => {
        const GETPROJECTDETAIL = `
            query GetProjectDetail (
                $ProjectID: Int!
            ){
                GetProjectDetail (
                    ProjectID: $ProjectID
                ) {
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    GFMIS,
                    Moo,
                    Village,
                    SubdistrictID,
                    DistrictID,
                    ProvinceID,
                    BasinID,
                    SubbasinID,
                    Lat,
                    Lng,
                    OrganizationID,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    ActivityID,
                    ProjectTypeID,
                    ProjectTypeCode,
                    RequestAmount,
                    DraftAmount,
                    AllocateAmount,
                    StrategyID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    BudgetTypeID,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    OrderProvince,
                    OrderBasin,
                    OrderCountry,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    MasterPlanID,
                    MasterPlanName,
                    Files {
                        FileID,
                        FileName,
                        GenFileName,
                        ProjectPrepareStatusID,
                        ProjectPrepareStatusName,
                        Description,
                        Path
                    },
                    IsLock,
                    MYProject,
                    SignoffID,
                    WaterResource
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETPROJECTDETAIL,
            variables: {
                ProjectID: ProjectID,
            },
            errorPolicy: "all",
        });
    },

    createProject: (data) => {
        data = {
            FormType: data.FormType || 1,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            GFMISCode: data.GFMISCode ? data.GFMISCode.trim() : undefined,
            GFMIS: data.GFMIS ? data.GFMIS.trim() : undefined,
            Moo: data.Moo ? data.Moo.trim() : undefined,
            Village: data.Village ? data.Village.trim() : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lng ? data.Lng : undefined,
            OrganizationID: data.OrganizationID,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID,
            BudgetYear: data.BudgetYear,
            OperationStartYear: data.OperationStartYear,
            OperationEndYear: data.OperationEndYear,
            OperationStartMonth: data.OperationStartMonth || undefined,
            OperationEndMonth: data.OperationEndMonth || undefined,
            Duration: data.Duration || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            TProjectImportantID: data.TProjectImportantID || undefined,
            RequestAmount: !data.RequestAmount && typeof data.RequestAmount !== 'number' ? undefined : data.RequestAmount,
            DraftAmount: !data.DraftAmount && typeof data.DraftAmount !== 'number' ? undefined : data.DraftAmount,
            ApproveAmount: data.ApproveAmount || undefined,
            AllocateAmount: !data.AllocateAmount && typeof data.AllocateAmount !== 'number' ? undefined : data.AllocateAmount,
            StrategyID: data.StrategyID || undefined,
            KPIPlan: data.KPIPlan || undefined,
            EducationPrepareID: data.EducationPrepareID || undefined,
            LandPrepareID: data.LandPrepareID || undefined,
            ListPatternPrepareID: data.ListPatternPrepareID || undefined,
            EstimatePrepareID: data.EstimatePrepareID || undefined,
            Remark: data.Remark ? data.Remark.trim() : undefined,
            OrderProject: data.OrderProject || undefined,
            OrderProvince: data.OrderProvince || undefined,
            OrderBasin: data.OrderBasin || undefined,
            OrderCountry: data.OrderCountry || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            BenefitArea: data.BenefitArea || undefined,
            WaterCapacity: data.WaterCapacity || undefined,
            BenefitHousehold: data.BenefitHousehold || undefined,
            Cashflow: data.Cashflow || undefined,
            CashflowAll: data.CashflowAll.length ? data.CashflowAll : undefined,
            ParentMasterPlanID: data.ParentMasterPlanID || undefined,
            ParentProjectID: data.ParentProjectID || undefined,
            TagID: data.TagID || undefined,
            SignoffID: data.SignoffID || undefined,
            WaterResource: data.WaterResource?.length ? data.WaterResource : undefined,
            IsPolicyProject: data.IsPolicyProject || undefined,
            Description: data.Description || undefined,
            PolicyTypeID: data.PolicyTypeID || undefined,
            PolicyDate: data.PolicyDate || undefined
        };
        const CREATEPROJECT = `
        mutation CreateProject(
            $FormType: Int
            $ProjectName: String!
            $GFMISCode: String
            $GFMIS: String
            $Moo: String
            $Village: String
            $SubdistrictID: Int
            $DistrictID: Int
            $ProvinceID: Int!
            $SubbasinID: Int
            $BasinID: Int
            $Lat: Float
            $Lng: Float
            $OrganizationID: Int!
            $DLAOrganizationID: Int
            $OperatorOrganizationID: Int
            $BudgetYear: Int!
            $OperationStartYear: Int!
            $OperationEndYear: Int!
            $OperationStartMonth: Int
            $OperationEndMonth: Int
            $Duration: Int
            $BudgetSourceID: Int
            $BudgetTypeID: Int
            $ActivityID: Int
            $ProjectTypeID: Int
            $AreaBaseID: Int
            $BudgetDimensionID: Int
            $TProjectImportantID: Int
            $RequestAmount: Float
            $DraftAmount: Float
            $ApproveAmount: Float
            $AllocateAmount: Float
            $StrategyID: Int
            $KPIPlan: [InputKPIPlan]
            $EducationPrepareID: Int
            $LandPrepareID: Int
            $ListPatternPrepareID: Int
            $EstimatePrepareID: Int
            $Remark: String
            $OrderProject: Int
            $OrderProvince: Int
            $OrderBasin: Int
            $OrderCountry: Int
            $ProjectStatusID: Int
            $BenefitArea: Float
            $WaterCapacity: Float
            $BenefitHousehold: Int
            $Cashflow: InputCashflow,
            $CashflowAll: [InputProjectCashflowAll]
            $ParentMasterPlanID: Int,
            $ParentProjectID: Int
            $TagID: Int
            $SignoffID: Int
            $WaterResource: [JSON]
            $IsPolicyProject: Int
            $Description: String
            $PolicyTypeID: Int
            $PolicyDate: Date
        ) {
            CreateProject(
                FormType: $FormType
                ProjectName: $ProjectName
                GFMISCode: $GFMISCode
                GFMIS: $GFMIS
                Moo: $Moo
                Village: $Village
                SubdistrictID: $SubdistrictID
                DistrictID: $DistrictID
                ProvinceID: $ProvinceID
                SubbasinID: $SubbasinID
                BasinID: $BasinID
                Lat: $Lat
                Lng: $Lng
                OrganizationID: $OrganizationID
                DLAOrganizationID: $DLAOrganizationID
                OperatorOrganizationID: $OperatorOrganizationID
                BudgetYear: $BudgetYear
                OperationStartYear: $OperationStartYear
                OperationEndYear: $OperationEndYear
                OperationStartMonth: $OperationStartMonth
                OperationEndMonth: $OperationEndMonth
                Duration: $Duration
                BudgetSourceID: $BudgetSourceID
                BudgetTypeID: $BudgetTypeID
                ActivityID: $ActivityID
                ProjectTypeID: $ProjectTypeID
                AreaBaseID: $AreaBaseID
                BudgetDimensionID: $BudgetDimensionID
                TProjectImportantID: $TProjectImportantID
                RequestAmount: $RequestAmount
                DraftAmount: $DraftAmount
                ApproveAmount: $ApproveAmount
                AllocateAmount: $AllocateAmount
                StrategyID: $StrategyID
                KPIPlan: $KPIPlan
                EducationPrepareID: $EducationPrepareID
                LandPrepareID: $LandPrepareID
                ListPatternPrepareID: $ListPatternPrepareID
                EstimatePrepareID: $EstimatePrepareID
                Remark: $Remark
                OrderProject: $OrderProject
                OrderProvince: $OrderProvince
                OrderBasin: $OrderBasin
                OrderCountry: $OrderCountry
                ProjectStatusID: $ProjectStatusID
                BenefitArea: $BenefitArea
                WaterCapacity: $WaterCapacity
                BenefitHousehold: $BenefitHousehold
                Cashflow: $Cashflow,
                CashflowAll: $CashflowAll
                ParentMasterPlanID: $ParentMasterPlanID
                ParentProjectID: $ParentProjectID
                TagID: $TagID
                SignoffID: $SignoffID
                WaterResource: $WaterResource
                IsPolicyProject: $IsPolicyProject 
                Description: $Description
                PolicyTypeID: $PolicyTypeID
                PolicyDate: $PolicyDate
            ) {
                ProjectID
            }
        }`;

        return Axios.post("/twpservice", {
            query: CREATEPROJECT,
            variables: data,
            errorPolicy: "all",
        });
    },

    updateProject: (data) => {
        data = {
            FormType: data.FormType || 1,
            ProjectID: data.ProjectID,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            GFMISCode: data.GFMISCode ? data.GFMISCode.trim() : undefined,
            GFMIS: data.GFMIS ? data.GFMIS.trim() : undefined,
            Moo: data.Moo ? data.Moo.trim() : undefined,
            Village: data.Village ? data.Village.trim() : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lng ? data.Lng : undefined,
            OrganizationID: data.OrganizationID,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID,
            BudgetYear: data.BudgetYear,
            OperationStartYear: data.OperationStartYear,
            OperationEndYear: data.OperationEndYear,
            OperationStartMonth: data.OperationStartMonth || undefined,
            OperationEndMonth: data.OperationEndMonth || undefined,
            Duration: data.Duration || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            TProjectImportantID: data.TProjectImportantID || undefined,
            RequestAmount: !data.RequestAmount && typeof data.RequestAmount !== 'number' ? undefined : data.RequestAmount,
            DraftAmount: !data.DraftAmount && typeof data.DraftAmount !== 'number' ? undefined : data.DraftAmount,
            ApproveAmount: data.ApproveAmount || undefined,
            AllocateAmount: !data.AllocateAmount && typeof data.AllocateAmount !== 'number' ? undefined : data.AllocateAmount,
            StrategyID: data.StrategyID || undefined,
            KPIPlan: data.KPIPlan || undefined,
            EducationPrepareID: data.EducationPrepareID || undefined,
            LandPrepareID: data.LandPrepareID || undefined,
            ListPatternPrepareID: data.ListPatternPrepareID || undefined,
            EstimatePrepareID: data.EstimatePrepareID || undefined,
            Remark: data.Remark ? data.Remark.trim() : undefined,
            OrderProject: data.OrderProject || undefined,
            OrderProvince: data.OrderProvince || undefined,
            OrderBasin: data.OrderBasin || undefined,
            OrderCountry: data.OrderCountry || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            BenefitArea: data.BenefitArea || undefined,
            WaterCapacity: data.WaterCapacity || undefined,
            BenefitHousehold: data.BenefitHousehold || undefined,
            Cashflow: data.Cashflow || undefined,
            CashflowAll: data.CashflowAll.length ? data.CashflowAll : undefined,
            ParentMasterPlanID: data.ParentMasterPlanID || undefined,
            ParentProjectID: data.ParentProjectID || undefined,
            Files: data.Files?.length ? data.Files : undefined,
            DelFiles: data.DelFiles?.length ? data.DelFiles : undefined,
            WaterResource: data.WaterResource.length ? data.WaterResource : undefined,
            IsPolicyProject: data.IsPolicyProject || undefined,
            Description: data.Description || undefined,
            PolicyTypeID: data.PolicyTypeID || undefined,
            PolicyDate: data.PolicyDate || undefined
        };
        const UPDATEPROJECT = `
        mutation UpdateProject(
            $FormType: Int
            $ProjectID: Int!,
            $ProjectName: String!
            $GFMISCode: String
            $GFMIS: String
            $Moo: String
            $Village: String
            $SubdistrictID: Int
            $DistrictID: Int
            $ProvinceID: Int!
            $SubbasinID: Int
            $BasinID: Int
            $Lat: Float
            $Lng: Float
            $OrganizationID: Int!
            $DLAOrganizationID: Int
            $OperatorOrganizationID: Int
            $BudgetYear: Int!
            $OperationStartYear: Int!
            $OperationEndYear: Int!
            $OperationStartMonth: Int
            $OperationEndMonth: Int
            $Duration: Int
            $BudgetSourceID: Int
            $BudgetTypeID: Int
            $ActivityID: Int
            $ProjectTypeID: Int
            $AreaBaseID: Int
            $BudgetDimensionID: Int
            $TProjectImportantID: Int
            $RequestAmount: Float
            $DraftAmount: Float
            $ApproveAmount: Float
            $AllocateAmount: Float
            $StrategyID: Int
            $KPIPlan: [InputKPIPlan]
            $EducationPrepareID: Int
            $LandPrepareID: Int
            $ListPatternPrepareID: Int
            $EstimatePrepareID: Int
            $Remark: String
            $OrderProject: Int
            $OrderProvince: Int
            $OrderBasin: Int
            $OrderCountry: Int
            $ProjectStatusID: Int
            $BenefitArea: Float
            $WaterCapacity: Float
            $BenefitHousehold: Int
            $Cashflow: InputCashflow,
            $CashflowAll: [InputProjectCashflowAll],
            $ParentMasterPlanID: Int,
            $ParentProjectID: Int,
            $Files: [InputProjectFile],
            $DelFiles: [InputProjectFile],
            $WaterResource: [JSON]
            $IsPolicyProject: Int
            $Description: String
            $PolicyTypeID: Int
            $PolicyDate: Date
        ) {
            UpdateProject(
                FormType: $FormType
                ProjectID: $ProjectID
                ProjectName: $ProjectName
                GFMISCode: $GFMISCode
                GFMIS: $GFMIS
                Moo: $Moo
                Village: $Village
                SubdistrictID: $SubdistrictID
                DistrictID: $DistrictID
                ProvinceID: $ProvinceID
                SubbasinID: $SubbasinID
                BasinID: $BasinID
                Lat: $Lat
                Lng: $Lng
                OrganizationID: $OrganizationID
                DLAOrganizationID: $DLAOrganizationID
                OperatorOrganizationID: $OperatorOrganizationID
                BudgetYear: $BudgetYear
                OperationStartYear: $OperationStartYear
                OperationEndYear: $OperationEndYear
                OperationStartMonth: $OperationStartMonth
                OperationEndMonth: $OperationEndMonth
                Duration: $Duration
                BudgetSourceID: $BudgetSourceID
                BudgetTypeID: $BudgetTypeID
                ActivityID: $ActivityID
                ProjectTypeID: $ProjectTypeID
                AreaBaseID: $AreaBaseID
                BudgetDimensionID: $BudgetDimensionID
                TProjectImportantID: $TProjectImportantID
                RequestAmount: $RequestAmount
                DraftAmount: $DraftAmount
                ApproveAmount: $ApproveAmount
                AllocateAmount: $AllocateAmount
                StrategyID: $StrategyID
                KPIPlan: $KPIPlan
                EducationPrepareID: $EducationPrepareID
                LandPrepareID: $LandPrepareID
                ListPatternPrepareID: $ListPatternPrepareID
                EstimatePrepareID: $EstimatePrepareID
                Remark: $Remark
                OrderProject: $OrderProject
                OrderProvince: $OrderProvince
                OrderBasin: $OrderBasin
                OrderCountry: $OrderCountry
                ProjectStatusID: $ProjectStatusID
                BenefitArea: $BenefitArea
                WaterCapacity: $WaterCapacity
                BenefitHousehold: $BenefitHousehold
                Cashflow: $Cashflow,
                CashflowAll: $CashflowAll,
                ParentMasterPlanID: $ParentMasterPlanID,
                ParentProjectID: $ParentProjectID,
                Files: $Files,
                DelFiles: $DelFiles
                WaterResource: $WaterResource
                IsPolicyProject: $IsPolicyProject 
                Description: $Description
                PolicyTypeID: $PolicyTypeID
                PolicyDate: $PolicyDate
            ) {
                ProjectID
            }
        }`;

        return Axios.post("/twpservice", {
            query: UPDATEPROJECT,
            variables: data,
            errorPolicy: "all",
        });
    },

    changeBudgetYearByID: (args) => {
        const payload = {
            ProjectID: args.selected,
            BudgetYearToChange: args.TargetYear - 543
        }

        const CHANGEBUDGETYEARBYID = `
            mutation ChangeBudgetYearByID(
                $ProjectID: [Int!]
                $BudgetYearToChange: Int!
            ) {
                ChangeBudgetYearByID(
                    ProjectID: $ProjectID
                    BudgetYearToChange: $BudgetYearToChange
                )
            }
        `

        return Axios.post("/twpservice", {
            query: CHANGEBUDGETYEARBYID,
            variables: payload,
            errorPolicy: "all",
        });
    },

    changeBudgetYearByFilter: (args) => {
        let ProjectCode = args.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        let GFMISCode = args.GFMISCode || undefined;
        if (GFMISCode) {
            GFMISCode = String(GFMISCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }

        args = {
            BudgetYearToChange: args.BudgetYearToChange - 543,
            ProjectName: args.ProjectName ? args.ProjectName.trim() : undefined,
            BudgetYear: args.BudgetYear || undefined,
            GFMISCode: GFMISCode,
            SubdistrictID: args.SubdistrictID || undefined,
            DistrictID: args.DistrictID || undefined,
            ProvinceID: args.ProvinceID || undefined,
            SubbasinID: args.SubbasinID || undefined,
            BasinID: args.BasinID || undefined,
            OrganizationID: args.OrganizationID || undefined,
            DLAOrganizationID: args.DLAOrganizationID || undefined,
            OperatorOrganizationID: args.OperatorOrganizationID || undefined,
            BudgetSourceID: args.BudgetSourceID || undefined,
            BudgetDimensionID: args.BudgetDimensionID || undefined,
            ProjectStatusID: args.ProjectStatusID || undefined,
            ProjectCode: ProjectCode || undefined,
            BudgetTypeID: args.BudgetTypeID || undefined,
            ActivityID: args.ActivityID || undefined,
            ProjectTypeID: args.ProjectTypeID || undefined,
            AreaBaseID: args.AreaBaseID || undefined,
            StrategyID: args.StrategyID || undefined,
            Keyword: args.Keyword || undefined,
            TagID: args.TagID || undefined,
            BudgetRangeStart: args.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: args.BudgetRangeEnd ?? undefined,
            HasMasterPlan: args.HasMasterPlan ?? undefined,
            IsLock: args.IsLock ?? undefined,
            MYProject: args.MYProject ?? undefined,
            HasComment: args.HasComment ?? undefined,
            ReadinessStatusID: args.ReadinessStatusID || undefined,
            GroupOrder: args.GroupOrder || undefined,
            GroupOrderDesc: args.GroupOrderDesc || undefined,
            SignoffID: args.SignoffID || undefined,
            Offset: args.Offset,
            Limit: args.Limit,
            ProjectTypeAmount: args.ProjectTypeAmount ?? undefined,
            GetMap: args.GetMap ?? undefined
        };

        const CHANGEBUDGETYEARBYFILTER = `
            mutation ChangeBudgetYearFilter(
                $BudgetYearToChange: Int!,
                $ProjectName: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $StrategyID: [Int],
                $Keyword: String,
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusID: [Int],
                $HasMasterPlan: Boolean,
                $IsLock: Int,
                $MYProject: Boolean,
                $HasComment: Boolean,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $SignoffID: Int,
                $Offset: Int,
                $Limit: Int,
                $ProjectTypeAmount: Int,
                $GetMap: Boolean
            ) {
                ChangeBudgetYearFilter(
                    BudgetYearToChange: $BudgetYearToChange,
                    ProjectName: $ProjectName,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    StrategyID: $StrategyID,
                    Keyword: $Keyword,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusID: $ReadinessStatusID,
                    HasMasterPlan: $HasMasterPlan,
                    IsLock: $IsLock,
                    MYProject: $MYProject,
                    HasComment: $HasComment,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    SignoffID: $SignoffID,
                    Offset: $Offset,
                    Limit: $Limit,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    GetMap: $GetMap
                )
            }
        `

        return Axios.post("/twpservice", {
            query: CHANGEBUDGETYEARBYFILTER,
            variables: args,
            errorPolicy: "all",
        });
    },

    checkChangeBudgetYearByID: (args) => {
        const payload = {
            ProjectID: args.selected,
            BudgetYearToChange: args.TargetYear - 543
        }

        const CHECKCHANGEBUDGETYEARBYID = `
            query CheckChangeBudgetYearByID(
                $ProjectID: [Int!]
            ) {
                CheckChangeBudgetYearByID(
                    ProjectID: $ProjectID
                ) {
                    BudgetStartYear,
                    BudgetEndYear
                }
            }
        `

        return Axios.post("/twpservice", {
            query: CHECKCHANGEBUDGETYEARBYID,
            variables: payload,
            errorPolicy: "all",
        });
    },

    checkChangeBudgetYearByFilter: (args) => {
        let ProjectCode = args.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        let GFMISCode = args.GFMISCode || undefined;
        if (GFMISCode) {
            GFMISCode = String(GFMISCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }

        args = {
            ProjectName: args.ProjectName ? args.ProjectName.trim() : undefined,
            BudgetYear: args.BudgetYear || undefined,
            GFMISCode: GFMISCode,
            SubdistrictID: args.SubdistrictID || undefined,
            DistrictID: args.DistrictID || undefined,
            ProvinceID: args.ProvinceID || undefined,
            SubbasinID: args.SubbasinID || undefined,
            BasinID: args.BasinID || undefined,
            OrganizationID: args.OrganizationID || undefined,
            DLAOrganizationID: args.DLAOrganizationID || undefined,
            OperatorOrganizationID: args.OperatorOrganizationID || undefined,
            BudgetSourceID: args.BudgetSourceID || undefined,
            BudgetDimensionID: args.BudgetDimensionID || undefined,
            ProjectStatusID: args.ProjectStatusID || undefined,
            ProjectCode: ProjectCode || undefined,
            BudgetTypeID: args.BudgetTypeID || undefined,
            ActivityID: args.ActivityID || undefined,
            ProjectTypeID: args.ProjectTypeID || undefined,
            AreaBaseID: args.AreaBaseID || undefined,
            StrategyID: args.StrategyID || undefined,
            Keyword: args.Keyword || undefined,
            TagID: args.TagID || undefined,
            BudgetRangeStart: args.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: args.BudgetRangeEnd ?? undefined,
            HasMasterPlan: args.HasMasterPlan ?? undefined,
            IsLock: args.IsLock ?? undefined,
            MYProject: args.MYProject ?? undefined,
            HasComment: args.HasComment ?? undefined,
            ReadinessStatusID: args.ReadinessStatusID || undefined,
            GroupOrder: args.GroupOrder || undefined,
            GroupOrderDesc: args.GroupOrderDesc || undefined,
            SignoffID: args.SignoffID,
            Offset: args.Offset,
            Limit: args.Limit,
            ProjectTypeAmount: args.ProjectTypeAmount ?? undefined,
            GetMap: args.GetMap ?? undefined
        };

        const CHECKCHANGEBUDGETYEARBYFILTER = `
            query CheckChangeBudgetYearFilter(
                $ProjectName: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $StrategyID: [Int],
                $Keyword: String,
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusID: [Int],
                $HasMasterPlan: Boolean,
                $IsLock: Int,
                $MYProject: Boolean,
                $HasComment: Boolean,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $SignoffID: Int!,
                $Offset: Int,
                $Limit: Int,
                $ProjectTypeAmount: Int,
                $GetMap: Boolean
            ) {
                CheckChangeBudgetYearFilter(
                    ProjectName: $ProjectName,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    StrategyID: $StrategyID,
                    Keyword: $Keyword,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusID: $ReadinessStatusID,
                    HasMasterPlan: $HasMasterPlan,
                    IsLock: $IsLock,
                    MYProject: $MYProject,
                    HasComment: $HasComment,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    SignoffID: $SignoffID,
                    Offset: $Offset,
                    Limit: $Limit,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    GetMap: $GetMap
                ) {
                    BudgetStartYear,
                    BudgetEndYear
                }
            }
        `

        return Axios.post("/twpservice", {
            query: CHECKCHANGEBUDGETYEARBYFILTER,
            variables: args,
            errorPolicy: "all",
        });
    },

    updateIsLockProject: (ProjectID) => {
        const UPDATEISLOCKPROJECT = `
            mutation UpdateIsLockProject($ProjectID: Int!) {
                UpdateIsLockProject(ProjectID: $ProjectID)
            }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEISLOCKPROJECT,
            variables: {
                ProjectID: ProjectID,
            },
            errorPolicy: "all",
        });
    },

    deleteProject: (ProjectID) => {
        const DELETEPROJECT = `
            mutation DeleteProject(
                $ProjectID: Int!
            ) {
                DeleteProject(
                    ProjectID: $ProjectID
                ){
                    ProjectID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEPROJECT,
            variables: {
                ProjectID: ProjectID,
            },
            errorPolicy: "all",
        });
    },

    deleteProjectMultiple: (ProjectID) => {
        const DELETEPROJECT = `
            mutation DeleteProjectMultiple(
                $ProjectID: [Int]!
            ) {
                DeleteProjectMultiple(
                    ProjectID: $ProjectID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: DELETEPROJECT,
            variables: {
                ProjectID: ProjectID,
            },
            errorPolicy: "all",
        });
    },

    ///////////////// SPREADSHEET ///////////////////

    getHeaderSpreadsheet001: (args) => {
        let data = {
            Year: args.Year,
            Type: args.Type,
            SignoffID: args.SignoffID,
            GroupOrder: args.GroupOrder,
            BudgetDimensionID: args.BudgetDimensionID,
            CashflowStartYear: args.CashflowStartYear || undefined,
            CashflowEndYear: args.CashflowEndYear || undefined,
            SheetType: args.SheetType || undefined,
            SkipConfirm: args.SkipConfirm || false
        };
        const GETHEADERSPREADSHEET = `
            query GetHeaderSpreadsheet (
                $Year: Int
                $Type: String!
                $GroupOrder: Int
                $SignoffID: Int
                $BudgetDimensionID: Int
                $CashflowStartYear: Int 
                $CashflowEndYear: Int
                $SheetType: Int
                $SkipConfirm: Boolean
            ){
                GetHeaderSpreadsheet(
                    Year: $Year
                    Type: $Type
                    GroupOrder: $GroupOrder
                    SignoffID: $SignoffID
                    BudgetDimensionID: $BudgetDimensionID
                    CashflowStartYear: $CashflowStartYear 
                    CashflowEndYear: $CashflowEndYear
                    SheetType: $SheetType
                    SkipConfirm: $SkipConfirm
                ) {
                    HeaderTop {
                        label
                        colspan
                    }
                    HeaderSecond {
                        label
                        colspan
                    }
                    ColumnName
                    ColumnType {
                        data
                        validator
                        type
                        source
                        allowEmpty
                        errorCell
                        warningCell
                        width
                        enableCol
                        columnType
                        readOnly
                        numericFormat
                    }
                    ColumnKPI {
                        data
                        validator
                        type
                        source
                        allowEmpty
                        errorCell
                        warningCell
                        width
                        enableCol
                        columnType
                        readOnly
                    }
                    CanEdit
                }
            }
        `;
        return Axios.post("/twpservice", {
            query: GETHEADERSPREADSHEET,
            variables: data,
            errorPolicy: "all",
        });
    },

    getHeaderSpreadsheet004: (args) => {
        let data = {
            Year: args.Year,
            Type: args.Type,
            SignoffID: args.SignoffID,
            GroupOrder: args.GroupOrder,
        };
        const GETHEADERSPREADSHEET = `
            query GetHeaderSpreadsheet004 (
                $Year: Int!
                $Type: String!
                $GroupOrder: Int
                $SignoffID: Int
            ){
                GetHeaderSpreadsheet004(
                    Year: $Year
                    Type: $Type
                    GroupOrder: $GroupOrder
                    SignoffID: $SignoffID
                ) {
                    HeaderTop {
                        label
                        colspan
                    }
                    HeaderSecond {
                        label
                        colspan
                    }
                    ColumnName
                    ColumnType {
                        data
                        validator
                        type
                        source
                        allowEmpty
                        errorCell
                        warningCell
                        width
                        enableCol
                        columnType
                        readOnly
                        numericFormat
                    }
                    CanEdit
                }
            }
        `;
        return Axios.post("/twpservice", {
            query: GETHEADERSPREADSHEET,
            variables: data,
            errorPolicy: "all",
        });
    },

    getHeaderSpreadsheet004zuper: (args) => {
        let data = {
            Year: args.Year,
            Type: args.Type,
            GroupOrder: args.GroupOrder,
        };
        const GETHEADERSPREADSHEET = `
            query GetHeaderSpreadsheet004zuper (
                $Year: Int!
                $Type: String!
                $GroupOrder: Int
            ){
                GetHeaderSpreadsheet004zuper (
                    Year: $Year
                    Type: $Type
                    GroupOrder: $GroupOrder
                ) {
                    HeaderTop {
                        label
                        colspan
                    }
                    HeaderSecond {
                        label
                        colspan
                    }
                    ColumnName
                    ColumnType {
                        data
                        validator
                        type
                        source
                        allowEmpty
                        errorCell
                        warningCell
                        width
                        enableCol
                        columnType
                        readOnly
                    }
                    CanEdit
                }
            }
        `;
        return Axios.post("/twpservice", {
            query: GETHEADERSPREADSHEET,
            variables: data,
            errorPolicy: "all",
        });
    },

    getProjectByIDs: (data) => {
        const GETPROJECTBYIDS = `
            query GetProjectByIDs (
                $ProjectIDs: [Int]!,
                $GroupOrder: Int
                $IsspreadSheetMode: Boolean
                $WithCashflow: Boolean
                $ProjectStartYear: Int,
                $ProjectEndYear: Int,
            ){
                GetProjectByIDs (
                    ProjectIDs: $ProjectIDs,
                    GroupOrder: $GroupOrder,
                    IsspreadSheetMode: $IsspreadSheetMode
                    WithCashflow: $WithCashflow
                    ProjectStartYear: $ProjectStartYear,
                    ProjectEndYear: $ProjectEndYear,
                )
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTBYIDS,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 90000,
            }
        );
    },
    upsertProjectSpreadsheet001: (
        projectData,
        Year,
        isCheckData,
        checkGIS,
        TagID,
        SignoffID,
        BudgetDimension,
        Type,
        CashflowStartYear,
        CashflowEndYear,
        SkipConfirm
    ) => {
        const UPSERTPROJECT = `
        mutation UpsertProject (
            $Projects: JSON,
            $Year: Int!,
            $isCheckData: Boolean,
            $checkGIS: Boolean,
            $TagID: Int,
            $SignoffID: Int,
            $BudgetDimension: Int!
            $Type: Int
            $CashflowStartYear: Int,
            $CashflowEndYear: Int,
            $SkipConfirm: Boolean
        ) {
            UpsertProject (
                Projects: $Projects,
                Year: $Year,
                isCheckData: $isCheckData,
                checkGIS: $checkGIS,
                TagID: $TagID,
                SignoffID: $SignoffID,
                BudgetDimensionID: $BudgetDimension
                Type: $Type,
                CashflowStartYear: $CashflowStartYear,
                CashflowEndYear: $CashflowEndYear,
                SkipConfirm: $SkipConfirm
            )
        }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: UPSERTPROJECT,
                variables: {
                    Projects: projectData,
                    Year: Year,
                    isCheckData: isCheckData,
                    checkGIS: checkGIS,
                    TagID: TagID,
                    SignoffID: SignoffID,
                    BudgetDimension,
                    Type,
                    CashflowStartYear,
                    CashflowEndYear,
                    SkipConfirm
                },
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60 * 7,
            }
        );
    },

    upsertProjectSpreadsheet004: (projectData, Year, isCheckData, checkGIS, TagID, SignoffID) => {
        const UPSERTPROJECT = `
        mutation UpsertProject004 (
            $Projects: JSON,
            $Year: Int!,
            $isCheckData: Boolean,
            $checkGIS: Boolean,
            $TagID: Int,
            $SignoffID: Int,
        ) {
            UpsertProject004 (
                Projects: $Projects,
                Year: $Year,
                isCheckData: $isCheckData,
                checkGIS: $checkGIS,
                TagID: $TagID,
                SignoffID: $SignoffID
            )
        }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: UPSERTPROJECT,
                variables: {
                    Projects: projectData,
                    Year: Year,
                    isCheckData: isCheckData,
                    checkGIS: checkGIS,
                    TagID: TagID,
                    SignoffID: SignoffID,
                },
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60 * 7,
            }
        );
    },

    upsertProjectSpreadsheet004zuper: (projectData, Year, isCheckData, checkGIS, TagID, SignoffID) => {
        const UPSERTPROJECT = `
        mutation UpsertProject004zuper (
            $Projects: JSON,
            $Year: Int!,
            $isCheckData: Boolean,
            $checkGIS: Boolean,
            $TagID: Int,
            $SignoffID: Int,
        ) {
            UpsertProject004zuper (
                Projects: $Projects,
                Year: $Year,
                isCheckData: $isCheckData,
                checkGIS: $checkGIS,
                TagID: $TagID,
                SignoffID: $SignoffID
            )
        }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: UPSERTPROJECT,
                variables: {
                    Projects: projectData,
                    Year: Year,
                    isCheckData: isCheckData,
                    checkGIS: checkGIS,
                    TagID: TagID,
                    SignoffID: SignoffID,
                },
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60 * 7,
            }
        );
    },

    //////////////////// FILE //////////////////////

    uploadFile: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : [],
            ProjectID: data.ProjectID,
        };

        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
            formData.append("Description", element.Description ?? "");
            formData.append("ProjectPrepareStatusID", element.ProjectPrepareStatusID ?? "");
        }
        formData.append("ProjectID", data.ProjectID);
        console.log(formData);

        return Axios({
            method: "post",
            url: "/upload/project",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },

    /////////////////// COMMENT //////////////////////

    getProjectComment: (ProjectID) => {
        let data = {
            ProjectID: ProjectID,
        };
        const GETPROJECTCOMMENT = `
            query GetProjectComment(
                $ProjectID: Int!,
            ) {
                GetProjectComment(
                    ProjectID: $ProjectID
                ) {
                    CommentID
                    ProjectID
                    Comment
                    CreatedDateTime
                    CreatedUsername
                    CreatedAccountID
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTCOMMENT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },

    addProjectComment: (data) => {
        data = {
            ProjectID: data.ProjectID || undefined,
            Comment: data.Comment || undefined,
        };
        const GETPROJECTCOMMENT = `
            mutation AddProjectComment(
                $ProjectID: Int!,
                $Comment: String,
            ) {
                AddProjectComment(
                    ProjectID: $ProjectID,
                    Comment: $Comment
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTCOMMENT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },

    updateProjectComment: (data) => {
        data = {
            CommentID: data.CommentID || undefined,
            Comment: data.Comment || undefined,
        };
        const GETPROJECTCOMMENT = `
            mutation UpdateProjectComment(
                $CommentID: Int!,
                $Comment: String,
            ) {
                UpdateProjectComment(
                    CommentID: $CommentID,
                    Comment: $Comment
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTCOMMENT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },

    deleteProjectComment: (data) => {
        data = {
            CommentID: data.CommentID || undefined,
        };
        const GETPROJECTCOMMENT = `
            mutation DeleteProjectComment(
                $CommentID: Int!
            ) {
                DeleteProjectComment(
                    CommentID: $CommentID,
                ) {
                    CommentID
                }
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETPROJECTCOMMENT,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },

    ///////////////// Zuper //////////////////

    importProjectZuper: (data) => {
        console.log(data);
        data = {
            Files: data.Files?.length ? data.Files : [],
        };

        let formData = new FormData();

        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
        }

        return Axios({
            method: "post",
            url: "/upload/projectimport",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 180000,
        });
    },

    updateBudgetYear: (data) => {
        data = {
            ProjectID: data.ProjectID ?? undefined,
            UpdateNextYear: data.UpdateNextYear ?? undefined,
        };

        const UPDATEBUDGETYEAR = `
            mutation UpdateBudgetYear(
                $ProjectID: Int!
                $UpdateNextYear: Boolean!
            ) {
                UpdateBudgetYear(
                    ProjectID: $ProjectID,
                    UpdateNextYear: $UpdateNextYear
                )
            }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: UPDATEBUDGETYEAR,
                variables: data,
                errorPolicy: "all",
            },
            {
                timeout: 1000 * 60,
            }
        );
    },
};
export default ProjectService;
