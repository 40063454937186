import React from 'react';
import {
    Button,
    Grow,
    Slide,
    IconButton,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
    Box,
} from '@material-ui/core';
import {
    Tune,
    Close,
    Description,
    Book,
    Search,
    Room,
    Assignment,
    MonetizationOn,
    LocalOffer,
    FiberManualRecord,
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import ExpandableContent from '../../@coremat/CmtCardExpendableContent';
import AutoComplete from '../../Util/Autocomplete';
import TextFieldMemo from '../../Util/TextFieldMemo';
import MultiSelect from '../../Util/MultiSelect';
import MultiSelectServer from '../../Util/MultiSelectServer';
// const GridBreak = () => <div style={{ width: '100%' }} />

// const renderNumberOrganization = (selected, options) => {
//     if (!options?.length) return "(0/0)";
//     return `(${selected?.length ?? 0}/${options.length})`;
// }

// const renderNumberProvince = (selected, options) => {
//     if (!options?.length) return "(0/0)";
//     let num = 0;
//     if (selected?.length) {
//         for (let i = 0; i < selected.length; i++) {
//             const element = selected[i];
//             if (element.Type === 1) num++;
//         }
//     }
//     return `(${num}/${options.length})`;
// }

class MapFilter extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.showFilter === false && this.props.showFilter === false)
            return false;
        return !(
            nextProps.showFilter === this.props.showFilter &&
            nextProps.filterPage === this.props.filterPage &&
            nextProps.searchLayer === this.props.searchLayer &&
            nextProps.filter === this.props.filter &&
            nextProps.placesOptions === this.props.placesOptions &&
            nextProps.basicSearchData === this.props.basicSearchData &&
            nextProps.searchOrganization === this.props.searchOrganization &&
            nextProps.searchOrganizationFilter === this.props.searchOrganizationFilter &&
            nextProps.searchProvince === this.props.searchProvince &&
            nextProps.searchProvinceFilter === this.props.searchProvinceFilter &&
            nextProps.tag === this.props.tag &&
            nextProps.preset === this.props.preset
        )
    }

    onChangeBasicOrganization = (e, id) => {
        let newSelected = this.props.filter?.OrganizationID ? [...this.props.filter?.OrganizationID] : [];
        if (!e.target.checked) {
            let index = newSelected.findIndex(el => id === el);
            if (index >= 0) newSelected.splice(index, 1);
        }
        else newSelected.push(id);
        this.props.setSearch(newSelected, "OrganizationID");
    }

    renderBasicOrganization = () => {
        if (!this.props.basicSearchData?.organizationData && !this.props.searchOrganizationFilter) return null;
        let renderData = this.props.searchOrganizationFilter ?? this.props.basicSearchData.organizationData;
        let selectedData = this.props.filter.OrganizationID ?? [];
        let elements = [];
        for (let i = 0; i < renderData.length; i++) {
            const obj = renderData[i];
            elements.push(
                <Grid container spacing={6} key={"layer-" + i} display="flex" alignItems="center">
                    <Grid item xs={9}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedData.includes(obj.ID) ?? false}
                                    onChange={(e) => this.onChangeBasicOrganization(e, obj.ID)}
                                />
                            }
                            label={obj.Name}
                            className="mr-03"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box textAlign="end">{obj.ProjectCount}</Box>
                    </Grid>
                </Grid>
            );
        }
        return elements;
    }

    onChangeBasicProvince = (e, obj) => {
        let newSelected = this.props.filter?.SelectedPlace ? [...this.props.filter?.SelectedPlace] : [];
        let val = obj.ID + "-1";
        if (!e.target.checked) {
            let index = newSelected.findIndex(el => val === el.Value);
            if (index >= 0) newSelected.splice(index, 1);
        }
        else newSelected.push({
            Label: obj.Name,
            Value: val,
            ProvinceID: obj.ID,
            Type: 1
        });
        this.props.setSearch(newSelected, "SelectedPlace");
    }

    renderBasicProvince = () => {
        if (!this.props.basicSearchData?.provinceData && !this.props.searchProvinceFilter) return null;
        let renderData = this.props.searchProvinceFilter ?? this.props.basicSearchData.provinceData;
        let selectedData = this.props.filter.SelectedPlace ?? [];
        let elements = [];
        for (let i = 0; i < renderData.length; i++) {
            const obj = renderData[i];
            elements.push(
                <Grid container spacing={6} key={"layer-" + i} display="flex" alignItems="center">
                    <Grid item xs={9}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedData.findIndex((el) => el.Value === obj.ID + "-1") >= 0}
                                    onChange={(e) => this.onChangeBasicProvince(e, obj)}
                                />
                            }
                            label={obj.Name}
                            className="mr-03"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box textAlign="end">{obj.ProjectCount}</Box>
                    </Grid>
                </Grid>
            );
        }
        return elements;
    }

    collectionLabel = (
        <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
            <Book className="mr-03" /> Collection
        </div>
    )

    searchOrgLabel = (
        <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
            <Search className="mr-03" /> ค้นหาหน่วยรับงบประมาณ
        </div>
    )

    searchProvLabel = (
        <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
            <Search className="mr-03" /> ค้นหาจังหวัด
        </div>
    )

    render() {
        const {
            showFilter,
            setShowFilter,
            filterPage,
            //setFilterPage,
            masterData,
            setSearch,
            setSearchPlaces,
            setSearchPlacesInput,
            onClickSearchProject,
            onClickClearFilter,
            // setShowPreset,
            // OrganizationActionComponent,
            // ProvinceActionComponent,
            // searchLayer,
            // setSearchLayer,
            // searchOrganization,
            // setSearchOrganizationOptions,
            // searchProvince,
            // setSearchProvinceOptions,
            setSearchBudgetDimension,
            setSearchPreset,
            filter,
            placesOptions,
            budgetYearOptions,
            signoffList,
            //basicSearchData,
            tag,
            preset
        } = this.props;
        return (
            <Grow in={showFilter} className="map-filter d-flex flex-column">
                <CmtCard>
                    <div className="section-header main d-flex align-items-center py-05 px-10 mb-02">
                        <Tune className="mr-05" />
                        <h4>การคัดกรองข้อมูลโครงการ</h4>
                        <IconButton className="ml-auto p-02 text-default" onClick={setShowFilter}>
                            <Close />
                        </IconButton>
                    </div>
                    <hr className="hr-light" />
                    <CmtCardContent className="flex-fill overflowY-auto overflowX-hidden px-05 py-10" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
                        <Slide in={filterPage === 0} direction="left">
                            <Grid
                                container
                                spacing={6}
                                className={"px-10 " + (filterPage === 0 ? "" : "d-none")}
                            >
                                {/* <Grid item xs={12} className="section-header sub">
                                    <div
                                        className="d-flex align-items-center"
                                    >
                                        <Book className="mr-05" />
                                        Collection
                                    </div>
                                    <hr />
                                </Grid> */}
                                {/* <Grid item xs={12} className="px-0">
                                    <AutoComplete
                                        label={this.collectionLabel}
                                        color="primary"
                                        placeholder="Collection"
                                        value={filter.PresetCollectionID ?? ''}
                                        dataname='PresetCollection'
                                        name='PresetCollectionID'
                                        dataobjectname="PresetCollectionID"
                                        setvalue={setSearchPreset}
                                        data={preset}
                                    />
                                </Grid>
                                <Grid item xs={12} className="px-0">
                                    <div className="section-header sub mb-10">
                                        <div
                                            className="d-flex align-items-center"
                                        >
                                            <Tune className="mr-05" />
                                            คัดกรองข้อมูล
                                        </div>
                                        <hr />
                                    </div>
                                    <ExpandableContent
                                        actionsComponent={
                                            <OrganizationActionComponent
                                                number={renderNumberOrganization(filter.OrganizationID, this.props.basicSearchData?.organizationData)}
                                            />
                                        }
                                        className="section-border"
                                        actionsClasses="map-collapse-header"
                                        contentClass="p-10"
                                    >
                                        <div>
                                            <TextFieldMemo
                                                label={this.searchOrgLabel}
                                                placeholder="ค้นหาหน่วยงาน"
                                                variant="outlined"
                                                size="small"
                                                value={searchOrganization ?? ''}
                                                name='searchOrganization'
                                                onChange={setSearchOrganizationOptions}
                                                fullWidth
                                            />
                                        </div>
                                        {
                                            basicSearchData?.organizationData?.length ?
                                                this.renderBasicOrganization()
                                                : null
                                        }
                                    </ExpandableContent>
                                    <ExpandableContent
                                        actionsComponent={
                                            <ProvinceActionComponent
                                                number={renderNumberProvince(filter.SelectedPlace, this.props.basicSearchData?.provinceData)}
                                            />
                                        }
                                        actionsClasses="map-collapse-header"
                                        contentClass="p-10"
                                        className="mt-10 section-border"
                                    >
                                        <div>
                                            <TextFieldMemo
                                                label={this.searchProvLabel}
                                                placeholder="ค้นหาจังหวัด"
                                                variant="outlined"
                                                size="small"
                                                value={searchProvince ?? ''}
                                                name='searchProvince'
                                                onChange={setSearchProvinceOptions}
                                                fullWidth
                                            />
                                        </div>
                                        {
                                            basicSearchData?.provinceData?.length ?
                                                this.renderBasicProvince()
                                                : null
                                        }
                                    </ExpandableContent>
                                </Grid> */}
                            </Grid>
                        </Slide>
                        <Slide in={filterPage === 1} direction="right">
                            <Grid
                                container
                                spacing={6}
                                className={(filterPage === 1 ? "" : "d-none")}
                            >
                                {/* <Grid item xs={12} className="section-header sub">
                                    <div
                                        className="d-flex align-items-center"
                                    >
                                        <Book className="mr-05" />
                                        Collection
                                    </div>
                                    <hr />
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" paddingLeft={2} paddingRight={2}>
                                        <Box flex="1 1 auto" marginRight={2}>
                                            <AutoComplete
                                                label={this.collectionLabel}
                                                color="primary"
                                                placeholder="Collection"
                                                value={filter.PresetCollectionID ?? ''}
                                                dataname='PresetCollection'
                                                name='PresetCollectionID'
                                                dataobjectname="PresetCollectionID"
                                                setvalue={setSearchPreset}
                                                data={preset}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton onClick={setShowPreset} color="primary">
                                                <Edit />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12}>
                                    {/* <div className="section-header sub mb-05">
                                        <div className="d-flex align-items-center">
                                            <Description className="mr-05" />
                                            ข้อมูลพื้นฐาน
                                        </div>
                                    </div> */}
                                    <ExpandableContent
                                        actionsComponent={
                                            <div className="section-header sub mb-05 d-flex align-items-center justify-content-center">
                                                <Description className="mr-05" />
                                                <span>ข้อมูลพื้นฐาน</span>
                                                <FiberManualRecord
                                                    style={{
                                                        fill: "#e04d5b",
                                                        fontSize: "1rem",
                                                        marginLeft: "0.3rem",
                                                        display:
                                                            (
                                                                filter.MYProject ||
                                                                filter.ProjectName ||
                                                                filter.ProjectCode ||
                                                                filter.BudgetYear?.length ||
                                                                filter.OrganizationID?.length
                                                            ) ? "" : "none"
                                                    }}
                                                />
                                            </div>
                                        }
                                        contentClass="p-10 pt-0"
                                    >
                                        <div>
                                            {/* <div className="d-flex align-items-center mb-10">
                                                <CustomSwitch
                                                    name="MYProject"
                                                    label={
                                                        <Box display="flex" alignItems="center">
                                                            {
                                                                filter.MYProject ?
                                                                    <Star className="star-icon" />
                                                                    :
                                                                    <StarBorder />
                                                            }
                                                            <Tooltip title="แสดงเฉพาะโครงการของฉัน">
                                                                <div className="text-ellipsis">เฉพาะโครงการของฉัน</div>
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    checked={filter.MYProject ?? false}
                                                    onChange={(e) => {
                                                        setSearch(e.target.checked, "MYProject")
                                                    }}
                                                />
                                            </div> */}
                                            <div>
                                                <TextFieldMemo
                                                    label="ชื่อโครงการ"
                                                    variant="outlined"
                                                    size="small"
                                                    value={filter.ProjectName ?? ''}
                                                    name='ProjectName'
                                                    onChange={(e) => setSearch(e.target.value, e.target.name)}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <TextFieldMemo
                                                    label="รหัสโครงการ"
                                                    variant="outlined"
                                                    size="small"
                                                    value={filter.ProjectCode ?? ''}
                                                    name='ProjectCode'
                                                    onChange={(e) => setSearch(e.target.value, e.target.name)}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="ปีงบประมาณ"
                                                    value={filter.BudgetYear ?? []}
                                                    dataname='BudgetYear'
                                                    name='BudgetYear'
                                                    dataobjectname="BudgetYearID"
                                                    setvalue={setSearch}
                                                    data={budgetYearOptions}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="หน่วยรับงบประมาณ"
                                                    value={filter.OrganizationID ?? []}
                                                    dataname='Organization'
                                                    name='OrganizationID'
                                                    dataobjectname="OrganizationID"
                                                    setvalue={setSearch}
                                                    data={masterData?.FilterOrganizationOption ?? []}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="หน่วยรับงบประมาณ (อปท.)"
                                                    value={filter.DLAOrganizationID ?? []}
                                                    dataname='Organization'
                                                    name='DLAOrganizationID'
                                                    dataobjectname="OrganizationID"
                                                    setvalue={setSearch}
                                                    data={masterData?.FilterDLAOrganizationOption ?? []}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="หน่วยดำเนินการ"
                                                    value={filter.OperatorOrganizationID ?? []}
                                                    dataname='Organization'
                                                    name='OperatorOrganizationID'
                                                    dataobjectname="OrganizationID"
                                                    setvalue={setSearch}
                                                    data={masterData?.FilterOrganizationOperatorOption}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <AutoComplete
                                                    label="Sign-off"
                                                    value={filter.SignoffID ?? ""}
                                                    dataname='Signoff'
                                                    name='SignoffID'
                                                    dataobjectname="SignoffID"
                                                    setvalue={setSearch}
                                                    data={signoffList ?? []}
                                                    disabled={filter.disabledSignoff}
                                                />
                                            </div>
                                        </div>
                                    </ExpandableContent>
                                    <hr className="hr-light" />
                                    <ExpandableContent
                                        actionsComponent={
                                            <div className="section-header sub mb-05 d-flex align-items-center justify-content-center">
                                                <Room className="mr-05" />
                                                <span>ที่ตั้งโครงการ</span>
                                                <FiberManualRecord
                                                    style={{
                                                        fill: "#e04d5b",
                                                        fontSize: "1rem",
                                                        marginLeft: "0.3rem",
                                                        display: (filter.SelectedPlace?.length) ? "" : "none"
                                                    }}
                                                />
                                            </div>
                                        }
                                        className="mt-05"
                                        contentClass="p-10 pt-0"
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <div className="mt-05">
                                                    <MultiSelectServer
                                                        label="สถานที่"
                                                        placeholder="จังหวัด, สถานที่..."
                                                        name="SelectedPlace"
                                                        options={placesOptions}
                                                        selected={filter.SelectedPlace}
                                                        onChange={setSearchPlaces}
                                                        onChangeInput={setSearchPlacesInput}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MultiSelect
                                                    label="Area-Based"
                                                    value={filter.AreaBaseID ?? []}
                                                    dataname='AreaBase'
                                                    name='AreaBaseID'
                                                    dataobjectname="AreaBaseID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetAreaBase}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="จังหวัด"
                                                    value={filter.ProvinceID ?? []}
                                                    dataname='Province'
                                                    name='ProvinceID'
                                                    dataobjectname="ProvinceID"
                                                    setvalue={setSearchProvince}
                                                    data={masterData.GetProvince}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="อำเภอ"
                                                    value={filter.DistrictID ?? []}
                                                    dataname='District'
                                                    name='DistrictID'
                                                    dataobjectname="DistrictID"
                                                    setvalue={setSearchDistrict}
                                                    data={getDistrictOptions(filter.ProvinceID, masterData.GetDistrictObject)}
                                                    disabled={filter.ProvinceID && filter.ProvinceID?.length ? false : true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="ตำบล"
                                                    value={filter.SubdistrictID ?? ''}
                                                    dataname='Subdistrict'
                                                    name='SubdistrictID'
                                                    dataobjectname="SubdistrictID"
                                                    setvalue={setSearch}
                                                    data={getSubistrictOptions(filter.DistrictID, masterData.GetSubdistrictObject)}
                                                    disabled={filter.DistrictID && filter.DistrictID?.length ? false : true}
                                                />
                                            </Grid>
                                            <GridBreak />
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="ลุ่มน้ำหลัก"
                                                    value={filter.BasinID ?? []}
                                                    dataname='Basin'
                                                    name='BasinID'
                                                    dataobjectname="BasinID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetBasin}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="ลุ่มน้ำสาขา"
                                                    value={filter.SubbasinID ?? []}
                                                    dataname='Subbasin'
                                                    name='SubbasinID'
                                                    dataobjectname="SubbasinID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetSubbasin}
                                                />
                                            </Grid> */}
                                        </Grid>
                                    </ExpandableContent>
                                    <hr className="hr-light" />
                                    <ExpandableContent
                                        actionsComponent={
                                            <div className="section-header sub mb-05 d-flex align-items-center justify-content-center">
                                                <Assignment className="mr-05" />
                                                <span>ประเภทโครงการและแผนแม่บทฯน้ำ 20 ปี</span>
                                                <FiberManualRecord
                                                    style={{
                                                        fill: "#e04d5b",
                                                        fontSize: "1rem",
                                                        marginLeft: "0.3rem",
                                                        display:
                                                            (
                                                                filter.ActivityID?.length ||
                                                                filter.ProjectTypeID?.length ||
                                                                filter.StrategyID?.length ||
                                                                filter.PolicyID?.length
                                                            ) ? "" : "none"
                                                    }}
                                                />
                                            </div>
                                        }
                                        className="mt-05"
                                        contentClass="p-10 pt-0"
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="กิจกรรม"
                                                    value={filter.ActivityID ?? []}
                                                    dataname='Activity'
                                                    name='ActivityID'
                                                    dataobjectname="ActivityID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetActivity}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="ลักษณะงาน"
                                                    value={filter.ProjectTypeID ?? []}
                                                    dataname='ProjectType'
                                                    name='ProjectTypeID'
                                                    dataobjectname="ProjectTypeID"
                                                    setvalue={setSearch}
                                                    data={masterData.SearchProjectType}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MultiSelect
                                                    label="แผนแม่บทฯน้ำ 20 ปี"
                                                    value={filter.StrategyID ?? []}
                                                    dataname='Strategy'
                                                    name='StrategyID'
                                                    dataobjectname="StrategyID"
                                                    setvalue={setSearch}
                                                    data={masterData.StrategyOptions}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MultiSelect
                                                    label="กลยุทธ์"
                                                    value={filter.PolicyID ?? []}
                                                    dataname='Strategy'
                                                    name='PolicyID'
                                                    dataobjectname="StrategyID"
                                                    setvalue={setSearch}
                                                    data={masterData.PolicyOptions}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpandableContent>
                                    <hr className="hr-light" />
                                    <ExpandableContent
                                        actionsComponent={
                                            <div className="section-header sub mb-05 d-flex align-items-center justify-centent-center">
                                                <MonetizationOn className="mr-05" />
                                                <span>งบประมาณ</span>
                                                <FiberManualRecord
                                                    style={{
                                                        fill: "#e04d5b",
                                                        fontSize: "1rem",
                                                        marginLeft: "0.3rem",
                                                        display:
                                                            (
                                                                filter.BudgetSourceID?.length ||
                                                                filter.ProjectStatusID?.length ||
                                                                filter.ProjectTypeAmountID?.length ||
                                                                filter.BudgetTypeID?.length ||
                                                                (filter.BudgetRangeStart || filter.BudgetRangeStart === 0) ||
                                                                (filter.BudgetRangeEnd || filter.BudgetRangeEnd === 0) ||
                                                                filter.BudgetDimensionID?.length
                                                            ) ? "" : "none"
                                                    }}
                                                />
                                            </div>
                                        }
                                        className="mt-05"
                                        contentClass="p-10 py-0"
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="แหล่งที่มางบประมาณ"
                                                    value={filter.BudgetSourceID ?? []}
                                                    dataname='BudgetSource'
                                                    name='BudgetSourceID'
                                                    dataobjectname="BudgetSourceID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetBudgetSource}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="สถานะงบประมาณ"
                                                    value={filter.ProjectStatusID ?? []}
                                                    dataname='ProjectStatus'
                                                    name='ProjectStatusID'
                                                    dataobjectname="ProjectStatusID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetProjectStatus}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} md={6}>
                                                <MultiSelect
                                                    label="ประเภทงบประมาณ"
                                                    value={filter.BudgetTypeID ?? []}
                                                    dataname='BudgetType'
                                                    name='BudgetTypeID'
                                                    dataobjectname="BudgetTypeID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetBudgetType}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">วงเงินงบประมาณ (ล้านบาท)</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldMemo
                                                    label="ตั้งแต่"
                                                    variant="outlined"
                                                    size="small"
                                                    value={filter.BudgetRangeStart ?? ''}
                                                    name='BudgetRangeStart'
                                                    onChange={(e) => setSearch(e.target.value, e.target.name)}
                                                    onBlur={(e) => {
                                                        let val = parseFloat(e.target.value);
                                                        if (
                                                            (filter.BudgetRangeEnd || filter.BudgetRangeEnd === 0) &&
                                                            filter.BudgetRangeEnd < val
                                                        ) {
                                                            setSearch((val || val === 0) ? val : null, 'BudgetRangeEnd')
                                                        }
                                                        setSearch((val || val === 0) ? val : null, e.target.name)
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldMemo
                                                    label="จนถึง"
                                                    variant="outlined"
                                                    size="small"
                                                    value={filter.BudgetRangeEnd ?? ''}
                                                    name='BudgetRangeEnd'
                                                    onChange={(e) => setSearch(e.target.value, e.target.name)}
                                                    onBlur={(e) => {
                                                        let val = parseFloat(e.target.value);
                                                        if (
                                                            (filter.BudgetRangeStart || filter.BudgetRangeStart === 0) &&
                                                            filter.BudgetRangeStart > val
                                                        ) {
                                                            setSearch((val || val === 0) ? val : null, 'BudgetRangeStart')
                                                        }
                                                        setSearch((val || val === 0) ? val : null, e.target.name)
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AutoComplete
                                                    label="ประเภทวงเงิน"
                                                    value={filter.ProjectTypeAmountID ?? ''}
                                                    dataname='ProjectStatus'
                                                    name='ProjectTypeAmountID'
                                                    dataobjectname="ProjectStatusID"
                                                    setvalue={setSearch}
                                                    data={masterData.GetProjectStatus}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">มิติงบประมาณ</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CmtCard className="p-05 px-10 mb-10">
                                                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                                                        {
                                                            masterData?.GetBudgetDimension ?
                                                                masterData.GetBudgetDimension.map((obj, index) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={filter?.BudgetDimensionID ? filter.BudgetDimensionID.includes(obj.BudgetDimensionID) : false}
                                                                                onChange={() => setSearchBudgetDimension(obj.BudgetDimensionID)}
                                                                                name="BudgetDimensionID"
                                                                            />
                                                                        }
                                                                        key={"budgetdimension" + index}
                                                                        label={obj.BudgetDimensionName}
                                                                        className="mr-03"
                                                                    />
                                                                ))
                                                                : null
                                                        }
                                                    </Box>
                                                </CmtCard>
                                            </Grid>
                                        </Grid>
                                    </ExpandableContent>
                                    <hr className="hr-light" />
                                    <ExpandableContent
                                        actionsComponent={
                                            <div className="section-header sub mb-05 d-flex align-items-center justify-content-center">
                                                <LocalOffer className="mr-05" />
                                                <span>Tag</span>
                                                <FiberManualRecord
                                                    style={{
                                                        fill: "#e04d5b",
                                                        fontSize: "1rem",
                                                        marginLeft: "0.3rem",
                                                        display: (filter.TagID?.length) ? "" : "none"
                                                    }}
                                                />
                                            </div>
                                        }
                                        className="mt-05"
                                        contentClass="p-10 pt-0"
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <MultiSelect
                                                    label="Tag"
                                                    value={filter.TagID ?? []}
                                                    dataname='Tag'
                                                    name='TagID'
                                                    dataobjectname="TagID"
                                                    setvalue={setSearch}
                                                    data={tag}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpandableContent>
                                </Grid>
                            </Grid>
                        </Slide>
                    </CmtCardContent>
                    <div className="d-flex justify-content-center my-05">
                        <Button
                            onClick={onClickSearchProject}
                            color="primary"
                            variant="contained"
                            size="small"
                        >
                            <Search className="mr-03" />
                            ค้นหา
                        </Button>
                        <Button
                            onClick={onClickClearFilter}
                            color="default"
                            variant="contained"
                            className="ml-10"
                            size="small"
                        >
                            <Close className="mr-03" />
                            ล้างค่า
                        </Button>
                    </div>
                    {/* <Button
                        onClick={setFilterPage}
                        color="secondary"
                        variant="contained"
                        size="small"
                        className="m-05"
                    >
                        <Tune className="mr-05" />
                        {filterPage === 0 ? "Advance Search" : "Basic Search"}
                    </Button> */}
                </CmtCard >
            </Grow >
        );
    }
}

export default MapFilter;