import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TextFieldMemo from "../Util/TextFieldMemo";

import moment from "moment";
import MomentBE from "./MomentBE";
import "moment/locale/th";
moment.locale("th");

const datepickerTheme = createTheme({
    typography: {
        fontFamily: "Sarabun",
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#007aee",
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            day: {
                color: "#000",
            },
            daySelected: {
                backgroundColor: "#007aee",
            },
            dayDisabled: {
                color: "#007aee",
            },
            current: {
                color: "#007aee",
            },
        },
        MuiPickersMonthSelection: {
            container: {
                padding: 2,
            },
        },
        MuiPickersMonth: {
            root: {
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    borderRadius: 5,
                },
            },
            monthSelected: {
                backgroundColor: "#007aee",
                color: "#FFFFFF",
                borderRadius: "5px",
                "&:hover": {
                    backgroundColor: "#007aee",
                },
            },
            current: {
                color: "#007aee",
            },
        },
        MuiPickersYear: {
            root: {
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
            },
            yearSelected: {
                backgroundColor: "rgb(0 122 238 / 20%)",
                color: "#007AEE",
                "&:hover": {
                    backgroundColor: "rgb(0 122 238 / 20%)",
                },
            },
            current: {
                color: "#007aee",
            },
        },
    },
});

export default class Datepicker extends Component {
    state = {
        views: ["year", "month", "date"],
    };

    componentDidMount() {
        if (this.props.format === "YYYY") {
            this.setState({ views: this.state.views.splice(0, 1) });
        }
        if (this.props.format === "MM") {
            this.setState({ views: [this.state.views[1]] });
        }
        this.isChecktypeDate();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.isChecktypeDate();
            return;
        }
    }

    isChecktypeDate = () => {
        let { format, value } = this.props;
        if (!value) return null;
        if (format === "YYYY") {
            return this.subYear();
        } else if (format === "MM") {
            return this.subMonth();
        } else if (format === "DD-MM-YYYY") {
            return moment(value, "DD-MM-YYYY")
                .subtract(543, "year")
                .format("YYYY-MM-DD");
        }
    };

    setDate = (e) => {
        let { format } = this.props;
        switch (format) {
            case "YYYY":
                this.props.setvalue(
                    parseInt(moment(e).add(543, "year").format(format)),
                    this.props.name
                );
                break;
            case "MM":
                let value = moment(e).add(543, "year").format(format);
                value = parseInt(value);
                this.props.setvalue(value, this.props.name);
                break;
            case "DD-MM-YYYY":
                this.props.index !== ""
                    ? this.props.setvalue(
                        moment(e).add(543, "year").format(format),
                        this.props.name,
                        this.props.index
                    )
                    : this.props.setvalue(
                        moment(e).add(543, "year").format(format),
                        this.props.name
                    );
                break;

            default:
                break;
        }
    };

    subYear = () => {
        let month = new Date().getMonth() + 1;
        let date = new Date().getDate();
        let crDate = `${date}-${month}-${this.props.value}`;
        return moment(`${crDate}`, "DD-MM-YYYY")
            .subtract(543, "year")
            .format("YYYY-MM-DD");
    };

    subMonth = () => {
        let date = new Date().getDate();
        return moment(
            `${date}-${this.props.value}-${new Date().getFullYear()}`,
            "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
    };

    classes = this.useStyles;

    renderInput = (props) => (
        <TextFieldMemo
            type="text"
            onClick={props.onClick}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            label={props.label}
            fullWidth
            variant="outlined"
            size={props.size}
            required={props.required}
            error={props.error}
        />
    );



    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentBE} locale="th">
                <ThemeProvider theme={datepickerTheme}>
                    <DatePicker
                        label={this.props.label}
                        format={this.props.format === "MM" ? "MMM" : this.props.format}
                        views={this.state.views}
                        value={this.isChecktypeDate()}
                        inputVariant="outlined"
                        maxDate={this.props.maxYear ?? moment(new Date()).add(this.props.maxdate ? this.props.maxdate : 100, "year")}
                        minDate={this.props.minYear ?? moment(new Date()).subtract(this.props.mindate ? this.props.mindate : 100, "year")}
                        onChange={(e) => this.setDate(e)}
                        okLabel="ตกลง"
                        cancelLabel="ยกเลิก"
                        fullWidth
                        size={this.props?.size ?? "small"}
                        autoOk
                        disableToolbar={this.props.format === "MM" ? true : false}
                        disabled={this.props.disabled ?? false}
                        TextFieldComponent={this.renderInput}
                        required={this.props.required}
                        error={this.props.error}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}
