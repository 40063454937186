import React from 'react';
import {
    Grow,
    IconButton,
    Grid,
    Tabs,
    Tab,
    Slide,
    Box,
    Typography,
    Chip,
    List,
    Card,
    Tooltip,
    Button
} from '@material-ui/core';
import {
    Close,
    TrackChanges,
    Layers,
    Tune,
    Add,
    Remove,
    Visibility,
    VisibilityOff,
    NewReleases,
    MonetizationOn,
    Room,
    AccountTree,
    Info,
    Search,
    Star,
    Opacity
} from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import CmtCard from '../../@coremat/CmtCard';
import ExpandableContent from '../../@coremat/CmtCardExpendableContent';
import TextFieldMemo from '../../Util/TextFieldMemo';
import MultiSelect from '../../Util/MultiSelect';
import Select from '../../Util/Select';
import Utilities from '../../Util/Utilities';

const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
}

const getAmount = (StatusID) => {
    if (StatusID === 1) return "RequestAmount";
    if (StatusID === 2) return "DraftAmount";
    if (StatusID === 3) return "AllocateAmount";
}

class MapArea extends React.Component {
    state = {
        selectedTab: 0,
        page: 1,
        w_page: 1
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showAreaWindow === false && this.props.showAreaWindow === false)
            return false;
        return !(
            nextState.selectedTab === this.state.selectedTab &&
            nextState.page === this.state.page &&
            nextProps.w_page === this.state.w_page &&
            nextProps.showAreaWindow === this.props.showAreaWindow &&
            nextProps.showLayer === this.props.showLayer &&
            nextProps.areaFilter === this.props.areaFilter &&
            nextProps.criticalArea === this.props.criticalArea &&
            nextProps.projectArea === this.props.projectArea &&
            nextProps.waterResourceArea === this.props.waterResourceArea &&
            nextProps.limit === this.props.limit
        )
    }

    onChangeTab = (e, index) => {
        this.setState({ selectedTab: index });
    }

    setShowAreaWindow = () => {
        this.setState({
            selectedTab: 0,
            page: 1,
            w_page: 1
        }, () => {
            this.props.setShowAreaWindow();
        })
    }

    getProjectPage = (data, page) => {
        let newData = [];
        if (!data?.length) return newData;
        let start = (page - 1) * this.props.limit;
        for (let i = start; i < start + this.props.limit; i++) {
            if (!data[i]) break;
            newData.push(data[i]);
        }
        return newData;
    }

    renderProject = (data, onClickProjectLocation) => {
        let newData = this.getProjectPage(data, this.state.page);
        return newData.map((obj, index) => (
            <Card key={"projectarea-" + index} className="mt-05">
                <div className="p-10">
                    <Box display="flex" alignItems="center">
                        {
                            obj.MYProject ?
                                <Tooltip title="โครงการของฉัน">
                                    <Star className="star-icon" />
                                </Tooltip>
                                :
                                null
                        }
                        <Tooltip title={obj.ProjectName || ""}>
                            <Typography className="text-ellipsis" variant="h5" color="primary">
                                {
                                    "#" + (((this.state.page - 1) * this.props.limit) + 1 + index) +
                                    " " + obj.ProjectName
                                }
                            </Typography>
                        </Tooltip>
                    </Box>
                    <small className="text-ellipsis">
                        {
                            ((obj.ProvinceName) ? ("จ." + obj.ProvinceName + " ") : "") +
                            ((obj.DistrictName) ? ("อ." + obj.DistrictName + " ") : "") +
                            ((obj.SubdistrictName) ? ("ต." + obj.SubdistrictName + " ") : "") +
                            ((obj.Village) ? (obj.Village + " ") : "") +
                            ((obj.Moo) ? (obj.Moo) : "")
                        }
                    </small>
                    <div className="d-flex align-items-center mt-02">
                        <Chip
                            label={"สถานะ: " + (obj.ProjectStatusName ?? "-")}
                            variant="outlined"
                            size="small"
                            color="secondary"
                        />
                        <span className="ml-auto">
                            <Button
                                className="btn-small"
                                color="secondary"
                                variant="contained"
                                onClick={() => openInNewTab(`/twp/v2/project/projectdetail/${obj.ProjectID}`)}
                            >
                                <Info />
                            </Button>
                            <Button
                                className="ml-03 btn-small"
                                color="primary"
                                variant="contained"
                                onClick={() => onClickProjectLocation(obj.ProjectID, true)}
                            >
                                <Room />
                            </Button>
                        </span>
                    </div>
                    <div className="mt-05 d-flex flex-wrap">
                        <Box className="mr-auto text-ellipsis" display="flex" alignItems="center" component="small">
                            <AccountTree className="mr-03 text-secondary" style={{ fontSize: "1rem" }} />
                            {obj.OrganizationName}
                        </Box>
                        <Box display="flex" alignItems="center" component="small">
                            <MonetizationOn className="mr-03 text-secondary" style={{ fontSize: "1rem" }} />
                            วงเงินงบฯ: {obj[getAmount(obj.ProjectStatusID)] ?? "-"} ลบ.
                        </Box>
                    </div>
                </div>
            </Card>
        ))
    }

    statusWaterResource = (Approvement_Status) => {
        let statusName = '';
        if (Approvement_Status === 1) statusName = 'อนุมัติ';
        if (Approvement_Status === -1) statusName = 'ข้อมูลไม่ถูกต้อง';
        if (Approvement_Status === 0) statusName = 'รอตรวจสอบ';
        return statusName;
    }

    renderWreSource = (data, onClickWaterResourceLocation) => {
        let newData = this.getProjectPage(data, this.state.w_page);
        return newData.map((obj, index) => (
            <Card key={"waterResourceArea-" + index} className="mt-05">
                <div className="p-10">
                    <Box display="flex" alignItems="center">
                        {
                            obj.MYProject ?
                                <Tooltip title="โครงการของฉัน">
                                    <Star className="star-icon" />
                                </Tooltip>
                                :
                                null
                        }
                        <Tooltip title={obj.Name_THAI || ""}>
                            <Typography className="text-ellipsis" variant="h5" color="primary">
                                {
                                    "#" + (((this.state.w_page - 1) * this.props.limit) + 1 + index) +
                                    " " + obj.Name_THAI
                                }
                            </Typography>
                        </Tooltip>
                    </Box>
                    <div className="d-flex align-items-center mt-02">
                        <span>
                            <small className="text-ellipsis" >ประเภทแหล่งน้ำ</small>
                            <div><small>{obj.TypeName ?? '-'}</small></div>
                        </span>
                        <span className="ml-auto">
                            <small className="text-ellipsis" >ระยะห่างพิกัด</small>
                            <div>
                                <small>{obj.DistanceKM ? Utilities.toPrecision(obj.DistanceKM, 4) + ' กม.' : '-'}</small>
                            </div>
                        </span>
                    </div>
                    <small className="text-ellipsis">
                        {
                            ((obj.ProvinceName) ? ("จ." + obj.ProvinceName + " ") : "") +
                            ((obj.DistrictName) ? ("อ." + obj.DistrictName + " ") : "") +
                            ((obj.SubdistrictName) ? ("ต." + obj.SubdistrictName + " ") : "") +
                            ((obj.Village) ? (obj.Village + " ") : "") +
                            ((obj.Moo) ? (obj.Moo) : "")
                        }
                    </small>
                    <div className="d-flex align-items-center mt-02">
                        <Chip
                            label={"สถานะ: " + (this.statusWaterResource(obj.Approvement_Status) ?? "-")}
                            variant="outlined"
                            size="small"
                            color="secondary"
                        />
                        <span className="ml-auto">
                            <Button
                                className="btn-small"
                                color="secondary"
                                variant="contained"
                                onClick={() => openInNewTab(`/twp/v2/wresource/wresourcedetail/${obj.WaterResourceID}`)}
                            >
                                <Info />
                            </Button>
                            <Button
                                className="ml-03 btn-small"
                                color="primary"
                                variant="contained"
                                onClick={() => onClickWaterResourceLocation(obj.WaterResourceID, true)}
                            >
                                <Room />
                            </Button>
                        </span>
                    </div>
                    <div className="mt-05 d-flex flex-wrap">
                        <Box className="mr-auto text-ellipsis" display="flex" alignItems="center" component="small">
                            <AccountTree className="mr-03 text-secondary" style={{ fontSize: "1rem" }} />
                            {obj.OrganizationName}
                        </Box>
                    </div>
                </div>
            </Card>
        ))
    }

    onClickSearchProjectArea = async () => {
        let isChecked = this.props.getProjectNearLatLng();
        if (!isChecked) return;
        else await isChecked;
        this.setState({ page: 1, w_page: 1 });
    }

    render() {
        const {
            showAreaWindow,
            setSearchAreaFilter,
            clearSearchAreaFilter,
            //setSearchAreaProject,
            onChangeRadius,
            onChangeAreaCircleVisible,
            onClickProjectLocation,
            onClickWaterResourceLocation,
            setShowLayer,
            showLayer,
            areaFilter,
            masterData,
            criticalArea,
            projectArea,
            waterResourceArea,
            limit,
            budgetYearOptions,
        } = this.props;
        return (
            <Grow in={showAreaWindow} className="map-info d-flex flex-column p-0">
                <CmtCard>
                    <div className="section-header main d-flex align-items-center py-05 px-10 mb-02">
                        <TrackChanges className="mr-05" />
                        <h4>ข้อมูลพื้นที่</h4>
                        <IconButton className="ml-auto p-02 text-default" onClick={this.setShowAreaWindow}>
                            <Close />
                        </IconButton>
                    </div>
                    <hr className="hr-light" />
                    <Tabs
                        value={this.state.selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.onChangeTab}
                        centered
                        scrollButtons="auto"
                        variant="scrollable"
                    >
                        <Tab
                            label={
                                <Box display="flex" alignContent="center">
                                    <TrackChanges className="mr-02" />โครงการในรัศมี
                                </Box>
                            }
                        />
                        <Tab
                            label={
                                <Box display="flex" alignContent="center">
                                    <Layers className="mr-02" />ข้อมูลเชิงพื้นที่
                                </Box>
                            }
                        />
                        <Tab
                            label={
                                <Box display="flex" alignContent="center">
                                    <Opacity className="mr-02" />ข้อมูลแหล่งน้ำ
                                </Box>
                            }
                        />
                    </Tabs>
                    <div className="flex-fill py-10 overflowY-auto overflowX-hidden" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
                        <Slide in={this.state.selectedTab === 0} direction="right">
                            <div className={(this.state.selectedTab === 0 ? "" : "d-none")}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <div className="px-10">
                                        <Typography variant="h5">รัศมี</Typography>
                                    </div>
                                    <div className="flex-fill d-flex align-items-center">
                                        <span className="mr-05">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => onChangeRadius((parseFloat(areaFilter.Radius) || 0) - 1)}
                                                disabled={areaFilter.Radius < 1}
                                            >
                                                <Remove />
                                            </IconButton>
                                        </span>
                                        <TextFieldMemo
                                            label="รัศมี"
                                            placeholder="รัศมี"
                                            variant="outlined"
                                            size="small"
                                            value={areaFilter.Radius ?? ''}
                                            name='Radius'
                                            onChange={(e) => setSearchAreaFilter(e.target.value, e.target.name)}
                                            onBlur={(e) => onChangeRadius(parseFloat(e.target.value) || null)}
                                            fullWidth
                                        />
                                        <span className="ml-05">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => onChangeRadius((parseFloat(areaFilter.Radius) || 0) + 1)}
                                            >
                                                <Add />
                                            </IconButton>
                                        </span>
                                    </div>
                                    <div className="pl-10">
                                        <Typography variant="h5">km.</Typography>
                                    </div>
                                    <div className="px-10">
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => onChangeAreaCircleVisible(areaFilter.Invisible ? false : true)}
                                        >
                                            {
                                                areaFilter.Invisible ?
                                                    <Visibility />
                                                    : <VisibilityOff />
                                            }
                                        </IconButton>
                                    </div>
                                </Box>
                                <Grid
                                    container
                                    spacing={3}
                                    className={(this.state.selectedTab === 0 ? "" : "d-none")}
                                >
                                    <Grid item xs={12}>
                                        <ExpandableContent
                                            actionsComponent={
                                                <div className="section-header sub mb-05 d-flex align-items-center">
                                                    <Tune className="mr-05" />
                                                    <span>การคัดกรองข้อมูลพื้นที่</span>
                                                </div>
                                            }
                                            className="mt-05"
                                            contentClass="p-10 pt-0"
                                        >
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <MultiSelect
                                                        label="ปีงบประมาณ"
                                                        value={areaFilter.BudgetYear ?? []}
                                                        dataname='BudgetYear'
                                                        name='BudgetYear'
                                                        dataobjectname="BudgetYearID"
                                                        setvalue={setSearchAreaFilter}
                                                        data={budgetYearOptions}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={6}>
                                                    <MultiSelect
                                                        label="สถานะงบประมาณ"
                                                        value={areaFilter.ProjectStatusID ?? []}
                                                        dataname='ProjectStatus'
                                                        name='ProjectStatusID'
                                                        dataobjectname="ProjectStatusID"
                                                        setvalue={setSearchAreaFilter}
                                                        data={masterData.GetProjectStatus}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="หน่วยรับงบประมาณ"
                                                    value={areaFilter.OrganizationID ?? []}
                                                    dataname='Organization'
                                                    name='OrganizationID'
                                                    dataobjectname="OrganizationID"
                                                    setvalue={setSearchAreaFilter}
                                                    data={masterData.SearchOrganization}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="ลักษณะงาน"
                                                    value={areaFilter.ProjectTypeID ?? []}
                                                    dataname='ProjectType'
                                                    name='ProjectTypeID'
                                                    dataobjectname="ProjectTypeID"
                                                    setvalue={setSearchAreaFilter}
                                                    data={masterData.SearchProjectType}
                                                />
                                            </div>
                                            <Box display="flex" alignItems="center" marginTop={4} className="section-header sub">
                                                <MonetizationOn color="primary" className="mr-03" />
                                                <div>วงเงินงบประมาณ (ล้านบาท)</div>
                                            </Box>
                                            <div className="mt-10">
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextFieldMemo
                                                            label="ตั้งแต่"
                                                            variant="outlined"
                                                            size="small"
                                                            value={areaFilter.BudgetRangeStart ?? ''}
                                                            name='BudgetRangeStart'
                                                            onChange={(e) => setSearchAreaFilter(e.target.value, e.target.name)}
                                                            onBlur={(e) => {
                                                                if (
                                                                    areaFilter.BudgetRangeEnd &&
                                                                    areaFilter.BudgetRangeEnd < parseFloat(e.target.value)
                                                                ) {
                                                                    setSearchAreaFilter(areaFilter.BudgetRangeEnd, e.target.name)
                                                                }
                                                                else
                                                                    setSearchAreaFilter(parseFloat(e.target.value) || null, e.target.name)
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextFieldMemo
                                                            label="จนถึง"
                                                            variant="outlined"
                                                            size="small"
                                                            value={areaFilter.BudgetRangeEnd ?? ''}
                                                            name='BudgetRangeEnd'
                                                            onChange={(e) => setSearchAreaFilter(e.target.value, e.target.name)}
                                                            onBlur={(e) => {
                                                                if (
                                                                    areaFilter.BudgetRangeStart &&
                                                                    areaFilter.BudgetRangeStart > parseFloat(e.target.value)
                                                                ) {
                                                                    setSearchAreaFilter(areaFilter.BudgetRangeStart, e.target.name)
                                                                }
                                                                else
                                                                    setSearchAreaFilter(parseFloat(e.target.value) || null, e.target.name)
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <Box display="flex" justifyContent="center" marginTop={4}>
                                                <Button
                                                    onClick={this.onClickSearchProjectArea}
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    <Search className="mr-03" />
                                                    ค้นหา
                                                </Button>
                                                <Button
                                                    color="default"
                                                    variant="contained"
                                                    className="ml-10"
                                                    size="small"
                                                    onClick={() => clearSearchAreaFilter()}
                                                >
                                                    <Close className="mr-03" />
                                                    ล้างค่า
                                                </Button>
                                            </Box>
                                        </ExpandableContent>
                                        <hr className="hr-light" />
                                        <ExpandableContent
                                            actionsComponent={
                                                <div className="section-header sub mb-05 d-flex align-items-center">
                                                    <List className="mr-05" />
                                                    <span>รายชื่อโครงการ</span>
                                                </div>
                                            }
                                            className="mt-05"
                                            contentClass="p-10 pt-0"
                                        >
                                            <small className="text-danger">* ข้อมูลจะแสดงสูงสุด 100 โครงการ</small>
                                            {/* <TextFieldMemo
                                                label={
                                                    <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
                                                        <Search className="mr-03" /> ค้นหาโครงการ
                                                </div>
                                                }
                                                placeholder="ค้นหาโครงการ"
                                                variant="outlined"
                                                size="small"
                                                value={areaFilter.ProjectName ?? ''}
                                                name='ProjectName'
                                                onChange={setSearchAreaProject}
                                                fullWidth
                                                className="mb-05"
                                            /> */}
                                            {
                                                projectArea?.length ?
                                                    this.renderProject(projectArea, onClickProjectLocation)
                                                    :
                                                    <Card className="width-100 mb-05">
                                                        <div className="p-10" style={{ textAlign: "center" }}>
                                                            ไม่มีข้อมูล...
                                                        </div>
                                                    </Card>
                                            }
                                            <Grid item xs={12}>
                                                <Pagination
                                                    count={(projectArea?.length) ? Math.floor(projectArea.length / limit) + (projectArea.length % limit > 0) : 1}
                                                    page={this.state.page}
                                                    onChange={(obj, page) => this.setState({ page: page })}
                                                    color="primary"
                                                    className="my-05 pagination-center"
                                                />
                                            </Grid>
                                        </ExpandableContent>
                                    </Grid>
                                </Grid>
                            </div>
                        </Slide>
                        <Slide in={this.state.selectedTab === 1} direction="right">
                            <div className={(this.state.selectedTab === 1 ? "" : "d-none")}>
                                <Box display="flex" alignItems="center" padding={4} paddingTop={0}>
                                    <NewReleases className="mr-05" color="primary" />
                                    <Typography variant="h4">ข้อมูลเชิงพื้นที่สำคัญ</Typography>
                                </Box>
                                <Grid
                                    container
                                    spacing={3}
                                    className={(this.state.selectedTab === 1 ? "" : "d-none")}
                                >
                                    {
                                        criticalArea?.length ?
                                            criticalArea.map((obj, index) => (
                                                <Grid item xs={12} key={"critarea-" + index}>
                                                    <CmtCard>
                                                        <Box display="flex" alignItems="center" padding={3}>
                                                            <Chip
                                                                label={index + 1}
                                                                color="primary"
                                                                className="mr-05"
                                                            />
                                                            <div className="flex-fill">
                                                                <Typography variant="h5">{obj.ShapeType}</Typography>
                                                                <small className="mt-02">{obj.ShapeName}</small>
                                                            </div>
                                                            <IconButton
                                                                color={(showLayer[obj.LayerID]) ? "primary" : "default"}
                                                                onClick={() =>
                                                                    setShowLayer({
                                                                        target: {
                                                                            name: obj.LayerID,
                                                                            checked: (showLayer[obj.LayerID]) ? false : true
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <Layers />
                                                            </IconButton>
                                                        </Box>
                                                    </CmtCard>
                                                </Grid>
                                            ))
                                            : null
                                    }
                                </Grid>
                            </div>
                        </Slide>
                        <Slide in={this.state.selectedTab === 2} direction="right">
                            <div className={(this.state.selectedTab === 2 ? "" : "d-none")}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <div className="px-10">
                                        <Typography variant="h5">รัศมี</Typography>
                                    </div>
                                    <div className="flex-fill d-flex align-items-center">
                                        <span className="mr-05">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => onChangeRadius((parseFloat(areaFilter.Radius) || 0) - 1)}
                                                disabled={areaFilter.Radius < 1}
                                            >
                                                <Remove />
                                            </IconButton>
                                        </span>
                                        <TextFieldMemo
                                            label="รัศมี"
                                            placeholder="รัศมี"
                                            variant="outlined"
                                            size="small"
                                            value={areaFilter.Radius ?? ''}
                                            name='Radius'
                                            onChange={(e) => setSearchAreaFilter(e.target.value, e.target.name)}
                                            onBlur={(e) => onChangeRadius(parseFloat(e.target.value) || null)}
                                            fullWidth
                                        />
                                        <span className="ml-05">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => onChangeRadius((parseFloat(areaFilter.Radius) || 0) + 1)}
                                            >
                                                <Add />
                                            </IconButton>
                                        </span>
                                    </div>
                                    <div className="pl-10">
                                        <Typography variant="h5">km.</Typography>
                                    </div>
                                    <div className="px-10">
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => onChangeAreaCircleVisible(areaFilter.Invisible ? false : true)}
                                        >
                                            {
                                                areaFilter.Invisible ?
                                                    <Visibility />
                                                    : <VisibilityOff />
                                            }
                                        </IconButton>
                                    </div>
                                </Box>
                                <Grid
                                    container
                                    spacing={3}
                                    className={(this.state.selectedTab === 2 ? "" : "d-none")}
                                >
                                    <Grid item xs={12}>
                                        <ExpandableContent
                                            actionsComponent={
                                                <div className="section-header sub mb-05 d-flex align-items-center">
                                                    <Tune className="mr-05" />
                                                    <span>การคัดกรองข้อมูลแหล่งน้ำ</span>
                                                </div>
                                            }
                                            className="mt-05"
                                            contentClass="p-10 pt-0"
                                        >
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="ลุ่มน้ำ"
                                                    value={areaFilter.W_BasinID ?? []}
                                                    dataname='Basin'
                                                    name='W_BasinID'
                                                    dataobjectname="BasinID"
                                                    setvalue={setSearchAreaFilter}
                                                    data={masterData.GetBasin}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="จังหวัด"
                                                    value={areaFilter.W_ProvinceID ?? null}
                                                    dataname='Province'
                                                    name='W_ProvinceID'
                                                    dataobjectname="ProvinceID"
                                                    setvalue={setSearchAreaFilter}
                                                    data={masterData.GetProvince}
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <TextFieldMemo
                                                    label="ขนาดแหล่งน้ำ (ล้าน ลบ.ม.)"
                                                    value={areaFilter.W_Size ?? ""}
                                                    name='W_Size'
                                                    onChange={(e) => setSearchAreaFilter(e.target.value, e.target.name)}
                                                    onBlur={(e) => {
                                                        let val = parseFloat(e.target.value.replace(/,/g, ""));
                                                        if (Number.isNaN(val)) val = null;
                                                        setSearchAreaFilter(val, e.target.name)
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <MultiSelect
                                                    label="ประเภทแหล่งน้ำ"
                                                    value={areaFilter.W_Type ?? []}
                                                    dataname='WaterResourceType'
                                                    name='W_Type'
                                                    dataobjectname="WaterResourceTypeID"
                                                    setvalue={setSearchAreaFilter}
                                                    data={masterData.GetWaterResourceType}
                                                />
                                            </div>
                                            <Box display="flex" justifyContent="center" marginTop={4}>
                                                <Button
                                                    onClick={this.onClickSearchProjectArea}
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    <Search className="mr-03" />
                                                    ค้นหา
                                                </Button>
                                                <Button
                                                    onClick={() => { }}
                                                    color="default"
                                                    variant="contained"
                                                    className="ml-10"
                                                    size="small"
                                                    onClick={clearSearchAreaFilter}
                                                >
                                                    <Close className="mr-03" />
                                                    ล้างค่า
                                                </Button>
                                            </Box>
                                        </ExpandableContent>
                                        <hr className="hr-light" />
                                        <ExpandableContent
                                            actionsComponent={
                                                <div className="section-header sub mb-05 d-flex align-items-center">
                                                    <List className="mr-05" />
                                                    <span>รายการแหล่งน้ำ</span>
                                                </div>
                                            }
                                            className="mt-05"
                                            contentClass="p-10 pt-0"
                                        >
                                            <small className="text-danger">* ข้อมูลจะแสดงสูงสุด 100 แหล่งน้ำ</small>
                                            {
                                                waterResourceArea?.length ?
                                                    this.renderWreSource(waterResourceArea, onClickWaterResourceLocation)
                                                    :
                                                    <Card className="width-100 mb-05">
                                                        <div className="p-10" style={{ textAlign: "center" }}>
                                                            ไม่มีข้อมูล...
                                                        </div>
                                                    </Card>
                                            }
                                            <Grid item xs={12}>
                                                <Pagination
                                                    count={(waterResourceArea?.length) ? Math.floor(waterResourceArea.length / limit) + (waterResourceArea.length % limit > 0) : 1}
                                                    page={this.state.w_page}
                                                    onChange={(obj, page) => this.setState({ w_page: page })}
                                                    color="primary"
                                                    className="my-05 pagination-center"
                                                />
                                            </Grid>
                                        </ExpandableContent>
                                    </Grid>
                                </Grid>
                            </div>
                        </Slide>
                    </div>
                </CmtCard>
            </Grow>
        );
    }
}

export default MapArea;