import React from 'react';
import CmtCard from '../../@coremat/CmtCard';
import PageContainer from "../../@jumbo/components/PageComponents/layouts/PageContainer";
import {
    Description,
    History,
    KeyboardArrowLeft
} from '@material-ui/icons';
import {
    Grid,
    Box,
    Button,
} from '@material-ui/core';
import { connect } from "react-redux";
import { hideMenu, showMenu } from "../../redux/action";
import { Link } from 'react-router-dom';
import VersionService from '../../Service/_VersionService';
import ExpandableContent from '../../@coremat/CmtCardExpendableContent';

const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home"
    },
    {
        label: "Released Versions",
        isActive: true,
        link: "/releasedversions"
    },
];

class ReleasedVersions extends React.Component {
    state = {
        version: []
    }

    componentDidMount() {
        if (!this.props.isAuth)
            this.props.dispatch(hideMenu());

        VersionService.GetReleaseNote()
            .then((values) => {
                this.setState({
                    version: values?.data?.GetReleaseNote ?? []
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    componentWillUnmount() {
        if (!this.props.isAuth)
            this.props.dispatch(showMenu());
    }

    render() {
        const El = (this.props.isAuth) ? PageContainer : Box;
        return (
            <El padding={8} heading="Released Versions" breadcrumbs={location}>
                {
                    this.props.isAuth ?
                        null :
                        <Box marginBottom={4}>
                            <Link to="landingpage">
                                <Button color="default" className="btn-danger">
                                    <KeyboardArrowLeft />
                                    กลับ
                                </Button>
                            </Link>
                        </Box>
                }
                <CmtCard>
                    <Box padding={5}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} className="section-header main">
                                <div className="d-flex align-items-center">
                                    <Description className="mr-05" />
                                    Released Versions
                                </div>
                                <hr />
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12} sm={3} md={2}><h4>Version</h4></Grid>
                                <Grid item xs={12} sm={9} md={10}>{this.state?.version?.[0]?.Version}</Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12} sm={3} md={2}><h4>Build</h4></Grid>
                                <Grid item xs={12} sm={9} md={10}>{this.state?.version?.[0]?.Build}</Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12} sm={3} md={2}><h4>Release Note</h4></Grid>
                                <Grid item xs={12} sm={9} md={10}>
                                    {
                                        this.state?.version?.[0]?.Note?.length ?
                                            <ul>
                                                {this.state.version[0].Note.map((x, i) => (
                                                    <li key={"note-" + i} style={{ marginBottom: "10px" }}>
                                                        <div dangerouslySetInnerHTML={{ __html: x }}></div>
                                                    </li>
                                                ))}
                                            </ul>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <ExpandableContent
                                        actionsComponent={
                                            <span className="d-flex align-items-center">
                                                <History className="mr-05" />
                                                <h4>History</h4>
                                            </span>
                                        }
                                    >
                                        <Box pl={4}>
                                            {
                                                this.state?.version?.[0]?.Note?.length ?
                                                    this.state.version.map((obj, index) => {
                                                        if (index === 0) return null;
                                                        return (
                                                            <Box mb={8} key={"h-" + index}>
                                                                <hr className="hr-light mb-10" />
                                                                <Box display="flex" alignItems="center" mt={3}>
                                                                    <h4>Version : </h4>
                                                                    <div>{obj.Version}</div>
                                                                </Box>
                                                                <Box display="flex" alignItems="center" mt={3}>
                                                                    <h4>Build : </h4>
                                                                    <div>{obj.Build}</div>
                                                                </Box>
                                                                <ul className="mt-10 pl-20">
                                                                    {obj.Note.map((x, i) => (
                                                                        <li key={"h-note-" + i} style={{ marginBottom: "10px" }}>
                                                                            <div dangerouslySetInnerHTML={{ __html: x }}></div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Box>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Box>
                                    </ExpandableContent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CmtCard>
            </El>
        )
    }
}

const mapStatetoProps = (state) => ({
    isAuth: state.isAuth
});

export default connect(mapStatetoProps)(ReleasedVersions);