import React from "react";
import Highcharts from 'highcharts/highcharts';
Highcharts.setOptions({
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

class Chart extends React.Component {
    chartInstance = undefined;

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.data && this.chartInstance === undefined) {
            this.renderChart(nextProps.data);
            return true;
        }
        else if (nextProps.data !== this.props.data && this.chartInstance !== undefined) {
            this.chartInstance.destroy();
            this.renderChart(nextProps.data);
            return true;
        }
        return false;
    }

    componentDidMount() {
        if (this.props.data && this.chartInstance === undefined) {
            this.renderChart(this.props.data);
        }
    }

    renderChart(data) {
        let pieOption = {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true
        }
        if(this.props.print) {
            pieOption = {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `<span style="font-family:Sarabun">${this.key}: ${this.y} (${this.percentage.toFixed(1)})  %</span>`;
                    }
                }
            }
        }
        this.chartInstance = Highcharts.chart(this.chart, {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: this.props.height ?? undefined
            },
            title: {
                text: /* this.props.title ?? */ ''
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.key.substr(0, 9)}</b>: ${Highcharts.numberFormat(this.y, 4)} (${this.percentage.toFixed(1)} % ) `;
                },
            },
            plotOptions: {
                pie: {
                    ...pieOption
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            series: data
        });
    }

    render() {
        return (
            <div ref={el => this.chart = el} style={{ width: "100%", height: "100%" }} />
        )
    }
}

export default Chart;
