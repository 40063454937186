import Axios from "./_BaseService";

const SignoffService = {
    searchSignoff: (data) => {
        data = {
            SignoffID: data.SignoffID || undefined,
            SignoffName: data.SignoffName ? data.SignoffName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            CanSignoff: data.CanSignoff || undefined,
        };

        const SEARCHSIGNOFF = `
            query SearchSignoff (
                $SignoffID: Int,
                $SignoffName: String,
                $BudgetYear: Int,
                $BudgetSourceID: Int
                $CanSignoff: Boolean
            ) {
                SearchSignoff (
                    SignoffID: $SignoffID,
                    SignoffName: $SignoffName,
                    BudgetYear: $BudgetYear,
                    BudgetSourceID: $BudgetSourceID
                    CanSignoff: $CanSignoff
                ) {
                    SignoffID,
                    SignoffName,
                    BudgetStartYear,
                    BudgetEndYear,
                    SignOffStatusID,
                    SignoffStatusName,
                    BudgetSourceIDs,
                    BudgetSourceNames,
                    SignoffStepID,
                    SignoffStepName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFF,
            variables: data,
            errorPolicy: "all",
        });
    },
    searchSignoffOrderProfileManage: (data) => {
        data = {
            SignoffID: data.SignoffID || undefined,
            SignoffName: data.SignoffName ? data.SignoffName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            CanSignoff: data.CanSignoff || undefined,
        };

        const SEARCHSIGNOFFORDERPROFILEMANAGE = `
            query SearchSignoffOrderProfileManage (
                $SignoffID: Int,
                $SignoffName: String,
                $BudgetYear: Int,
                $BudgetSourceID: Int
                $CanSignoff: Boolean
            ) {
                SearchSignoffOrderProfileManage (
                    SignoffID: $SignoffID,
                    SignoffName: $SignoffName,
                    BudgetYear: $BudgetYear,
                    BudgetSourceID: $BudgetSourceID
                    CanSignoff: $CanSignoff
                ) {
                    SignoffID,
                    SignoffName,
                    BudgetStartYear,
                    BudgetEndYear,
                    SignOffStatusID,
                    SignoffStatusName,
                    BudgetSourceIDs,
                    BudgetSourceNames,
                    SignoffStepID,
                    SignoffStepName
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFFORDERPROFILEMANAGE,
            variables: data,
            errorPolicy: "all",
        });
    },
    getSignoffStepAccount: (SignoffStepID) => {
        let data = {
            SignoffStepID: SignoffStepID || undefined,
        };

        const GETSIGNOFFSTEPACCOUNT = `
            query GetSignoffStepAccount (
                $SignoffStepID: Int!
            ) {
                GetSignoffStepAccount (
                    SignoffStepID: $SignoffStepID
                ) {
                    SignoffStepID
                    AccountID
                    ID
                    Name
                    Username
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETSIGNOFFSTEPACCOUNT,
            variables: data,
            errorPolicy: "all",
        });
    },
    getSignoffDetail: (SignoffID, PermissionOrderProfile = false) => {
        let data = {
            SignoffID: SignoffID || undefined,
            PermissionOrderProfile: PermissionOrderProfile
        };

        const GETSIGNOFFDETAIL = `
            query GetSignoffDetail (
                $SignoffID: Int!
                $PermissionOrderProfile: Boolean
            ) {
                GetSignoffDetail (
                    SignoffID: $SignoffID
                    PermissionOrderProfile: $PermissionOrderProfile
                ) {
                    SignoffID,
                    SignoffName,
                    BudgetStartYear,
                    BudgetEndYear,
                    SignOffStatusID,
                    SignoffStatusName,
                    BudgetSource {
                        BudgetSourceID
                        BudgetSourceName
                    }
                    TagID,
                    CanUpdate,
                    IsPublic,
                    Highlight,
                    SignoffStep {
                        SignoffStepID
                        SignoffStepName
                        SignoffID
                        WorkplanGroupID
                        WorkplanGroupName
                        StartDate
                        EndDate
                        SignoffStepOrder
                        SignoffStepStatusName
                        SignoffStepStatusID
                        CanUpdate
                        Approved
                    }
                    RequireFile
                    CanUpdatePrepareFile
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETSIGNOFFDETAIL,
            variables: data,
            errorPolicy: "all",
        });
    },
    searchSignoffProject: (data) => {
        data = {
            SignoffID: data.SignoffID,
            StepID: data.StepID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            BasinID: data.BasinID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            SignoffStatusID: data.SignoffStatusID || undefined,
        };

        const SEARCHSIGNOFFPROJECT = `
            query SearchSignoffProject(
                $SignoffID: Int!,
                $StepID: Int,
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $ProvinceID: [Int],
                $BasinID: [Int],
                $BudgetDimensionID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $SignoffStatusID: Int
            ) {
                SearchSignoffProject(
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    ProvinceID: $ProvinceID,
                    BasinID: $BasinID,
                    BudgetDimensionID: $BudgetDimensionID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    SignoffStatusID: $SignoffStatusID
                ) {
                    Project,
                    Count,
                    CountDataSet,
                    CountSignoff,
                    CountDataSet,
                    Amount,
                    CountWaitingSignoff,
                    AmountSignoff
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFFPROJECT,
            variables: data,
            errorPolicy: "all",
        });
    },
    searchSignoffProjectOld: (data) => {
        data = {
            SignoffID: data.SignoffID,
            StepID: data.StepID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            BasinID: data.BasinID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            SignoffStatusID: data.SignoffStatusID || undefined,
        };

        const SEARCHSIGNOFFPROJECTOLD = `
            query SearchSignoffProjectOld(
                $SignoffID: Int!,
                $StepID: Int,
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $ProvinceID: [Int],
                $BasinID: [Int],
                $BudgetDimensionID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $SignoffStatusID: Int
            ) {
                SearchSignoffProjectOld(
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    ProvinceID: $ProvinceID,
                    BasinID: $BasinID,
                    BudgetDimensionID: $BudgetDimensionID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    SignoffStatusID: $SignoffStatusID
                ) {
                    Project,
                    Count,
                    CountDataSet,
                    CountSignoff,
                    CountDataSet,
                    Amount,
                    CountWaitingSignoff,
                    AmountSignoff
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFFPROJECTOLD,
            variables: data,
            errorPolicy: "all",
        });
    },
    getSignoffStepProjectDetail: (data) => {
        data = {
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
        };

        const GETSIGNOFFSTEPPROJECTDETAIL = `
            query GetSignoffStepProjectDetail(
                $SignoffStepID: Int!,
                $ReferenceID: Int,
                $SignoffID: Int!,
                $StepID: Int
            ) {
                GetSignoffStepProjectDetail(
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID
                ) {
                    SignoffStepID
                    SignoffStepName
                    SignoffID
                    WorkplanGroupID
                    BudgetStartYear
                    BudgetEndYear
                    SignoffApproveStatusID
                    SignoffApproveStatusName
                    FileName
                    Remark
                    FirstName
                    LastName
                    Path
                    ApproveDateTime
                    ReferenceID
                    Name
                    CanSignoff
                    Readiness {
                        ReadinessStatusID
                        ReadinessStatusName
                        Count
                        Amount
                    }
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETSIGNOFFSTEPPROJECTDETAIL,
            variables: data,
            errorPolicy: "all",
        });
    },
    getSignoffStepProjectDetailOld: (data) => {
        data = {
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
        };

        const GETSIGNOFFSTEPPROJECTDETAILOLD = `
            query GetSignoffStepProjectDetailOld(
                $SignoffStepID: Int!,
                $ReferenceID: Int,
                $SignoffID: Int!,
                $StepID: Int
            ) {
                GetSignoffStepProjectDetailOld(
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID
                ) {
                    SignoffStepID
                    SignoffStepName
                    SignoffID
                    WorkplanGroupID
                    BudgetStartYear
                    BudgetEndYear
                    SignoffApproveStatusID
                    SignoffApproveStatusName
                    FileName
                    Remark
                    FirstName
                    LastName
                    Path
                    ApproveDateTime
                    ReferenceID
                    Name
                    CanSignoff
                    Readiness {
                        ReadinessStatusID
                        ReadinessStatusName
                        Count
                        Amount
                    }
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETSIGNOFFSTEPPROJECTDETAILOLD,
            variables: data,
            errorPolicy: "all",
        });
    },
    searchSignoffStepProject: (data) => {
        data = {
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            ProjectName: data.ProjectName || undefined,
            ProjectCode: data.ProjectCode || undefined,
            ReadinessID: data.ReadinessID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BasinID: data.BasinID || undefined,
            BudgetYear: data.BudgetYear || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            GroupOrder: data.GroupOrder || null,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            GroupOrderDesc: data.GroupOrderDesc || null,
            Offset: data.Offset || undefined,
            Limit: data.Limit || undefined,
        };

        const SEARCHSIGNOFFSTEPPROJECT = `
            query SearchSignoffStepProject(
                $SignoffStepID: Int!,
                $ReferenceID: Int,
                $SignoffID: Int!,
                $StepID: Int,
                $ProjectName: String,
                $ProjectCode: String,
                $ReadinessID: Int,
                $ProvinceID: Int,
                $OrganizationID: Int,
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BasinID: Int,
                $BudgetYear: Int,
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $BudgetDimensionID: [Int],
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int
            ) {
                SearchSignoffStepProject(
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    ProjectName: $ProjectName,
                    ProjectCode: $ProjectCode,
                    ReadinessID: $ReadinessID,
                    ProvinceID: $ProvinceID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BasinID: $BasinID,
                    BudgetYear: $BudgetYear,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    BudgetDimensionID: $BudgetDimensionID,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    Offset: $Offset,
                    Limit: $Limit
                ) {
                    SignoffStepID
                    SignoffStepName
                    SignoffID
                    WorkplanGroupID
                    BudgetStartYear
                    BudgetEndYear
                    ReferenceID
                    Name
                    Count
                    Amount
                    Project {
                        ProjectID
                        ProjectName
                        RequestAmount
                        BudgetYear
                        OrganizationID
                        OrganizationName
                        BasinID
                        ProvinceID
                        ID
                        Name
                        ReadinessStatusID
                        ReadinessStatusName
                        OrderProject
                    }
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFFSTEPPROJECT,
            variables: data,
            errorPolicy: "all",
        });
    },
    searchSignoffStepProjectOld: (data) => {
        data = {
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            ProjectName: data.ProjectName || undefined,
            ProjectCode: data.ProjectCode || undefined,
            ReadinessID: data.ReadinessID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BasinID: data.BasinID || undefined,
            BudgetYear: data.BudgetYear || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            GroupOrder: data.GroupOrder || null,
            GroupOrderDesc: data.GroupOrderDesc || null,
            Offset: data.Offset || undefined,
            Limit: data.Limit || undefined,
        };

        const SEARCHSIGNOFFSTEPPROJECTOLD = `
            query SearchSignoffStepProjectOld(
                $SignoffStepID: Int!,
                $ReferenceID: Int,
                $SignoffID: Int!,
                $StepID: Int,
                $ProjectName: String,
                $ProjectCode: String,
                $ReadinessID: Int,
                $ProvinceID: Int,
                $OrganizationID: Int,
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BasinID: Int,
                $BudgetYear: Int,
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $BudgetDimensionID: [Int],
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int
            ) {
                SearchSignoffStepProjectOld(
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    ProjectName: $ProjectName,
                    ProjectCode: $ProjectCode,
                    ReadinessID: $ReadinessID,
                    ProvinceID: $ProvinceID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BasinID: $BasinID,
                    BudgetYear: $BudgetYear,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    BudgetDimensionID: $BudgetDimensionID,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    Offset: $Offset,
                    Limit: $Limit
                ) {
                    SignoffStepID
                    SignoffStepName
                    SignoffID
                    WorkplanGroupID
                    BudgetStartYear
                    BudgetEndYear
                    ReferenceID
                    Name
                    Count
                    Amount
                    Project {
                        ProjectID
                        ProjectName
                        RequestAmount
                        BudgetYear
                        OrganizationID
                        OrganizationName
                        BasinID
                        ProvinceID
                        ID
                        Name
                        ReadinessStatusID
                        ReadinessStatusName
                        OrderProject
                    }
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHSIGNOFFSTEPPROJECTOLD,
            variables: data,
            errorPolicy: "all",
        });
    },

    createSignoff: (data) => {
        data = {
            SignoffName: data.SignoffName || undefined,
            BudgetStartYear: data.BudgetStartYear || undefined,
            BudgetEndYear: data.BudgetEndYear || undefined,
            SignOffStatusID: data.SignOffStatusID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            TagID: data.TagID || undefined,
            IsPublic: data.IsPublic ?? undefined,
            Highlight: data.Highlight ?? undefined,
            SignoffStep: data.SignoffStep || undefined,
            RequireFile: data.RequireFile?.length ? data.RequireFile : undefined
        };

        const CREATESIGNOFF = `
            mutation CreateSignoff (
                $SignoffName: String!
                $BudgetStartYear: Int!,
                $BudgetEndYear: Int!,
                $SignOffStatusID: Int,
                $BudgetSourceID: [Int!]!
                $TagID: Int
                $IsPublic: Int
                $Highlight: Int
                $SignoffStep: [InputSignoffStep]
                $RequireFile: [JSON]
            ) {
                CreateSignoff(
                    SignoffName: $SignoffName,
                    BudgetStartYear: $BudgetStartYear,
                    BudgetEndYear: $BudgetEndYear,
                    SignOffStatusID: $SignOffStatusID,
                    BudgetSourceID: $BudgetSourceID,
                    TagID: $TagID,
                    IsPublic: $IsPublic,
                    Highlight: $Highlight,
                    SignoffStep: $SignoffStep,
                    RequireFile: $RequireFile,
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: CREATESIGNOFF,
            variables: data,
            errorPolicy: "all",
        });
    },
    updateSignoff: (data) => {
        data = {
            SignoffID: data.SignoffID || undefined,
            SignoffName: data.SignoffName || undefined,
            BudgetStartYear: data.BudgetStartYear || undefined,
            BudgetEndYear: data.BudgetEndYear || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            TagID: data.TagID || undefined,
            IsPublic: data.IsPublic ?? undefined,
            Highlight: data.Highlight ?? undefined,
            SignoffStep: data.SignoffStep || undefined,
            RequireFile: data.RequireFile?.length ? data.RequireFile : undefined
        };

        const UPDATESIGNOFF = `
            mutation UpdateSignoff (
                $SignoffID: Int!
                $SignoffName: String!
                $BudgetStartYear: Int!,
                $BudgetEndYear: Int!,
                $BudgetSourceID: [Int!]!
                $TagID: Int
                $IsPublic: Int
                $Highlight: Int
                $SignoffStep: [InputSignoffStep]
                $RequireFile: [JSON]
            ) {
                UpdateSignoff(
                    SignoffID: $SignoffID,
                    SignoffName: $SignoffName,
                    BudgetStartYear: $BudgetStartYear,
                    BudgetEndYear: $BudgetEndYear,
                    BudgetSourceID: $BudgetSourceID,
                    TagID: $TagID,
                    IsPublic: $IsPublic,
                    Highlight: $Highlight,
                    SignoffStep: $SignoffStep,
                    RequireFile: $RequireFile,
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: UPDATESIGNOFF,
            variables: data,
            errorPolicy: "all",
        });
    },
    upsertSignoffStepAccount: (data) => {
        data = {
            SignoffStepID: data.SignoffStepID || undefined,
            AccountID: data.AccountID || undefined,
            ReferenceID: data.ReferenceID || undefined,
        };

        const UPDATESIGNOFFSTEPACCOUNT = `
              mutation UpsertSignoffStepAccount (
                  $SignoffStepID: Int!
                  $AccountID: [Int!]
                  $ReferenceID: Int,
              ) {
                UpsertSignoffStepAccount(
                      SignoffStepID: $SignoffStepID,
                      AccountID: $AccountID,
                      ReferenceID: $ReferenceID,
                  )
              }
          `;

        return Axios.post("/twpservice", {
            query: UPDATESIGNOFFSTEPACCOUNT,
            variables: data,
            errorPolicy: "all",
        });
    },

    approveSignoff: (data) => {
        data = {
            File: data.File ?? null,
            SignoffStepID: data.SignoffStepID,
            ReferenceID: data.ReferenceID,
            Remark: data.Remark,
        };

        let formData = new FormData();

        formData.append("File", data.File);
        formData.append("SignoffStepID", data.SignoffStepID);
        formData.append("ReferenceID", data.ReferenceID);
        formData.append("Remark", data.Remark);

        return Axios({
            method: "post",
            url: "/service/signoff_approve",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },
    withdrawSignoff: (data) => {
        data = {
            File: data.File ?? null,
            SignoffStepID: data.SignoffStepID,
            ReferenceID: data.ReferenceID,
            Remark: data.Remark,
        };

        let formData = new FormData();

        formData.append("File", data.File);
        formData.append("SignoffStepID", data.SignoffStepID);
        formData.append("ReferenceID", data.ReferenceID);
        formData.append("Remark", data.Remark);

        return Axios({
            method: "post",
            url: "/service/signoff_withdraw",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },
    changeSignoff: (data) => {
        data = {
            File: data.File ?? null,
            SignoffStepID: data.SignoffStepID,
            ReferenceID: data.ReferenceID,
            Remark: data.Remark,
        };

        let formData = new FormData();

        formData.append("File", data.File);
        formData.append("SignoffStepID", data.SignoffStepID);
        formData.append("ReferenceID", data.ReferenceID);
        formData.append("Remark", data.Remark);

        return Axios({
            method: "post",
            url: "/service/signoff_change",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000,
        });
    },
};

export default SignoffService;
