import React, { useState } from 'react';
import {
    // TextField,
    // Grid,
    Button,
    Dialog,
    DialogTitle,
    // DialogActions,
    DialogContent,
    // CircularProgress,
    // Slide,
    IconButton,
    Grow,
    Box,
    Typography
} from '@material-ui/core';
import {
    Close,
    Videocam,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    GetApp
} from '@material-ui/icons';
// import AccountService from '../../../Service/AccountService';
// import { setAccountLogin } from '../../../redux/action';
// import { setUsersCookies } from '../../../Service/_CookieService';
// import { ArrowBack } from '@material-ui/icons';
// import Swal from 'sweetalert2';

const listFile = [
    {
        link: "https://www.youtube.com/embed/OCggAveHbis",
        name: "1. การล็อคอินเข้าระบบ"
    },
    {
        link: "https://www.youtube.com/embed/A7cpE79uELU",
        name: "2-A. การสร้างโครงการด้วย Graphic Mode (โครงการปีเดียว)"
    },
    {
        link: "https://www.youtube.com/embed/GsNUeAZ67MI",
        name: "2-B. การสร้างโครงการด้วย Graphic Mode (โครงการต่อเนื่องใหม่)"
    },
    {
        link: "https://www.youtube.com/embed/RrIcvZNMNiA",
        name: "3-A. การสร้างโครงการด้วย Smart Spreadsheet (โครงการปีเดียว)"
    },
    {
        link: "https://www.youtube.com/embed/y_GyEdF5qK8",
        name: "3-B. การสร้างโครงการด้วย Smart Spreadsheet (โครงการหลายปี)"
    },
    {
        link: "https://www.youtube.com/embed/0w1I_qzVvT8",
        name: "4. การค้นหาและคัดกรองโครงการ"
    },
    {
        link: "https://www.youtube.com/embed/ccHMxO8x7b4",
        name: "5-6. การแก้ไขและลบโครงการ"
    },
    {
        link: "https://www.youtube.com/embed/M3xozWxTIIg",
        name: "7.1 กระบวนการ Sign Off แผนงาน (สร้างและรวบรวมแผน)"
    },
    {
        link: "https://www.youtube.com/embed/ezQ2EAJ6K5s",
        name: "7.2 กระบวนการ Sign Off แผนงาน (ตรวจสอบความซ้ำซ้อน)"
    },
    {
        link: "https://www.youtube.com/embed/rS5bZG_VhuM",
        name: "7.3 กระบวนการ Sign Off แผนงาน (ตรวจสอบสถานะความพร้อม)"
    },
    {
        link: "https://www.youtube.com/embed/3knrL-JdYOY",
        name: "7.4 กระบวนการ Sign Off แผนงาน 4 (เลือกแผนง่านและจัดลำดับความสำคัญ)"
    },
    {
        link: "https://www.youtube.com/embed/BKYI6AEa6wY",
        name: "7.5 กระบวนการ Sign Off แผนงาน (นำส่ง Sign Off)"
    },
    // {
    //     link: "https://www.youtube.com/embed/DaEAXC0Am2k",
    //     downloadLink: "/twp/v2/files/ตอนที่2_การสร้างโครงการด้วยฟอร์ม001(โครงการต่อเนื่องใหม่).mp4",
    //     fileName: "ตอนที่2_การสร้างโครงการด้วยฟอร์ม001(โครงการต่อเนื่องใหม่).mp4",
    //     name: "ตอนที่2: การสร้างโครงการด้วยฟอร์ม สทนช.001 (โครงการต่อเนื่อง/ผูกพันใหม่)"
    // },
    // {
    //     link: "https://www.youtube.com/embed/ziwws7bGE9U",
    //     downloadLink: "/twp/v2/files/ตอนที่3_การสร้างโครงการด้วยฟอร์ม001(โครงการต่อเนื่องเดิม).mp4",
    //     fileName: "ตอนที่3_การสร้างโครงการด้วยฟอร์ม001(โครงการต่อเนื่องเดิม).mp4",
    //     name: "ตอนที่3: การสร้างโครงการด้วยฟอร์ม สทนช.001โครงการต่อเนื่อง/ผูกพันเดิม"
    // },
    // {
    //     link: "https://www.youtube.com/embed/V4Tn4PuFBDc",
    //     downloadLink: "/twp/v2/files/ตอนที่4_การสร้างโครงการด้วยฟอร์ม004.mp4",
    //     fileName: "ตอนที่4_การสร้างโครงการด้วยฟอร์ม004.mp4",
    //     name: "ตอนที่4: การสร้างโครงการด้วยฟอร์ม สทนช.004"
    // },
    // {
    //     link: "https://www.youtube.com/embed/NlZtYt0WLqs",
    //     downloadLink: "/twp/v2/files/ตอนที่5_การสร้างโครงการด้วยSpreadsheet.mp4",
    //     fileName: "ตอนที่2_การสร้างโครงการด้วยSpreadsheet.mp4",
    //     name: "ตอนที่2: การสร้างโครงการด้วย Smart Spreadsheet"
    // },
    // {
    //     link: "https://www.youtube.com/embed/LyDY58yWj7s",
    //     downloadLink: "/twp/v2/files/ตอนที่6_การค้นหาและคัดกรองโครงการ.mp4",
    //     fileName: "ตอนที่3_การค้นหาและคัดกรองโครงการ.mp4",
    //     name: "ตอนที่3: การค้นหาและคัดกรองโครงการ"
    // },
    // {
    //     link: "https://www.youtube.com/embed/mXyvXldDMVI",
    //     downloadLink: "/twp/v2/files/ตอนที่7_การลบและแก้ไขโครงการ.mp4",
    //     fileName: "ตอนที่4_การลบและแก้ไขโครงการ.mp4",
    //     name: "ตอนที่4: การลบและแก้ไขโครงการ"
    // },
    // {
    //     link: "https://www.youtube.com/embed/xXVxPmjcPTY",
    //     downloadLink: "/twp/v2/files/ตอนที่8_Sign-Off_A_การสร้างและรวบรวมแผนงาน.mp4",
    //     fileName: "ตอนที่5: Sign-Off: A: การสร้างและรวบรวมแผนงาน",
    //     name: "ตอนที่5: Sign-Off: A: การสร้างและรวบรวมแผนงาน"
    // },
    // {
    //     link: "https://www.youtube.com/embed/eNJNjBEBaHw",
    //     downloadLink: "/twp/v2/files/ตอนที่9_Sign-Off_B_การตรวจสอบความซ้ำซ้อน.mp4",
    //     fileName: "ตอนที่6: Sign-Off: B: การตรวจสอบความซ้ำซ้อน",
    //     name: "ตอนที่6: Sign-Off: B: การตรวจสอบความซ้ำซ้อน"
    // },
    // {
    //     link: "https://www.youtube.com/embed/IBA_l4oaWhc",
    //     downloadLink: "/twp/v2/files/ตอนที่10_Sign-Off_C_การตรวจสอบความพร้อม.mp4",
    //     fileName: "ตอนที่7: Sign-Off: C: การตรวจสอบความพร้อม",
    //     name: "ตอนที่7: Sign-Off: C: การตรวจสอบความพร้อม"
    // },
    // {
    //     link: "https://www.youtube.com/embed/3e7Qy-Ow1bs",
    //     downloadLink: "/twp/v2/files/ตอนที่11_Sign-Off_D_การเลือกแผนงานนำส่ง_Sign-Off.mp4",
    //     fileName: "ตอนที่8: Sign-Off: D: การเลือกแผนงานนำส่ง Sign-Off",
    //     name: "ตอนที่8: Sign-Off: D: การเลือกแผนงานนำส่ง Sign-Off"
    // },
    // {
    //     link: "https://www.youtube.com/embed/hukBr-cGFWE",
    //     downloadLink: "/twp/v2/files/ตอนที่12_Sign-Off_E_การเซ็นอนุมัติ_Sign-Off.mp4",
    //     fileName: "ตอนที่9: Sign-Off: E: การเซ็นอนุมัติ Sign-Off",
    //     name: "ตอนที่9: Sign-Off: E: การเซ็นอนุมัติ Sign-Off"
    // },
]

// const onClickLinkDownload = (link, name) => {
//     if (!link) {
//         Swal.fire({
//             title: "เกิดข้อผิดพลาด",
//             text: "ไม่พบไฟล์",
//             icon: "error"
//         });
//         return;
//     }
//     let a = document.createElement('a');
//     a.setAttribute('href', link);
//     a.setAttribute('download', name);
//     a.setAttribute('target', '_blank');
//     a.click();
//     console.log(a);
//     a.remove();
// }

const LoginModal = ({ show, setShow }) => {
    const [step, setStep] = useState(0);
    const [link, setLink] = useState(null);
    const onClickLink = (data) => {
        if (data.linkNewTab) {
            window.open(data.linkNewTab, '_blank');
        } else {
            setStep(1);
            setLink(data);
        }
    }
    const onClickBack = () => {
        setStep(0);
        setLink(null);
    }
    return (
        <Dialog
            onClose={setShow}
            TransitionProps={{
                onExited: onClickBack
            }}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle disableTypography className="d-flex align-items-center">
                <Videocam className="mr-05" />
                <Typography variant="h4">วิดีโอสอนการใช้งาน TWP</Typography>
                <IconButton className="ml-auto p-02" onClick={setShow}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grow
                    in={show && step === 0}
                    timeout={400}
                    style={{ display: step !== 0 ? "none" : "block" }}
                >
                    <Box padding={3} paddingTop={0}>
                        {
                            listFile.map((obj, index) => (
                                <React.Fragment key={index}>
                                    <Box
                                        marginTop={4}
                                        marginBottom={4}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {
                                            obj.downloadLink ?
                                                <a href={obj.downloadLink} download={obj.fileName} target="_blank" rel="noreferrer">
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        // onClick={() => onClickLinkDownload(obj.downloadLink, obj.fileName)}
                                                        className="mr-05 icon-button-contained"
                                                    >
                                                        <GetApp style={{ fontSize: "0.8rem", fill: "white" }} />
                                                    </Button>
                                                </a>
                                                :
                                                null
                                        }
                                        <Box
                                            className="cursor-pointer"
                                            display="flex"
                                            alignItems="center"
                                            flex="1 1"
                                            onClick={() => onClickLink(obj)}
                                        >
                                            <Typography variant="h5" color="primary">{obj.name}</Typography>
                                            <KeyboardArrowRight className="ml-auto" style={{ fill: "#00000092" }} />
                                        </Box>
                                    </Box>
                                    {
                                        index === listFile.length - 1 ?
                                            null : <hr className="hr-light" />
                                    }
                                </React.Fragment>
                            ))
                        }
                    </Box>
                </Grow>
                <Grow
                    in={show && step === 1}
                    style={{ display: step !== 1 ? "none" : "" }}
                >
                    <Box>
                        <Box>
                            <Button color="default" className="btn-danger" onClick={onClickBack}>
                                <KeyboardArrowLeft />
                                กลับ
                            </Button>
                            <Box display="flex" justifyContent="center" flexWrap="wrap" marginTop={2}>
                                <Typography variant="h4">{link?.name ?? ""}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" marginTop={2}>
                                {
                                    (step === 1 && link?.link) ?
                                        <iframe
                                            title={link.name}
                                            type="text/html"
                                            src={link.link}
                                            frameBorder="0"
                                            allowFullScreen
                                            style={{
                                                width: "80%",
                                                maxWidth: "100%",
                                                minWidth: "50%",
                                                maxHeight: "500px",
                                                minHeight: "500px"
                                            }}
                                        />
                                        :
                                        <Box height={200} width="100%" />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grow>
            </DialogContent>
        </Dialog>
    )
}

export default LoginModal;