import CoreService from "../Service/CoreService";
import {
    Assignment,
    BorderColor,
    Settings,
    Computer
} from "@material-ui/icons"
const months = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
const days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];

const navigationMenus = [
    {
        name: 'สร้าง/จัดการ/สรุปรายงาน แผนปฏิบัติการด้านทรัพยากรน้ำ',
        subText: "",
        groupHeader: true,
        icon: <Assignment fontSize="large" />,
        highlight: true,
        subMenu: [],
        permission: ["ProjectAdd"]
    },
    // {
    //     name: 'จัดการโครงการ',
    //     menuName: "ProjectAdd",
    //     subText: "จัดการข้อมูลโครงการ ตรวจสอบแผนงานและ Sign-off",
    //     imgSrc: "/images/portal/project-icon.png",
    //     permission: ["Project"],
    //     subMenu: [],
    // },
    {
        name: 'ฟังก์ชันใช้งานในระบบ',
        subText: "",
        groupHeader: true,
        icon: <BorderColor fontSize="medium" />,
        subMenu: [],
        permission: [
            "ProjectData", "Map", "WaterResource",
            "ProjectImportant", "ProjectRequest",
            "Dashboard", "Export", "ReportMinistry"
        ]
    },
    {
        name: 'สรุปแผนปฏิบัติการกนช.เห็นชอบ',
        subText: "ผ่านการเห็นชอบกนช.สำหรับสำนักงบประมาณและกระทรวง",
        imgSrc: "/images/portal/report-ministry-main-icon.png",
        highlight: true,
        highlightClass: " menu-highlight-blue",
        imgClass: "menu-image-wiggle",
        subMenu: [
            {
                name: 'แผนปฏิบัติการประจำปี',
                imgSrc: "/images/portal/report-ministry-1-icon.png",
                subMenu: [
                    {
                        name: 'แผนปฏิบัติการประจำปีงบประมาณ พ.ศ.2567',
                        subText: "",
                        imgSrc: "/images/portal/report-ministry-1-icon.png",
                        subMenu: [
                            {
                                name: 'แผนปฏิบัติการประจำปีงบประมาณ พ.ศ.2567 (ในกรอบ)',
                                // subText: "ผ่านความเห็นชอบจาก กนช.ในการประชุม กนช. ครั้งที่ 1/65 วันที่ 14 มีนาคม 2565",
                                imgSrc: "/images/portal/report-ministry-1-icon.png",
                                subMenu: [],
                                link: "/reportministry/5",
                                modalTitle: "การคัดกรองข้อมูลรายงาน",
                                permission: ["ReportMinistry"]
                            },
                            {
                                name: 'แผนปฏิบัติการประจำปีงบประมาณ พ.ศ.2567 (เกินกรอบ)',
                                // subText: "ผ่านความเห็นชอบจาก กนช.ในการประชุม กนช. ครั้งที่ 1/65 วันที่ 14 มีนาคม 2565",
                                imgSrc: "/images/portal/report-ministry-1-icon.png",
                                subMenu: [],
                                link: "/reportministry/6",
                                modalTitle: "การคัดกรองข้อมูลรายงาน",
                                permission: ["ReportMinistry"]
                            },
                        ],
                        modalTitle: "การคัดกรองข้อมูลรายงาน",
                        permission: ["ReportMinistry"]
                    },
                    {
                        name: 'แผนปฏิบัติการประจำปีงบประมาณ พ.ศ.2566',
                        subText: "ผ่านความเห็นชอบจาก กนช.ในการประชุม กนช. ครั้งที่ 1/65 วันที่ 14 มีนาคม 2565",
                        imgSrc: "/images/portal/report-ministry-1-icon.png",
                        subMenu: [],
                        link: "/reportministry/1",
                        modalTitle: "การคัดกรองข้อมูลรายงาน",
                        permission: ["ReportMinistry"]
                    },
                ],
                modalTitle: "การคัดกรองข้อมูลรายงาน",
                permission: ["ReportMinistry"]
            },
            {
                name: 'แผนปฏิบัติการเร่งด่วน',
                imgSrc: "/images/portal/report-ministry-2-icon.png",
                subMenu: [
                    {
                        name: 'โครงการเพิ่มประสิทธิภาพ การบริหารจัดการทรัพยากรน้ําเพื่อรองรับ สถานการณ์ภัยแล้งและฝนทิ้งช่วง ปี 2566',
                        subText: "ผ่านการตรวจสอบและกนช.เห็นชอบ (Sign-Off 3)",
                        imgSrc: "/images/portal/report-ministry-2-icon.png",
                        subMenu: [],
                        link: "/reportministry/4",
                        modalTitle: "การคัดกรองข้อมูลรายงาน",
                        permission: ["ReportMinistry"]
                    },
                    {
                        name: 'แผนงานโครงการเพิ่มประสิทธิภาพการบริหารจัดการทรัพยากรน้ำในช่วงฤดูฝน ปี 2565 และการกักเก็บน้ำเพื่อฤดูแล้ง ปี 2565/2566',
                        subText: "ผ่านการตรวจสอบและกนช.เห็นชอบ (Sign-Off 3)",
                        imgSrc: "/images/portal/report-ministry-2-icon.png",
                        subMenu: [],
                        link: "/reportministry/3",
                        modalTitle: "การคัดกรองข้อมูลรายงาน",
                        permission: ["ReportMinistry"]
                    },
                    {
                        name: 'แผนปฏิบัติการด้านน้ำเพื่อรองรับภัยแล้งและฝนทิ้งช่วง ปี 2565',
                        subText: "ผ่านการตรวจสอบและกนช.เห็นชอบ (Sign-Off 3)",
                        imgSrc: "/images/portal/report-ministry-2-icon.png",
                        subMenu: [],
                        link: "/reportministry/2",
                        modalTitle: "การคัดกรองข้อมูลรายงาน",
                        permission: ["ReportMinistry"]
                    },
                ],
                modalTitle: "การคัดกรองข้อมูลรายงาน",
                permission: ["ReportMinistry"]
            },
        ],
        permission: ["ReportMinistry"]
    },
    {
        name: 'ค้นหาโครงการ',
        subText: "ค้นหาแผนงาน และโครงการในฐานข้อมูล",
        imgSrc: "/images/portal/project-search-icon.png",
        selectOption: true,
        options: ["BudgetYear", "BudgetSource", "Organization", "Province", "Basin"],
        subMenu: [],
        link: "project/projectlist",
        modalTitle: "การคัดกรองข้อมูลโครงการ",
        permission: ["ProjectData"],
        menuName: "ProjectData",
    },
    {
        name: 'ระบบแผนที่',
        subText: "ตรวจสอบแผนงาน และข้อมูลเชิงภูมิศาสตร์",
        imgSrc: "/images/portal/map-icon.png",
        subMenu: [],
        selectOption: true,
        options: ["BudgetYear", "Organization", "Province", "Basin"],
        link: "/map",
        modalTitle: "การคัดกรองข้อมูลแผนที่",
        permission: ["Map"]
    },
    {
        //link: "/projectrequest/projectlist",
        name: 'นำเข้าฐานข้อมูลแหล่งน้ำ',
        subText: "จัดการข้อมูลแหล่งน้ำ และตรวจสอบ",
        imgSrc: "/images/portal/twrs/twrs-icon.png",
        subMenu: [
            {
                name: 'ฐานข้อมูลแหล่งน้ำที่ได้รับการอนุมัติ',
                subText: "ฐานข้อมูลแหล่งน้ำที่ได้รับการอนุมัติ",
                imgSrc: "/images/portal/twrs/waterpass-icon.png",
                subMenu: [],
                link: "/wresource/wresourcelist",
                permission: ['WaterResource']
            },
            {
                name: 'นำเข้าฐานข้อมูลแหล่งน้ำและตรวจสอบแหล่งน้ำ',
                subText: "นำเข้าฐานข้อมูลแหล่งน้ำและตรวจสอบฐานข้อมูลแหล่งน้ำ",
                imgSrc: "/images/portal/verified-icon.png",
                subMenu: [],
                link: "/wresource/wresourceverify",
                permission: ['WaterResourceImport']
            },
        ],
        permission: ['WaterResource']
    },
    {
        name: 'โครงการสำคัญ',
        subText: "ตรวจสอบแผนงาน และโครงการสำคัญ",
        imgSrc: "/images/portal/analytics.png",
        subMenu: [
            {
                name: 'ข้อมูลโครงการสำคัญ',
                subText: "ตรวจสอบแผนงาน และโครงการสำคัญ",
                imgSrc: "/images/portal/project-important-list.png",
                subMenu: [],
                link: "/projectimportant/projectlist",
                permission: ["ProjectImportant"]
            },
            {
                name: 'Masterplan',
                subText: "ระบบบริหารจัดการ Masterplan",
                imgSrc: "/images/portal/masterplan.png",
                subMenu: [],
                link: "/masterplan/masterplanlist",
                permission: ["MasterplanProjectImportant"]
            },
            {
                name: 'Roadmap โครงการสำคัญ',
                subText: "วิเคราะห์ประมวลผลในภาพรวมแบบสรุปโครงการสำคัญ",
                imgSrc: "/images/portal/importantreport.png",
                subMenu: [],
                link: "/projectimportant/roadmap",
                permission: ["ProjectImportant"]
            },
            {
                name: 'Sign-Off โครงการสำคัญ',
                subText: "ระบบ Sign-Off โครงการสำคัญ",
                imgSrc: "/images/portal/signoff-icon.png",
                subMenu: [],
                link: "/projectimportant/signoff",
                permission: ["ProjectImportantSignoff"]
            },
            {
                name: 'รายงานสรุปแผนงานโครงการโครงการสำคัญ',
                subText: "สรุปข้อมูลโครงการสำคัญ และรายงานในมิติต่างๆ",
                imgSrc: "/images/portal/report-icon.png",
                subMenu: [],
                link: "/reportprojectimportant",
                permission: ["ProjectImportant"]
            },
            {
                name: 'ระบบแผนที่ โครงการสำคัญ',
                subText: "ตรวจสอบโครงการสำคัญ และข้อมูลเชิงภูมิศาสตร์",
                imgSrc: "/images/portal/map-icon.png",
                subMenu: [],
                link: "/projectimportant/map",
                permission: ["ProjectImportant"]
            },
        ],
        permission: ["ProjectImportant"]
    },
    {
        name: 'ระบบการขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.',
        subText: "ตรวจสอบแผนงาน และการขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.",
        imgSrc: "/images/portal/projectrequest.png",
        subMenu: [
            {
                name: 'ข้อมูลการขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.',
                subText: "ตรวจสอบแผนงาน และการขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.",
                imgSrc: "/images/portal/projectrequest.png",
                subMenu: [],
                link: "/projectrequest/projectlist",
                permission: ["ProjectRequest"]
            },
            {
                name: 'Masterplan',
                subText: "ระบบบริหารจัดการ Masterplan",
                imgSrc: "/images/portal/masterplan.png",
                subMenu: [],
                link: "/masterplanb/masterplanblist",
                permission: ["MasterplanProjectRequest"]
            },
            {
                name: 'Sign-off การขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.',
                subText: "ระบบบริหารจัดการ Sign-off การขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.",
                imgSrc: "/images/portal/signoff-icon.png",
                subMenu: [],
                link: "/projectrequest/signoff",
                permission: ["ProjectRequestSignoff", "ProjectRequestSignoff1", "ProjectRequestSignoff-1", "ProjectRequestSignoff-2", "ProjectRequestSignoff-3", "ProjectRequestSignoff7"]
            },
            {
                name: 'รายงานสรุปการขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.',
                subText: "สรุปข้อมูลคำขอตั้งงบประมาณภายใน และรายงานในมิติต่างๆ",
                imgSrc: "/images/portal/report-icon.png",
                subMenu: [],
                link: "/reportprojectrequest",
                permission: ["ProjectRequest"]
            },
            {
                name: 'ระบบแผนที่ การขอตั้งงบประมาณรายจ่ายประจำปีของ สทนช.',
                subText: "ตรวจสอบคำขอตั้งงบประมาณภายใน และข้อมูลเชิงภูมิศาสตร์",
                imgSrc: "/images/portal/map-icon.png",
                subMenu: [],
                link: "/projectrequest/map",
                permission: ["ProjectRequest"]
            },
        ],
        permission: ["ProjectRequest"]
    },
    {
        name: 'TWP Dashboard',
        subText: "วิเคราะห์ประมวลผลในภาพรวมแบบสรุป",
        imgSrc: "/images/portal/dashboard-icon.png",
        // selectOption: true,
        // options: ["report", "Organization", "Province", "Basin"],
        subMenu: [],
        externalLink: true,
        link: "https://twp.onwr.go.th/public_dashboard.php",
        permission: ["Dashboard"]
    },
    {
        name: 'สรุปรายงาน',
        subText: "สรุปข้อมูลงบประมาณ และรายงานในมิติต่างๆ",
        imgSrc: "/images/portal/report-icon.png",
        subMenu: [],
        selectOption: true,
        options: ["BudgetYear", "BudgetSource", "Organization", "Province", "Basin"],
        link: "/report",
        modalTitle: "การคัดกรองข้อมูลรายงาน",
        permission: ["MasterData"]
    },
    // {
    //     name: 'รายงานความคืบหน้า',
    //     subText: "รายงานผลความคืบหน้าการดำเนินงาน",
    //     imgSrc: "/images/portal/progress-icon.png",
    //     selectOption: true,
    //     options: ["BudgetYear", "BudgetStatus", "BudgetSource", "Organization", "Province", "Basin"],
    //     subMenu: [],
    //     method: "report",
    //     link: "/projectlist"
    // },
    {
        name: 'การจัดการระบบหลัก',
        subText: "",
        groupHeader: true,
        icon: <Settings fontSize="medium" />,
        subMenu: [],
        permission: [
            "AccountLog", "Account", "Organization",
            "MasterData", "FileRecovery"
        ]
    },
    {
        name: 'ประวัติการเปลี่ยนแปลงผู้ใช้งาน',
        subText: "",
        imgSrc: "/images/portal/user/user-management-log.png",
        link: "/settings/accountlog",
        permission: ["AccountLog"]
    },
    {
        name: 'จัดการผู้ใช้งาน',
        subText: "",
        imgSrc: "/images/portal/user/user-management-icon3.png",
        link: "/settings/user",
        permission: ["Account"]
    },
    {
        name: 'หน่วยงาน',
        subText: "",
        imgSrc: "/images/portal/masterdata/organization-icon.png",
        link: "/settings/organization",
        permission: ["Organization"]
    },
    {
        name: 'จัดการ Sign-off',
        subText: "",
        imgSrc: "/images/portal/sign-off.png",
        link: "/signoffmanage",
        permission: ["MasterData"]
    },
    {
        name: 'Tag',
        subText: "",
        imgSrc: "/images/portal/masterdata/category-icon.png",
        link: "/settings/tag",
        permission: ["MasterData"]
    },
    {
        name: 'การเปิด/ปิดระบบ',
        subText: "",
        imgSrc: "/images/portal/masterdata/permission-date.png",
        link: "/settings/permissiondate",
        permission: ["MasterData"]
    },
    {
        name: 'ฐานข้อมูล',
        subText: "จัดการโครงสร้างข้อมูลพิ้นฐาน และข้อมูลต่างๆที่เกี่ยวข้อง",
        imgSrc: "/images/portal/database-icon2.png",
        subMenu: [
            {
                name: 'แผนแม่บทฯน้ำ 20 ปี',
                subText: "",
                imgSrc: "/images/portal/masterdata/strategy-icon.png",
                link: "/settings/strategy",
                permission: ["MasterData"]
            },
            {
                name: 'ตัวชี้วัด',
                subText: "",
                imgSrc: "/images/portal/masterdata/kpi.png",
                link: "/settings/kpi",
                permission: ["MasterData"]
            },
            {
                name: 'กิจกรรม',
                subText: "",
                imgSrc: "/images/portal/masterdata/incident.png",
                link: "/settings/activity",
                permission: ["MasterData"]
            },
            {
                name: 'ลักษณะงาน',
                subText: "",
                imgSrc: "/images/portal/masterdata/projecttype.png",
                link: "/settings/projecttype",
                permission: ["MasterData"]
            },
            {
                name: 'กลุ่มจังหวัด',
                subText: "",
                imgSrc: "/images/portal/masterdata/province-icon.png",
                link: "/settings/provincegroup",
                permission: ["MasterData"]
            },
            {
                name: 'หมวดหมู่เหตุการณ์',
                subText: "",
                imgSrc: "/images/portal/masterdata/situation-icon.png",
                link: "/settings/incidentcategory",
                permission: ["MasterData"]
            },
            {
                name: 'ข้อมูลเหตุการณ์',
                subText: "",
                imgSrc: "/images/portal/masterdata/event-icon.png",
                link: "/incident",
                permission: ["MasterData"]
            },
        ],
        permission: ["MasterData"]
    },
    {
        name: 'เกณฑ์การจัดลำดับความสำคัญ',
        subText: "",
        imgSrc: "/images/portal/masterdata/order-icon.png",
        link: "/settings/orderprofile/list",
        permission: ["Signoff"]
    },
    {
        name: 'เครื่องมือกู้ไฟล์',
        subText: "",
        imgSrc: "/images/portal/masterdata/file-recovery.png",
        link: "/settings/filerecovery",
        permission: ["FileRecovery"]
    },
    {
        name: 'ข้อมูลผู้ลงทะเบียนสมัคร Training',
        subText: "",
        imgSrc: "/images/portal/masterdata/feedback.png",
        link: "/settings/TrainingRequest",
        permission: ["TrainingRequest"]
    },
    {
        name: 'การจัดการระบบย่อย',
        subText: "",
        groupHeader: true,
        icon: <Settings fontSize="medium" />,
        subMenu: [],
        permission: ["MasterData", "MasterDataPerson"]
    },
    {
        name: 'กลุ่มหลัก / กลุ่มย่อย โครงการสำคัญ',
        subText: "",
        imgSrc: "/images/portal/masterdata/importantgroup.png",
        link: "/settings/importantgroup",
        permission: ["MasterData"]
    },
    {
        name: 'สำนัก/กองภายในสทนช.',
        subText: "",
        imgSrc: "/images/portal/masterdata/organizationinternal.png",
        link: "/settings/organizationinternal",
        permission: ["MasterData"]
    },
    {
        name: 'ผู้รับผิดชอบ กผง.001',
        subText: "",
        imgSrc: "/images/portal/user/user-management-icon2.png",
        link: "/settings/projectrequestperson",
        permission: ["MasterDataPerson"]
    },
    {
        name: 'เกี่ยวกับการใช้งาน',
        subText: "",
        groupHeader: true,
        icon: <Computer fontSize="medium" />,
        subMenu: [],
        permission: []
    },
    {
        name: 'บันทึกการใช้งาน',
        subText: "",
        imgSrc: "/images/portal/user/user-transaction-icon.png",
        link: "/settings/history",
        permission: ["Core"]
    },
    {
        name: 'เกี่ยวกับระบบ',
        subText: "ระบบบริหารจัดการแผนงานโครงการและฐานข้อมูล",
        imgSrc: "/images/portal/about-icon.png",
        subMenu: [],
        externalLink: true,
        link: "/about/"
    },
    {
        name: 'Release version',
        subText: "",
        imgSrc: "/images/portal/release-icon.png",
        subMenu: [],
        link: "/releasedversions"
    }
];

const navigationTrainingMenus = [
    {
        name: 'สร้าง/จัดการ/สรุปรายงาน แผนปฏิบัติการด้านทรัพยากรน้ำ',
        subText: "",
        groupHeader: true,
        icon: <Assignment fontSize="large" />,
        highlight: true,
        subMenu: [],
        permission: ["ProjectAdd"]
    },
    {
        name: 'ฟังก์ชันใช้งานในระบบ',
        subText: "",
        groupHeader: true,
        icon: <BorderColor fontSize="medium" />,
        subMenu: [],
        permission: [
            "ProjectData", "Map", "WaterResource",
            "ProjectImportant", "ProjectRequest",
            "Dashboard", "Export", "ReportMinistry"
        ]
    },
    {
        name: 'เกณฑ์การจัดลำดับความสำคัญ',
        subText: "",
        imgSrc: "/images/portal/masterdata/order-icon.png",
        link: "/settings/orderprofile/list",
        permission: []
    },
    {
        name: 'เกี่ยวกับการใช้งาน',
        subText: "",
        groupHeader: true,
        icon: <Computer fontSize="medium" />,
        subMenu: [],
        permission: []
    },
    {
        name: 'บันทึกการใช้งาน',
        subText: "",
        imgSrc: "/images/portal/user/user-transaction-icon.png",
        link: "/settings/history",
        permission: ["Core"]
    },
    {
        name: 'เกี่ยวกับระบบ',
        subText: "ระบบบริหารจัดการแผนงานโครงการและฐานข้อมูล",
        imgSrc: "/images/portal/about-icon.png",
        subMenu: [],
        externalLink: true,
        link: "/about/"
    },
    {
        name: 'Release version',
        subText: "",
        imgSrc: "/images/portal/release-icon.png",
        subMenu: [],
        link: "/releasedversions"
    }
];

const utilities = {
    toCommas: (value, numdigit) => {
        if (!value && typeof value !== 'number') return value;
        return Number(value).toLocaleString('en', {
            maximumFractionDigits: 6,
            minimumFractionDigits: numdigit
        });
        // return Number(value).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        // let num = Number(value);
        // if (!num || Number.isNaN(num)) return value;
        // return String(num).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    isNumber: (value) => {
        return typeof value === 'number' && isFinite(value);
    },
    numberWithCommas: (x) => {
        if (!x) return "";

        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    checkEmail: (text) => {
        const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return reg.test(text);
    },
    toPrecision: (num, decimal = 2, getString = false, getComma = false) => {
        if (typeof num !== 'number') return null;
        var numDecimal = Math.pow(10, decimal);
        let result = Math.round(num * numDecimal) / numDecimal
        return getString ? (getComma ? utilities.numberWithCommas(result.toFixed(decimal)) : result.toFixed(decimal)) : result;
    },
    toDateTimeThai: (dateTime) => {
        if (!dateTime) return "";
        let data = dateTime.split(" ");
        let date = data[0].split("-");
        return date[0] + " " + months[parseInt(date[1])] + " " + date[2] + " " + data[1];
    },
    getDefaultBudgetYear: () => {
        return 2566;
        // let date = new Date();
        // if (date.getMonth() < 10) return date.getFullYear() + 543;
        // else return date.getFullYear() + 544;
    },
    genBudgetYearOptions: (Year) => {
        let year = Year ? Year : new Date().getFullYear() - 8 + 543;
        year = 2560;
        let arr = new Array(21);
        for (let i = 0; i < 21; ++i) arr[i] = year + i;
        return arr;
    },
    onImageError: (e) => {
        if (!e?.target) return;

        e.target.onerror = null;
        e.target.src = "/images/user_icon.png";
    },
    genMonthOptions: () => {
        return [
            { MonthID: 1, MonthName: months[1] },
            { MonthID: 2, MonthName: months[2] },
            { MonthID: 3, MonthName: months[3] },
            { MonthID: 4, MonthName: months[4] },
            { MonthID: 5, MonthName: months[5] },
            { MonthID: 6, MonthName: months[6] },
            { MonthID: 7, MonthName: months[7] },
            { MonthID: 8, MonthName: months[8] },
            { MonthID: 9, MonthName: months[9] },
            { MonthID: 10, MonthName: months[10] },
            { MonthID: 11, MonthName: months[11] },
            { MonthID: 12, MonthName: months[12] },
        ]
        // return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    getMonthThai: (month) => {
        return months[month];
    },
    getDayThai: (day) => {
        return days[day];
    },
    initProjectNavigationMenu: async (account, isTraining) => {
        return CoreService.getProjectMenu()
            .then(res => {
                let data = res.data.GetProjectMenu ?? [];
                // let newData = [];
                let signoffTypeObj = [];
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    const PermissionAdd = account.Permissions?.["ProjectAdd" + element.SignoffID] === false;
                    const PermissionEdit = (
                        account.Permissions?.["ProjectEdit" + element.SignoffID] === false &&
                        account.Permissions?.["ProjectFile" + element.SignoffID] === false &&
                        account.Permissions?.["ProjectReadiness" + element.SignoffID] === false &&
                        account.Permissions?.["ProjectAmount" + element.SignoffID] === false
                    );
                    const dataObj = {
                        name: element.SignoffName,
                        menuName: "ProjectAdd",
                        subText: "",
                        imgSrc: "/images/portal/project/budget-source-all.png",
                        selectOption: 0,
                        permission: ["ProjectAdd"],
                        permissionSignoff: PermissionAdd,
                        highlight: element.Highlight,
                        subMenu: [
                            {
                                name: 'เพิ่มโครงการ',
                                subText: "",
                                imgSrc: "/images/portal/project/project-add-icon.png",
                                method: "add",
                                subMethod: "projectgui",
                                subMenu: PermissionAdd ? null : [
                                    {
                                        name: 'ทีละรายการ',
                                        menuName: "ProjectAdd",
                                        subText: "",
                                        imgSrc: "/images/portal/project/project-add-gui-icon.png",
                                        method: "add",
                                        subMethod: "projectgui",
                                        selectOption: true,
                                        options: ["BudgetYear", "Form", "BudgetType", "BudgetDimension"],
                                        required001: [0, 1, 3],
                                        required004: [0, 1],
                                        requiredData001: ["BudgetYear", "FormID", "BudgetDimensionID"],
                                        requiredData004: ["BudgetYear", "FormID"],
                                        componentData: {
                                            BudgetTypeID: 1,
                                            TagID: element.TagID,
                                            SignoffID: element.SignoffID,
                                            StartYear: element.StartYear ? parseInt(element.StartYear) : undefined,
                                            EndYear: element.EndYear ? parseInt(element.EndYear) : undefined,
                                        },
                                        getOptions: {
                                            BudgetSource: function (BudgetSource) {
                                                if (this.BudgetSourceOptions) return this.BudgetSourceOptions;
                                                if (!BudgetSource) return [];
                                                let data = BudgetSource.filter(x => element.BudgetSourceID?.includes?.(x.BudgetSourceID) ?? true);
                                                this.BudgetSourceOptions = data;
                                                return data;
                                            }
                                        },
                                        getRequired: function (filter) {
                                            if (filter?.FormID === "001") return this.required001;
                                            return this.required004;
                                        },
                                        getRequiredData: function (filter) {
                                            if (filter?.FormID === "001") return this.requiredData001;
                                            return this.requiredData004;
                                        },
                                        onChangeValue: function (component, prop) {
                                            return new Promise((resolve) => {
                                                if (prop === "FormID") {
                                                    component.setState({
                                                        filter: {
                                                            ...component.state.filter,
                                                            // BudgetSourceID: (component.state.filter.FormID === "001") ? 1 : null,
                                                            BudgetTypeID: (component.state.filter.FormID === "001") ?
                                                                this.componentData.BudgetTypeID : null,
                                                            BudgetDimensionID: (component.state.filter.FormID === "001") ?
                                                                this.componentData.BudgetDimensionID : null,
                                                            TagID: this.componentData.TagID,
                                                            SignoffID: this.componentData.SignoffID,
                                                        }
                                                    }, resolve);
                                                }
                                                else
                                                    resolve();
                                            });
                                        },
                                        subMenu: [],
                                        link: "project/projectgui",
                                        altLink: "project/projectlist",
                                        modalTitle: "เพิ่มโครงการทีละรายการ",
                                        permission: ["ProjectAdd"],
                                        onClick: function (component, data, dataName) {
                                            if (data && dataName) {
                                                component.setState({
                                                    [dataName]: {
                                                        ...data,
                                                        BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                        TagID: this.componentData.TagID,
                                                        SignoffID: this.componentData.SignoffID,
                                                    }
                                                });
                                                return;
                                            }
                                            component.setState({
                                                BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                TagID: this.componentData.TagID,
                                                SignoffID: this.componentData.SignoffID,
                                            });
                                        },
                                        OnExit: (component) => {
                                            component.setState({
                                                BudgetYear: null,
                                                TagID: null,
                                                SignoffID: null,
                                                BudgetTypeID: null,
                                                BudgetDimensionID: null,
                                            });
                                        }
                                    },
                                    {
                                        name: 'หลายรายการ',
                                        menuName: "ProjectAdd",
                                        subText: "",
                                        imgSrc: "/images/portal/project/project-add-spreadsheet-icon.png",
                                        method: "add",
                                        subMethod: "spreadsheet",
                                        selectOption: true,
                                        // options: ["BudgetYear", "Form", "BudgetType"],
                                        options: ["Form", "BudgetYear", "BudgetDimension", "ProjectStartYear", "ProjectEndYear"],
                                        required001Cashflow: [0, 2, 3, 4],
                                        required001: [0, 1, 2],
                                        required004: [0, 1],
                                        requiredData001Cashflow: ["FormID", "BudgetDimensionID", "ProjectStartYear", "ProjectEndYear"],
                                        requiredData001: ["FormID", "BudgetYear", "BudgetDimensionID"],
                                        requiredData004: ["FormID", "BudgetYear"],
                                        componentData: {
                                            // BudgetTypeID: 1,
                                            TagID: element.TagID,
                                            SignoffID: element.SignoffID,
                                            StartYear: element.StartYear ? parseInt(element.StartYear) : undefined,
                                            EndYear: element.EndYear ? parseInt(element.EndYear) : undefined,
                                            ProjectStartYear: new Date().getFullYear() + 543,
                                            ProjectEndYear: new Date().getFullYear() + 543
                                        },
                                        getOptions: {
                                            BudgetSource: function (BudgetSource) {
                                                if (this.BudgetSourceOptions) return this.BudgetSourceOptions;
                                                if (!BudgetSource) return [];
                                                let data = BudgetSource.filter(x => element.BudgetSourceID?.includes?.(x.BudgetSourceID) ?? true);
                                                this.BudgetSourceOptions = data;
                                                return data;
                                            }
                                        },
                                        getRequired: function (filter) {
                                            if (filter?.FormID === "001") return this.required001;
                                            else if (filter?.FormID === "001cashflow") return this.required001Cashflow;
                                            return this.required004;
                                        },
                                        getRequiredData: function (filter) {
                                            if (filter?.FormID === "001") return this.requiredData001;
                                            else if (filter?.FormID === "001cashflow") return this.requiredData001Cashflow;
                                            return this.requiredData004;
                                        },
                                        onChangeValue: function (component, prop) {
                                            return new Promise((resolve) => {
                                                if (prop === "FormID") {
                                                    component.setState({
                                                        filter: {
                                                            ...component.state.filter,
                                                            // BudgetSourceID: (component.state.filter.FormID === "001") ? 1 : null,
                                                            BudgetTypeID: (component.state.filter.FormID === "001") ?
                                                                this.componentData.BudgetTypeID : null,
                                                            TagID: this.componentData.TagID,
                                                            SignoffID: this.componentData.SignoffID,
                                                            BudgetDimensionID: (component.state.filter.FormID === "001") ?
                                                                this.componentData.BudgetDimensionID : null,
                                                        }
                                                    }, resolve);
                                                }
                                                else
                                                    resolve();
                                            });
                                        },
                                        subMenu: [],
                                        link: "project/spreadsheet",
                                        altLink: "project/projectlist",
                                        modalTitle: "เพิ่ม/นำเข้าโครงการหลายรายการ",
                                        permission: ["ProjectAdd"],
                                        onClick: function (component, data, dataName) {
                                            if (data && dataName) {
                                                component.setState({
                                                    [dataName]: {
                                                        ...data,
                                                        FormID: "001",
                                                        BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                        TagID: this.componentData.TagID,
                                                        SignoffID: this.componentData.SignoffID,
                                                        ProjectStartYear: this.componentData.ProjectStartYear,
                                                        ProjectEndYear: this.componentData.ProjectEndYear,
                                                    }
                                                });
                                                return;
                                            }
                                            component.setState({
                                                filter: {
                                                    FormID: "001",
                                                    BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                    TagID: this.componentData.TagID,
                                                    SignoffID: this.componentData.SignoffID,
                                                    ProjectStartYear: this.componentData.ProjectStartYear,
                                                    ProjectEndYear: this.componentData.ProjectEndYear,
                                                }
                                            });
                                        },
                                        OnExit: function (component) {
                                            component.setState({
                                                filter: {
                                                    BudgetYear: null,
                                                    TagID: null,
                                                    SignoffID: null,
                                                    BudgetDimensionID: null,
                                                    ProjectStartYear: null,
                                                    ProjectEndYear: null,
                                                }
                                            });
                                        }
                                    }
                                ],
                                link: "project/projectgui",
                                altLink: "project/projectlist",
                                modalTitle: "เพิ่มโครงการทีละรายการ",
                                permission: PermissionAdd ? ["ProjectAdd" + element.SignoffID] : ["ProjectAdd"],
                            },
                            {
                                name: PermissionEdit ?
                                    'ค้นหาโครงการ' :
                                    'ค้นหา / แก้ไขโครงการ',
                                menuName: "ProjectEdit",
                                subText: "",
                                imgSrc: "/images/portal/project/project-edit-icon.png",
                                method: "edit",
                                selectOption: true,
                                options: ["BudgetYear", "BudgetSource", "Organization", "Province", "Basin"],
                                subMenu: [],
                                link: "project/projectlist",
                                modalTitle: "การคัดกรองข้อมูลโครงการ",
                                permission: PermissionEdit ?
                                    ["Project"] :
                                    ["ProjectEdit", "ProjectReadiness", "ProjectFile", "ProjectAmount"],
                                componentData: {
                                    // BudgetTypeID: 1,
                                    TagID: element.TagID,
                                    SignoffID: element.SignoffID,
                                    StartYear: element.StartYear ? parseInt(element.StartYear) : undefined,
                                    EndYear: element.EndYear ? parseInt(element.EndYear) : undefined,
                                },
                                getOptions: {
                                    BudgetSource: function (BudgetSource) {
                                        if (this.BudgetSourceOptions) return this.BudgetSourceOptions;
                                        if (!BudgetSource) return [];
                                        let data = BudgetSource.filter(x => element.BudgetSourceID?.includes?.(x.BudgetSourceID) ?? true);
                                        this.BudgetSourceOptions = data;
                                        return data;
                                    }
                                },
                                onClick: function (component, data, dataName) {
                                    if (data && dataName) {
                                        component.setState({
                                            [dataName]: {
                                                ...data,
                                                BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                TagID: this.componentData.TagID,
                                                SignoffID: this.componentData.SignoffID,
                                            }
                                        });
                                        return;
                                    }
                                    component.setState({
                                        filter: {
                                            BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                            TagID: this.componentData.TagID,
                                            SignoffID: this.componentData.SignoffID,
                                        }
                                    });
                                },
                                OnExit: function (component) {
                                    component.setState({
                                        filter: {
                                            BudgetYear: null,
                                            TagID: null,
                                            SignoffID: null,
                                        }
                                    });
                                },
                            },
                            {
                                name: 'สรุปรายงาน',
                                subText: "สรุปข้อมูลงบประมาณ และรายงานในมิติต่างๆ",
                                imgSrc: "/images/portal/report-icon.png",
                                subMenu: [],
                                selectOption: true,
                                options: ["BudgetYear", "BudgetSource", "Organization", "Province", "Basin"],
                                link: "/report",
                                modalTitle: "การคัดกรองข้อมูลรายงาน",
                                permission: ["Export"],
                                componentData: {
                                    BudgetTypeID: 1,
                                    TagID: element.TagID,
                                    SignoffID: element.SignoffID,
                                    StartYear: element.StartYear ? parseInt(element.StartYear) : undefined,
                                    EndYear: element.EndYear ? parseInt(element.EndYear) : undefined,
                                },
                                onClick: function (component, data, dataName) {
                                    if (data && dataName) {
                                        component.setState({
                                            [dataName]: {
                                                ...data,
                                                BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                TagID: this.componentData.TagID,
                                                SignoffID: this.componentData.SignoffID,
                                            }
                                        });
                                        return;
                                    }
                                    component.setState({
                                        filter: {
                                            BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                            TagID: this.componentData.TagID,
                                            SignoffID: this.componentData.SignoffID,
                                        }
                                    });
                                },
                                OnExit: function (component) {
                                    component.setState({
                                        filter: {
                                            BudgetYear: null,
                                            TagID: null,
                                            SignoffID: null,
                                        }
                                    });
                                }
                            },
                            {
                                name: 'ตรวจสอบแผนปฏิบัติการ',
                                subText: "",
                                imgSrc: "/images/portal/verified-icon.png",
                                link: "",
                                permission: ["Project"],
                                subMenu: [
                                    {
                                        name: 'ตรวจสอบโครงการซ้ำซ้อน',
                                        subText: "",
                                        imgSrc: "/images/portal/verified-icon.png",
                                        link: `/signoff/duplicate/compare/${element.SignoffID}`,
                                        permission: ["Project"],
                                    },
                                    {
                                        name: 'ตรวจสอบและเลือกนำส่ง Sign-off',
                                        subText: "",
                                        imgSrc: "/images/portal/verified-icon.png",
                                        link: `/signoff/status/detail/${element.SignoffID}`,
                                        permission: ["Project"],
                                    }
                                ]
                            },
                            ...(
                                account.Permissions?.SignoffExcludes ? []
                                    : [{
                                        name: 'Sign-off แผนปฏิบัติการ',
                                        subText: "",
                                        imgSrc: "/images/portal/signoff-icon.png",
                                        link: `/signoff/detail/${element.SignoffID}`,
                                        permission: [`Signoff`]
                                    }]
                            ),
                            {
                                name: 'ระบบแผนที่',
                                subText: "ตรวจสอบแผนงาน และข้อมูลเชิงภูมิศาสตร์",
                                imgSrc: "/images/portal/map-icon.png",
                                subMenu: [],
                                projectMap: true,
                                selectOption: true,
                                options: ["BudgetYear", "Organization", "Province", "Basin"],
                                link: "/map",
                                modalTitle: "การคัดกรองข้อมูลแผนที่",
                                componentData: {
                                    TagID: element.TagID,
                                    SignoffID: element.SignoffID,
                                    StartYear: element.StartYear ? parseInt(element.StartYear) : undefined,
                                    EndYear: element.EndYear ? parseInt(element.EndYear) : undefined,
                                },
                                onClick: function (component, data, dataName) {
                                    if (data && dataName) {
                                        component.setState({
                                            [dataName]: {
                                                ...data,
                                                BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                                TagID: this.componentData.TagID,
                                                SignoffID: this.componentData.SignoffID,
                                            }
                                        });
                                        return;
                                    }
                                    component.setState({
                                        filter: {
                                            BudgetYear: this.componentData.StartYear ? (this.componentData.StartYear + 543) : utilities.getDefaultBudgetYear(),
                                            TagID: this.componentData.TagID,
                                            SignoffID: this.componentData.SignoffID,
                                        }
                                    });
                                },
                                OnExit: function (component) {
                                    component.setState({
                                        filter: {
                                            BudgetYear: null,
                                            TagID: null,
                                            SignoffID: null,
                                        }
                                    });
                                },
                                permission: [`Map`]
                            },
                        ],
                        parent: {
                            name: element.SignoffTypeName ? element.SignoffTypeName : isTraining ? "แผนปฏิบัติการสำหรับ Training" : "แผนปฏิบัติการจากแหล่งงบประมาณอื่นๆ",
                            menuName: "ProjectAdd",
                            subText: "จัดการข้อมูลโครงการ ตรวจสอบแผนงานและ Sign-off",
                            imgSrc: "/images/portal/project-icon.png",
                            permission: ["Project"],
                        }
                    }
                    let pos = element.SignoffTypeID ?? 0;
                    if (!signoffTypeObj[pos])
                        signoffTypeObj[pos] = {
                            name: element.SignoffTypeName ? element.SignoffTypeName : isTraining ? "แผนปฏิบัติการสำหรับ Training" : "แผนปฏิบัติการจากแหล่งงบประมาณอื่นๆ",
                            menuName: "ProjectAdd",
                            subText: "",
                            imgSrc: "/images/portal/project/budget-source-all.png",
                            selectOption: 0,
                            permission: ["ProjectAdd"],
                            permissionSignoff: PermissionAdd,
                            highlight: element.Highlight,
                            subMenu: [],
                            SignoffTypeID: pos
                        };
                    signoffTypeObj[pos].subMenu.push(dataObj);
                    signoffTypeObj[pos].highlight = signoffTypeObj[pos].highlight || element.Highlight;
                    // newData.push(dataObj);
                }
                let dataSorted = signoffTypeObj.filter(Boolean).sort((a, b) => a.SignoffTypeID - b.SignoffTypeID);
                if (dataSorted?.[0]?.SignoffTypeID === 0) {
                    let temp = dataSorted[0];
                    dataSorted.splice(0, 1);
                    dataSorted.push(temp)
                }
                return dataSorted;
            })
            .catch(e => {
                console.log(e)
                return [];
            });
    },
    clearNavigationMenu: (isTraining, account) => {
        const navigation = isTraining && account?.RoleID !== 1 ? navigationTrainingMenus : navigationMenus
        let navProjectIndex = navigation.findIndex(x => x.menuName === "ProjectAdd");
        while (navProjectIndex >= 0) {
            navProjectIndex = navigation.findIndex(x => x.menuName === "ProjectAdd");
            if (navProjectIndex >= 0) {
                navigation.splice(navProjectIndex, 1);
            }
        }
        // if (navProject?.subMenu)
        //     navProject.subMenu = [];
    },
    getAllNavigationMenu: async function (account, getHeader, isTraining) {
        let data = [];
        if (!account?.Permissions) return data;
        // let navProject = navigationMenus.find(x => x.menuName === "ProjectAdd");
        // if (navProject?.subMenu && !navProject.subMenu.length) {
        //     navProject.subMenu = await this.initProjectNavigationMenu(account);
        // }
        const navigation = isTraining && account?.RoleID !== 1 ? navigationTrainingMenus : navigationMenus
        if (navigation.findIndex(x => x.menuName === "ProjectAdd") < 0) {
            let navProject = await this.initProjectNavigationMenu(account, isTraining);
            let pos = 1;
            for (let i = 0; i < navProject.length; i++) {
                navigation.splice(pos, 0, navProject[i]);
                pos++;
            }
        }
        const checkMenu = (element) => {
            if (element.subMenu?.length) {
                let hasPermission = false;
                let newData = { ...element, subMenu: [] };
                for (let i = 0; i < element.subMenu.length; i++) {
                    const childEl = checkMenu(element.subMenu[i]);
                    if (childEl) {
                        newData.subMenu.push(childEl);
                        hasPermission = true;
                    }
                }
                if (hasPermission) return newData;
                return;
            }
            else if (
                (!element.permission?.length && !element.subMenu?.length) ||
                // account.Permissions[element.permission]
                (element.permission?.length && element.permission.some(x => account.Permissions[x]))
            ) return element;
        }
        for (let i = 0; i < navigation.length; i++) {
            const element = navigation[i];
            let dataEl = checkMenu(element);
            if (dataEl) data.push(dataEl);
        }
        if (!getHeader) data = data.filter(x => !x.groupHeader);
        return data;
    },
    getNavigationMenu: (account, name, checkSignoff, getHeader, getParent) => {
        let data = [];
        let dataSelect = getParent ? {} : null;
        if (!account?.Permissions) return data;
        for (let i = 0; i < navigationMenus.length; i++) {
            const element = navigationMenus[i];
            if (element.subMenu?.length) {
                let hasPermission = false;
                let newData = { ...element, subMenu: [] };
                for (let i = 0; i < element.subMenu.length; i++) {
                    const childEl = element.subMenu[i];
                    if (account.Permissions[childEl.permission]) {
                        newData.subMenu.push(childEl);
                        hasPermission = true;
                    }
                }
                if (hasPermission) data.push(newData);
            }
            else if (
                (
                    (!element.permission?.length && !element.subMenu?.length) ||
                    // account.Permissions[element.permission]
                    (element.permission?.length && element.permission.some(x => account.Permissions[x]))
                ) &&
                (!checkSignoff || (checkSignoff && !element.permissionSignoff))
            ) data.push(element);
        }
        const checkMenu = (element, name) => {
            if (
                element.menuName === name &&
                (!checkSignoff || (checkSignoff && !element.permissionSignoff))
            ) {
                let el = { ...element };
                if (el.subMenu?.length) {
                    let sub = [...el.subMenu];
                    el.subMenu = [];
                    for (let i = 0; i < sub.length; i++) {
                        const childElement = { ...sub[i] };
                        let dataChild = checkMenu(childElement, name);
                        if (dataChild) {
                            if (dataChild?.length)
                                el.subMenu = [...el.subMenu, ...dataChild];
                            else
                                el.subMenu.push(dataChild);
                        }
                    }
                }
                return el;
            }
            else if (
                element.subMenu?.length &&
                (!checkSignoff || (checkSignoff && !element.permissionSignoff))
            ) {
                let data = [];
                for (let i = 0; i < element.subMenu.length; i++) {
                    const childElement = element.subMenu[i];
                    let dataEl = checkMenu(childElement, name);
                    if (dataEl) data.push(dataEl);
                }
                return data?.length ? data : null;
            }
        }
        if (!getHeader)
            data = data.filter(x => !x.groupHeader);
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let dataEl = checkMenu(element, name);
            if (dataEl) {
                if (getParent) {
                    dataSelect = { ...dataSelect, ...dataEl.parent };
                    if (!dataSelect.subMenu) dataSelect.subMenu = [];
                    dataSelect.subMenu.push(dataEl)
                }
                else {
                    dataSelect = dataEl;
                    break;
                }
            }
        }
        return dataSelect ? dataSelect : {};
    }
}

export default utilities;