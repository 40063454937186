import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Button
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import Utilities from './Utilities';
import SplitButton from './SplitButton';

const defaultFont = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em"
};

// const primaryColor = ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc5"];
const warningColor = ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"];
const dangerColor = ["#f44336", "#ef5350", "#e53935", "#f55a4e"];
const successColor = ["#4caf50", "#66bb6a", "#43a047", "#5cb860"];
const infoColor = ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573"];
const grayColor = [
    "#999",
    "#777",
    "#3C4858",
    "#AAAAAA",
    "#D2D2D2",
    "#DDD",
    "#b4b4b4",
    "#555555",
    "#333",
    "#a9afbb",
    "#eee",
    "#e7e7e7"
];
// const blackColor = "#000";
// const whiteColor = "#FFF";

const tableStyle = theme => ({
    warningTableHeader: {
        color: warningColor[0]
    },
    primaryTableHeader: {
        color: '#1565c0',
        "& svg": {
            color: "#3065a1"
        }
        // color: primaryColor[0]
    },
    dangerTableHeader: {
        color: dangerColor[0]
    },
    successTableHeader: {
        color: successColor[0]
    },
    infoTableHeader: {
        color: infoColor[0]
    },
    roseTableHeader: {
        color: roseColor[0]
    },
    grayTableHeader: {
        color: grayColor[0]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        color: "inherit",
        ...defaultFont,
        "&, &$tableCell": {
            fontSize: "1em"
        }
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem"
    },
    tableResponsive: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    tableHeadRow: {
        height: "56px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableBodyRow: {
        height: "48px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableFixed: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
        tableLayout: "fixed"
    }
});

// const useStyles = makeStyles(tableStyle);

class CustomTable extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            this.props.limit !== nextProps.limit ||
            this.props.page !== nextProps.page ||
            this.props.tableData !== nextProps.tableData ||
            this.props.tableHead !== nextProps.tableHead ||
            this.props.optionalFilter !== nextProps.optionalFilter
        );
    }

    getOnClickButton = (data, head) => {
        return () => head.click(data[head.dataName], data[head.optionalData]);
    }

    renderTableData = (tableData, tableHead, classes, limit, page) => {
        let tableElement = [];
        let group = null;
        let start = (!this.props.pagination) ? ((page - 1) * limit) : 0;
        for (let i = start; i < start + limit; i++) {
            if (!tableData[i]) break;
            let obj = tableData[i];
            let data = [];
            if (this.props.group && this.props.groupData) {
                if (group !== obj[this.props.groupData]) {
                    group = obj[this.props.groupData];
                    let groupname = (typeof this.props.groupName === 'function') ? this.props.groupName(obj[this.props.groupData]) : obj[this.props.groupName];
                    tableElement.push(
                        <TableRow
                            key={'groupData' + i}
                            className={classes.tableBodyRow + ' groupHead'}
                        >
                            <TableCell colSpan={(this.props.tableHead) ? this.props.tableHead.length : 0}>
                                {
                                    "#" + obj[this.props.groupData] + " " +
                                    (groupname || "")
                                }
                            </TableCell>
                        </TableRow>
                    );
                }
            }
            for (let j = 0; j < tableHead.length; j++) {
                switch (tableHead[j].type) {
                    case 'no': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={classes.tableCell}
                                style={(this.props.group) ? { paddingLeft: '2rem' } : null}
                                width="10%"
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                            >
                                {(this.props.pagination) ? (((page - 1) * limit) + i + 1) : (i + 1)}
                            </TableCell>
                        );
                        break;
                    }
                    case 'text': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={
                                    classes.tableCell +
                                    (tableHead[j].className ? (" " + tableHead[j].className) : "") +
                                    (tableHead[j].groupCol ? ((obj.SubGroup) ? (" pl-" + obj.SubGroup + "0") : "") : "")
                                }
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                            >
                                {obj[tableHead[j].dataName] ? obj[tableHead[j].dataName] : '-'}
                            </TableCell>
                        );
                        break;
                    }
                    case 'button': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={classes.tableCell}
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                                width="10%"
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="default"
                                    onClick={this.getOnClickButton(obj, tableHead[j])}
                                    className={tableHead[j].className}
                                >
                                    {tableHead[j].icon}
                                </Button>
                            </TableCell>
                        );
                        break;
                    }
                    case 'button-group': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={classes.tableCell}
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                            >
                                <SplitButton
                                    className="ml-auto"
                                    text=''
                                    icon={tableHead[j].icon}
                                    options={tableHead[j].options}
                                    data={obj[tableHead[j].dataName]}
                                    btnClassName={tableHead[j].btnClassName ?? ''}
                                />
                            </TableCell>
                        );
                        break;
                    }
                    case 'number': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={classes.tableCell + (tableHead[j].className ? (" " + tableHead[j].className) : "")}
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                            >
                                {(obj[tableHead[j].dataName]) ? Utilities.toCommas(obj[tableHead[j].dataName]) : this.props.section === 'trainingReport' ? 0 : '-'}
                            </TableCell>
                        );
                        break;
                    }
                    case 'function': {
                        data.push(
                            <TableCell
                                key={'cell' + i + '-' + j}
                                className={
                                    classes.tableCell +
                                    (tableHead[j].className ? (" " + tableHead[j].className) : "") +
                                    (tableHead[j].groupCol ? ((obj.SubGroup) ? (" pl-" + obj.SubGroup + "0") : "") : "")
                                }
                                align={(tableHead[j]?.align ? tableHead[j].align : 'left')}
                            >
                                {(tableHead[j].function) ? tableHead[j].function(obj) : '-'}
                            </TableCell>
                        );
                        break;
                    }
                    default: {
                        data.push(
                            <TableCell key={'cell' + i + '-' + j} className={classes.tableCell}>
                            </TableCell>
                        );
                    }
                }
            }
            let classHighlight = "";
            if (tableData[i].Highlight) classHighlight = ' groupHead ';
            if (tableData[i].subHighlight) classHighlight = ' groupSubHead ';
            tableElement.push(<TableRow key={'row' + i} className={classes.tableBodyRow + classHighlight}>{data}</TableRow>)
        }
        return tableElement;
    }

    renderDataPosition = (tableData, limit, page) => {
        if (!tableData) return ' 0 - 0 ';
        let count = 0;
        let start = (page - 1) * limit;
        let i;
        if (!this.props.pagination)
            for (i = start; i < start + limit; i++) {
                if (!tableData[i]) break;
                count++;
            }
        else
            for (i = 0; i < limit; i++) {
                if (!tableData[i]) break;
                count++;
            }
        return (" " + (start + (tableData.length > 0)) + " - " + (start + count) + " ");
    }

    render() {
        const tableHeaderColor = this.props.tableHeaderColor;
        const classes = this.props.classes;
        return (
            <div className={classes.tableResponsive}>
                <Table className={this.props.layoutFixed ? classes.tableFixed : classes.table}>
                    {this.props.tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow className={classes.tableHeadRow}>
                                {this.props.tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                            style={prop.style}
                                            align={prop.align ? prop.align : 'center'}
                                        >
                                            {prop.headerFunction ?
                                                prop.headerFunction()
                                                : prop.name
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    <TableBody>
                        {(this.props.tableData && this.props.tableData.length) ?
                            this.renderTableData(this.props.tableData, this.props.tableHead, classes, this.props.limit, this.props.page)
                            :
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell colSpan={(this.props.tableHead) ? this.props.tableHead.length : 0} align='center'>ไม่มีข้อมูล...</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                <div className="mt-10 d-flex">
                    <div>แสดงข้อมูล
                        <span>
                            {this.renderDataPosition(this.props.tableData, this.props.limit, this.props.page)}
                        </span>
                        จากทั้งหมด <span>{this.props.dataCount ?? '0'}</span> {this.props.unit ?? ''}
                    </div>
                    <Pagination
                        className="ml-auto"
                        count={(this.props.dataCount) ? Math.floor(this.props.dataCount / this.props.limit) + (this.props.dataCount % this.props.limit > 0) : 1}
                        page={this.props.page}
                        onChange={(obj, page) => this.props.onChangePage(page)}
                    />
                </div>
            </div >
        );
    }
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
};

CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
    tableData: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(tableStyle)(CustomTable);