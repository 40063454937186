import Axios from './_BaseService';

const PDPAService = {

    insertPDPAConsent: () => {
        const INSERTPDPACONSENT = `
        mutation {
            InsertPDPAConsent
        }
        `;

        return Axios.post("/twpservice", {
            query: INSERTPDPACONSENT,
            errorPolicy: "all",
        });
    },
}

export default PDPAService;