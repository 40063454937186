import Axios from './_BaseService';

const NewsService = {
    insertNews: (data) => {
        data = {
            Title: data.title || "",
            Content: data.content.replace(/  /g, "&emsp;").replace(/\n/g, "<br />") || "",
            Thumbnail: data.thumbnail || [],
            CreatedAt: data.createdAt || "",
            Reference: data.reference || ""
        };

        const INSERTNEWS = `
        mutation InsertNews (
            $Title: String!,
            $Content: String!,
            $Thumbnail: [String],
            $CreatedAt: String!,
            $Reference: String
        ) {
            InsertNews (
                Title: $Title,
                Content: $Content,
                Thumbnail: $Thumbnail,
                CreatedAt: $CreatedAt,     
                Reference: $Reference     
            ){
                ID,
                Title,
                Content,
                Thumbnail,
                CreatedAt,
                Reference
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: INSERTNEWS,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    updateNews: (data, isThumbnailExist) => {
        data = {
            ID: data.id || undefined,
            Title: data.title || "",
            Content: data.content.replace(/  /g,"&emsp;").replace(/\n/g, "<br />") || "",
            Thumbnail: data.thumbnail || [],
            CreatedAt: data.createdAt || "",
            Reference: data.reference || "",
            IsThumbnailUpdate: isThumbnailExist
        };

        const UPDATENEWS = `
        mutation UpdateNews (
            $ID: Int!,
            $Title: String!,
            $Content: String!,
            $Thumbnail: [String],
            $CreatedAt: String!,
            $Reference: String,
            $IsThumbnailUpdate: Boolean
        ) {
            UpdateNews (
                ID: $ID,
                Title: $Title,
                Content: $Content,
                Thumbnail: $Thumbnail,
                CreatedAt: $CreatedAt,     
                Reference: $Reference,
                IsThumbnailUpdate: $IsThumbnailUpdate   
            ){
                ID,
                Title,
                Content,
                Thumbnail,
                CreatedAt,
                Reference
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATENEWS,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    deleteNews: (NewsID) => {
        const DELETENEWS = `
        mutation DeleteNews (
            $ID: Int!
        ) {
            DeleteNews (
                ID: $ID    
            ){
                ID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: DELETENEWS,
            variables: {
                ID: NewsID
            },
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    uploadFileNewsImage: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : [],
            NewsID: data.NewsID
        }
        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
        }
        formData.append("NewsID", data.NewsID);

        return Axios({
            method: "post",
            url: "/upload/news",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000
        });
    },

}

export default NewsService;