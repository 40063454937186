import Axios from "./_BaseService";
let timeout = 1000 * 60 * 2;

const GISService = {
    getLayerPath: () => {
        const GETLAYERPATH = `
            query{
                GetGEOServerProjectLayer {
                    ServerPath
                    LayerPath
                }
                GetLayerPath{
                    LayerID
                    LayerGroupID
                    LayerGroupName
                    LayerName
                    ServerPath
                    LayerPath
                    Description
                    SourceURL
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETLAYERPATH,
            errorPolicy: "all",
        });
    },

    getGISAreaInfoByLatLng: (lat, lng, getMultiple) => {
        lat = lat || lat === 0 ? parseFloat(lat) : undefined;
        lng = lng || lng === 0 ? parseFloat(lng) : undefined;
        getMultiple = !!getMultiple
        const GETAREAINFOBYLATLNG = `
        query GetGISAreaInfoByLatLng (
            $Lat: Float!,
            $Lng: Float!,
            $GetMultiple: Boolean
        ) {
            GetGISAreaInfoByLatLng (
                Lat: $Lat,
                Lng: $Lng,
                GetMultiple: $GetMultiple
            ) {
                ShapeName,
                ShapeID,
                ShapeCode,
                ShapeType,
                LayerID
            }
        }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETAREAINFOBYLATLNG,
                variables: {
                    Lat: lat,
                    Lng: lng,
                    GetMultiple: getMultiple,
                },
                errorPolicy: "all",
            },
            {
                timeout: timeout,
            }
        );
    },

    getGISAreaInfoByLatLngs: (lat, lng, getMultiple) => {
        lat = lat || lat.length ? lat : [];
        lng = lng || lng.length ? lng : [];
        getMultiple = !!getMultiple
        const GETAREAINFOBYLATLNGS = `
        query GetGISAreaInfoByLatLngs (
            $Lat: [Float]!,
            $Lng: [Float]!,
            $GetMultiple: Boolean
        ) {
            GetGISAreaInfoByLatLngs (
                Lat: $Lat,
                Lng: $Lng,
                GetMultiple: $GetMultiple
            ) {
                ShapeName,
                ShapeID,
                ShapeCode,
                ShapeType,
                Index,
                LayerID
            }
        }
        `;

        return Axios.post(
            "/twpservice",
            {
                query: GETAREAINFOBYLATLNGS,
                variables: {
                    Lat: lat,
                    Lng: lng,
                    GetMultiple: getMultiple,
                },
                errorPolicy: "all",
            },
            {
                timeout: timeout,
            }
        );
    },

    getGISCriticalAreaInfoByLatLng: (lat, lng) => {
        const GETCRITICALAREA = `
            query GetGISCriticalAreaInfoByLatLng (
                $Lat: Float!,
                $Lng: Float!
            ){
                GetGISCriticalAreaInfoByLatLng( 
                    Lat: $Lat, 
                    Lng: $Lng
                ) {
                    Lat
                    Lng
                    ShapeName
                    ShapeID
                    ShapeCode
                    ShapeType
                    Index
                    LayerID
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETCRITICALAREA,
            variables: {
                Lat: lat,
                Lng: lng,
            },
            errorPolicy: "all",
        });
    },

    GetGISNearProjectsMultiSelect: (data) => {
        data = {
            Lat: data.Lat || undefined,
            Lng: data.Lng || undefined,
            RadiusKM: data.RadiusKM ? data.RadiusKM : undefined,
            BudgetYears: data.BudgetYear ? data.BudgetYear : undefined,
            ProjectTypeIDs: data.ProjectTypeID || undefined,
            OrganizationIDs: data.OrganizationID || undefined,
            DLAOrganizationIDs: data.DLAOrganizationID || undefined,
            OperatorOrganizationIDs: data.OperatorOrganizationID || undefined,
            ProjectStatusIDs: data.ProjectStatusID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
        };
        const GETGISNEARPROJECTINFOS = `
            query GetGISNearProjectsMultiSelect (
                $Lat: Float!,
                $Lng: Float!,
                $RadiusKM: Float!,
                $BudgetYears: [Int],
                $ProjectTypeIDs: [Int],
                $OrganizationIDs: [Int],
                $DLAOrganizationIDs: [Int],
                $OperatorOrganizationIDs: [Int],
                $ProjectStatusIDs: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float
            ){
                GetGISNearProjectsMultiSelect (
                    Lat: $Lat,
                    Lng: $Lng,
                    RadiusKM: $RadiusKM,
                    BudgetYears: $BudgetYears,
                    ProjectTypeIDs: $ProjectTypeIDs,
                    OrganizationIDs: $OrganizationIDs,
                    DLAOrganizationIDs: $DLAOrganizationIDs, 
                    OperatorOrganizationIDs: $OperatorOrganizationIDs, 
                    ProjectStatusIDs: $ProjectStatusIDs,
                    BudgetRangeStart: $BudgetRangeStart,           
                    BudgetRangeEnd: $BudgetRangeEnd,         
                ) {
                    Lat
                    Lng
                    ProjectID
                    ProjectName
                    ProjectCode
                    BudgetYear
                    RequestAmount
                    DraftAmount
                    ApproveAmount
                    AllocateAmount
                    OrganizationID
                    ProjectTypeID
                    ProjectStatusName
                    ProjectStatusID
                    DistanceKM
                    OrganizationName
                    ProjectStatusName
                    MYProject
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETGISNEARPROJECTINFOS,
            variables: data,
            errorPolicy: "all",
        });
    },

    GetGISProjectByLatLngMultiSelect: (lat, lng, zoomLevel, data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        let GFMISCode = data.GFMISCode || undefined;
        if (GFMISCode) {
            GFMISCode = String(GFMISCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }

        data = {
            Lat: lat,
            Lng: lng,
            ZoomLevel: zoomLevel,
            Mode: 1,
            IconFactor: 3,
            ProjectName: data.ProjectName || undefined,
            ProjectCodes: data.ProjectCode || undefined,
            ProjectStatusIDs: data.ProjectStatusID || undefined,
            BudgetYears: data.BudgetYear || undefined,
            OrganizationIDs: data.OrganizationID || undefined,
            DLAOrganizationIDs: data.DLAOrganizationID || undefined,
            OperatorOrganizationIDs: data.OperatorOrganizationID || undefined,
            ProvinceIDs: data.ProvinceID || undefined,
            DistrictIDs: data.DistrictID || undefined,
            SubdistrictIDs: data.SubdistrictID || undefined,
            BasinIDs: data.BasinID || undefined,
            SubbasinIDs: data.SubbasinID || undefined,
            ActivityIDs: data.ActivityID || undefined,
            ProjectTypeIDs: data.ProjectTypeID || undefined,
            StrategyIDs: data.StrategyID || undefined,
            BudgetSourceIDs: data.BudgetSourceID || undefined,
            BudgetDimensionIDs: data.BudgetDimensionID || undefined,
            TagIDs: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            AreaBaseIDs: data.AreaBaseID || undefined,
        };
        const GETGISPROJECTBYLATLNG = `
            query GetGISProjectByLatLngMultiSelect (
                $Lat: Float!
                $Lng: Float!
                $ZoomLevel: Int!
                $Mode: Int!
                $IconFactor: Float
                $ProjectName: String
                $ProjectCodes: [String]
                $ProjectStatusIDs: [Int]
                $BudgetYears: [Int]
                $OrganizationIDs: [Int]
                $DLAOrganizationIDs: [Int]
                $OperatorOrganizationIDs: [Int]
                $ProvinceIDs: [Int]
                $DistrictIDs: [Int]
                $SubdistrictIDs: [Int]
                $BasinIDs: [Int]
                $SubbasinIDs: [Int]
                $ActivityIDs: [Int]
                $ProjectTypeIDs: [Int]
                $StrategyIDs: [Int]
                $BudgetSourceIDs: [Int]
                $BudgetDimensionIDs: [Int]
                $TagIDs: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $AreaBaseIDs: [Int]
            ) {
                GetGISProjectByLatLngMultiSelect (
                    Lat: $Lat, 
                    Lng: $Lng, 
                    ZoomLevel: $ZoomLevel, 
                    Mode: $Mode, 
                    IconFactor: $IconFactor, 
                    ProjectName: $ProjectName, 
                    ProjectCodes: $ProjectCodes, 
                    ProjectStatusIDs: $ProjectStatusIDs, 
                    BudgetYears: $BudgetYears, 
                    OrganizationIDs: $OrganizationIDs, 
                    DLAOrganizationIDs: $DLAOrganizationIDs, 
                    OperatorOrganizationIDs: $OperatorOrganizationIDs, 
                    ProvinceIDs: $ProvinceIDs, 
                    DistrictIDs: $DistrictIDs, 
                    SubdistrictIDs: $SubdistrictIDs, 
                    BasinIDs: $BasinIDs, 
                    SubbasinIDs: $SubbasinIDs, 
                    ActivityIDs: $ActivityIDs, 
                    ProjectTypeIDs: $ProjectTypeIDs, 
                    StrategyIDs: $StrategyIDs, 
                    BudgetSourceIDs: $BudgetSourceIDs, 
                    BudgetDimensionIDs: $BudgetDimensionIDs, 
                    TagIDs: $TagIDs, 
                    BudgetRangeStart: $BudgetRangeStart, 
                    BudgetRangeEnd: $BudgetRangeEnd,
                    AreaBaseIDs: $AreaBaseIDs
                ){
                    ProjectID,
                    ProjectCode,
                    ProjectName,
                    GFMISCode,
                    Moo,
                    Village,
                    SubdistrictID,
                    SubdistrictName,
                    DistrictID,
                    DistrictName,
                    ProvinceID,
                    ProvinceName,
                    SubbasinID,
                    SubbasinName,
                    BasinID,
                    BasinName,
                    Lat,
                    Lng,
                    OrganizationID,
                    OrganizationName,
                    BudgetYear,
                    OperationStartYear,
                    OperationEndYear,
                    OperationStartMonth,
                    OperationEndMonth,
                    Duration,
                    BudgetSourceID,
                    BudgetSourceName,
                    BudgetTypeID,
                    BudgetTypeName,
                    ActivityID,
                    ActivityName,
                    ProjectTypeID,
                    ProjectTypeCode,
                    ProjectTypeName,
                    AreaBaseID,
                    AreaBaseName,
                    BudgetDimensionID,
                    BudgetDimensionName,
                    RequestAmount,
                    DraftAmount,
                    ApproveAmount,
                    AllocateAmount,
                    StrategyID,
                    StrategyName,
                    EducationPrepareID,
                    LandPrepareID,
                    ListPatternPrepareID,
                    EstimatePrepareID,
                    Remark,
                    CreatedDateTime,
                    ModifiedDateTime,
                    CreatedUsername,
                    ModifiedUsername,
                    OrderProject,
                    ProjectStatusID,
                    ProjectStatusName,
                    BenefitArea,
                    WaterCapacity,
                    BenefitHousehold,
                    MYProject
                }
            }
        `;
        return Axios.post("/twpservice", {
            query: GETGISPROJECTBYLATLNG,
            variables: data,
            errorPolicy: "all",
        });
    },

    getGISNearWaterResourceMultiSelect: (data) => {
        data = {
            Lat: data.Lat || undefined,
            Lng: data.Lng || undefined,
            RadiusKM: data.RadiusKM ? data.RadiusKM : undefined,
            BasinID: data.W_BasinID || undefined,
            Type: data.W_Type?.length ? data.W_Type : undefined,
            StatusID: data.StatusID ?? undefined,
            Size: data.W_Size ?? undefined,
            ProvinceID: data.W_ProvinceID || undefined,
            getOnlyCQL: data.getOnlyCQL ?? undefined,
        };

        const GETWATERRESOURCE = `
            query GetGISNearWaterResourceMultiSelect (
                $Lat: Float!,
                $Lng: Float!,
                $RadiusKM: Float!,
                $BasinID: [Int],
                $Type: [Int],
                $StatusID: Int,
                $Size: Float,
                $ProvinceID : [Int],
                $getOnlyCQL: Boolean
            ){
                GetGISNearWaterResourceMultiSelect (
                    Lat: $Lat,
                    Lng: $Lng,
                    RadiusKM: $RadiusKM,
                    BasinID: $BasinID,
                    Type: $Type,    
                    StatusID: $StatusID
                    Size: $Size,
                    ProvinceID: $ProvinceID
                    getOnlyCQL: $getOnlyCQL
                ) {
                    WaterResource
                    CQL
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: GETWATERRESOURCE,
            variables: data,
            errorPolicy: "all",
        });
    },
};

export default GISService;
