import React, { useContext } from "react";
import AppContext from "../../contextProvider/AppContextProvider/AppContext";
import CmtVerticalLayout from "../../../../@coremat/CmtLayouts/Vertical";
import CmtHeader from "../../../../@coremat/CmtLayouts/Vertical/Header";
import Header from "../partials/Header";
import CmtSidebar from "../../../../@coremat/CmtLayouts/Vertical/Sidebar";
import SidebarHeader from "../partials/SidebarHeader";
import SideBar from "../partials/SideBar";
import CmtContent from "../../../../@coremat/CmtLayouts/Vertical/Content";
import { SIDEBAR_TYPE } from "../../../constants/ThemeOptions";
import clsx from "clsx";
import CmtFooter from "../../../../@coremat/CmtLayouts/Vertical/Footer";
import Footer from "../partials/Footer";
import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import VersionService from '../../../../Service/_VersionService';

const version = VersionService.GetVersion();

const useStyles = makeStyles(theme => ({
	SideBarBottom: {
		paddingLeft: 10,
		transition: "all .4s ease-out",
		height: 40,
		overflowY: 'hidden',
		flex: "1 1",
		opacity: 1,
		".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
			height: 0,
			paddingTop: 0,
			opacity: 0,
			transition: "all 0.1s ease"
		}
	}
}));

const VerticalMinimal = ({ className, children, showMenu, mapMode, account, trainingPlatform }) => {
	const {
		drawerBreakPoint,
		headerType,
		isSidebarFixed,
		sidebarStyle,
		sidebarSize,
		showFooter
	} = useContext(AppContext);
	const classes = useStyles();
	const history = useHistory();
	return (
		<CmtVerticalLayout
			drawerBreakPoint={drawerBreakPoint}
			sidebarWidth={showMenu ? sidebarSize : 0}
			className={
				clsx(
					"verticalMinimalLayout",
					className,
					(history.location.pathname === "/landingpage") ?
						"bg-white" : "bg-img"
				)
			}
			style={{ height: (mapMode) ? "100vh" : "" }}
		>
			{
				showMenu ?
					[
						<CmtHeader type={headerType} key="header">
							<Header />
						</CmtHeader>,
						<CmtSidebar
							type={SIDEBAR_TYPE.MINI}
							isSidebarFixed={isSidebarFixed}
							{...sidebarStyle}
							key="sidebar"
						>
							<SidebarHeader account={account} trainingPlatform={trainingPlatform} />
							<SideBar account={account} trainingPlatform={trainingPlatform} />
							<Box className={classes.SideBarBottom}>
								Version: {version.Version} Build {version.Build}
							</Box>
						</CmtSidebar>
					]
					: null
			},
			<CmtContent className={showMenu && !mapMode ? "" : "p-0"}>
				{children}
				{/* <ContentLoader /> */}
			</CmtContent>
			{showFooter && (
				<CmtFooter type="static">
					<Footer />
				</CmtFooter>
			)}
		</CmtVerticalLayout>
	);
};

export default VerticalMinimal;
