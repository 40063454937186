import React, { Component } from "react";
import { Button, IconButton } from "@material-ui/core";
import { Delete, Image, Description } from "@material-ui/icons";

class UploadFile extends Component {
    handleFileChoose = this.props.handleFileChoose;
    handleRemoveFile = this.props.handleRemoveFile;

    checkFileType = (obj) => {
        if (!obj.FileName) return;
        var filetype = obj.FileName.split(".");
        filetype = filetype[filetype.length - 1].toLowerCase();
        switch (filetype) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <Image className="mr-05" />;
            default:
                return <Description className="mr-05" />;
        }
    };

    onSelectFiles = (e) => {
        let files = e.target.files;
        for (let index = 0; index < files.length; index++) {
            this.props.handleDrop(files[index]);
        }
    };

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }} id="multi-item-wrapper">
                {this.props.section === 'news' && this.props.files?.length >= this.props.fileLimit ? null : this.props.section === 'changeOwner' && this.props.files?.length > 2 ? null : (
                    <>
                        <label htmlFor="attached-file">
                            <Button variant="contained" color="primary" component="span">
                                {this.props.section !== 'news' ? "แนบเอกสารลงนาม" : "แนบภาพข่าว"}
                            </Button>
                        </label>
                        {this.props.section === 'news' ?
                            <input
                                type="file"
                                id="attached-file"
                                onChange={(e) => {
                                    this.handleFileChoose(e);
                                    e.target.value = "";
                                }}
                                className="d-none"
                                multiple
                            /> :
                            <input
                                type="file"
                                id="attached-file"
                                onChange={(e) => {
                                    this.handleFileChoose(e);
                                    e.target.value = "";
                                }}
                                className="d-none"
                            />}

                    </>
                )}
                <div className="overflowY-auto pt-05 pb-05">
                    {this.props.files?.length
                        ? this.props.files.map((obj, index) => {
                            return (
                                <React.Fragment key={"draganddrop-" + index}>
                                    <div
                                        className={`d-flex ${index > 0 ? "mt-05" : ""}`}
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="d-flex">
                                            {this.checkFileType(obj)}
                                            <span style={{ wordBreak: "break-all" }}>{obj.FileName}</span>
                                        </div>
                                        <IconButton
                                            variant="contained"
                                            color="default"
                                            size="small"
                                            className="btn-danger ml-05"
                                            onClick={() => {
                                                this.handleRemoveFile(index);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </div>
                                </React.Fragment>
                            );
                        })
                        : null}
                </div>
            </div>
        );
    }
}
export default UploadFile;
