import React, { useContext, useEffect, useState } from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
	createTheme,
	jssPreset,
	StylesProvider
} from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { create } from "jss";
import rtl from "jss-rtl";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import AppLayout from "../AppLayout";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useDispatch, connect } from 'react-redux';
// import { useDispatch, connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setLocalStorage, removeLocalStorage, getLocalStorage, getLocalStorageMaster, removeUserToken } from '../../../Service/_LocalStorageService';
import { getUsersCookies, setUsersCookies, removeAllUsersCookies } from '../../../Service/_CookieService';
import { setAccountLogin, setAccountLogout, setMasterData, showLoading, hideLoading, setTrainingData } from '../../../redux/action';
import AccountService from '../../../Service/AccountService';
import CoreService from '../../../Service/CoreService';
import Swal from 'sweetalert2';
import Utilities from "../../../Util/Utilities";
import jsonpack from "jsonpack";
import worker from "../../../Util/worker";

import useGoogleAnalytic from "./hooks/useGoogleAnalytic";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Loader = connect(({ showLoading }) => ({ showLoading }))(({ showLoading }) => {
	if (showLoading)
		return (
			<Box className="circularProgressRoot">
				<CircularProgress></CircularProgress>
			</Box>
		)
	else return null;
});

const AppWrapper = ({ Children, ...other }) => {
	const { theme } = useContext(AppContext);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	// const isSetMasterData = useSelector(({ masterData }) => masterData && Object.keys(masterData).length > 0 && masterData.constructor === Object ? true : false);
	const dispatch = useDispatch();
	const history = useHistory();
	const useGA = useGoogleAnalytic(other.account);

	const setMasterDataApp = async (account) => {
		let Promises = [];
		let masterData = getLocalStorageMaster("masterData");
		let buildData = await CoreService.chackBuildData().then(res => res.data.CheckBuildData);

		if (!masterData || !buildData) {
			Promises.push(CoreService.getAllMasterDataDirect());
			removeLocalStorage("masterData");
			removeLocalStorage("districtMasterData");
			removeLocalStorage("masterProject");
		}

		Promises.push(CoreService.getPermissionMasterData());
		dispatch(showLoading());
		return Promise.all(Promises)
			.then(res => {
				if (!res?.length) {
					removeLocalStorage("masterData");
					// removeAllUsersCookies();
					removeUserToken();
					Utilities.clearNavigationMenu();
					dispatch(setAccountLogout());
					AccountService.Logout();
					Swal.fire({
						title: 'เกิดข้อผิดพลาด',
						text: 'ไม่สามารถทำรายการได้',
						icon: 'error'
					});
					return;
				}
				let data = {};
				for (let i = 0; i < res.length; i++) {
					const element = res[i];
					if (!element.data || element.errors) {
						removeLocalStorage("masterData");
						// removeAllUsersCookies();
						removeUserToken();
						Utilities.clearNavigationMenu();
						dispatch(setAccountLogout());
						AccountService.Logout();
						Swal.fire({
							title: 'เกิดข้อผิดพลาด',
							text: 'ไม่สามารถทำรายการได้',
							icon: 'error'
						});
						return;
					}
					data = { ...data, ...element.data };
				}
				if (!masterData || !buildData) {
					let element = res[0];
					if (!element.data || element.errors) {
						removeLocalStorage("masterData");
						// removeAllUsersCookies();
						removeUserToken();
						Utilities.clearNavigationMenu();
						dispatch(setAccountLogout());
						AccountService.Logout();
						Swal.fire({
							title: 'เกิดข้อผิดพลาด',
							text: 'ไม่สามารถทำรายการได้',
							icon: 'error'
						});
						return;
					}
					removeLocalStorage("masterData");
					worker(element.data)
						.then((packedData) => {
							if (!packedData) {
								return setLocalStorage("masterData", jsonpack.pack(element.data));
							};
							setLocalStorage("masterData", packedData);
						})
						.catch((e) => {
							console.error(e);
						});
					let operatorOrg = [...data?.SearchOrganization ?? []];
					// if (data?.SearchOrganizationAll) {
					// 	data.SearchOrganizationAll = jsonpack.unpack(data.SearchOrganizationAll)
					// }
					data = {
						...data,
						FilterOrganizationOption: [
							...(data?.SearchOrganization?.filter(x => x.OrganizationID !== 194) ?? []),
							...(data?.SearchOrganizationAll?.filter(x =>
								x.OrganizationID === 194 || x.OrganizationCode.slice(0, 3) === 'DLA') ?? [])
						],
						FilterDLAOrganizationOption: data?.SearchOrganizationAll?.filter(x => x.IsDLAOrganization) ?? [],
						FilterOrganizationOperatorOption: operatorOrg,
						FilterOrganizationImportantOption: data?.SearchOrganizationAll ?
							// jsonpack.unpack(data?.SearchOrganizationProjectImportant)
							data?.SearchOrganizationAll?.filter(x => x.ConditionOrgImportant === 1)
							: undefined
						// [
						// 	...((
						// 		other.account?.Permnmission?.RoleID === 1 ||
						// 		other.account?.Permnmission?.RoleID === 2 ||
						// 		other.account?.Permnmission?.RoleProjectImportantID === 1
						// 	) ?
						// 		(
						// 			[
						// 				...(data?.SearchOrganization?.filter(x => x.OrganizationID !== 194) ?? []),
						// 				...(data?.SearchOrganizationAll?.filter(x =>
						// 					x.OrganizationID === 194 ||
						// 					x.OrganizationCode.slice(0, 3) === 'DLA' ||
						// 					x.OrganizationName.includes("จังหวัด (")
						// 				) ?? [])
						// 			]
						// 		) :
						// 		(
						// 			[
						// 				...(data?.SearchOrganizationAll?.filter(x =>
						// 					x.OrganizationID === other.account?.OrganizationID
						// 				) ?? [])
						// 			]
						// 		)
						// 	)
						// ]
					};
					operatorOrg = [...operatorOrg];
					let dataMap = {};
					for (let i = 0; i < operatorOrg.length; i++) {
						dataMap[operatorOrg[i].OrganizationID] = 1;
					}
					let dataOp = {};
					if (data.GetDLAPatternOrganization) {
						for (let i = 0; i < data.SearchOrganizationAll.length; i++) {
							const element = data.SearchOrganizationAll[i];
							if (element.DLAPatternID && data.GetDLAPatternOrganization[element.DLAPatternID]) {
								let dataEl = data.GetDLAPatternOrganization[element.DLAPatternID];
								for (let i = 0; i < dataEl.length; i++) {
									const child = dataEl[i];
									if (!dataOp[child.OrganizationID] && !dataMap[child.OrganizationID]) {
										operatorOrg.push(child);
										dataOp[child.OrganizationID] = 1;
									}
								}
							}
						}
					}
					data = { ...data, FilterOrganizationOperatorOption: operatorOrg };
				}
				else {
					let operatorOrg = [...data?.SearchOrganization ?? []];
					// if (data?.SearchOrganizationAll) {
					// 	data.SearchOrganizationAll = jsonpack.unpack(data.SearchOrganizationAll)
					// }
					data = {
						...data,
						...masterData,
						FilterOrganizationOption: [
							...(data?.SearchOrganization?.filter(x => x.OrganizationID !== 194) ?? []),
							...(data?.SearchOrganizationAll?.filter(x =>
								x.OrganizationID === 194 || x.OrganizationCode.slice(0, 3) === 'DLA') ?? [])
						],
						FilterDLAOrganizationOption: data?.SearchOrganizationAll?.filter(x => x.IsDLAOrganization) ?? [],
						FilterOrganizationOperatorOption: operatorOrg,
						FilterOrganizationImportantOption: data?.SearchOrganizationAll ?
							// jsonpack.unpack(data?.SearchOrganizationProjectImportant)
							data?.SearchOrganizationAll?.filter(x => x.ConditionOrgImportant === 1)
							: undefined
					};
					operatorOrg = [...operatorOrg];
					let dataMap = {};
					for (let i = 0; i < operatorOrg.length; i++) {
						dataMap[operatorOrg[i].OrganizationID] = 1;
					}
					let dataOp = {};
					if (data.GetDLAPatternOrganization) {
						for (let i = 0; i < data.SearchOrganizationAll.length; i++) {
							const element = data.SearchOrganizationAll[i];
							if (element.DLAPatternID && data.GetDLAPatternOrganization[element.DLAPatternID]) {
								let dataEl = data.GetDLAPatternOrganization[element.DLAPatternID];
								for (let i = 0; i < dataEl.length; i++) {
									const child = dataEl[i];
									if (!dataOp[child.OrganizationID] && !dataMap[child.OrganizationID]) {
										operatorOrg.push(child);
										dataOp[child.OrganizationID] = 1;
									}
								}
							}
						}
					}
					data = { ...data, FilterOrganizationOperatorOption: operatorOrg };
				}
				dispatch(setMasterData(data));
				setIsDataLoaded(true);
			})
			.catch(e => {
				console.error(e);
				removeLocalStorage("masterData");
				// removeAllUsersCookies();
				removeUserToken();
				Utilities.clearNavigationMenu();
				dispatch(setAccountLogout());
				AccountService.Logout();
				Swal.fire({
					title: 'เกิดข้อผิดพลาด',
					text: 'ไม่สามารถทำรายการได้',
					icon: 'error'
				});
			})
			.finally(() => {
				dispatch(hideLoading());
			})
	}

	const setTraining = async () => {
		return fetch((((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '') + "/twp/v2/gettraining"), { method: "post" })
			.then(res => res.json())
			.then(data => dispatch(setTrainingData(data?.training, data?.trainingDays)))
			.catch(e => console.log(e))
	}

	useEffect(
		() => {
			document.body.setAttribute("dir", "ltr");
			let SessionToken = getLocalStorage("SessionToken");
			// if (getUsersCookies('sessionToken')) {
			setTraining();
			if (SessionToken) {
				// dispatch(setAccountLogin({ SessionToken: getUsersCookies('sessionToken') }));
				dispatch(setAccountLogin({ SessionToken: SessionToken }));
				// AccountService.getAccountToken(getUsersCookies('sessionToken'))
				AccountService.getAccountToken(SessionToken)
					.then(res => {
						setIsDataLoaded(true);
						let data = res?.data?.GetAccountByToken;
						if (!data || res.errors) {
							// removeAllUsersCookies();
							removeUserToken();
							Utilities.clearNavigationMenu();
							dispatch(setAccountLogout());
							AccountService.Logout();
							return;
						}
						// setUsersCookies('sessionToken', res.data.GetAccountByToken.SessionToken);
						removeAllUsersCookies();
						setLocalStorage("SessionToken", data.SessionToken);
						dispatch(setAccountLogin(data));
						setMasterDataApp(data);
					})
					.catch(e => {
						console.log(e);
						setIsDataLoaded(true);
						// removeAllUsersCookies();
						removeUserToken();
						Utilities.clearNavigationMenu();
						dispatch(setAccountLogout());
						AccountService.Logout();
					})
			}
			else {
				if (
					!history.location.pathname.includes("/resetpassword")
					// !history.location.pathname.includes("/landingpage") &&
					// !history.location.pathname.includes("/404")
				) {
					history.push("/landingpage");
					// window.location.replace(`${window.origin}/404`);
				}
				setTimeout(() => { setIsDataLoaded(true); }, 750);
			}
		},
		[]
	);

	// useEffect(
	// 	() => {
	// 		if (direction === "rtl") {
	// 			document.body.setAttribute("dir", "rtl");
	// 		} else {
	// 			document.body.setAttribute("dir", "ltr");
	// 		}
	// 	},
	// 	[direction]
	// );

	return (
		<ThemeProvider theme={createTheme(theme)}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<StylesProvider jss={jss}>
					<CssBaseline />
					<AppLayout isDataLoaded={isDataLoaded}>
						<Children setMasterData={setMasterDataApp} />
					</AppLayout>
					<Loader />
				</StylesProvider>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
};

const mapStatetoProps = (state) => ({
	account: state.account
});

export default connect(mapStatetoProps)(AppWrapper);