import React from "react";
import Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);
Highcharts.setOptions({
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

class Chart extends React.Component {
    chartInstance = undefined;

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.data !== this.props.data
        if (nextProps.data && this.chartInstance === undefined) {
            this.renderChart(nextProps.data);
            return true;
        }
        else if (nextProps.data !== this.props.data && this.chartInstance !== undefined) {
            this.chartInstance.destroy();
            this.renderChart(nextProps.data);
            return true;
        }
        // return (this.chartInstance === undefined);
        return false;
    }

    componentDidMount() {
        if (this.props.data && this.chartInstance === undefined) {
            this.renderChart(this.props.data);
        }
    }

    renderChart(data) {
        let unit = this.props.unit;
        this.chartInstance = Highcharts.chart(this.chart, {
            chart: {
                type: 'column',
                height: this.props.height ?? undefined
            },
            title: {
                text: 'สรุปจำนวนสถานะความพร้อม'
            },
            xAxis: {
                type: 'category',
                title: {
                    text: this.props.typeName ?? 'ชื่อ'
                },
                min: 0,
                scrollbar: {
                    enabled: true
                },
                tickLength: 0
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'จำนวนสถานะความพร้อม',
                    align: 'high'
                },
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.key}</b>: ${this.y} ${unit ?? ""}`;
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            series: data
        });
    }

    render() {
        return (
            <div ref={el => this.chart = el} style={{ width: "100%", height: "100%" }} />
        )
    }
}

export default Chart;