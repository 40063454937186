import Axios from './_BaseService';
import { removeLocalStorage } from './_LocalStorageService';

const AccountService = {
    Login: (data) => {
        data = {
            Username: data.Username?.trim() || undefined,
            Password: data.Password?.trim() || undefined
        };
        const LOGIN = `
            query Login(
                $Username: String!,
                $Password: String!
            ) {
                Login(
                    Username: $Username,
                    Password: $Password
                ) {
                    AccountID,
                    Username,
                    OrganizationID,
                    OrganizationName,
                    ParentOrganizationID,
                    ParentOrganizationName,
                    RoleID,
                    RoleProjectImportantID,
                    RoleProjectRequestID,
                    RoleProjectImportantName,
                    RoleProjectRequestName,
                    OrganizationInternalName,
                    RoleName,
                    FirstName,
                    LastName,
                    Email,
                    PhoneNumber,
                    OfficeNumber,
                    OrganizationInternal,
				    Position,
                    Remark,
                    SessionToken,
                    PDPAConsentID,
                    PDPA,
                    Permissions,
                    DefaultPresetCollectionID,
                    APIKeys,
                    DLAProvinceID,
                    DLAProvinceCode,
                    DLAProvinceName
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: LOGIN,
            variables: data,
            errorPolicy: "all",
        });
    },

    Logout: () => {
        removeLocalStorage("buildData")

        return Axios.post("/logout");
    },

    getAccountToken: (token) => {
        const GETACCOUNTTOKEN = `
            query GetAccountByToken(
                $SessionToken: String!
            ) {
                GetAccountByToken(
                    SessionToken: $SessionToken
                ) {
                    AccountID,
                    Username,
                    OrganizationID,
                    OrganizationName,
                    ParentOrganizationID,
                    ParentOrganizationName,
                    RoleID,
                    RoleProjectImportantID,
                    RoleProjectRequestID,
                    RoleProjectImportantName,
                    RoleProjectRequestName,
                    OrganizationInternalName,
                    RoleName,
                    FirstName,
                    LastName,
                    Email,
                    PhoneNumber,
                    OfficeNumber,
                    OrganizationInternal,
				    Position,
                    Remark,
                    SessionToken,
                    PDPAConsentID,
                    PDPA,
                    Permissions,
                    DefaultPresetCollectionID,
                    APIKeys,
                    DLAProvinceID,
                    DLAProvinceCode,
                    DLAProvinceName
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: GETACCOUNTTOKEN,
            variables: {
                SessionToken: token
            },
            errorPolicy: "all",
        });
    },

    forgetpassword: (email, username) => {
        const FORGETPASSWORD = `
        mutation Forgetpassword (
            $Email: String!
            $Username: String!
        ) {
            Forgetpassword (
                Email: $Email
                Username: $Username      
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: FORGETPASSWORD,
            variables: {
                Email: !email ? undefined : email,
                Username: !username ? undefined : username
            },
            errorPolicy: "all",
        }, {
            timeout: 2 * 60 * 1000
        });
    },

    updateAccountPassword: (data) => {
        data = {
            AccountID: data.AccountID ?? undefined,
            Password: data.Password ? data.Password.trim() : undefined,
            ConfirmPassword: data.ConfirmPassword ? data.ConfirmPassword.trim() : undefined
        };

        const UPDATEACCOUNTPASSWIRD = `
        mutation UpdateAccountPassword (
            $AccountID: Int!
            $Password: String!
            $ConfirmPassword: String!
        ) {
            UpdateAccountPassword (
                AccountID: $AccountID
                Password: $Password
                ConfirmPassword: $ConfirmPassword
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATEACCOUNTPASSWIRD,
            variables: data,
            errorPolicy: "all",
        });
    },

    resetpassword: (data) => {
        data = {
            ResetToken: data.ResetToken ? data.ResetToken.trim() : undefined,
            OldPassword: data.OldPassword ? data.OldPassword.trim() : undefined,
            NewPassword: data.NewPassword ? data.NewPassword.trim() : undefined
        };

        const RESETPASSWORD = `
        mutation Resetpassword (
            $ResetToken: String!
            $NewPassword: String!
        ) {
            Resetpassword (
                ResetToken: $ResetToken
                NewPassword: $NewPassword
            )
        }
        `;

        return Axios.post("/twpservice", {
            query: RESETPASSWORD,
            variables: data,
            errorPolicy: "all",
        });
    },

    uploadProfilePicture: (file) => {
        let formData = new FormData();
        formData.append("File", file)

        return Axios({
            method: "post",
            url: "/upload/profilepic",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000
        });
    },
}

export default AccountService;