import React from 'react';
import {
    TextField,
    MenuItem
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    disabled: {
        backgroundColor: '#eeeeee !important'
    }
}));

// class Select extends React.Component {
//     // constructor(props) {
//     //     super(props);
//     // }

//     shouldComponentUpdate(nextProps, nextState) {
//         return (this.props.data !== nextProps.data || this.props.value !== nextProps.value || this.props.error !== nextProps.error)
//     }

//     renderOptions(data, name, index) {
//         var options = []
//         if (name) {
//             for (var i = 0; i < data.length; i++) {
//                 if (i === 100) break;
//                 var obj = data[i];
//                 options.push(<MenuItem value={index ? i : obj[name + 'ID']} key={name + obj[name + 'ID']}>{obj[name + 'Name']}</MenuItem>)
//             }
//         }
//         else {
//             for (var j = 0; j < data.length; j++) {
//                 if (j === 100) break;
//                 var object = data[j];
//                 options.push(<MenuItem value={object} key={'limit' + object}>{object}</MenuItem>)
//             }
//         }
//         return options;
//     }

//     render() {
//         return (
//             <TextField
//                 label={this.props.label}
//                 placeholder={this.props.label}
//                 variant="outlined"
//                 size="small"
//                 value={this.props.value ?? ''}
//                 name={this.props.name}
//                 onChange={(e) => this.props.setvalue(e.target.value, e.target.name)}
//                 error={this.props.error}
//                 required={this.props.required}
//                 fullWidth
//                 select
//             >
//                 {this.props.noDefault ?
//                     null
//                     :
//                     <MenuItem value=''>{'ทุก' + this.props.label}</MenuItem>
//                 }
//                 {this.renderOptions(this.props.data, this.props.dataname, this.props.index)}
//             </TextField>
//         )
//     }
// }

function renderOptions(data, name, index) {
    var options = []
    if (name && data?.length) {
        for (var i = 0; i < data.length; i++) {
            if (i === 100) break;
            var obj = data[i];
            options.push(
                <MenuItem
                    value={index ? i : obj[name + 'ID']}
                    key={name + obj[name + 'ID']}
                >
                    {obj[name + 'Name']}
                </MenuItem>)
        }
    }
    else {
        if (data?.length) {
            for (var j = 0; j < data.length; j++) {
                if (j === 100) break;
                var object = data[j];
                options.push(<MenuItem value={object} key={'limit' + object}>{object}</MenuItem>)
            }
        }
    }
    return options;
}

const Select = (props) => {
    const classes = useStyles();
    return (
        <TextField
            className={props.className ?? ''}
            label={props.label}
            placeholder={props.label}
            variant="outlined"
            size="small"
            value={props.value ?? ''}
            name={props.name}
            onChange={(e) => props.setvalue(e.target.value, e.target.name)}
            onBlur={props.onBlur}
            error={props.error}
            required={props.required}
            fullWidth
            select
            disabled={props.disabled ?? false}
            InputProps={{
                classes: {
                    disabled: classes.disabled
                }
            }}
        >
            {props.noDefault ?
                null
                :
                <MenuItem value=''>{'ทุก' + props.label}</MenuItem>
            }
            {props.noOption ?
                <MenuItem value=''>ไม่เลือก</MenuItem>
                : null
            }
            {renderOptions(props.data, props.dataname, props.index)}
        </TextField>
    )
}

export default React.memo(Select
    , (prevProps, nextProps) =>
    (
        prevProps.data === nextProps.data &&
        prevProps.value === nextProps.value &&
        prevProps.error === nextProps.error &&
        prevProps.disabled === nextProps.disabled
    )
);

// export default Select;