import React from 'react';
import {
    Box,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ModalItem = ({ show, setShow, item }) => {
    const handleClose = () => {
        setShow(false);
    };

    const month = {
        "01": "มกราคม",
        "02": "กุมภาพันธ์",
        "03": "มีนาคม",
        "04": "เมษายน",
        "05": "พฤษภาคม",
        "06": "มิถุนายน",
        "07": "กรกฎาคม",
        "08": "สิงหาคม",
        "09": "กันยายน",
        "10": "ตุลาคม",
        "11": "พฤศจิกายน",
        "12": "ธันวาคม"
    }

    const thumbnail = item.Thumbnail?.find?.(element => (element.split?.('/').pop())?.split('.')?.[0] == 'thumbnail');
    const reference = ` <div style={{ wordBreak: "break-word" }}>ที่มา : <a href="${item.Reference}" target="_blank">${item.Reference}</a></div> `

    return (
        <Dialog
            onClose={setShow}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle style={{ paddingRight: '2rem' }}>
                {item.Title}
                <Box style={{ fontSize: '14px', fontWeight: 'normal', color: 'gray' }}>
                    {
                        (item.CreatedAt?.split?.('-')?.[0] || "") + " " +
                        month[item.CreatedAt?.split?.('-')?.[1]] + " " +
                        (item.CreatedAt?.split?.('-')?.[2] || "")
                    }
                </Box>
                <IconButton aria-label="close" style={{ position: 'absolute', right: 0, top: 0 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '1rem', paddingBottom: '1.5rem' }}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <img className="img-fluid mx-auto d-block" src={((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : "") + thumbnail} alt="" />
                    </Grid>
                    <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: item.Content }} />
                    </Grid>
                    {
                        !item.Reference ? null :
                            <Grid item xs={12}>
                                <div dangerouslySetInnerHTML={{ __html: reference }} />
                            </Grid>
                    }
                    {
                        item.Thumbnail?.length > 0 ?
                            <>
                                {item.Thumbnail.filter(element => element !== thumbnail).map((image) => {
                                    return (
                                        <Grid item xs={12}>
                                            <img className="img-fluid mx-auto d-block" src={(process.env.NODE_ENV === 'development') ? 'http://localhost:9000' + image : image} alt="" />
                                        </Grid>
                                    )
                                })}
                            </> : null
                    }
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default ModalItem;