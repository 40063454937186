import React, { Component } from "react";
import CmtCard from "../../@coremat/CmtCard";
import PageContainer from "../../@jumbo/components/PageComponents/layouts/PageContainer";
import ExpandableContent from "../../@coremat/CmtCardExpendableContent";
import AutoComplete from "../../Util/Autocomplete";
import Table from '../../Util/Table';
import SplitButton from '../../Util/SplitButton';
import Select from "../../Util/Select";
import MultiSelect from "../../Util/MultiSelect";
import {
    Box,
    Button,
    Grid,
    Slide,
    Tabs,
    Tab,
    Chip
} from "@material-ui/core";
import { CmtTitle } from "../../@coremat/CmtTypography";

import {
    Search,
    Cancel,
    GetApp,
    Tune,
    Assignment,
    AccountTree,
    BarChart as BarChartIcon
} from "@material-ui/icons";
import BarChartScroll from './Component/BarChartScroll';

import CoreService from "../../Service/CoreService";
import ExportService from "../../Service/ExportService";
import Swal from "sweetalert2";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import {
    setMasterData,
    showLoading,
    hideLoading,
} from "../../redux/action";
import Utilities from "../../Util/Utilities";
import PieChart from "./Component/PirChart";
import TreeHorizontal from "./Component/TreeHorizontal";
import PrintTree from "./Component/PrintTree";

import ReportProjectImportantService from "../../Service/ReportProjectImportantService";
import ProjectImportantService from '../../Service/ProjectImportantService';
import TagService from "../../Service/TagService";
import { getLocalStorageMaster } from '../../Service/_LocalStorageService';
import {
    checkMasterProjectExist,
    checkDistrictMasterDataExist,
    serviceWrapper,
    checkMasterDataLoaded
} from '../../Util/_ManageMasterData';


const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home"
    },
    {
        label: "รายงานโครงการสำคัญ",
        isActive: true
    }
];

class ReportProjectImportant extends Component {

    state = {
        Page: 1,
        Pageprepare: 1,
        Limitprepare: 10,
        Limit: 10,
        tabStep1: 0,
        countGroup: 0,
        countProjectImportantPrepare: 0,
        loadingData: false,
        showPrint: false,
        typeTag: false,
        IsCanceled: "0",
        masterLoaded: checkMasterDataLoaded(
            this.props,
            [
                "searchTag",
                "masterProject",
                "districtMasterData"
            ]
        ) ? false : true,
    }

    budgetYearOptions = Utilities.genBudgetYearOptions();
    budgetYearOptionsObject = this.budgetYearOptions.map(obj => ({ BudgetYearID: obj, BudgetYearName: "" + obj }));

    getSearchJsonExport = () => {
        return {
            StartYear: this.state.StartYear || undefined,
            OrganizationID: this.state.OrganizationID ? [this.state.OrganizationID] : undefined,
            ProjectImportantGroupID: this.state.ProjectImportantGroupID ? this.state.ProjectImportantGroupID : undefined,
            ProjectImportantGroupSubID: this.state.ProjectImportantGroupSubID ? this.state.ProjectImportantGroupSubID : undefined,
            BudgetSourceID: this.state.BudgetSourceID ?? undefined,
            BudgetDimensionID: this.state.BudgetDimensionID || undefined,
            ProjectImportantStatusID: this.state.ProjectImportantStatusID || undefined,
            TagID: this.state.TagID ? [this.state.TagID] : undefined,
            Offset: ((this.state.Page - 1) * this.state.Limit),
            Limit: this.state.Limit,
            OffsetPrepare: ((this.state.Pageprepare - 1) * this.state.Limitprepare),
            Limitprepare: this.state.Limitprepare,
            IsCanceled: (this.state.IsCanceled === 2) ? undefined :
                (this.state.IsCanceled ? (parseInt(this.state.IsCanceled) || 0) : undefined),
        }
    }

    onClickExportProjects = () => {
        this.props.dispatch(showLoading());
        ExportService.exportProjectImportant(this.getSearchJsonExport())
            .then(res => {
                let data = res.data?.ExportProjectImportant;
                if (!data || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: res.errors?.[0]?.Message || 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                var a = document.createElement('a');
                let link = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';
                a.setAttribute('download', data.FileName);
                a.setAttribute('href', link + data.Link);
                a.setAttribute('target', '_blank');
                a.click();
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.dispatch(hideLoading());
            });
    }

    buttonOptions = [
        {
            name: <span>ส่งออกรายการ</span>,
            onClick: () => this.onClickExportProjects()
        },
        {
            name: <span>TREE: แยกตามกลุ่ม</span>,
            onClick: () => this.onClickPrint(this.state.ProjectImportantTree, this.state.totalTree, 'group')
        },
    ];

    buttonOptionsTags = [
        {
            name: <span>ส่งออกรายการ</span>,
            onClick: () => this.onClickExportProjects()
        },
        {
            name: <span>TREE: แยกตาม TAG</span>,
            onClick: () => this.onClickPrint(this.state.ProjectImportantTagTree, this.state.totalTagTree, 'tag')
        },
    ];

    buttonOptionsTagsReportTree = [
        {
            name: <span>ส่งออกรายการ</span>,
            onClick: () => this.onClickExportProjects()
        },
        {
            name: <span >TREE: แยกตามกลุ่มและ TAG</span>,
            onClick: () => this.onClickPrint(this.state.ProjectImportantTagReportTree, this.state.totalTagReportTree, 'grouptag')
        },
    ];

    buttonOptionsStatusReport = [
        {
            name: <span>ส่งออกรายการ</span>,
            onClick: () => this.onClickExportProjects()
        },
    ];

    cancelOptions = [
        {
            IsCanceled: 2,
            IsCanceledName: "แสดงทั้งหมดรวมโครงการที่ถูกยกเลิก"
        },
        {
            IsCanceled: "0",
            IsCanceledName: "โครงการในแผนงาน"
        },
        {
            IsCanceled: 1,
            IsCanceledName: "โครงการถูกยกเลิก"
        }
    ]

    componentDidMount() {
        if (!this.state.masterLoaded) {
            this.props.dispatch(showLoading());
            let dataPromise = [];
            let districtMasterData = getLocalStorageMaster("districtMasterData")
            let masterProject = getLocalStorageMaster("masterProject");

            if (checkDistrictMasterDataExist(this.props) && !districtMasterData) {
                dataPromise.push(serviceWrapper("districtMasterData", CoreService.getDistrictMasterData()));
            }
            if (checkMasterProjectExist(this.props) && !masterProject) {
                dataPromise.push(serviceWrapper("masterProject", CoreService.getMasterProject()));
            }
            if (!this.props.masterData.SearchTag) {
                dataPromise.push(TagService.searchTag({ TagID: null, TagName: null }));
            }

            if (!this.props.masterData.GetProjectImportantSignoffStatus) {
                dataPromise.push(CoreService.getAllMasterData());
            }

            Promise.all(dataPromise)
                .then((res) => {
                    if (
                        (!res[0]?.data || res[0]?.errors) ||
                        (res[1] && (!res[1].data || res[1]?.errors)) ||
                        (res[2] && (!res[2].data || res[2]?.errors)) ||
                        (res[3] && (!res[3].data || res[3]?.errors))
                    ) return;

                    let data = {}

                    if (districtMasterData) data = { ...data, ...districtMasterData };
                    if (masterProject) data = { ...data, ...masterProject };

                    for (let i = 0; i < res.length; i++) {
                        data = { ...data, ...res[i].data };
                    }

                    let GetAllStrategyWorkplan = this.setMasterWorkPlan(data.GetAllStrategyKPI);
                    if (GetAllStrategyWorkplan)
                        data.GetAllStrategyWorkplan = GetAllStrategyWorkplan;
                    let GetAllStrategyKPI = this.setMasterDataWorkPlan(data.GetAllStrategyKPI);
                    if (GetAllStrategyKPI)
                        data.GetAllStrategyKPI = GetAllStrategyKPI;
                    if (data.GetStrategy) {
                        data.StrategyOptions = this.setMasterStrategy(data.GetStrategy);
                        data.PolicyOptions = this.setMasterPolicy(data.GetStrategy);
                    }
                    this.props.dispatch(setMasterData(data));
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.props.dispatch(hideLoading());
                    this.setState({ masterLoaded: true });
                })
        }
        this.onLoadData()
    }

    onLoadData() {
        this.setState({ loadingData: true }, async () => {
            await this.SearchProjectImportantGroup();
            await this.onFetchTreeGroupdata();
            await this.onFetchPrepareData();
            await this.onFetchTreeGroupTagdata();
            await this.onFetchTagReportTree();
            this.setState({ loadingData: false });
        });
    }

    onFetchTreeGroupdata = () => {
        this.getProjectImportantGroupReport();
        this.searchProjectImportantGroupReportData();
    }

    onFetchPrepareData = () => {
        this.getProjectImportantPrepare();
        this.searchProjectImportantPrepareData();
    }

    onFetchTreeGroupTagdata = () => {
        this.getProjectImportantGroupTagReport();
        this.searchProjectImportantGroupTagReportData();
    }

    onFetchTagReportTree = () => {
        this.getProjectImportantGroupTagReportTree();
        this.searchProjectImportantGroupTagReportTreeDataMapping();
    }

    limitOptions = [10, 15, 25, 50, 100];


    actionComponent = <span className="d-flex align-items-center">
        <Tune className="mr-05" />
        <CmtTitle variant="h3" content="Filters" />
    </span>;

    setMasterWorkPlan = (strategy) => {
        if (!strategy) return;
        let workPlanData = [];
        strategy.forEach(obj => {
            if (obj.Policy?.length) {
                obj.Policy.forEach(object => {
                    if (object.WorkPlan?.length) {
                        object.WorkPlan.forEach(workplan => {
                            workPlanData.push(workplan);
                        });
                    }
                });
            }
        });
        return workPlanData;
    }

    setMasterDataWorkPlan = (strategy) => {
        if (!strategy) return;
        let workPlanData = [];
        strategy.forEach(obj => {
            if (obj.Policy?.length) {
                obj.Policy.forEach(object => {
                    if (object.WorkPlan?.length) {
                        for (let i = 0; i < object.WorkPlan.length; i++) {
                            const element = object.WorkPlan[i];
                            if (element.StrategyCode)
                                element.StrategyName = element.StrategyCode + ". " + element.StrategyName;
                        }
                        workPlanData = [...workPlanData, ...object.WorkPlan];
                    }
                })
            }
        });
        return workPlanData;
    }

    setMasterStrategy = (strategy) => {
        let Strategy = strategy.filter(x => x.Level === 1);
        for (let i = 0; i < Strategy.length; i++) {
            //const element = Strategy[i];
        }
        return Strategy;
    }

    setMasterPolicy = (strategy) => {
        let Strategy = strategy.filter(x => x.Level === 2);
        for (let i = 0; i < Strategy.length; i++) {
            const element = Strategy[i];
            element.StrategyName = element.StrategyCode + " " + element.StrategyName;
        }
        return Strategy;
    }

    onClickPrint = (data, totalCount, type) => {
        this.setState({ showPrint: true, dataPrinter: data, totalCount: totalCount, typeTag: type });
    }

    hidePrint = () => {
        this.setState({ showPrint: false });
    }

    setData = (value, prop) => {
        var error = { ...this.state.error };
        error[prop] = false;
        this.setState({ [prop]: value, error: error });
    }

    getTag = () => {
        TagService.searchTag({})
            .then(res => {
                if (!res.data?.SearchTag || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                this.setState({
                    tag: res.data.SearchTag
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
    }

    SearchProjectImportantGroup = () => {
        return ProjectImportantService.searchProjectImportantGroup({
            ProjectImportantGroupName: this.state.ProjectImportantGroupName || undefined,
            ProjectImportantGroupLevel: this.state.ProjectImportantGroupLevel || undefined
        })
            .then(res => {
                let data = res.data
                if (!data?.SearchProjectImportantGroup) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                }
                const GroupMain = data?.SearchProjectImportantGroup.filter(x => x.ProjectImportantGroupLevel === 1)
                const GroupSub = data?.SearchProjectImportantGroup.filter(x => x.ProjectImportantGroupLevel === 2)
                this.setState({ ProjectImportantGroup: GroupMain, ProjectImportantGroupSub: GroupSub })
            }).catch(e => {
                this.setState({
                    ProjectImportantGroup: [],
                });
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
    }

    clearSearch = () => {
        this.setState({
            StartYear: "",
            OrganizationID: "",
            ProjectImportantGroupID: "",
            ProjectImportantGroupSubID: null,
            BudgetSourceID: null,
            BudgetDimensionID: "",
            TagID: "",
            ProjectImportantStatusID: null,
            IsCanceled: "0",
        });
    }

    getSearchJson = () => {
        return {
            StartYear: this.state.StartYear || undefined,
            OrganizationID: this.state.OrganizationID ? [this.state.OrganizationID] : undefined,
            ProjectImportantGroupID: this.state.ProjectImportantGroupID ? [this.state.ProjectImportantGroupID] : undefined,
            ProjectImportantGroupSubID: this.state.ProjectImportantGroupSubID ? [this.state.ProjectImportantGroupSubID] : undefined,
            BudgetSourceID: this.state.BudgetSourceID ?? undefined,
            BudgetDimensionID: this.state.BudgetDimensionID || undefined,
            TagID: this.state.TagID ? [this.state.TagID] : undefined,
            ProjectImportantStatusID: this.state.ProjectImportantStatusID || undefined,
            Offset: ((this.state.Page - 1) * this.state.Limit),
            Limit: this.state.Limit,
            OffsetPrepare: ((this.state.Pageprepare - 1) * this.state.Limitprepare),
            Limitprepare: this.state.Limitprepare,
            IsCanceled: (this.state.IsCanceled === 2) ? undefined :
                (this.state.IsCanceled ? (parseInt(this.state.IsCanceled) || 0) : undefined),
        }
    }

    getProjectImportantGroupReport = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.GetProjectImportantGroupReport(this.getSearchJson())
                .then(res => {
                    if (res.error || !res.data?.GetProjectImportantGroupReport) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    //let data = res.data?.GetProjectImportantGroupReport
                    this.setState({
                        ProjectImportantTree: res.data?.GetProjectImportantGroupReport.data,
                        totalTree: res.data?.GetProjectImportantGroupReport.total,
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    getProjectImportantPrepare = () => {
        this.setState({ Page: 1, loadingData: false, selected: [] }, () => {
            ReportProjectImportantService.GetProjectImportantPrepare(this.getSearchJson())
                .then(res => {
                    if (res.error || !res.data?.GetProjectImportantPrepare) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    let dataPrepareChart = [];
                    let data = res.data?.GetProjectImportantPrepare
                    let tableData = [];
                    for (let index = 0; index < data?.data?.length; index++) {
                        const element = data?.data[index];
                        let ParentProjectImportantPrepare = element.ProjectImportantPrepare
                        let dataPrepare = ParentProjectImportantPrepare.map(row => [row.ProjectImportantPrepareName, row.ProjectCount]);
                        tableData = dataPrepare?.map(x => ({ name: x[0], count: x[1] }))
                        dataPrepareChart = [
                            {
                                type: "column",
                                name: 'ด้านโครงการ',
                                color: "#1776c3",
                                data: dataPrepare
                            }
                        ]
                    }
                    this.setState({
                        ProjectImportantPrepare: res.data?.GetProjectImportantPrepare?.data,
                        totalPrepare: res.data?.GetProjectImportantPrepare?.total,
                        dataPrepareChart: dataPrepareChart,
                        tableDataPrepare: tableData
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    searchProjectImportantGroupReportData = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupReportData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupReportData
                    if (res.error || data?.SearchProjectImportantGroupReportData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableGroup: data.ProjectImportant
                        , countGroup: data.Count
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    searchProjectImportantPrepareData = () => {
        this.setState({ Pageprepare: 1, loadingData: false, selected: [] }, () => {
            ReportProjectImportantService.SearchProjectImportantPrepareData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantPrepareData
                    if (res.error || data?.SearchProjectImportantPrepareData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableProjectImportant: data.ProjectImportant,
                        countProjectImportantPrepare: data.Count
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    getProjectImportantGroupTagReport = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.GetProjectImportantGroupTagReport(this.getSearchJson())
                .then(res => {
                    let data = res.data?.GetProjectImportantGroupTagReport
                    if (res.error || !data) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        ProjectImportantTagTree: data.data,
                        totalTagTree: data.total,
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    searchProjectImportantGroupTagReportData = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupTagReportData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupTagReportData
                    if (res.error || data?.SearchProjectImportantGroupTagReportData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableTagGroup: data.ProjectImportant,
                        countTagGroup: data.Count
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    getProjectImportantGroupTagReportTree = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.GetProjectImportantGroupTagReportTree(this.getSearchJson())
                .then(res => {
                    let data = res.data?.GetProjectImportantGroupTagReportTree
                    if (res.error || !data) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        ProjectImportantTagReportTree: data.data,
                        totalTagReportTree: data.total,
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    //mapping group and tag
    searchProjectImportantGroupTagReportTreeDataMapping = () => {
        this.setState({ Page: 1, selected: [] }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupTagReportTreeDataMapping(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupTagReportTreeData
                    if (res.error || data?.SearchProjectImportantGroupTagReportTreeData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableGroupTreeMapping: data.ProjectImportant,
                        countTagGroupTreeMapping: data.Count
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    setDataDillDownTree = (ProjectImportantGroupID, ProjectImportantGroupSubID, year, typeTag, tagID) => {
        switch (typeTag) {
            case 'group':
                this.setState({
                    loadingData: true,
                    StartYear: year ? [year] : null,
                    ProjectImportantGroupID: ProjectImportantGroupID ?? null,
                    ProjectImportantGroupSubID: ProjectImportantGroupSubID ?? null,
                }, async () => {
                    await this.onFetchTreeGroupdata();
                    this.setState({ loadingData: false });
                });
                break;

            case 'tag':
                this.setState({
                    loadingData: true,
                    StartYear: year ? [year] : null,
                    TagID: tagID ?? null,
                }, async () => {
                    await this.onFetchTreeGroupTagdata();
                    this.setState({ loadingData: false });
                })
                break;

            case 'grouptag':
                this.setState({
                    loadingData: true,
                    ProjectImportantGroupID: ProjectImportantGroupID ?? null,
                    ProjectImportantGroupSubID: ProjectImportantGroupSubID ?? null,
                    StartYear: year ? [year] : null,
                    TagID: tagID ?? null,
                }, async () => {
                    await this.onFetchTagReportTree();
                    this.setState({ loadingData: false });
                })
                break;

            default:
                break;
        }

    }

    setLimit = (limit) => {
        var currRecord = ((this.state.Page - 1) * this.state.Limit);
        this.setState({ Limit: limit });
        var page = Math.floor(currRecord / limit) + 1;
        this.onChangePage(page);
    }

    setLimitPrepare = (limit) => {
        var currRecord = ((this.state.Pageprepare - 1) * this.state.Limitprepare);
        this.setState({ Limitprepare: limit });
        var page = Math.floor(currRecord / limit) + 1;
        this.onChangePagePrepare(page);
    }

    onChangePage = (page) => {
        this.setState({ Page: page, loadingData: true }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupReportData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupReportData
                    if (res.error || data?.SearchProjectImportantGroupReportData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    let headerDataTreeGroup = [
                        { name: 'ลำดับความสำคัญ', type: 'no', align: "left" },
                        { name: 'ชื่อโครงการ', type: 'text', align: "left", dataName: 'ProjectImportantName' },
                        { name: 'กลุ่มหลัก', type: 'text', align: "left", dataName: 'MainGroupName' },
                        { name: 'กลุ่มยย่อย', type: 'text', align: "left", dataName: 'SubGroupName' },
                        { name: 'ปี', type: 'text', align: "left", dataName: 'BudgetYear' },
                    ];
                    this.setState({
                        dataTableGroup: data.ProjectImportant,
                        countGroup: data.Count,
                        headerDataTreeGroup: headerDataTreeGroup,
                        loadingData: false
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        });
    }

    onChangePageTag = (page) => {
        this.setState({ Page: page, loadingData: true }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupTagReportData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupTagReportData
                    if (res.error || data?.SearchProjectImportantGroupTagReportData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableTagGroup: data.ProjectImportant,
                        countTagGroup: data.Count,
                        loadingData: false
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        });
    }

    onChangePageGroupTreeMapping = (page) => {
        this.setState({ Page: page, loadingData: true }, () => {
            ReportProjectImportantService.SearchProjectImportantGroupTagReportTreeDataMapping(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantGroupTagReportTreeData
                    if (res.error || data?.SearchProjectImportantGroupTagReportTreeData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableGroupTreeMapping: data.ProjectImportant,
                        countTagGroupTreeMapping: data.Count,
                        loadingData: false
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        });
    }

    onChangePagePrepare = (page) => {
        this.setState({ Pageprepare: page, loadingData: true }, () => {
            ReportProjectImportantService.SearchProjectImportantPrepareData(this.getSearchJson())
                .then(res => {
                    let data = res.data?.SearchProjectImportantPrepareData
                    if (res.error || data?.SearchProjectImportantPrepareData) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    this.setState({
                        dataTableProjectImportant: data.ProjectImportant,
                        countProjectImportantPrepare: data.Count,
                        loadingData: false
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        });
    }

    onChangeTab = (e, tab) => {
        let page = this.state.Page;
        let limit = this.state.Limit;
        if (tab !== this.state.tabStep1) {
            page = 1;
            limit = 10;
            this.clearSearch()
        }
        this.setState({ tabStep1: tab, Page: page, Limit: limit });
    }

    onClickDetail = (ProjectID) => {
        this.props.history.push(`/projectimportant/projectdetail/${ProjectID}`);
    }

    headerDataTreeGroup = [
        { name: 'ชื่อโครงการสำคัญ', type: 'text', align: "left", dataName: 'ProjectImportantName' },
        { name: 'หน่วยงานหลัก', type: 'text', align: "left", dataName: 'OrganizationName' },
        { name: 'ปีเริ่ม', type: 'text', align: "left", dataName: 'OperationStartYear' },
        { name: 'วงเงินรวม (ล้านบาท)', type: 'number', align: "left", dataName: 'RequestAmount' },
        { name: 'กลุ่มหลัก', type: 'text', align: "left", dataName: 'MainGroupName' },
        { name: 'กลุ่มย่อย', type: 'text', align: "left", dataName: 'SubGroupName' },
        { name: 'รายละเอียด', type: 'button', align: "center", icon: <Assignment />, dataName: 'TProjectImportantID', click: this.onClickDetail },
    ];

    headerDataTreeTagGroup = [
        { name: 'ชื่อโครงการสำคัญ', type: 'text', align: "left", dataName: 'ProjectImportantName' },
        { name: 'หน่วยงานหลัก', type: 'text', align: "left", dataName: 'OrganizationName' },
        { name: 'ปีเริ่ม', type: 'text', align: "left", dataName: 'OperationStartYear' },
        { name: 'วงเงินรวม (ล้านบาท)', type: 'number', align: "left", dataName: 'RequestAmount' },
        { name: 'Tag', type: 'text', align: "left", dataName: 'TagName' },
        { name: 'กลุ่มหลัก', type: 'text', align: "left", dataName: 'MainGroupName' },
        { name: 'กลุ่มย่อย', type: 'text', align: "left", dataName: 'SubGroupName' },
        { name: 'รายละเอียด', type: 'button', align: "center", icon: <Assignment />, dataName: 'TProjectImportantID', click: this.onClickDetail },
    ];

    renderTab = () => {
        return (
            <div>
                <Box mt={4}>
                    <Box
                        display="inline-block"
                        borderRadius="4px 4px 0 0"
                        style={{
                            backgroundColor: "white",
                            boxShadow:
                                "0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%)",
                        }}
                    >
                        <Tabs
                            value={this.state.tabStep1}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.onChangeTab}
                        >
                            <Tab
                                label={
                                    <Box display="flex" alignContent="center">
                                        1. สรุป TREE: แยกตามกลุ่มและ Tag
                                    </Box>
                                }
                                style={{
                                    boxShadow:
                                        this.state.tabStep1 === 0
                                            ? "0px 0px 14px 0px #007AEE40"
                                            : "",
                                    transition: "all 0.45s",
                                }}
                            />
                            <Tab
                                label={
                                    <Box display="flex" alignContent="center">
                                        2. สรุป TREE: แยกตามกลุ่ม
                                    </Box>
                                }
                                style={{
                                    borderRight: "1px solid 007AEE50",
                                    boxShadow:
                                        this.state.tabStep1 === 1
                                            ? "2px 0px 7px 0px #007AEE40"
                                            : "",
                                    transition: "all 0.45s",
                                }}
                            />
                            <Tab
                                label={
                                    <Box display="flex" alignContent="center">
                                        3. สรุป TREE: แยกตาม Tag
                                    </Box>
                                }
                                style={{
                                    boxShadow:
                                        this.state.tabStep1 === 2
                                            ? "0px 0px 14px 0px #007AEE40"
                                            : "",
                                    transition: "all 0.45s",
                                }}
                            />
                            <Tab
                                label={
                                    <Box display="flex" alignContent="center">
                                        4. สรุป CHART: แยกตามสถานะความพร้อม
                                    </Box>
                                }
                                style={{
                                    boxShadow:
                                        this.state.tabStep1 === 3
                                            ? "0px 0px 14px 0px #007AEE40"
                                            : "",
                                    transition: "all 0.45s",
                                    maxWidth: 300
                                }}
                            />

                        </Tabs>
                    </Box>
                </Box>
                <CmtCard
                    style={{
                        borderRadius: "0 4px 4px 4px",
                    }}
                >
                    <Box mt={2} padding={4} pt={0}>
                        {this.renderTabTagReportTree()}
                        {this.renderTabOne()}
                        {this.renderTabStatus()}
                        {this.renderTabTag()}
                    </Box>
                </CmtCard>
            </div>
        );
    };

    setSelectedYear = (value, prop) => {
        let state;
        if (prop === 'StartYear' && value > this.state.EndYear) {
            state = {
                [prop]: value,
                EndYear: value
            }
        } else if (prop === 'EndYear' && value < this.state.StartYear) {
            state = {
                [prop]: value,
                StartYear: value
            }
        } else {
            state = {
                [prop]: value,
            }
        }
        this.setState(state)
    }

    findNameObjectData = (array, condition, dataName) => {
        let nameObject = array.find(condition);
        return nameObject ? nameObject[dataName] : "";
    }

    displayDillDownComponent = (type) => {
        return (
            <div>
                {
                    this.state.ProjectImportantGroupID ?
                        <Chip
                            label={'กลุ่มหลัก ' + this.findNameObjectData(this.state.ProjectImportantGroup, (x => x.ProjectImportantGroupID === this.state.ProjectImportantGroupID), "ProjectImportantGroupName")}
                            onDelete={() => this.setDataDillDownTree(null, null, null, type)}
                            className="mr-03"
                        />
                        : null
                }
                {
                    this.state.ProjectImportantGroupSubID ?
                        <Chip
                            label={'กลุ่มย่อย ' + this.findNameObjectData(this.state.ProjectImportantGroupSub, (x => x.ProjectImportantGroupID === this.state.ProjectImportantGroupSubID), "ProjectImportantGroupName")}
                            onDelete={() => this.setDataDillDownTree(this.state.ProjectImportantGroupID, null, null, type)}
                            className="mr-03"
                        />
                        : null
                }
                {
                    this.state.TagID ?
                        <Chip
                            label={'Tag ' + this.findNameObjectData(this.props.masterData?.SearchTag, (x => x.TagID === this.state.TagID), 'TagName')}
                            onDelete={() => this.setDataDillDownTree(this.state.ProjectImportantGroupID, this.state.ProjectImportantGroupSubID, null, type)}
                            className="mr-03"
                        />
                        : null
                }
                {
                    this.state.StartYear ?
                        <Chip
                            label={'ปี ' + this.state.StartYear}
                            onDelete={() => this.setDataDillDownTree(this.state.ProjectImportantGroupID, this.state.ProjectImportantGroupSubID, null, type, this.state.TagID)}
                        />
                        : null
                }
            </div>
        )
    }

    renderTabTagReportTree = () => {
        return (
            <Slide in={this.state.tabStep1 === 0} direction="left">
                <div className={this.state.tabStep1 === 0 ? "d-block" : "d-none"}>
                    <Grid item xs={12} className="section-header main">
                        <div className="d-flex align-items-center">
                            <AccountTree className="mr-05" />
                            การสรุปรายงานแบบ Group Tag Tree
                        </div>
                        <hr />
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {this.displayDillDownComponent('grouptag')}
                        <SplitButton
                            variant='contained'
                            color='primary'
                            text='ส่งออกรายการ'
                            icon={<GetApp className='mr-03' />}
                            options={this.buttonOptionsTagsReportTree}
                        />
                    </Box>
                    <Grid container spacing={6}>
                        {this.state.pieChart ? this.state.pieChart.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <div style={{ fontWeight: "700", textTransform: "capitalize" }}>
                                    <PieChart
                                        data={item.data ?? []}
                                        typeName={"ลักษณะงาน"}
                                        unit="ลบ."
                                        title={item.title}
                                    />
                                </div>
                            </Grid>
                        )) : null
                        }
                        <br />
                        <Grid item xs={12}>
                            <TreeHorizontal setDataDillDownTree={this.setDataDillDownTree} ProjectImportantTree={this.state.ProjectImportantTagReportTree} total={this.state.totalTagReportTree} type={'grouptag'} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center" style={{ margin: '1rem 0' }}>
                                <div>
                                    <Select
                                        label="จำนวน"
                                        value={this.state.Limit}
                                        setvalue={this.setLimit}
                                        data={this.limitOptions}
                                        noDefault
                                    >
                                    </Select>
                                </div>
                            </div>
                            <Table
                                tableHeaderColor="primary"
                                unit=''
                                tableData={this.state.dataTableGroupTreeMapping ?? []}
                                tableHead={this.headerDataTreeTagGroup}
                                page={this.state.Page}
                                dataCount={this.state.countTagGroupTreeMapping}
                                onChangePage={this.onChangePageGroupTreeMapping}
                                limit={this.state.Limit}
                                pagination
                            />
                            <div className="loadingBackdrop" style={{ opacity: ((this.state.loadingData) ? '1' : '0') }}>
                                <CircularProgress color="inherit" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slide>
        )
    }

    renderTabOne = () => {
        return (
            <Slide in={this.state.tabStep1 === 1} direction="left">
                <div className={this.state.tabStep1 === 1 ? "d-block" : "d-none"}>
                    <Grid item xs={12} className="section-header main">
                        <div className="d-flex align-items-center">
                            <AccountTree className="mr-05" />
                            การสรุปรายงานแบบ Group Tree
                        </div>
                        <hr />
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {this.displayDillDownComponent('group')}
                        <SplitButton
                            variant='contained'
                            color='primary'
                            text='ส่งออกรายการ'
                            icon={<GetApp className='mr-03' />}
                            options={this.buttonOptions}
                        />
                    </Box>
                    <Grid container spacing={6}>
                        {this.state.pieChart ? this.state.pieChart.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <div style={{ fontWeight: "700", textTransform: "capitalize" }}>
                                    <PieChart
                                        data={item.data ?? []}
                                        typeName={"ลักษณะงาน"}
                                        unit="ลบ."
                                        title={item.title}
                                    />
                                </div>
                            </Grid>
                        )) : null
                        }
                        <br />
                        <Grid item xs={12}>
                            <TreeHorizontal setDataDillDownTree={this.setDataDillDownTree} ProjectImportantTree={this.state.ProjectImportantTree} total={this.state.totalTree} type="group" />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center" style={{ margin: '1rem 0' }}>
                                <div>
                                    <Select
                                        label="จำนวน"
                                        value={this.state.Limit}
                                        setvalue={this.setLimit}
                                        data={this.limitOptions}
                                        noDefault
                                    >
                                    </Select>
                                </div>
                            </div>
                            <Table
                                tableHeaderColor="primary"
                                unit=''
                                tableData={this.state.dataTableGroup ?? []}
                                tableHead={this.headerDataTreeGroup}
                                page={this.state.Page}
                                dataCount={this.state.countGroup}
                                onChangePage={this.onChangePage}
                                limit={this.state.Limit}
                                pagination
                            />
                            <div className="loadingBackdrop" style={{ opacity: ((this.state.loadingData) ? '1' : '0') }}>
                                <CircularProgress color="inherit" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slide>
        )
    }

    statusTableHeader = [
        { name: 'ชื่อโครงการ', type: 'text', align: "left", dataName: 'ProjectImportantName' },
        { name: 'หน่วยงานหลัก', type: 'text', align: "left", dataName: 'OrganizationName' },
        { name: 'ปีเริ่ม', type: 'text', align: "left", dataName: 'OperationStartYear' },
        { name: 'วงเงินรวม (ล้านบาท)', type: 'text', align: "left", dataName: 'RequestAmount' },
        { name: 'สถานะเตรียมการ', type: 'text', align: "left", dataName: 'PrepareStatusName' },
        { name: 'รายละเอียด', type: 'button', align: "center", icon: <Assignment />, dataName: 'TProjectImportantID', click: this.onClickDetail },
    ];



    renderTabTag = () => {
        return (
            <Slide in={this.state.tabStep1 === 2} direction="left">
                <div className={this.state.tabStep1 === 2 ? "d-block" : "d-none"}>
                    <Grid item xs={12} className="section-header main">
                        <div className="d-flex align-items-center">
                            <AccountTree className="mr-05" />
                            การสรุปรายงานแบบ Group Tag Tree
                        </div>
                        <hr />
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {this.displayDillDownComponent('tag')}
                        <SplitButton
                            variant='contained'
                            color='primary'
                            text='ส่งออกรายการ'
                            icon={<GetApp className='mr-03' />}
                            options={this.buttonOptionsTags}
                        />
                    </Box>
                    <Grid container spacing={6}>
                        {this.state.pieChart ? this.state.pieChart.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <div style={{ fontWeight: "700", textTransform: "capitalize" }}>
                                    <PieChart
                                        data={item.data ?? []}
                                        typeName={"ลักษณะงาน"}
                                        unit="ลบ."
                                        title={item.title}
                                    />
                                </div>
                            </Grid>
                        )) : null
                        }
                        <br />
                        <Grid item xs={12}>
                            <TreeHorizontal setDataDillDownTree={this.setDataDillDownTree} ProjectImportantTree={this.state.ProjectImportantTagTree} total={this.state.totalTagTree} type={'tag'} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center" style={{ margin: '1rem 0' }}>
                                <div>
                                    <Select
                                        label="จำนวน"
                                        value={this.state.Limit}
                                        setvalue={this.setLimit}
                                        data={this.limitOptions}
                                        noDefault
                                    >
                                    </Select>
                                </div>
                            </div>
                            <Table
                                tableHeaderColor="primary"
                                unit=''
                                tableData={this.state.dataTableTagGroup ?? []}
                                tableHead={this.headerDataTreeTagGroup}
                                page={this.state.Page}
                                dataCount={this.state.countTagGroup}
                                onChangePage={this.onChangePageTag}
                                limit={this.state.Limit}
                                pagination
                            />
                            <div className="loadingBackdrop" style={{ opacity: ((this.state.loadingData) ? '1' : '0') }}>
                                <CircularProgress color="inherit" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slide>
        )
    }

    renderTabStatus = () => {
        return (
            <Slide in={this.state.tabStep1 === 3} direction="left">
                <div className={this.state.tabStep1 === 3 ? "d-block" : "d-none"}>
                    <Grid item xs={12} className="section-header main">
                        <div className="d-flex align-items-center">
                            <BarChartIcon className="mr-05" />
                            รายงานแยกตามสถานะการเตรียมความพร้อม
                        </div>
                        <hr />
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <SplitButton
                            variant='contained'
                            color='primary'
                            text='ส่งออกรายการ'
                            icon={<GetApp className='mr-03' />}
                            options={this.buttonOptionsStatusReport}
                        />
                    </Box>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <BarChartScroll
                                data={this.state.dataPrepareChart ?? []}
                                typeName=""
                                unit=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center" style={{ margin: '1rem 0' }}>
                                <div>
                                    <Select
                                        label="จำนวน"
                                        value={this.state.Limitprepare}
                                        setvalue={this.setLimitPrepare}
                                        data={this.limitOptions}
                                        noDefault
                                    >
                                    </Select>
                                </div>
                            </div>
                            <Table
                                tableHeaderColor="primary"
                                unit=''
                                tableData={this.state.dataTableProjectImportant}
                                tableHead={this.statusTableHeader}
                                page={this.state.Pageprepare}
                                dataCount={this.state.countProjectImportantPrepare}
                                onChangePage={this.onChangePagePrepare}
                                limit={this.state.Limitprepare}
                                pagination
                            />
                            <div className="loadingBackdrop" style={{ opacity: ((this.state.loadingData) ? '1' : '0') }}>
                                <CircularProgress color="inherit" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slide>
        )
    }




    renderFilterTab1 = () => {
        return (
            <div className={this.state.tabStep1 === 1 ? "d-block" : "d-none"}>
                <CmtCard>
                    <ExpandableContent actionsComponent={this.actionComponent} defaultExpanded={true}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="ปีเริ่ม"
                                    name='StartYear'
                                    dataname='BudgetYear'
                                    dataobjectname="BudgetYearID"
                                    value={this.state.StartYear ?? ""}
                                    setvalue={this.setData}
                                    data={this.budgetYearOptionsObject}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="หน่วยงานหลัก"
                                    value={this.state.OrganizationID ?? ''}
                                    dataname='Organization'
                                    name='OrganizationID'
                                    dataobjectname="OrganizationID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.FilterOrganizationImportantOption ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มหลัก"
                                    value={this.state.ProjectImportantGroupID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroup ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มย่อย"
                                    value={this.state.ProjectImportantGroupSubID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupSubID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroupSub ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="แหล่งที่มางบประมาณ"
                                    dataname='BudgetSource'
                                    name='BudgetSourceID'
                                    dataobjectname="BudgetSourceID"
                                    value={this.state.BudgetSourceID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetSource ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="มิติงบประมาณ"
                                    dataname='BudgetDimension'
                                    name='BudgetDimensionID'
                                    dataobjectname="BudgetDimensionID"
                                    value={this.state.BudgetDimensionID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetDimension ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="สถานะโครงการ"
                                    dataname='ProjectImportantStatus'
                                    name='ProjectImportantStatusID'
                                    dataobjectname="ProjectImportantStatusID"
                                    value={this.state.ProjectImportantStatusID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetProjectImportantSignoffStatus ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="สถานะการยกเลิก"
                                    value={this.state.IsCanceled}
                                    name='IsCanceled'
                                    dataname="IsCanceled"
                                    dataobjectname="IsCanceled"
                                    setvalue={this.setData}
                                    data={this.cancelOptions ?? []}
                                />
                            </Grid>
                        </Grid>
                        <div className="d-flex mt-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="ml-auto"
                                onClick={this.onFetchTreeGroupdata}
                                size="small"
                            >
                                <Search className="mr-03" />
                                ค้นหา
                            </Button>
                            <Button
                                className="ml-10"
                                variant='contained'
                                onClick={this.clearSearch}
                                size="small"
                            >
                                <Cancel className="mr-03" />
                                ล้างค่า
                            </Button>
                        </div>
                    </ExpandableContent>
                </CmtCard>
            </div>
        )
    }

    renderFilterStatus = () => {
        return (
            <div className={this.state.tabStep1 === 3 ? "d-block" : "d-none"}>
                <CmtCard>
                    <ExpandableContent actionsComponent={this.actionComponent} defaultExpanded={true}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="ปีเริ่ม"
                                    name='StartYear'
                                    dataname='BudgetYear'
                                    dataobjectname="BudgetYearID"
                                    value={this.state.StartYear ?? ""}
                                    setvalue={this.setData}
                                    data={this.budgetYearOptionsObject}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="หน่วยงานหลัก"
                                    value={this.state.OrganizationID ?? ''}
                                    dataname='Organization'
                                    name='OrganizationID'
                                    dataobjectname="OrganizationID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.SearchOrganization ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มหลัก"
                                    value={this.state.ProjectImportantGroupID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroup ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มย่อย"
                                    value={this.state.ProjectImportantGroupSubID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupSubID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroupSub ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="แหล่งที่มางบประมาณ"
                                    dataname='BudgetSource'
                                    name='BudgetSourceID'
                                    dataobjectname="BudgetSourceID"
                                    value={this.state.BudgetSourceID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetSource ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="มิติงบประมาณ"
                                    dataname='BudgetDimension'
                                    name='BudgetDimensionID'
                                    dataobjectname="BudgetDimensionID"
                                    value={this.state.BudgetDimensionID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetDimension ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="สถานะโครงการ"
                                    dataname='ProjectImportantStatus'
                                    name='ProjectImportantStatusID'
                                    dataobjectname="ProjectImportantStatusID"
                                    value={this.state.ProjectImportantStatusID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetProjectImportantSignoffStatus ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="สถานะการยกเลิก"
                                    value={this.state.IsCanceled}
                                    name='IsCanceled'
                                    dataname="IsCanceled"
                                    dataobjectname="IsCanceled"
                                    setvalue={this.setData}
                                    data={this.cancelOptions ?? []}
                                />
                            </Grid>
                        </Grid>
                        <div className="d-flex mt-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="ml-auto"
                                onClick={() => {
                                    this.getProjectImportantPrepare()
                                    this.searchProjectImportantPrepareData()
                                }}
                                size="small"
                            >
                                <Search className="mr-03" />
                                ค้นหา
                            </Button>
                            <Button
                                className="ml-10"
                                variant='contained'
                                onClick={this.clearSearch}
                                size="small"
                            >
                                <Cancel className="mr-03" />
                                ล้างค่า
                            </Button>
                        </div>
                    </ExpandableContent>
                </CmtCard>
            </div>
        )
    }

    renderFilterTabTag = () => {
        return (
            <div className={this.state.tabStep1 === 2 ? "d-block" : "d-none"}>
                <CmtCard>
                    <ExpandableContent actionsComponent={this.actionComponent} defaultExpanded={true}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="ปีเริ่ม"
                                    name='StartYear'
                                    dataname='BudgetYear'
                                    dataobjectname="BudgetYearID"
                                    value={this.state.StartYear ?? ""}
                                    setvalue={this.setData}
                                    data={this.budgetYearOptionsObject}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="หน่วยงานหลัก"
                                    value={this.state.OrganizationID ?? ''}
                                    dataname='Organization'
                                    name='OrganizationID'
                                    dataobjectname="OrganizationID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.SearchOrganization ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มหลัก"
                                    value={this.state.ProjectImportantGroupID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroup ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มย่อย"
                                    value={this.state.ProjectImportantGroupSubID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupSubID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroupSub ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="Tag"
                                    value={this.state.TagID ?? ''}
                                    dataname='Tag'
                                    name='TagID'
                                    dataobjectname="TagID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.SearchTag ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="แหล่งที่มางบประมาณ"
                                    dataname='BudgetSource'
                                    name='BudgetSourceID'
                                    dataobjectname="BudgetSourceID"
                                    value={this.state.BudgetSourceID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetSource ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="มิติงบประมาณ"
                                    dataname='BudgetDimension'
                                    name='BudgetDimensionID'
                                    dataobjectname="BudgetDimensionID"
                                    value={this.state.BudgetDimensionID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetDimension ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="สถานะโครงการ"
                                    dataname='ProjectImportantStatus'
                                    name='ProjectImportantStatusID'
                                    dataobjectname="ProjectImportantStatusID"
                                    value={this.state.ProjectImportantStatusID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetProjectImportantSignoffStatus ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="สถานะการยกเลิก"
                                    value={this.state.IsCanceled}
                                    name='IsCanceled'
                                    dataname="IsCanceled"
                                    dataobjectname="IsCanceled"
                                    setvalue={this.setData}
                                    data={this.cancelOptions ?? []}
                                />
                            </Grid>
                        </Grid>
                        <div className="d-flex mt-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="ml-auto"
                                onClick={() => {
                                    this.getProjectImportantGroupTagReport()
                                    this.searchProjectImportantGroupTagReportData()
                                }}
                                size="small"
                            >
                                <Search className="mr-03" />
                                ค้นหา
                            </Button>
                            <Button
                                className="ml-10"
                                variant='contained'
                                onClick={this.clearSearch}
                                size="small"
                            >
                                <Cancel className="mr-03" />
                                ล้างค่า
                            </Button>
                        </div>
                    </ExpandableContent>
                </CmtCard>
            </div>
        )
    }

    renderFilterTabTREEGROUPANDTAG = () => {
        return (
            <div className={this.state.tabStep1 === 0 ? "d-block" : "d-none"}>
                <CmtCard>
                    <ExpandableContent actionsComponent={this.actionComponent} defaultExpanded={true}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="ปีเริ่ม"
                                    name='StartYear'
                                    dataname='BudgetYear'
                                    dataobjectname="BudgetYearID"
                                    value={this.state.StartYear ?? ""}
                                    setvalue={this.setData}
                                    data={this.budgetYearOptionsObject}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="หน่วยงานหลัก"
                                    value={this.state.OrganizationID ?? ''}
                                    dataname='Organization'
                                    name='OrganizationID'
                                    dataobjectname="OrganizationID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.SearchOrganization ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มหลัก"
                                    value={this.state.ProjectImportantGroupID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroup ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="จัดกลุ่มย่อย"
                                    value={this.state.ProjectImportantGroupSubID ?? ''}
                                    dataname='ProjectImportantGroup'
                                    name='ProjectImportantGroupSubID'
                                    dataobjectname="ProjectImportantGroupID"
                                    setvalue={this.setData}
                                    data={this.state.ProjectImportantGroupSub ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="Tag"
                                    value={this.state.TagID ?? ''}
                                    dataname='Tag'
                                    name='TagID'
                                    dataobjectname="TagID"
                                    setvalue={this.setData}
                                    data={this.props.masterData?.SearchTag ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="แหล่งที่มางบประมาณ"
                                    dataname='BudgetSource'
                                    name='BudgetSourceID'
                                    dataobjectname="BudgetSourceID"
                                    value={this.state.BudgetSourceID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetSource ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="มิติงบประมาณ"
                                    dataname='BudgetDimension'
                                    name='BudgetDimensionID'
                                    dataobjectname="BudgetDimensionID"
                                    value={this.state.BudgetDimensionID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetBudgetDimension ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <MultiSelect
                                    label="สถานะโครงการ"
                                    dataname='ProjectImportantStatus'
                                    name='ProjectImportantStatusID'
                                    dataobjectname="ProjectImportantStatusID"
                                    value={this.state.ProjectImportantStatusID ?? ""}
                                    setvalue={this.setData}
                                    data={this.props.masterData?.GetProjectImportantSignoffStatus ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <AutoComplete
                                    label="สถานะการยกเลิก"
                                    value={this.state.IsCanceled}
                                    name='IsCanceled'
                                    dataname="IsCanceled"
                                    dataobjectname="IsCanceled"
                                    setvalue={this.setData}
                                    data={this.cancelOptions ?? []}
                                />
                            </Grid>
                        </Grid>
                        <div className="d-flex mt-10">
                            <Button
                                variant="contained"
                                color="primary"
                                className="ml-auto"
                                onClick={() => {
                                    this.onFetchTagReportTree()
                                }}
                                size="small"
                            >
                                <Search className="mr-03" />
                                ค้นหา
                            </Button>
                            <Button
                                className="ml-10"
                                variant='contained'
                                onClick={this.clearSearch}
                                size="small"
                            >
                                <Cancel className="mr-03" />
                                ล้างค่า
                            </Button>
                        </div>
                    </ExpandableContent>
                </CmtCard>
            </div>
        )
    }

    render() {
        return (
            <>
                <PageContainer
                    heading="รายงานสรุปแผนงานโครงการโครงการสำคัญ"
                    breadcrumbs={location}
                >
                    {this.renderFilterTabTREEGROUPANDTAG()}
                    {this.renderFilterTab1()}
                    {this.renderFilterTabTag()}
                    {this.renderFilterStatus()}
                </PageContainer>
                {this.renderTab()}
                {
                    this.state.showPrint ?
                        <PrintTree
                            data={this.state.dataPrinter}
                            total={this.state.totalCount}
                            show={this.state.showPrint}
                            hide={this.hidePrint}
                            type={this.state.typeTag}
                        />
                        : null
                }
            </>
        );
    }
}
const mapStatetoProps = (state) => {
    return {
        masterData: state.masterData,
        filterData: state.filterData
    }
}

export default connect(mapStatetoProps)(ReportProjectImportant);
