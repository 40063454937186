import Axios from "./_BaseService";
const timeout = 300000;

const ExportService = {
    exportExcel001: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffDLAOrganizationID: data.SignoffDLAOrganizationID || undefined,
            SignoffOperatorOrganizationID: data.SignoffOperatorOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            ProjectTypeAmount: data.Type ?? undefined,
            IsCashflow: data.IsCashflow || undefined,
            CashflowEndYear: data.CashflowEndYear || undefined,
            CashflowStartYear: data.CashflowStartYear || undefined,
            IsMinistryFull: data.IsMinistryFull || undefined,
            IsMinistryWithCashflow: data.IsMinistryWithCashflow || undefined,
            ReportID: data.ReportID || undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined
        }
        const EXPORTEXCEL001 = `
            query ExportExcel001(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: [Int],
                $ReferenceID: Int,
                $SignoffID: [Int],
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffDLAOrganizationID: [Int],
                $SignoffOperatorOrganizationID: [Int],
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $ProjectTypeAmount: Int,
                $IsCashflow: Boolean,
                $CashflowEndYear: Int,
                $CashflowStartYear: Int,
                $IsMinistryFull: Boolean,
                $IsMinistryWithCashflow: Boolean,
                $ReportID: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
            ){
                ExportExcel001(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffDLAOrganizationID: $SignoffDLAOrganizationID,
                    SignoffOperatorOrganizationID: $SignoffOperatorOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    IsCashflow: $IsCashflow,
                    CashflowEndYear: $CashflowEndYear,
                    CashflowStartYear: $CashflowStartYear
                    IsMinistryFull: $IsMinistryFull,
                    IsMinistryWithCashflow: $IsMinistryWithCashflow,
                    ReportID: $ReportID,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL001,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportExcel004: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            ProjectTypeAmount: data.Type ?? undefined,
        }
        const EXPORTEXCEL004 = `
            query ExportExcel004(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: Int,
                $ReferenceID: Int,
                $SignoffID: Int,
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $ProjectTypeAmount: Int
            ){
                ExportExcel004(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    ProjectTypeAmount: $ProjectTypeAmount
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL004,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportReportList: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            Type: data.Type ?? undefined,
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            ProjectCode: ProjectCode,
            SubdistrictID: data.SubdistrictID?.length ? data.SubdistrictID : undefined,
            DistrictID: data.DistrictID?.length ? data.DistrictID : undefined,
            ProvinceID: data.ProvinceID?.length ? data.ProvinceID : undefined,
            SubbasinID: data.SubbasinID?.length ? data.SubbasinID : undefined,
            BasinID: data.BasinID?.length ? data.BasinID : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            DLAOrganizationID: data.DLAOrganizationID?.length ? data.DLAOrganizationID : undefined,
            OperatorOrganizationID: data.OperatorOrganizationID?.length ? data.OperatorOrganizationID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetTypeID: data.BudgetTypeID?.length ? data.BudgetTypeID : undefined,
            ActivityID: data.ActivityID?.length ? data.ActivityID : undefined,
            ProjectTypeID: data.ProjectTypeID?.length ? data.ProjectTypeID : undefined,
            AreaBaseID: data.AreaBaseID?.length ? data.AreaBaseID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            StrategyID: data.StrategyID?.length ? data.StrategyID : undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            ProjectStatusID: data.ProjectStatusID?.length ? data.ProjectStatusID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            SignoffID: data.SignoffID ?? undefined,
            SignoffStepID: data.SignoffStepID ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID?.length ? data.ReadinessStatusID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject || undefined,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined
        }
        const ExportReportList = `
            query ExportReportList(
                $Type: Int
                $BudgetYear: [Int]
                $ProjectCode: [String]
                $SubdistrictID: [Int]
                $DistrictID: [Int]
                $ProvinceID: [Int]
                $SubbasinID: [Int]
                $BasinID: [Int]
                $OrganizationID: [Int]
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int]
                $BudgetTypeID: [Int]
                $ActivityID: [Int]
                $ProjectTypeID: [Int]
                $AreaBaseID: [Int]
                $BudgetDimensionID: [Int]
                $StrategyID: [Int]
                $ParentStrategyID: Int
                $ProjectStatusID: [Int]
                $TagID: [Int]
                $SignoffID: [Int]
                $SignoffStepID: [Int]
                $ReadinessStatusID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $MYProject: Boolean
                $ProjectTypeAmount: Int
            ) {
                ExportReportList(
                    Type: $Type
                    BudgetYear: $BudgetYear
                    ProjectCode: $ProjectCode
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    DLAOrganizationID: $DLAOrganizationID
                    OperatorOrganizationID: $OperatorOrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ParentStrategyID: $ParentStrategyID
                    ProjectStatusID: $ProjectStatusID
                    TagID: $TagID
                    SignoffID: $SignoffID
                    SignoffStepID: $SignoffStepID
                    ReadinessStatusID: $ReadinessStatusID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    ProjectTypeAmount: $ProjectTypeAmount
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: ExportReportList,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportExcel001Ministry: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            IsMinistry: true,
            ProjectTypeAmount: data.Type ?? undefined,
            ReportID: data.ReportID || undefined
        }
        const EXPORTEXCEL001 = `
            query ExportExcel001(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: [Int],
                $ReferenceID: Int,
                $SignoffID: [Int],
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $IsMinistry: Boolean,
                $ProjectTypeAmount: Int
                $ReportID: Int
            ){
                ExportExcel001(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    IsMinistry: $IsMinistry,
                    ProjectTypeAmount: $ProjectTypeAmount
                    ReportID: $ReportID
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL001,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportExcel004Ministry: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            IsMinistry: true,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined
        }
        const EXPORTEXCEL004 = `
            query ExportExcel004(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: Int,
                $ReferenceID: Int,
                $SignoffID: Int,
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $IsMinistry: Boolean,
                $ProjectTypeAmount: Int
            ){
                ExportExcel004(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    IsMinistry: $IsMinistry,
                    ProjectTypeAmount: $ProjectTypeAmount
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL004,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportReportListMinistry: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            Type: data.Type ?? undefined,
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            ProjectCode: ProjectCode,
            SubdistrictID: data.SubdistrictID?.length ? data.SubdistrictID : undefined,
            DistrictID: data.DistrictID?.length ? data.DistrictID : undefined,
            ProvinceID: data.ProvinceID?.length ? data.ProvinceID : undefined,
            SubbasinID: data.SubbasinID?.length ? data.SubbasinID : undefined,
            BasinID: data.BasinID?.length ? data.BasinID : undefined,
            OrganizationID: data.OrganizationID?.length ? data.OrganizationID : undefined,
            DLAOrganizationID: data.DLAOrganizationID?.length ? data.DLAOrganizationID : undefined,
            OperatorOrganizationID: data.OperatorOrganizationID?.length ? data.OperatorOrganizationID : undefined,
            BudgetSourceID: data.BudgetSourceID?.length ? data.BudgetSourceID : undefined,
            BudgetTypeID: data.BudgetTypeID?.length ? data.BudgetTypeID : undefined,
            ActivityID: data.ActivityID?.length ? data.ActivityID : undefined,
            ProjectTypeID: data.ProjectTypeID?.length ? data.ProjectTypeID : undefined,
            AreaBaseID: data.AreaBaseID?.length ? data.AreaBaseID : undefined,
            BudgetDimensionID: data.BudgetDimensionID?.length ? data.BudgetDimensionID : undefined,
            StrategyID: data.StrategyID?.length ? data.StrategyID : undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            ProjectStatusID: data.ProjectStatusID?.length ? data.ProjectStatusID : undefined,
            TagID: data.TagID?.length ? data.TagID : undefined,
            SignoffID: data.SignoffID ?? undefined,
            SignoffStepID: data.SignoffStepID ?? undefined,
            ReadinessStatusID: data.ReadinessStatusID?.length ? data.ReadinessStatusID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject || undefined,
            IsMinistry: true,
            ProjectTypeAmount: data.ProjectTypeAmount ?? undefined,
            ReportID: data.ReportID || undefined
        }
        const ExportReportList = `
            query ExportReportList(
                $Type: Int
                $BudgetYear: [Int]
                $ProjectCode: [String]
                $SubdistrictID: [Int]
                $DistrictID: [Int]
                $ProvinceID: [Int]
                $SubbasinID: [Int]
                $BasinID: [Int]
                $OrganizationID: [Int]
                $DLAOrganizationID: [Int]
                $OperatorOrganizationID: [Int]
                $BudgetSourceID: [Int]
                $BudgetTypeID: [Int]
                $ActivityID: [Int]
                $ProjectTypeID: [Int]
                $AreaBaseID: [Int]
                $BudgetDimensionID: [Int]
                $StrategyID: [Int]
                $ParentStrategyID: Int
                $ProjectStatusID: [Int]
                $TagID: [Int]
                $SignoffID: [Int]
                $SignoffStepID: [Int]
                $ReadinessStatusID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $MYProject: Boolean
                $IsMinistry: Boolean
                $ProjectTypeAmount: Int
                $ReportID: Int
            ) {
                ExportReportList(
                    Type: $Type
                    BudgetYear: $BudgetYear
                    ProjectCode: $ProjectCode
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    DLAOrganizationID: $DLAOrganizationID
                    OperatorOrganizationID: $OperatorOrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ParentStrategyID: $ParentStrategyID
                    ProjectStatusID: $ProjectStatusID
                    TagID: $TagID
                    SignoffID: $SignoffID
                    SignoffStepID: $SignoffStepID
                    ReadinessStatusID: $ReadinessStatusID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsMinistry: $IsMinistry
                    ProjectTypeAmount: $ProjectTypeAmount
                    ReportID: $ReportID
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: ExportReportList,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportValidation001: (projectData, Year, BudgetDimensionID) => {
        const EXPORTVALIDATION001 = `
            query ExportValidation001 (
                $Projects: JSON
                $Year: Int!
                $BudgetDimensionID: Int
            ) {
                ExportValidation001 (
                    Projects: $Projects
                    Year: $Year
                    BudgetDimensionID: $BudgetDimensionID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTVALIDATION001,
            variables: {
                Projects: projectData,
                Year: Year,
                BudgetDimensionID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportValidation004: (projectData, Year, BudgetDimensionID) => {
        const EXPORTVALIDATION004 = `
            query ExportValidation004 (
                $Projects: JSON
                $Year: Int!
                $BudgetDimensionID: Int
            ) {
                ExportValidation004 (
                    Projects: $Projects
                    Year: $Year
                    BudgetDimensionID: $BudgetDimensionID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTVALIDATION004,
            variables: {
                Projects: projectData,
                Year: Year,
                BudgetDimensionID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportProjectImportant: (data) => {
        data = {
            ShowNotPass: data.ShowNotPass || undefined,
            ProjectImportantStatusID: data.ProjectImportantStatusID?.length ? data.ProjectImportantStatusID : undefined,
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            ProjectImportantCode: data.ProjectImportantCode ? data.ProjectImportantCode.trim() : undefined,
            OperationStartYear: data.OperationStartYear || undefined,
            OperationEndYear: data.OperationEndYear || undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
            MasterPlan: data.MasterPlan ?? undefined,
            ProjectImportantPrepareID: data.ProjectImportantPrepareID || undefined,
            ProjectImportantPrepareChildID: data.ProjectImportantPrepareChildID || undefined,
            ProjectImportantGroupSubID: data.ProjectImportantGroupSubID || undefined,
            ProjectImportantGroupID: data.ProjectImportantGroupID || undefined,
            IsCanceled: data.IsCanceled,
        }

        const EXPORTPROJECTIMPORTANT = `
            query ExportProjectImportant (
                $ShowNotPass: Int,
                $ProjectImportantStatusID: [Int],
                $ProjectImportantName: String,
                $ProjectImportantCode: String,
                $OperationStartYear: [Int],
                $OperationEndYear: [Int],
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int,
                $MasterPlan: Boolean,
                $ProjectImportantPrepareID: Int,
                $ProjectImportantPrepareChildID: Int,
                $ProjectImportantGroupSubID: Int,
                $ProjectImportantGroupID: Int,
                $IsCanceled: Int
            ) {
                ExportProjectImportant (
                    ShowNotPass: $ShowNotPass
                    ProjectImportantStatusID: $ProjectImportantStatusID
                    ProjectImportantName: $ProjectImportantName
                    ProjectImportantCode: $ProjectImportantCode
                    OperationStartYear: $OperationStartYear
                    OperationEndYear: $OperationEndYear
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    Offset: $Offset
                    Limit: $Limit
                    MasterPlan: $MasterPlan
                    ProjectImportantPrepareID: $ProjectImportantPrepareID
                    ProjectImportantPrepareChildID: $ProjectImportantPrepareChildID
                    ProjectImportantGroupSubID: $ProjectImportantGroupSubID,
                    ProjectImportantGroupID: $ProjectImportantGroupID,
                    IsCanceled: $IsCanceled
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportProjectImportantRoadMap: (data) => {
        data = {
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            IsCanceled: data.IsCanceled
        }

        const EXPORTPROJECTIMPORTANT = `
            query ExportProjectImportantRoadMap (
                $ProjectImportantName: String,
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $IsCanceled: Int
            ) {
                ExportProjectImportantRoadMap (
                    ProjectImportantName: $ProjectImportantName
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    IsCanceled: $IsCanceled
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportProjectImportantOnePage: (data) => {
        data = {
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            IsCanceled: data.IsCanceled
        }

        const EXPORTPROJECTIMPORTANT = `
            query ExportProjectImportantOnePage (
                $ProjectImportantName: String,
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int
                $IsCanceled: Int
            ) {
                ExportProjectImportantOnePage (
                    ProjectImportantName: $ProjectImportantName
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    IsCanceled: $IsCanceled
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },

    exportProjectImportantTree: (data) => {
        data = {
            ProjectImportantName: data.ProjectImportantName ? data.ProjectImportantName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            IsCanceled: data.IsCanceled
        }

        const EXPORTPROJECTIMPORTANT = `
            query ExportProjectImportantTree (
                $ProjectImportantName: String,
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $IsCanceled: Int
            ) {
                ExportProjectImportantTree (
                    ProjectImportantName: $ProjectImportantName
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    IsCanceled: $IsCanceled
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        });
    },
    exportProjectRequest: (data) => {
        data = {
            ShowNotPass: data.ShowNotPass || undefined,
            ProjectRequestID: data.ProjectRequestID || undefined,
            ProjectRequestName: data.ProjectRequestName ? data.ProjectRequestName.trim() : undefined,
            ProjectRequestCode: data.ProjectRequestCode ? data.ProjectRequestCode.trim() : undefined,
            ProjectOutput: data.ProjectOutput ? data.ProjectOutput.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            BudgetTypeRequestID: data.BudgetTypeRequestID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MYProject: data.MYProject ?? undefined,
            IsLock: data.IsLock ?? undefined,
            ProjectRequestSignoffStepID: data.ProjectRequestSignoffStepID,
            GroupOrder: data.GroupOrder || undefined,
            GroupOrderDesc: data.GroupOrderDesc || undefined,
            Offset: data.Offset ?? undefined,
            Limit: data.Limit ?? undefined,
            OrganizationInternalID: data.OrganizationInternalID || undefined,
            MasterPlanPlanID: data.MasterPlanPlanID || undefined,
            MasterPlanProductID: data.MasterPlanProductID || undefined,
            HasMasterPlan: data.HasMasterPlan ?? undefined,
        }

        const EXPORTPROJECTREQUESTSTEPONE = `
            query ExportProjectRequest (
                $ShowNotPass: Int,
                $ProjectRequestID: [Int],
                $ProjectRequestSignoffStepID: [Int],
                $ProjectRequestName: String,
                $ProjectRequestCode: String,
                $ProjectOutput: String,
                $BudgetYear: [Int],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $BudgetTypeRequestID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ReadinessStatusID: [Int],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $MYProject: Boolean,
                $IsLock: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int,
                $OrganizationInternalID: [Int],
                $MasterPlanPlanID: Int,
                $MasterPlanProductID: Int,
                $HasMasterPlan: Int
            ) {
                ExportProjectRequest (
                    ShowNotPass: $ShowNotPass
                    ProjectRequestID: $ProjectRequestID
                    ProjectRequestSignoffStepID: $ProjectRequestSignoffStepID
                    ProjectRequestName: $ProjectRequestName
                    ProjectRequestCode: $ProjectRequestCode
                    ProjectOutput: $ProjectOutput
                    BudgetYear: $BudgetYear
                    SubdistrictID: $SubdistrictID
                    DistrictID: $DistrictID
                    ProvinceID: $ProvinceID
                    SubbasinID: $SubbasinID
                    BasinID: $BasinID
                    OrganizationID: $OrganizationID
                    BudgetSourceID: $BudgetSourceID
                    BudgetTypeID: $BudgetTypeID
                    ActivityID: $ActivityID
                    ProjectTypeID: $ProjectTypeID
                    BudgetTypeRequestID: $BudgetTypeRequestID
                    AreaBaseID: $AreaBaseID
                    BudgetDimensionID: $BudgetDimensionID
                    StrategyID: $StrategyID
                    ProjectStatusID: $ProjectStatusID
                    ReadinessStatusID: $ReadinessStatusID
                    TagID: $TagID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MYProject: $MYProject
                    IsLock: $IsLock
                    GroupOrder: $GroupOrder
                    GroupOrderDesc: $GroupOrderDesc
                    Offset: $Offset
                    Limit: $Limit
                    OrganizationInternalID: $OrganizationInternalID,
                    MasterPlanPlanID: $MasterPlanPlanID,
                    MasterPlanProductID: $MasterPlanProductID,
                    HasMasterPlan: $HasMasterPlan
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTSTEPONE,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestStepOne: (data) => {
        data = {
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            RequestStrategyServiceID: data.RequestStrategyServiceID?.length ? data.RequestStrategyServiceID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MasterplanplanID: data.MasterPlanPlanID?.length ? data.MasterPlanPlanID : undefined,
            OrganizationInternalID: data.OrganizationInternalID ?? undefined,
            ProjectRequestSignoffStepID: data.ProjectRequestSignoffStepID?.length ? data.ProjectRequestSignoffStepID : undefined
        }

        const EXPORTPROJECTREQUESTSTEPONE = `
            query ExportProjectRequestStepOne (
                $BudgetYear: [Int]
                $MasterplanplanID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $OrganizationInternalID: [Int]
                $ProjectRequestSignoffStepID: [Int]
            ) {
                ExportProjectRequestStepOne (
                    BudgetYear: $BudgetYear
                    MasterplanplanID: $MasterplanplanID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    OrganizationInternalID: $OrganizationInternalID
                    ProjectRequestSignoffStepID: $ProjectRequestSignoffStepID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTSTEPONE,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestSteptwo: (data) => {
        data = {
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            MasterplanplanID: data.MasterplanplanID ?? undefined,
            OrganizationInternalID: data.OrganizationInternalID ?? undefined,
            ProjectRequestSignoffStepID: data.ProjectRequestSignoffStepID?.length ? data.ProjectRequestSignoffStepID : undefined
        }

        const EXPORTPROJECTREQUESTSTEPONE = `
            query ExportProjectRequestSteptwo (
                $BudgetYear: [Int]
                $MasterplanplanID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $OrganizationInternalID: [Int]
                $ProjectRequestSignoffStepID: [Int]
            ) {
                ExportProjectRequestSteptwo (
                    BudgetYear: $BudgetYear
                    MasterplanplanID: $MasterplanplanID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    OrganizationInternalID: $OrganizationInternalID
                    ProjectRequestSignoffStepID: $ProjectRequestSignoffStepID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTSTEPONE,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestSteptwoByunit: (data) => {
        data = {
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            MasterplanplanID: data.MasterplanplanID ?? undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
            OrganizationInternalID: data.OrganizationInternalID ?? undefined,
            ProjectRequestSignoffStepID: data.ProjectRequestSignoffStepID?.length ? data.ProjectRequestSignoffStepID : undefined
        }

        const EXPORTPROJECTREQUESTSTEPTWOBYUNIT = `
            query ExportProjectRequestSteptwoByUnit (
                $BudgetYear: [Int]
                $MasterplanplanID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $OrganizationInternalID: [Int]
                $ProjectRequestSignoffStepID: [Int]
            ) {
                ExportProjectRequestSteptwoByUnit (
                    BudgetYear: $BudgetYear
                    MasterplanplanID: $MasterplanplanID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    OrganizationInternalID: $OrganizationInternalID
                    ProjectRequestSignoffStepID: $ProjectRequestSignoffStepID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTSTEPTWOBYUNIT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestStepthree: (data) => {
        data = {
            BudgetYear: data.BudgetYear?.length ? data.BudgetYear : undefined,
            OrganizationInternalID: data.OrganizationInternalID ?? undefined,
            RequestStrategyServiceID: data.RequestStrategyServiceID?.length ? data.RequestStrategyServiceID : undefined,
            BudgetRangeStart: data.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: data.BudgetRangeEnd ?? undefined,
        }

        const EXPORTPROJECTIMPORTANT = `
            query ExportProjectRequestStepthree (
                $BudgetYear: [Int]
                $OrganizationInternalID : [Int]
                $RequestStrategyServiceID: [Int]
                $BudgetRangeStart: Float
                $BudgetRangeEnd: Float
                $MasterPlanPlanID: [Int]
            ) {
                ExportProjectRequestStepthree (
                    BudgetYear: $BudgetYear
                    OrganizationInternalID: $OrganizationInternalID
                    RequestStrategyServiceID: $RequestStrategyServiceID
                    BudgetRangeStart: $BudgetRangeStart
                    BudgetRangeEnd: $BudgetRangeEnd
                    MasterplanplanID: $MasterPlanPlanID
                ) {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTIMPORTANT,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportMasterplanB: (MasterPlanID) => {
        const EXPORTMASTERPLANB = `
            query ExportMasterplanB(
                $MasterplanRequestID: Int!,
            ) {
                ExportMasterplanB (
                    MasterplanRequestID: $MasterplanRequestID,
                )  {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTMASTERPLANB,
            variables: {
                MasterplanRequestID: MasterPlanID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportMasterplanA: (MasterPlanID) => {
        const EXPORTMASTERPLANA = `
            query ExportMasterplanA(
                $MasterPlanID: Int!,
            ) {
                ExportMasterplanA (
                    MasterPlanID: $MasterPlanID,
                )  {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTMASTERPLANA,
            variables: {
                MasterPlanID: MasterPlanID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestCashflow: (ProjectRequestID) => {
        const EXPORTPROJECTREQUESTCASHFLOW = `
            query ExportProjectRequestCashflow(
                $ProjectRequestID: Int!,
            ) {
                ExportProjectRequestCashflow (
                    ProjectRequestID: $ProjectRequestID,
                )  {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTCASHFLOW,
            variables: {
                ProjectRequestID: ProjectRequestID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportProjectRequestSignoff: (ProjectRequestID) => {
        const EXPORTPROJECTREQUESTCASHFLOW = `
            query ExportProjectRequestCashflow(
                $ProjectRequestID: Int!,
            ) {
                ExportProjectRequestCashflow (
                    ProjectRequestID: $ProjectRequestID,
                )  {
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTPROJECTREQUESTCASHFLOW,
            variables: {
                ProjectRequestID: ProjectRequestID
            },
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    exportWaterResource: (data) => {
        data = {
            WaterResourceName: data.WaterResourceName ? data.WaterResourceName.trim() : undefined,
            WaterResourceSize: data.WaterResourceSize ?? undefined,
            WaterResourceType: data.WaterReourceType ?? undefined,
            BasinID: data.BasinID ?? undefined,
            ProvinceID: data.ProvinceID ?? undefined,
            DistrictID: data.DistrictID ?? undefined,
            SubdistrictID: data.SubdistrictID ?? undefined,
            Lat: data.Lat ? data.Lat : undefined,
            Lng: data.Lng ? data.Lng : undefined,
            RadiusKM: data.RadiusKM ? data.RadiusKM : undefined,
            StatusID: data?.StatusID ?? undefined
        }

        const EXPORTWATERRESOURCE = `
            query ExportWaterResource (
                $WaterResourceName: String
                $WaterResourceSize: Int
                $WaterResourceType: Int
                $BasinID: Int
                $ProvinceID: Int
                $DistrictID: Int
                $SubdistrictID: Int
                $Lat: Float
                $Lng: Float
                $RadiusKM: Float
                $StatusID: Int
            ) {
                ExportWaterResource (
                    WaterResourceName: $WaterResourceName
                    WaterResourceSize: $WaterResourceSize
                    WaterResourceType: $WaterResourceType
                    BasinID: $BasinID
                    ProvinceID: $ProvinceID
                    DistrictID: $DistrictID
                    SubdistrictID: $SubdistrictID
                    Lat: $Lat
                    Lng: $Lng
                    RadiusKM: $RadiusKM
                    StatusID: $StatusID
                )
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTWATERRESOURCE,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },

    ExportExcel001WithWaterResource: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            IsWaterResrouce: true,
            ProjectTypeAmount: data.Type ?? undefined,
            ReportID: data.ReportID || undefined
        }
        const EXPORTEXCEL001 = `
            query ExportExcel001(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: [Int],
                $ReferenceID: Int,
                $SignoffID: [Int],
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $IsWaterResrouce: Boolean,
                $ProjectTypeAmount: Int,
                $ReportID: Int
            ){
                ExportExcel001(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    IsWaterResrouce: $IsWaterResrouce,
                    ProjectTypeAmount: $ProjectTypeAmount,
                    ReportID: $ReportID
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL001,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
    exportExcel001MinistryWithWaterResource: (data) => {
        let ProjectCode = data.ProjectCode || undefined;
        if (ProjectCode) {
            ProjectCode = String(ProjectCode)
                .split(/[ ,]+/)
                .map((el) => el.trim());
        }
        data = {
            OnlyForm: data.OnlyForm ?? false,
            ProjectName: data.ProjectName ? data.ProjectName.trim() : undefined,
            Keyword: data.Keyword ? data.Keyword.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            GFMISCode: data.GFMISCode ? String(data.GFMISCode).split(',').map((el => el.trim())) : undefined,
            SubdistrictID: data.SubdistrictID || undefined,
            DistrictID: data.DistrictID || undefined,
            ProvinceID: data.ProvinceID || undefined,
            SubbasinID: data.SubbasinID || undefined,
            BasinID: data.BasinID || undefined,
            OrganizationID: data.OrganizationID || undefined,
            DLAOrganizationID: data.DLAOrganizationID || undefined,
            OperatorOrganizationID: data.OperatorOrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            ActivityID: data.ActivityID || undefined,
            ProjectTypeID: data.ProjectTypeID || undefined,
            AreaBaseID: data.AreaBaseID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            ProjectImportantID: data.ProjectImportantID || undefined,
            StrategyID: data.StrategyID || undefined,
            ProjectStatusID: data.ProjectStatusID || undefined,
            ProjectCode: ProjectCode,
            TagID: data.TagID || undefined,
            BudgetRangeStart: data.BudgetRangeStart || undefined,
            BudgetRangeEnd: data.BudgetRangeEnd || undefined,
            MYProject: data.MYProject || undefined,
            IsSignoff: data.IsSignoff || undefined,
            SignoffIsOld: data.SignoffIsOld || undefined,
            IsSignoffStamp: data.IsSignoffStamp || undefined,
            SignoffStepID: data.SignoffStepID || undefined,
            ReferenceID: data.ReferenceID || undefined,
            SignoffID: data.SignoffID || undefined,
            StepID: data.StepID || undefined,
            SignoffProjectName: data.SignoffProjectName || undefined,
            SignoffProjectCode: data.SignoffProjectCode || undefined,
            SignoffReadinessID: data.SignoffReadinessID || undefined,
            SignoffProvinceID: data.SignoffProvinceID || undefined,
            SignoffOrganizationID: data.SignoffOrganizationID || undefined,
            SignoffBasinID: data.SignoffBasinID || undefined,
            SignoffBudgetYear: data.SignoffBudgetYear || undefined,
            SignoffBudgetRangeStart: data.SignoffBudgetRangeStart || undefined,
            SignoffBudgetRangeEnd: data.SignoffBudgetRangeEnd || undefined,
            ReadinessStatusIDs: data.ReadinessStatusIDs || undefined,
            ReadinessStatusID: data.ReadinessStatusID || undefined,
            ParentStrategyID: data.ParentStrategyID ?? undefined,
            IsReport: data.IsReport ?? undefined,
            Type: data.Type ?? undefined,
            IsMinistryWaterResource: true,
            ProjectTypeAmount: data.Type ?? undefined,
            ReportID: data.ReportID || undefined
        }
        const EXPORTEXCEL001 = `
            query ExportExcel001(
                $ProjectName: String,
                $Keyword: String,
                $BudgetYear: [Int],
                $GFMISCode: [String],
                $SubdistrictID: [Int],
                $DistrictID: [Int],
                $ProvinceID: [Int],
                $SubbasinID: [Int],
                $BasinID: [Int],
                $OrganizationID: [Int],
                $DLAOrganizationID: [Int],
                $OperatorOrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $ActivityID: [Int],
                $ProjectTypeID: [Int],
                $AreaBaseID: [Int],
                $BudgetDimensionID: [Int],
                $ProjectImportantID: [Int],
                $StrategyID: [Int],
                $ProjectStatusID: [Int],
                $ProjectCode: [String],
                $TagID: [Int],
                $BudgetRangeStart: Float,
                $BudgetRangeEnd: Float,
                $Favorite: Boolean,
                $OnlyForm: Boolean,
                $MYProject: Boolean,
                $IsSignoff: Boolean,
                $SignoffIsOld: Boolean,
                $IsSignoffStamp: Boolean,
                $SignoffStepID: [Int],
                $ReferenceID: Int,
                $SignoffID: [Int],
                $StepID: Int,
                $SignoffProjectName: String,
                $SignoffProjectCode: String,
                $SignoffReadinessID: Int,
                $SignoffProvinceID: Int,
                $SignoffOrganizationID: Int,
                $SignoffBasinID: Int,
                $SignoffBudgetYear: Int,
                $SignoffBudgetRangeStart: Float,
                $SignoffBudgetRangeEnd: Float,
                $ReadinessStatusIDs: [Int],
                $ReadinessStatusID: [Int],
                $ParentStrategyID: Int,
                $IsReport: Boolean,
                $Type: Int,
                $IsMinistryWaterResource: Boolean,
                $ProjectTypeAmount: Int,
                $ReportID: Int
            ){
                ExportExcel001(
                    ProjectName: $ProjectName,
                    Keyword: $Keyword,
                    BudgetYear: $BudgetYear,
                    GFMISCode: $GFMISCode,
                    SubdistrictID: $SubdistrictID,
                    DistrictID: $DistrictID,
                    ProvinceID: $ProvinceID,
                    SubbasinID: $SubbasinID,
                    BasinID: $BasinID,
                    OrganizationID: $OrganizationID,
                    DLAOrganizationID: $DLAOrganizationID,
                    OperatorOrganizationID: $OperatorOrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    ActivityID: $ActivityID,
                    ProjectTypeID: $ProjectTypeID,
                    AreaBaseID: $AreaBaseID,
                    BudgetDimensionID: $BudgetDimensionID,
                    ProjectImportantID: $ProjectImportantID,
                    StrategyID: $StrategyID,
                    ProjectStatusID: $ProjectStatusID,
                    ProjectCode: $ProjectCode,
                    TagID: $TagID,
                    BudgetRangeStart: $BudgetRangeStart,
                    BudgetRangeEnd: $BudgetRangeEnd,
                    Favorite: $Favorite,
                    OnlyForm: $OnlyForm,
                    MYProject: $MYProject,
                    IsSignoff: $IsSignoff,
                    SignoffIsOld: $SignoffIsOld,
                    IsSignoffStamp: $IsSignoffStamp,
                    SignoffStepID: $SignoffStepID,
                    ReferenceID: $ReferenceID,
                    SignoffID: $SignoffID,
                    StepID: $StepID,
                    SignoffProjectName: $SignoffProjectName,
                    SignoffProjectCode: $SignoffProjectCode,
                    SignoffReadinessID: $SignoffReadinessID,
                    SignoffProvinceID: $SignoffProvinceID,
                    SignoffOrganizationID: $SignoffOrganizationID,
                    SignoffBasinID: $SignoffBasinID,
                    SignoffBudgetYear: $SignoffBudgetYear,
                    SignoffBudgetRangeStart: $SignoffBudgetRangeStart,
                    SignoffBudgetRangeEnd: $SignoffBudgetRangeEnd,
                    ReadinessStatusIDs: $ReadinessStatusIDs,
                    ReadinessStatusID: $ReadinessStatusID,
                    ParentStrategyID: $ParentStrategyID,
                    IsReport: $IsReport,
                    Type: $Type,
                    IsMinistryWaterResource: $IsMinistryWaterResource,
                    ProjectTypeAmount: $ProjectTypeAmount
                    ReportID: $ReportID
                ){
                    FileName
                    Link
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: EXPORTEXCEL001,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: timeout
        });
    },
}
export default ExportService;