import React from "react";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Routes from '../../../../../routes';
const { getNavigationMenu, navigationTrainingMenus } = Routes;

const useStyles = makeStyles(theme => ({
	perfectScrollbarSidebar: {
		height: "100%",
		transition: "all 0.3s ease",
		".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
			height: "calc(100% - 120px)"
		},
		".Cmt-modernLayout &": {
			height: "calc(100% - 72px)"
		},
		".Cmt-miniLayout &": {
			height: "calc(100% - 91px)"
		},
		".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
			height: "calc(100% - 120px)"
		}
	}
}));

const SideBar = ({ account, trainingPlatform }) => {
	const classes = useStyles();
	let navMenu = trainingPlatform && account?.RoleID !== 1 ? navigationTrainingMenus : getNavigationMenu(account);
	return (
		<PerfectScrollbar className={classes.perfectScrollbarSidebar}>
			<CmtVertical menuItems={navMenu} />
		</PerfectScrollbar>
	);
};

export default SideBar;
