import Axios from './_BaseService';

const TagService = {
    searchTag: (data) => {
        data = {
            TagID: data.TagID || undefined,
            TagName: data.TagName ? data.TagName.trim() : undefined
        };

        const SEARCHTAG = `
        query SearchTag (
            $TagID: Int,
            $TagName: String
        ) {
            SearchTag (
                TagID: $TagID,
                TagName: $TagName
            ) {
                TagID,
                TagName
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: SEARCHTAG,
            variables: data,
            errorPolicy: "all",
        });
    },

    getProjectTag: (ProjectID) => {

        const GETTAGPROJECT = `
        query GetTagProject (
            $ProjectID: Int,
        ) {
            GetTagProject (
                ProjectID: $ProjectID,
            ) {
                TagID,
                TagName
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: GETTAGPROJECT,
            variables: {
                ProjectID:ProjectID
            },
            errorPolicy: "all",
        });
    },

    getProjectNoHaveTag: (data) => {
        data = {
            TagID: data.TagID || undefined,
            TagName: data.TagName ? data.TagName.trim() : undefined,
            BudgetYear: data.BudgetYear || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            GroupOrder: data.GroupOrder || null,
            GroupOrderDesc: data.GroupOrderDesc || null,
            Offset: data.Offset,
            Limit: data.Limit,
        }
        const SearchTagProject = `
            query SearchTagProject(
                $BudgetYear: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $BudgetDimensionID: [Int],
                $TagID: Int,
                $TagName: String,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int
            ) {
                SearchTagProject(
                    BudgetYear: $BudgetYear,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    BudgetDimensionID: $BudgetDimensionID,
                    TagID: $TagID,
                    TagName: $TagName,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    Offset: $Offset,
                    Limit: $Limit
                ){
                    Projects {
                        ProjectID
                        ProjectCode,
                        ProjectName,
                        BudgetYear,
                        ProjectTypeName,
                        OrganizationName,
                        ProjectStatusName,
                        ProjectStatusID,
                        MasterPlanID,
                        MasterPlanName,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        BudgetDimensionName,
                        IsLock,
                        MYProject,
                        OrderProject,
                        OrderProvince,
                        OrderCountry,
                        OrderGroup,
                        CommentCount
                    }
                    ProjectsHaveTag {
                        ProjectID
                        ProjectCode,
                        ProjectName,
                        BudgetYear,
                        ProjectTypeName,
                        OrganizationName,
                        ProjectStatusName,
                        ProjectStatusID,
                        MasterPlanID,
                        MasterPlanName,
                        RequestAmount,
                        DraftAmount,
                        AllocateAmount,
                        BudgetDimensionName,
                        IsLock,
                        MYProject,
                        OrderProject,
                        OrderProvince,
                        OrderCountry,
                        OrderGroup,
                        CommentCount
                    }
                    Count
                    CountHaveTag
                    CQLFilter
                    LayerStyle {
                        LayerID
                        CQLFilter
                        Style
                    }
                }
            }
        `;

        return Axios.post("/twpservice", {
            query: SearchTagProject,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 60000
        });
    },

    updateTag: (data) => {
        data = {
            TagID: data.TagID,
            TagName: data.TagName ? data.TagName.trim() : undefined
        };

        const UPDATETAG = `
        mutation UpdateTag (
            $TagID: Int!,
            $TagName: String!
        ) {
            UpdateTag (
                TagID: $TagID,
                TagName: $TagName
            ) {
                TagID,
                TagName
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: UPDATETAG,
            variables: data,
            errorPolicy: "all",
        });
    },

    createTag: (data) => {
        data = {
            TagName: data.TagName ? data.TagName.trim() : undefined
        };

        const CREATETAG = `
        mutation CreateTag (
            $TagName: String!
        ) {
            CreateTag (
                TagName: $TagName
            ) {
                TagID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: CREATETAG,
            variables: data,
            errorPolicy: "all",
        });
    },

    deleteTag: (TagID) => {
        const DELETETAG = `
        mutation DeleteTag (
            $TagID: Int!
        ) {
            DeleteTag (
                TagID: $TagID
            ) {
                TagID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: DELETETAG,
            variables: {
                TagID: TagID
            },
            errorPolicy: "all",
        });
    },

    createTagProject: (data) => {
        data = {
           TagID: data.TagID,
           ProjectID: data.ProjectID,
           Username: data.Username ? data.Username.trim() : undefined,
       };

       const CREATETAGPROJECT = `
       mutation CreateTagProject (
           $TagID: Int!
           $ProjectID: Int!
           $Username: String!
       ) {
           CreateTagProject (
               TagID: $TagID
               ProjectID: $ProjectID
               Username: $Username
           ) {
               TagID
           }
       }
       `;

       return Axios.post("/twpservice", {
           query: CREATETAGPROJECT,
           variables: data,
           errorPolicy: "all",
       });
    },
    

    updateTagProject: (data) => {
    data = {
        TagID: data.TagID,
        ProjectID: data.ProjectID,
        NewTagID: data.NewTagID
    };
    const UPDATETAGPROJECT = `
    mutation UpdateTagProject (
        $TagID: Int!
        $ProjectID: Int!
        $NewTagID: Int!
    ) {
        UpdateTagProject (
            TagID: $TagID,
            ProjectID: $ProjectID
            NewTagID: $NewTagID
        ) {
            TagID
            
        }
    }
    `;

    return Axios.post("/twpservice", {
        query: UPDATETAGPROJECT,
        variables: data,
        errorPolicy: "all",
    });
    },

    deleteProjectTag: (data) => {
        data = {
            TagID: data.TagID,
            ProjectID: data.ProjectID.length ? data.ProjectID : undefined,
        };
        const DELETETAGPROJECT = `
        mutation DeleteTagProject (
            $TagID: Int!
            $ProjectID: [Int!]
        ) {
            DeleteTagProject (
                TagID: $TagID
                ProjectID: $ProjectID
            ) {
                TagID
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: DELETETAGPROJECT,
            variables: data ,
            errorPolicy: "all",
        });
    },
    
    createProjectTagMulti: (data) => {
        data = {
            TagID: data.TagID || undefined,
            ProjectID: data.ProjectID || undefined,
        };
  
        const CREATETAGPROJECTMulti = `
        mutation CreateTagProjectMulti (
            $TagID: Int!
            $ProjectID: [Int!]
        ) {
            CreateTagProjectMulti (
                TagID: $TagID
                ProjectID: $ProjectID
            ) {
                TagID
            }
        }
        `;
 
        return Axios.post("/twpservice", {
            query: CREATETAGPROJECTMulti,
            variables: data,
            errorPolicy: "all",
        });
    },

    createProjectTagAll: (data) => {
        data = {
            TagID: data.TagID || undefined,
            BudgetYear: data.BudgetYear || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            GroupOrder: data.GroupOrder || null,
            GroupOrderDesc: data.GroupOrderDesc || null,
            Offset: data.Offset,
            Limit: data.Limit,
        }

        console.log(data)

        const CREATETAGPROJECTALL = `
        mutation CreateTagProjectAll (
                $BudgetYear: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $BudgetDimensionID: [Int],
                $TagID: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int
        ) {
            CreateTagProjectAll (
                    BudgetYear: $BudgetYear,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    BudgetDimensionID: $BudgetDimensionID,
                    TagID: $TagID,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    Offset: $Offset,
                    Limit: $Limit
            ) {
                    Projects
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: CREATETAGPROJECTALL,
            variables: data,
            errorPolicy: "all",
        });
    },

    deleteProjectTagAll: (data) => {
        data = {
            TagID: data.TagID || undefined,
            BudgetYear: data.BudgetYear || undefined,
            OrganizationID: data.OrganizationID || undefined,
            BudgetSourceID: data.BudgetSourceID || undefined,
            BudgetTypeID: data.BudgetTypeID || undefined,
            BudgetDimensionID: data.BudgetDimensionID || undefined,
            GroupOrder: data.GroupOrder || null,
            GroupOrderDesc: data.GroupOrderDesc || null,
            Offset: data.Offset,
            Limit: data.Limit,
        }

        console.log(data)

        const CREATETAGPROJECTALL = `
        mutation DeleteTagProjectAll (
                $BudgetYear: [Int],
                $OrganizationID: [Int],
                $BudgetSourceID: [Int],
                $BudgetTypeID: [Int],
                $BudgetDimensionID: [Int],
                $TagID: Int,
                $GroupOrder: Int,
                $GroupOrderDesc: Int,
                $Offset: Int,
                $Limit: Int
        ) {
            DeleteTagProjectAll (
                    BudgetYear: $BudgetYear,
                    OrganizationID: $OrganizationID,
                    BudgetSourceID: $BudgetSourceID,
                    BudgetTypeID: $BudgetTypeID,
                    BudgetDimensionID: $BudgetDimensionID,
                    TagID: $TagID,
                    GroupOrder: $GroupOrder,
                    GroupOrderDesc: $GroupOrderDesc,
                    Offset: $Offset,
                    Limit: $Limit
            ) {
                    Projects
            }
        }
        `;

        return Axios.post("/twpservice", {
            query: CREATETAGPROJECTALL,
            variables: data,
            errorPolicy: "all",
        });
    },
}

export default TagService;