import React from 'react';
import {
    Box,
    Grow,
    IconButton
} from '@material-ui/core';
import {
    LiveHelp,
    Layers,
    FiberManualRecord,
    Close
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';

// const openInNewTab = (url) => {
//     window.open(url, '_blank').focus();
// }

const renderLayerImg = (layers, baseURL, account) => {
    if (!layers) return null;
    let elements = [];
    let i = 0;
    if (layers["Project"]) {
        const element = layers["Project"];
        // let source = element.url + "wms?";
        // source += "request=GetLegendGraphic";
        // source += "&version=1.0.0";
        // source += "&layer=" + element.layerPath;
        let source = baseURL + "/getmaplegend?";
        source += "layer=" + element.layerPath;
        source += "&width=15&height=15";
        source += "&forceLabels=on&fontAntiAliasing=true&fontName=Sarabun&fontColor=0x000033&fontSize=7&bgColor=0xFFFFFF&dpi=180";
        source += "&token=" + (account.SessionToken);
        elements.push(
            <div className="section-header main d-flex align-items-center py-10 px-10 mb-0" key={"head0"}>
                <Layers className="mr-05" />
                <h5 className="mr-05">สัญลักษณ์โครงการ</h5>
            </div>
        );
        elements.push(
            <Box
                key={"layerinfo-" + i}
                display="flex"
                alignItems="center"
                paddingLeft={3}
                paddingRight={3}
            >
                <img src={source} alt='' />
            </Box>
        );
        elements.push(
            <div className="section-header main d-flex align-items-center pt-10 pb-05 px-10 mb-0" key={"head1"}>
                <Layers className="mr-05" />
                <h5 className="mr-05">สัญลักษณ์ชั้นข้อมูล</h5>
            </div>
        );
        i++;
    }
    let currentGroup = null;
    for (const key in layers) {
        if (key === "Project") continue;
        if (Object.hasOwnProperty.call(layers, key)) {
            const element = layers[key];
            if (!element) continue;
            if (element.layerPath.indexOf(',') !== -1) {
                const dataLayers = element.layerPath.split(',');
                if (element.groupID && element.groupID !== currentGroup) {
                    currentGroup = element.groupID;
                    elements.push(
                        <Box
                            key={"layerheader-" + i}
                            display="flex"
                            alignItems="center"
                            paddingLeft={6}
                            paddingRight={3}
                            marginTop={4}
                            marginBottom={4}
                        >
                            <FiberManualRecord className="mr-05" style={{ fontSize: "0.5rem" }} />
                            <h4 className="mr-05">{element.groupName}</h4>
                        </Box>
                    );
                }
                for (let index = 0; index < dataLayers.length; index++) {
                    const element = dataLayers[index];
                    let source = baseURL + "/getmaplegend?";
                    source += "layer=" + element;
                    source += "&width=15&height=15";
                    source += "&forceLabels=on&fontAntiAliasing=true&fontName=Sarabun&fontColor=0x000033&fontSize=7&bgColor=0xFFFFFF&dpi=180";
                    source += "&token=" + (account.SessionToken);
                    elements.push(
                        <Box
                            key={"layerinfo-" + i}
                            display="flex"
                            alignItems="center"
                            paddingLeft={3}
                            paddingRight={3}
                        >
                            <img src={source} />
                        </Box>
                    );
                    i++;
                }
            }
            else {
                let source = baseURL + "/getmaplegend?";
                source += "layer=" + element.layerPath;
                source += "&width=15&height=15";
                source += "&forceLabels=on&fontAntiAliasing=true&fontName=Sarabun&fontColor=0x000033&fontSize=7&bgColor=0xFFFFFF&dpi=180";
                source += "&token=" + (account.SessionToken);
                if (element.groupID && element.groupID !== currentGroup) {
                    currentGroup = element.groupID;
                    elements.push(
                        <Box
                            key={"layerheader-" + i}
                            display="flex"
                            alignItems="center"
                            paddingLeft={6}
                            paddingRight={3}
                            marginTop={4}
                            marginBottom={4}
                        >
                            <FiberManualRecord className="mr-05" style={{ fontSize: "0.5rem" }} />
                            <h4 className="mr-05">{element.groupName}</h4>
                        </Box>
                    );
                }
                elements.push(
                    <Box
                        key={"layerinfo-" + i}
                        display="flex"
                        alignItems="center"
                        paddingLeft={3}
                        paddingRight={3}
                    >
                        <img src={source} />
                    </Box>
                );
                i++;
            }
        }
    }
    return elements;
}

class MapFilter extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.showProject === false && this.props.showProject === false)
            return false;
        return !(
            nextProps.showInfo === this.props.showInfo &&
            nextProps.showLayer === this.props.showLayer
        )
    }

    render() {
        const {
            showInfo,
            setShowInfo,
            layers,
            baseURL,
            account
        } = this.props;

        return (
            <Grow in={showInfo} className="map-filter d-flex flex-column">
                <div>
                    <CmtCard
                        className="mt-05 p-0 overflowY-auto"
                    // style={{ minHeight: "52px", maxHeight: "25vh", flex: "0 0 auto" }}
                    >
                        <div className="section-header main d-flex align-items-center py-05 px-10 mb-0">
                            <LiveHelp className="mr-05" />
                            <h4 className="mr-05">คำอธิบายสัญลักษณ์</h4>
                            <IconButton className="ml-auto p-02 text-default" onClick={setShowInfo}>
                                <Close />
                            </IconButton>
                        </div>
                        {renderLayerImg(layers, baseURL, account)}
                    </CmtCard>
                </div>
            </Grow >
        );
    }
}

export default MapFilter;