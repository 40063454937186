import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Grow,
    FormControlLabel,
    Checkbox,
    Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DatePicker from 'react-modern-calendar-datepicker';
import TextFieldMemo from "../../../Util/TextFieldMemo";
import Autocomplete from '../../../Util/Autocomplete';
import TrainingRequestService from "../../../Service/TrainingRequest";
import { showLoading, hideLoading } from "../../../redux/action";
import Swal from "sweetalert2";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './Register.css';
import ExpandableContent from "../../../@coremat/CmtCardExpendableContent";
import { CmtTitle } from "../../../@coremat/CmtTypography";

const customLocale = {
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
    ],

    weekDays: [
        {
            name: 'อาทิตย์',
            short: 'อา',
            isWeekend: true
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday() {
        const startDate = new Date(Date.now() + 8 * 24 * 60 * 60 * 1000);
        return { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() };
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        if (`${digit}`.length === 4) {
            digit = digit + 543;
        }
        return digit;
    },

    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0
}

const RegisterTrainingModal = ({ show, setShow, trainingDays = 0 }) => {
    const dispatch = useDispatch();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [previewDate, setPreviewDate] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [dateUnavailable, setDateUnavailable] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState({ from: null, to: null });
    const [dateTraining, setDateTraining] = useState(null);
    const [minimum, setMinimum] = useState(null);
    const [maximum, setMaximum] = useState(null);
    const [consent, setConsent] = useState(false);
    const [state, setState] = useState({
        data: {
            Type: "",
            CardID: "",
            FirstName: "",
            LastName: "",
            OrganizationID: "",
            DLAOrganizationID: "",
            ProvinceID: "",
            Position: "",
            PhoneNumber: "",
            Email: "",
            LineID: "",
            UserNumber: "",
            StartDate: null,
            EndDate: null
        },
        error: {}
    });

    const startDate = new Date(Date.now() + ((trainingDays) * 24 * 60 * 60 * 1000));

    const trainingTypeList = [
        {
            image: "/images/trial-1.png",
            name: "หน่วยงานราชการ",
            borderColor: "#3c8cd4",
            value: 1
        },
        {
            image: "/images/trial-2.png",
            name: "จังหวัด",
            borderColor: "#81b079",
            value: 2
        },
        {
            image: "/images/trial-3.png",
            name: "องค์กรปกครองส่วนท้องถิ่น",
            borderColor: "#9c7c14",
            value: 3
        }
    ];

    useEffect(() => {
        if (!showConfirmModal && show) {
            if (state.data.Type) {
                const patternChoose = trainingTypeList.find(item => item.value == state.data.Type);
                handleType(patternChoose);
            } else {
                setMinMaxDate();
                checkTrainingUnavailable();
            }
        }
    }, [showConfirmModal, show]);

    useEffect(() => {
        if (dateTraining) disabledDate();
        if (state.data.UserNumber) {
            disabledDate(state.data.UserNumber);
            setSelectedDateRange({ from: null, to: null });
        }
    }, [dateTraining, state.data.UserNumber]);

    const setInit = () => {
        setSelectedDateRange({ from: null, to: null });
        setShowConfirmModal(false);
        setPreviewDate([]);
        setConsent(false);
        setState({
            data: {
                Type: "",
                CardID: "",
                FirstName: "",
                LastName: "",
                OrganizationID: "",
                DLAOrganizationID: "",
                ProvinceID: "",
                Position: "",
                PhoneNumber: "",
                Email: "",
                LineID: "",
                UserNumber: "",
                StartDate: null,
                EndDate: null
            },
            error: {}
        });
    };

    const onChange = (value, name) => {
        setState((prevState) => ({ ...prevState, data: { ...prevState.data, [name]: value } }));
    };

    const onEventChange = (e) => {
        const patternInt = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (patternInt.test(e.target.value)) {
            setState((prevState) => ({ ...prevState, data: { ...prevState.data, [e.target.name]: e.target.value } }));
        }
    };

    const setMinMaxDate = () => {
        setMinimum({ year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() });
        setMaximum({ year: startDate.getFullYear() + 2, month: startDate.getMonth() + 1, day: startDate.getDate() });
    };

    const checkTrainingUnavailable = () => {
        dispatch(showLoading());

        TrainingRequestService.checkTrainingAvailability({
            StartDate: handleDateFormat(startDate),
            EndDate: handleDateFormat(startDate, 'end')
        }).then(res => {
            dispatch(hideLoading());
            if (res.errors || !res.data?.CheckTrainingAvailability) {
                if (res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: res.errors?.[0]?.Message,
                        icon: 'error'
                    });
                }
                setDateUnavailable([]);
                return;
            }
            setDateTraining(res.data?.CheckTrainingAvailability);
        }).catch(() => {
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                icon: 'error'
            });
        });
    };

    const disabledDate = (userNumber) => {
        let dateListUnavailable = [];
        if (Object.keys(dateTraining).length !== 0 && dateTraining) {
            for (const [key, value] of Object.entries(dateTraining)) {
                const dateSplit = key.split('-');
                if (value >= 300 || parseInt(userNumber) + value > 300) {
                    dateListUnavailable.push({
                        year: parseInt(dateSplit[2]) - 543,
                        month: parseInt(dateSplit[1]),
                        day: parseInt(dateSplit[0]),
                    })
                }
                setDateUnavailable(dateListUnavailable);
            }
        }
    };

    const getOrganization = () => {
        dispatch(showLoading());
        TrainingRequestService.getOrganization().then(res => {
            dispatch(hideLoading());
            if (res.errors || !res.data?.GetOrganization) {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: res.errors?.[0]?.Message,
                    icon: 'error'
                });
                setState((prevState) => ({ ...prevState, data: { ...prevState.data, OrganizationID: "" } }));
                setOrganization([]);
                return;
            }
            setOrganization(res.data?.GetOrganization);
        }).catch(() => {
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                icon: 'error'
            });
        });
    };

    const getOrganizationDLA = () => {
        dispatch(showLoading());
        TrainingRequestService.getOrganizationDLA().then(res => {
            dispatch(hideLoading());
            if (res.errors || !res.data?.GetOrganizationDLA) {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: res.errors?.[0]?.Message,
                    icon: 'error'
                });
                setState((prevState) => ({ ...prevState, data: { ...prevState.data, DLAOrganizationID: "" } }));
                setOrganization([]);
                return;
            }
            setOrganization(res.data?.GetOrganizationDLA);
        }).catch(() => {
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                icon: 'error'
            });
        });
    };

    const getProvinceTrainingRequest = () => {
        dispatch(showLoading());
        TrainingRequestService.getProvinceTrainingRequest().then(res => {
            dispatch(hideLoading());
            if (res.errors || !res.data?.GetProvinceTrainingRequest) {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: res.errors?.[0]?.Message,
                    icon: 'error'
                });
                setState((prevState) => ({ ...prevState, data: { ...prevState.data, ProvinceID: "" } }));
                setOrganization([]);
                return;
            }
            setOrganization(res.data?.GetProvinceTrainingRequest);
        }).catch(() => {
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                icon: 'error'
            });
        });
    };

    const handleType = (item) => {
        setState((prevState) => ({ ...prevState, data: { ...prevState.data, Type: item.value } }));
        if (item.value === 1) getOrganization();
        else if (item.value === 2) getProvinceTrainingRequest();
        else if (item.value === 3) getOrganizationDLA();
    };

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const handleDateFormat = (date, key) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            key ? date.getFullYear() + 545 : date.getFullYear() + 543,
        ].join('-');
    }

    const checkData = () => {
        let error = {};
        if (!state.data.Type || state.data.Type === "") error.Type = true;
        if (!state.data.CardID || state.data.CardID === "" || state.data.CardID === "" || state.data.CardID.length !== 13) error.CardID = true;
        if (!state.data.FirstName || state.data.FirstName === "") error.FirstName = true;
        if (!state.data.LastName || state.data.LastName === "") error.LastName = true;
        if (!state.data.OrganizationID && !state.data.ProvinceID && !state.data.DLAOrganizationID) error.OrganizationID = true;
        if (!state.data.Position || state.data.Position === "") error.Position = true;
        if (!state.data.PhoneNumber || state.data.PhoneNumber === "" || state.data.PhoneNumber.length < 9) error.PhoneNumber = true;
        if (!state.data.Email || state.data.Email === "") error.Email = true;
        if (!state.data.UserNumber || state.data.UserNumber === "" || state.data.UserNumber > 50 || state.data.UserNumber <= 0) error.UserNumber = true;
        if (!selectedDateRange.from) error.StartDate = true;
        if (!selectedDateRange.to) error.EndDate = true;
        // check CardID
        if (state.data.CardID.length === 13) {
            let sum = 0;
            for (let i = 0; i < 12; i++) {
                sum += parseFloat(state.data.CardID.charAt(i)) * (13 - i);
            }
            if ((11 - sum % 11) % 10 != parseFloat(state.data.CardID.charAt(12))) error.CardIDInvalid = true;
        }

        // check Dete
        if (selectedDateRange.from && selectedDateRange.to) {
            if (checkDate()) error.DateInValid = true;
        }

        setState((prevState) => ({ ...prevState, error: error }));

        if (Object.keys(error).length) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                icon: "warning",
            });
            return;
        } else {
            setShowConfirmModal(!showConfirmModal);
        }
    };

    const checkDate = () => {
        const startDate = new Date(selectedDateRange.from.year + '/' + selectedDateRange.from.month + '/' + selectedDateRange.from.day)
        const endDate = new Date(selectedDateRange.to.year + '/' + selectedDateRange.to.month + '/' + selectedDateRange.to.day)

        setState((prevState) => ({
            ...prevState, data: {
                ...prevState.data,
                StartDate: startDate,
                EndDate: endDate
            }
        }));

        let dateRange = [];
        let currentDate = startDate;

        while (currentDate <= endDate) {
            dateRange.push(new Date(currentDate));
            currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }

        if (dateRange.length > 3) {
            return true;
        } else {
            setPreviewDate(dateRange);
            return false;
        }
    };

    const formatInputDate = () => {
        if (!selectedDateRange.from && !selectedDateRange.to) return;
        if (selectedDateRange.from && selectedDateRange.to) {
            return `ตั้งแต่ ${selectedDateRange.from.day.toString().padStart(2, '0')}-${selectedDateRange.from.month.toString().padStart(2, '0')}-${selectedDateRange.from.year + 543} จนถึง ${selectedDateRange.to.day.toString().padStart(2, '0')}-${selectedDateRange.to.month.toString().padStart(2, '0')}-${selectedDateRange.to.year + 543}`;
        }
    };

    const onSubmit = () => {
        dispatch(showLoading());
        const startDate = handleDateFormat(new Date(selectedDateRange.from.year + '-' + padTo2Digits(selectedDateRange.from.month) + '-' + padTo2Digits(selectedDateRange.from.day)));
        const endDate = handleDateFormat(new Date(selectedDateRange.to.year + '-' + padTo2Digits(selectedDateRange.to.month) + '-' + padTo2Digits(selectedDateRange.to.day)));

        TrainingRequestService.createTrainingRequest(state.data, startDate, endDate).then(res => {
            dispatch(hideLoading());
            if (res.errors || !res.data?.CreateTrainingRequest) {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: res.errors?.[0]?.Message,
                    icon: 'error'
                });
                return;
            }
            Swal.fire({
                title: "ยื่นคำขอเรียบร้อยแล้ว รอการอนุมัติทาง Email",
                html: `
                    <div class="d-flex align-items-center justify-content-center">
                        <div>สำเนาข้อมูลของท่านได้ถูกส่งไปยัง Email ที่ท่านได้ทำการลงทะเบียนเรียบร้อยแล้ว<div>
                        <div>ข้อมูลของท่านกำลังอยู่ระหว่างการตรวจสอบ ผลการตรวจสอบจะถูกแจ้งไปยัง Email ที่ท่านได้ทำการลงทะเบียน ภายใน 7 วัน</div>
                    </div>
                `,
                icon: "success",
                confirmButtonText: 'ปิด'
            }).then(() => {
                setInit();
                setShow();
            });
        }).catch((e) => {
            console.log(e);
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                icon: 'error'
            });
        });
    };

    const organizationSelect = (value, prop) => {
        setState((prevState) => ({ ...prevState, data: { ...prevState.data, [prop]: value } }))
    };

    return (
        <Dialog
            onClose={setShow}
            TransitionProps={{
                onExit: () =>
                    setTimeout(() => {
                        setInit()
                    }, 300)
            }}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle style={{ position: "relative" }}>
                <h3>
                    {!showConfirmModal ? "ลงทะเบียน" : "ตรวจสอบข้อมูลลงทะเบียน"}
                    {!showConfirmModal ?
                        <IconButton aria-label="close" style={{ position: 'absolute', right: 0, top: 0 }} onClick={() => setShow(false)}>
                            <CloseIcon />
                        </IconButton>
                        : null}
                </h3>

            </DialogTitle>
            {!showConfirmModal ?
                <DialogContent
                    style={{ paddingTop: "0", paddingBottom: "1rem" }}>
                    <Grow in={true} timeout={400}>
                        <>
                            <div className="section">
                                <Grid container style={{ minHeight: "75px" }}>
                                    <Grid item xs={3} className="d-flex align-items-center">
                                        รูปแบบการทดลองใช้ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-center">
                                        {trainingTypeList.map((item) => {
                                            return <Button
                                                onClick={() => handleType(item)} id={item.name}
                                                className={item.value === state.data.Type ? "typeChoose" : state.data.Type ? "typeUnChoose" : null}
                                                style={{
                                                    border: `${item.value === state.data.Type ? "4px solid " + item.borderColor : "none"}`
                                                }}
                                            >
                                                <img src={item.image} alt={item.name} />
                                            </Button>

                                        })}
                                    </Grid>
                                    {state.error.Type ?
                                        <>
                                            <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                            <Grid item xs={9} className="d-flex align-items-center">
                                                <span style={{ fontSize: "11px", color: "red" }}>* กรุณาเลือกรูปแบบการทดลองใช้</span>
                                            </Grid>
                                        </>
                                        : null}
                                </Grid>
                                {state.data.Type ?
                                    <div className="transition" style={{ paddingTop: "0px" }}>
                                        <Grid container style={{ minHeight: "75px" }}>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                เลขบัตรประชาชน <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-center mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic2"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="CardID"
                                                    value={state.data.CardID}
                                                    onChange={onEventChange}
                                                    error={state.error.CardID}
                                                    inputProps={{ maxLength: 13 }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {state.error.CardID || state.error.CardIDInvalid ?
                                                <>
                                                    <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                                    <Grid item xs={9} className="d-flex align-items-center">
                                                        {state.error.CardIDInvalid ?
                                                            <span style={{ fontSize: "11px", color: "red" }}>* เลขบัตรประชาชนไม่ถูกต้อง</span> :
                                                            !state.data.CardID.length ? null :
                                                                state.data.CardID.length === 13 ? null :
                                                                    <span style={{ fontSize: "11px", color: "red" }}>* กรุณาระบุเลขบัตรประชาชนให้ครบ 13 หลัก</span>
                                                        }
                                                    </Grid>
                                                </>
                                                : null}
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                ชื่อ
                                                <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-center mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic3"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="FirstName"
                                                    value={state.data.FirstName}
                                                    onChange={(e) => onChange(e.target.value, e.target.name)}
                                                    error={state.error.FirstName}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                นามสกุล
                                                <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-center mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic3"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="LastName"
                                                    value={state.data.LastName}
                                                    onChange={(e) => onChange(e.target.value, e.target.name)}
                                                    error={state.error.LastName}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {state.data.Type ?
                                                <>
                                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                        หน่วยงานสังกัด <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                                    </Grid>
                                                    <Grid item xs={9} className="mt-10">
                                                        <Autocomplete
                                                            placeholder="หน่วยงานสังกัด"
                                                            value={state.data.Type === 2 ? state.data.ProvinceID : state.data.Type === 1 ? state.data.OrganizationID : state.data.DLAOrganizationID}
                                                            dataname={state.data.Type === 2 ? "Province" : "Organization"}
                                                            name={state.data.Type === 2 ? "ProvinceID" : state.data.Type === 1 ? "OrganizationID" : "DLAOrganizationID"}
                                                            dataobjectname={state.data.Type === 2 ? "ProvinceID" : "OrganizationID"}
                                                            setvalue={organizationSelect}
                                                            data={organization}
                                                            required
                                                        />
                                                    </Grid>
                                                    {state.error.OrganizationID ?
                                                        <>
                                                            <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                                            <Grid item xs={9} className="d-flex align-items-center">
                                                                <span style={{ fontSize: "11px", color: "red" }}>* กรุณาเลือกหน่วยงานสังกัด</span>
                                                            </Grid>
                                                        </>
                                                        : null}
                                                </>
                                                : null}

                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                ตำแหน่ง <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic5"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="Position"
                                                    value={state.data.Position}
                                                    onChange={(e) => onChange(e.target.value, e.target.name)}
                                                    error={state.error.Position}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                เบอร์โทร <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic6"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="PhoneNumber"
                                                    value={state.data.PhoneNumber}
                                                    onChange={onEventChange}
                                                    error={state.error.PhoneNumber}
                                                    inputProps={{ maxLength: 10 }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {state.error.PhoneNumber ?
                                                <>
                                                    <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                                    <Grid item xs={9} className="d-flex align-items-center">
                                                        {!state.data.PhoneNumber.length ?
                                                            null : <span style={{ fontSize: "11px", color: "red" }}>{state.data.PhoneNumber.length < 9 ? "* กรุณาระบุเบอร์โทรให้ครบ 9 หรือ 10 หลัก" : null}</span>
                                                        }
                                                    </Grid>
                                                </>
                                                : null}
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                E-mail <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic1"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="Email"
                                                    value={state.data.Email}
                                                    onChange={(e) => onChange(e.target.value, e.target.name)}
                                                    error={state.error.Email}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                Line ID :
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic1"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="LineID"
                                                    value={state.data.LineID}
                                                    onChange={(e) => onChange(e.target.value, e.target.name)}
                                                    error={state.error.LineID}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                จำนวน User ที่ต้องการ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                                <TextFieldMemo
                                                    id="outlined-basic1"
                                                    variant="outlined"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="UserNumber"
                                                    value={state.data.UserNumber}
                                                    onChange={onEventChange}
                                                    error={state.error.UserNumber}
                                                    inputProps={{ maxLength: 2 }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                            <Grid item xs={9} className="d-flex align-items-center">
                                                <span style={{ fontSize: "11px", color: "#6495ED" }}>สูงสุดไม่เกิน 50 คน</span>
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center mt-10">
                                                วันที่ต้องการ <span className="no-print require" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                            </Grid>
                                            <Grid item xs={9} className="d-flex align-items-center mt-10">
                                                <DatePicker
                                                    inputPlaceholder={`หาวันจองสำหรับ ${state.data.UserNumber ? state.data.UserNumber : '-'} คน`}
                                                    value={selectedDateRange}
                                                    onChange={setSelectedDateRange}
                                                    formatInputText={formatInputDate}
                                                    disabledDays={dateUnavailable}
                                                    locale={customLocale}
                                                    minimumDate={minimum}
                                                    maximumDate={maximum}
                                                    colorPrimary="#007aee"
                                                    colorPrimaryLight="#A7C7E7"
                                                    shouldHighlightWeekends
                                                    renderFooter={() => (
                                                        <div style={{ padding: '1rem', marginTop: '-1rem' }} className="d-flex align-items-center justify-content-center">
                                                            <div style={{ fontSize: "14px", backgroundColor: "#E5E4E2", width: "100%", padding: "3px" }} className="d-flex align-items-center justify-content-center">
                                                                หาวันจองสำหรับ {state.data.UserNumber ? state.data.UserNumber : '-'} คน
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                            <Grid item xs={9} className="d-flex align-items-center">
                                                <span style={{ fontSize: "11px", color: "#6495ED" }}>วันที่ที่มีจำนวนผู้สมัครลงทะเบียนเข้าใช้ระบบเต็มแล้ว จะไม่สามารถเลือกได้ กำหนดวันต่อเนื่องสูงสุดได้ไม่เกิน 3 วัน</span>
                                            </Grid>
                                            {(state.error.StartDate && state.error.EndDate) || state.error.DateInValid ?
                                                <>
                                                    <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                                    <Grid item xs={9} className="d-flex align-items-center">
                                                        <span style={{ fontSize: "11px", color: "red" }}>{state.error.DateInValid ? "* กรุณาเลือกวันต่อเนื่องสูงสุดได้ไม่เกิน 3 วัน" : "* กรุณาระบุวันที่"}</span>
                                                    </Grid>
                                                </>
                                                : null}
                                            <Grid item xs={12} className="d-flex align-items-center no-print">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={consent}
                                                            onChange={(e) => setConsent(e.target.checked)}
                                                        />
                                                    }
                                                    label={
                                                        <h4>ยืนยันและยอมรับข้อกำหนดการใช้งาน TWP Training Platform</h4>
                                                    }
                                                    className="mt-15"
                                                />
                                            </Grid>
                                            <Grid item xs={12} className="d-flex align-items-center no-print">
                                                <ExpandableContent
                                                    actionsComponent={
                                                        <span className="d-flex align-items-center">
                                                            <CmtTitle variant="h5" content="ข้อกำหนดการใช้งานระบบ TWP Training" />
                                                        </span>
                                                    }
                                                    style={{ marginTop: "-12px", marginLeft: "20px" }}
                                                >
                                                    <Typography variant="h5" className="pl-10" style={{ marginTop: "-15px" }}>
                                                        <div className="training-spec">
                                                            <ArrowRightIcon /> ระบบเพื่อใช้สำหรับการ Training ข้อมูลการสร้างแผนจะไม่ถูกบันทึกในระบบใช้งานจริง
                                                        </div>
                                                        <div className="training-spec">
                                                            <ArrowRightIcon />เฉพาะวันที่ลงทะเบียนไว้เท่านั้น ข้อมูลการสร้างแผนจะถูกบันทึกตามระยะการจอง
                                                        </div>
                                                        <div className="training-spec">
                                                            <ArrowRightIcon />เฉพาะผู้ที่ได้รับอนุมัติการลงทะเบียนแล้วเท่านั้น
                                                        </div>
                                                        <div className="training-spec">
                                                            <ArrowRightIcon /><span style={{ color: "red" }}>ห้ามใช้ประโยชน์ในเชิงพาณิชย์</span>
                                                        </div>
                                                    </Typography>
                                                </ExpandableContent>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : null}



                            </div>
                            <div className="mr-10 no-print" style={{ textAlign: "right" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="mt-10"
                                    onClick={checkData}
                                    disabled={!consent}
                                >
                                    ลงทะเบียน
                                </Button>
                            </div>
                        </>
                    </Grow>
                </DialogContent> :
                <DialogContent
                    style={{ paddingTop: "0", paddingBottom: "1rem", overflowX: "hidden" }}>
                    <Grow in={true} timeout={400}>
                        <>
                            <div className="section">
                                <Grid container style={{ minHeight: "75px" }}>
                                    <Grid item xs={12} className="d-flex align-items-center">
                                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>ข้อมูลลงทะเบียน</span>
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        รูปแบบการทดลองใช้ :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-center mt-10">
                                        {trainingTypeList.find(element => element.value === state.data.Type).name || ""}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        เลขบัตรประชาชน :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-center mt-10">
                                        {state.data.CardID}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        ชื่อ-นามสกุล :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-center mt-10">
                                        {state.data.FirstName}{" "}{state.data.LastName}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        หน่วยงานสังกัด :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-center mt-10">
                                        {state.data.Type === 2 ?
                                            organization.find(item => item.ProvinceID === state.data.ProvinceID).ProvinceName || ""
                                            : state.data.Type === 1 ?
                                                organization.find(item => item.OrganizationID === state.data.OrganizationID).OrganizationName || ""
                                                : organization.find(item => item.OrganizationID === state.data.DLAOrganizationID).OrganizationName || ""
                                        }
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        ตำแหน่ง :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {state.data.Position}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        เบอร์โทร :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {state.data.PhoneNumber}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        E-mail :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {state.data.Email}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        Line ID :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {state.data.LineID || "-"}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        จำนวน User ที่ต้องการ :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {state.data.UserNumber}
                                    </Grid>
                                    <Grid item xs={3} className="d-flex align-items-center mt-10">
                                        วันที่ต้องการ :{" "}
                                    </Grid>
                                    <Grid item xs={9} className="d-flex align-items-cente mt-10">
                                        {previewDate.map(item => {
                                            return <>
                                                {item.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })} <br />
                                            </>

                                        })}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-10 d-flex">
                                <Button variant="contained" color="primary" className="mt-10" onClick={() => setShowConfirmModal(!showConfirmModal)}>
                                    กลับ
                                </Button>
                                <Button variant="contained" color="primary" className="mt-10 ml-auto" onClick={onSubmit}>
                                    ยืนยัน
                                </Button>
                            </div>
                        </>
                    </Grow>
                </DialogContent>
            }
        </Dialog >
    );
};

export default RegisterTrainingModal;
