import { setLocalStorage } from "../Service/_LocalStorageService";
import worker from '../Util/worker'
import jsonpack from "jsonpack";

const mapMasterCondition = (props) => {
    let masterDataSet = new Map();

    masterDataSet.set(
        "districtMasterData",
        !props.masterData?.GetDistrict ||
        !props.masterData?.GetSubdistrict ||
        !props.masterData?.GetDistrictObject ||
        !props.masterData?.GetSubdistrictObject
    );

    masterDataSet.set(
        "masterProject",
        !props.masterData?.GetOrganizationRelationObject ||
        !props.masterData?.GetOrganizationRelationObjectData ||
        !props.masterData?.GetOrganizationRelationObjectName ||
        !props.masterData?.GetDistrictObjectName ||
        !props.masterData?.GetSubdistrictObjectName ||
        !props.masterData?.GetActivityObject ||
        !props.masterData?.GetProjectTypeObject ||
        !props.masterData?.GetActivityObjectName ||
        !props.masterData?.GetProjectTypeObjectName ||
        !props.masterData?.GetAllStrategyKPIMapping ||
        !props.masterData?.GetStrategyObject ||
        !props.masterData?.GetStrategyObjectCode ||
        !props.masterData?.GetSubbasinObjectName ||
        !props.masterData?.GetActivity ||
        !props.masterData?.SearchProjectType ||
        !props.masterData?.GetAreaBase ||
        !props.masterData?.GetPrepareStatus ||
        !props.masterData?.GetProjectImportant ||
        !props.masterData?.GetStrategy ||
        !props.masterData?.GetAllStrategyKPI ||
        !props.masterData?.GetWorkplanGroup ||
        !props.masterData?.GetBudgetTypeRequest ||
        !props.masterData?.GetProjectTypeRequest
    );

    masterDataSet.set(
        "masterPlanMaster",
        !props.masterData?.GetMasterPlanCategory ||
        !props.masterData?.GetMasterPlanPlan ||
        !props.masterData?.GetMasterPlanPlanChild ||
        !props.masterData?.GetMasterPlanProduct
    );

    masterDataSet.set("organizationInternal", !props.masterData?.GetOrganizationInternal);

    masterDataSet.set("policyType", !props.masterData?.GetPolicyType);

    masterDataSet.set("waterResourceType", !props.masterData?.GetWaterResourceType);

    masterDataSet.set("searchTag", !props.masterData?.SearchTag);

    masterDataSet.set("layerPath", !props.masterData?.GetGEOServerProjectLayer || !props.masterData?.GetLayerPath);

    return masterDataSet;
};

export const checkMasterDataLoaded = (props, conditionSet = []) => {
    let mapConditionSet = mapMasterCondition(props);
    let isLoaded;

    for (const condition of conditionSet) {
        if (mapConditionSet.has(condition)) {
            isLoaded =
                typeof isLoaded !== "undefined"
                    ? isLoaded || mapConditionSet.get(condition)
                    : mapConditionSet.get(condition);
        }
    }

    return isLoaded;
};

export const checkDistrictMasterDataExist = (props) =>
    !props.masterData?.GetDistrict ||
    !props.masterData?.GetSubdistrict ||
    !props.masterData?.GetDistrictObject ||
    !props.masterData?.GetSubdistrictObject;

export const checkMasterPlanExist = (props) =>
    !props.masterData?.GetMasterPlanCategory ||
    !props.masterData?.GetMasterPlanPlan ||
    !props.masterData?.GetMasterPlanPlanChild ||
    !props.masterData?.GetMasterPlanProduct;

export const checkMasterProjectExist = (props) =>
    !props.masterData?.GetOrganizationRelationObject ||
    !props.masterData?.GetOrganizationRelationObjectData ||
    !props.masterData?.GetOrganizationRelationObjectName ||
    !props.masterData?.GetDistrictObjectName ||
    !props.masterData?.GetSubdistrictObjectName ||
    !props.masterData?.GetActivityObject ||
    !props.masterData?.GetProjectTypeObject ||
    !props.masterData?.GetActivityObjectName ||
    !props.masterData?.GetProjectTypeObjectName ||
    !props.masterData?.GetAllStrategyKPIMapping ||
    !props.masterData?.GetStrategyObject ||
    !props.masterData?.GetStrategyObjectCode ||
    !props.masterData?.GetSubbasinObjectName ||
    !props.masterData?.GetActivity ||
    !props.masterData?.SearchProjectType ||
    !props.masterData?.GetAreaBase ||
    !props.masterData?.GetPrepareStatus ||
    !props.masterData?.GetProjectImportant ||
    !props.masterData?.GetStrategy ||
    !props.masterData?.GetAllStrategyKPI ||
    !props.masterData?.GetWorkplanGroup ||
    !props.masterData?.GetBudgetTypeRequest ||
    !props.masterData?.GetProjectTypeRequest;

export const setMasterProjectOrg = ({ data }) => {
    if (!data) return;
    data.Org = [];
}

export const serviceWrapper = async (storageName, service) =>
    service.then((res) => {
        if (res.errors) return res;

        worker(res.data)
            .then((packedData) => {
                if(!packedData) {
                    return setLocalStorage(storageName, jsonpack.pack(res.data));
                };            
                setLocalStorage(storageName, packedData);
            })
            .catch((e) => {
                console.error(e);
            });

        if (storageName === "masterProject") setMasterProjectOrg(res);

        return res;
    });
