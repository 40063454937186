import React from "react";
import {
    AppBar,
    Toolbar,
    Box,
    Container,
    Button,
    Grid,
    Typography,
    Grow,
    Tooltip,
    Fade
} from "@material-ui/core";
import { Description, KeyboardArrowRight, Videocam } from "@material-ui/icons";
import { connect } from "react-redux";
import { hideMenu, showMenu, showLoading, hideLoading } from "../../redux/action";
import { setLocalStorage } from "../../Service/_LocalStorageService";
import { withStyles } from '@material-ui/core/styles';
import DailyFeed from "./DailyFeed";
import SplitButton from '../../Util/SplitButton';
import LoginModal from './LoginModal/LoginModal';
import ImportantModal from './ImportantModal';
import VideoModal from './VideoModal';

const CustomTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 1000,
        fontSize: 19,
        border: '1px solid #dadde9'
    },
}))(Tooltip);

class LandingPage extends React.Component {
    state = {
        showModal: false,
        showVideoModal: false,
        showModalImportant: false,
        showHeadlines: false,
        hover: []
    }
    timeoutModal = null;
    headlinesList = [];

    componentDidMount() {
        this.props.dispatch(hideMenu());
        this.getHeadlines();

        this.timeoutModal = setTimeout(() => {
            this.setState({ showModalImportant: false });
            setLocalStorage("showImportantMessage", "");
        }, 30000);
    }

    componentWillUnmount() {
        if (this.timeoutModal) {
            clearTimeout(this.timeoutModal);
            setLocalStorage("showImportantMessage", "");
        }
        this.props.dispatch(showMenu());
    }

    setShowModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    setShowModalImportant = () => {
        this.setState({ showModalImportant: false });
        setLocalStorage("showImportantMessage", "");
    }

    setShowVideoModal = () => {
        this.setState({ showVideoModal: !this.state.showVideoModal });
    }

    getHeadlines = () => {
        const url = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';
        fetch(`${url}/twp/v2/upload/news/headlines.json`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                this.headlinesList = data;
                this.headlinesList.length > 0 ? this.setState({ showHeadlines: true }) : this.setState({ showHeadlines: false });
                this.setHover();
            })
            .catch(() => {
                this.headlinesList = [];
            });
    }

    handleHover = (index) => {
        for (let i = 0; i < this.state.hover.length; i++) {
            let newHover = [...this.state.hover];
            newHover[index] = !this.state.hover[index];
            this.setState({
                hover: newHover
            })
        }
    }

    setHover = () => {
        for (let i = 0; i < this.headlinesList.length; i++) {
            let initHover = [...this.state.hover];
            initHover[i] = true;
            this.setState({
                hover: initHover
            })
        }
    }

    buttonOptions = [
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                คู่มือการจัดทำแผนปฏิบัติการด้านทรัพยากรน้ำ ผ่าน TWP
            </Box>,
            onClick: () => window.open("/twp/v2/files/twp_manual_full.pdf", '_blank')
        },
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                คู่มือการใช้งาน TWP Dashboard
            </Box>,
            onClick: () => window.open("/twp/v2/files/twp_manual_dashboard.pdf", '_blank')
        },
        // {
        //     name: <Box display="flex" alignItems="center"><Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} /> วิดีโอสอนการใช้งาน TWP </Box>,
        //     // onClick: () => window.open("https://drive.google.com/drive/folders/1lHsVK41_j47qAA3aZXOk5D4IiCeo9FZf?usp=sharing", '_blank')
        //     onClick: () => {
        //         document.body.click();
        //         this.setShowVideoModal();
        //     }
        // },
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                แบบฟอร์ม สทนช 001
            </Box>,
            onClick: () => window.open("/twp/v2/files/แบบฟอร์ม_สทนช_001.xlsx", '_blank')
        },
        {
            name: <Box
                display="flex"
                alignItems="center"
            >
                <Videocam fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
                VDO สอนการใช้งาน
            </Box>,
            onClick: () => {
                document.body.click();
                this.setShowVideoModal();
            }
        },
        // {
        //     name: <Box
        //         display="flex"
        //         alignItems="center"
        //     >
        //         <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
        //         แบบฟอร์ม สทนช 004 ปี 2566
        //     </Box>,
        //     onClick: () => window.open("/twp/v2/files/แบบฟอร์ม_สทนช_004.xlsx", '_blank')
        // },
        // {
        //     name: <Box
        //         display="flex"
        //         alignItems="center"
        //     >
        //         <Description fontSize="small" style={{ color: '#777777', marginRight: '3px' }} />
        //         คู่มือการ Sign-Off แผนงาน
        //     </Box>,
        //     onClick: () => window.open("/twp/v2/files/twp_manual_signoff.pdf", '_blank')
        // },
    ];

    render() {
        return (
            <>
                <div className="page-wrapper">
                    <AppBar className="header" position="static" color="transparent">
                        <Container maxWidth="lg" style={{ position: "relative" }}>
                            <Toolbar>
                                <Box flexGrow={1}>
                                    <img className="logo" src="/images/logo-black.png" style={{ maxWidth: "initial" }} alt="logo black" />
                                </Box>
                                <Box display={"flex"} alignItems="center" flexWrap={"wrap"}>
                                    <Button
                                        onClick={() => window.open("/twp/v2/files/twp_budget_calendar.pdf", '_blank')}
                                    >
                                        ปฎิทินการจัดทำงบประมาณ
                                    </Button>
                                    <a href="/about/">
                                        <Button>
                                            เกี่ยวกับ TWP
                                        </Button>
                                    </a>
                                    <SplitButton
                                        className="custom-button-navbar"
                                        btnStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                                        style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                                        text='การใช้งานระบบ'
                                        options={this.buttonOptions}
                                    />
                                    <Button onClick={() => window.open("https://twp.onwr.go.th/public_dashboard.php", '_blank')}>
                                        TWP Dashboard
                                    </Button>
                                    <Button onClick={() => window.open("https://training.thaiwaterplan.com/twp/v2/landingpage", '_blank')}>
                                        Training Platform
                                    </Button>
                                    {
                                        this.props.isAuth ?
                                            <Button
                                                onClick={() => this.props.history.push("/home")}
                                                size="large"
                                                color="primary"
                                                className="ml-05"
                                                variant="contained"
                                            >
                                                <Typography style={{ fontWeight: "bolder" }}>
                                                    Home
                                                </Typography>
                                            </Button>
                                            :
                                            <Button
                                                onClick={this.setShowModal}
                                                size="large"
                                                color="primary"
                                                className="ml-05"
                                                variant="contained"
                                            >
                                                <Typography style={{ fontWeight: "bolder" }}>
                                                    เข้าสู่ระบบ
                                                </Typography>
                                            </Button>
                                    }
                                    {/* <span
                                        onMouseEnter={() => this.setState({ hover: true })}
                                        onMouseLeave={() => this.setState({ hover: false })}
                                        className="ml-03 mt-02"
                                    >
                                        <Info />
                                    </span> */}
                                </Box>
                            </Toolbar>
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"flex-end"}>
                                {
                                    this.state.showHeadlines ?
                                        <>
                                            <Grow
                                                in={true}
                                                style={{ transformOrigin: 'bottom right' }}
                                                timeout={450}
                                            >
                                                <Fade
                                                    in={true}
                                                    direction="right"
                                                    timeout={450}
                                                >
                                                    <div>
                                                        {this.headlinesList.map((item, index) => {
                                                            return (
                                                                <CustomTooltip title={item.Content} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                                                                    <div className={"notification" + (this.state.hover[index] ? "" : " notification-close")} style={{ backgroundColor: item.BackgroundColor, marginBottom: "10px" }}>
                                                                        <div className="notification-content">
                                                                            <div className="notification-identifier" style={{ backgroundColor: item.TagColor }}
                                                                                onClick={() => this.handleHover(index)}></div>
                                                                            <div className="notification-text">
                                                                                {item.Content.length > 185 ? item.Content.substring(0, 185) + "..." : item.Content}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CustomTooltip>
                                                            )
                                                        })}
                                                    </div>
                                                </Fade>
                                            </Grow>
                                        </>
                                        : null
                                }
                            </Box>
                        </Container>
                    </AppBar>
                    <Container className="content" maxWidth="lg">
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid className="box-title my-20" item xs={12} md={5}>
                                <Typography
                                    className="title-main"
                                    variant="h1"
                                    gutterBottom
                                // style={{ fontSize: "2.5rem" }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs className="d-flex align-items-center" style={{ marginRight: "3rem" }}>
                                            TWP
                                        </Grid>
                                        {/* <Grid item xs>
                                            <a
                                                target="_blank"
                                                href="https://confirm.thaiwaterplan.com"
                                            >
                                                <Button
                                                    variant="contained"
                                                    className="btn-orange ml-05"
                                                    style={{ width: "260px" }}
                                                >
                                                    <div style={{ fontSize: "1.2rem", lineHeight: "160%" }}>
                                                        ยืนยัน แผนปฏิบัติการ<br />
                                                        ด้านทรัพยากรน้ำ<br />
                                                        ประจำปีงบประมาณ<br />
                                                        พ.ศ. 2567
                                                    </div>
                                                    <KeyboardArrowRight className="ml-03" />
                                                </Button>
                                            </a>
                                        </Grid> */}
                                    </Grid>
                                </Typography>
                                <Typography className="title-second" variant="h3" gutterBottom>
                                    Thai Water Plan
                                </Typography>
                                <Typography variant="h5" gutterBottom>
                                    <div>ระบบบริหารจัดการแผนงานโครงการและฐานข้อมูล</div>
                                    <div>สำหรับบูรณาการแผนเพื่อการบริหารจัดการทรัพยากรน้ำของประเทศ</div>
                                </Typography>

                                <DailyFeed
                                    account={this.props?.account?.RoleID}
                                    isAuth={this.props.isAuth}
                                    permissions={this.props?.account?.Permissions}
                                    setHeadlines={this.getHeadlines}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Box display={{ xs: "none", md: "block" }}>
                                    <img style={{ maxHeight: '638px', maxWidth: 'initial' }} src="/images/hero-2-img_main.png" alt="hero-  img" />
                                    {/* <div style={{ minHeight: '638px', Width: '1px' }}></div> */}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            spacing={3}
                        >
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Box className="box-footer">
                                    <div>© 2017-2022 สำนักงานทรัพยากรน้ำแห่งชาติ</div>
                                    <div>อาคารจุฑามาศ เลขที 89/168-170 ถนนวิภาวดีรังสิต</div>
                                    <div>แขวงตลาดบางเขน เขตหลักสี่ กรุงเทพฯ 10210</div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                {
                    this.props.isAuth ?
                        null
                        :
                        <LoginModal
                            show={this.state.showModal}
                            setShow={this.setShowModal}
                            history={this.props.history}
                            dispatch={this.props.dispatch}
                            setMasterData={this.props.setMasterData}
                            masterData={this.props.masterData}
                            showLoading={() => this.props.dispatch(showLoading())}
                            hideLoading={() => this.props.dispatch(hideLoading())}
                        />
                }
                <ImportantModal
                    show={this.state.showModalImportant}
                    setShow={this.setShowModalImportant}
                />
                <VideoModal
                    show={this.state.showVideoModal}
                    setShow={this.setShowVideoModal}
                />
            </>
        );
    }
};

const mapStatetoProps = (state) => ({
    isAuth: state.isAuth,
    masterData: state.masterData,
    account: state.account

});

export default connect(mapStatetoProps)(LandingPage);
