import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';

const useGoogleAnalytic = (account) => {
    const location = useLocation();
    const history = useHistory();
    const [state, setState] = useState({
        initiated: false,
        startTime: Date.now(),
        prevLocation: location.pathname,
        APIKey: null
    });

    useEffect(() => {
        let GAKey = account?.APIKeys?.GoogleAnalytics;
        if (GAKey) {
            ReactGA.initialize(GAKey);
        }
    }, [account]);
    
    useEffect(() => {
        const getPath = (pathname) => {
            let splitPath = pathname.split("/");
            let filterPath = splitPath.filter(p => isNaN(parseInt(p)));
            let validPath = ""

            for(let i = 0; i < filterPath.length; i++) {
                let p = filterPath[i]
                if (p !== "") validPath = `${validPath}/${p}`
            }
            
            return validPath
        }

        if(!account?.Username) return 

        if(!state.initiated) {
            ReactGA.send({ hitType: "pageview", page: getPath(location.pathname) });

            setState(prevState => ({
                ...prevState, 
                initiated: true,
                startTime: Date.now()
            }));

            history.listen((locations) => {
                ReactGA.send({ hitType: "pageview", page: getPath(locations.pathname) });
            });

        } else {
            // let pageSpentTime = (Date.now() - state.startTime)/1000;
            // let payload = {
            //     page_location: state.prevLocation,  
            //     user: username ?? "anonymous",
            //     total_time: Math.round(pageSpentTime)
            // }

            // if (payload.variable !== "/") {
            //     ReactGA._gtag("event", "time_on_page", payload);
            // }

            setState(prevState => ({
                ...prevState, 
                startTime: Date.now(),
                prevLocation: getPath(location.pathname)
            }));
        }        
        
    }, [location.pathname, account])

    return null
} 

export default useGoogleAnalytic;
