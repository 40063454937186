import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from 'react-redux';
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import Home from '../App/Home/Home';
import NotFound from '../App/404/404';
import LandingPage from '../App/LandingPage/LandingPage';
import LandingPageTraining from '../App/LandingPage/Training/LandingPageTraining';
import Map from '../App/Map/Map';
import ReleasedVersions from '../App/ReleasedVersions/ReleasedVersions';
import ReportProjectImportant from "../App/Report/ReportProjectImportant";
import PDPA from "../App/PDPA/PDPA";

const OrgManage = lazy(() => import('../App/Orgnizationmanage/OrganizationManage'));
const OrgInternalManage = lazy(() => import('../App/OrgnizationInternalmanage/OrganizationInternalManage'));
const UserManage = lazy(() => import('../App/Usermanage/UserManage'));
const AccountLog = lazy(() => import('../App/AccountLog/AccountLog'));
const StrategyManage = lazy(() => import('../App/Strategymanage/StrategyManage'));
const ActivityManage = lazy(() => import('../App/Activitymanage/ActivityManage'));
const KPIUnitManage = lazy(() => import('../App/KPIUnitManage/KPIUnitManage'));
const TagManage = lazy(() => import('../App/Tagmanage/TagManage'));
const KPIManage = lazy(() => import('../App/KPImanage/KPIManage'));
const Project = lazy(() => import('../App/Project/Project'));
const MasterPlan = lazy(() => import('../App/MasterPlan/MasterPlan'));
const IncidentCategoryManage = lazy(() => import('../App/Incidentcategorymanage/IncidentCategoryManage'));
const IncidenttypeManage = lazy(() => import('../App/Incidenttypemanage/IncidentTypeManage'));
const ProjectTypeManage = lazy(() => import('../App/ProjectTypemanage/ProjectTypeManage'));
const ProvinceGroupManage = lazy(() => import('../App/ProvinceGroupmanage/ProvinceGroupManage'));
const Report = lazy(() => import('../App/Report/Report'));
const ReportProjectRequest = lazy(() => import('../App/Report/ReportProjectRequest'));
const ReportMinistry = lazy(() => import('../App/ReportMinistry/ReportMinistry'));
const IncidentManage = lazy(() => import('../App/Incidentmanage/IncidentManage'));
const ResetPassword = lazy(() => import('../App/ResetPassword/ResetPassword'));
const ProfileManage = lazy(() => import('../App/Profile/Profile'));
const PermissionDate = lazy(() => import('../App/PermissionDate/PermissionDate'));
const Settings = lazy(() => import('../App/Settings/Settings'));
const History = lazy(() => import('../App/Historymanage/HistoryManage'));
const Signoff = lazy(() => import('../App/Signoff/Signoff'));
const SignoffManage = lazy(() => import('../App/SignoffManage/SignoffManage'));
const SignoffDuplicate = lazy(() => import('../App/SignoffDuplicate/SignoffDuplicate'));
const SignoffStatusCheck = lazy(() => import('../App/SignoffStatusCheck/SignoffStatusCheck'));
const FileRecovery = lazy(() => import('../App/FileRecovery/FileRecovery'));
const TrainingRequest = lazy(() => import('../App/TrainingRequest/TrainingRequest'));
const ProjectTransfer = lazy(() => import('../App/ProjectTransfer/ProjectTransfer'));
const Wresource = lazy(() => import("../App/Wresource/Wresource"));
const EmailChecker = lazy(() => import('../App/EmailChecker/EmailChecker'));
const OrderProfile = lazy(() => import('../App/OrderProfile/OrderProfile'));

const ProjectImportant = lazy(() => import('../App/ProjectImportant/ProjectImportant'));
const RoadmapImportant = lazy(() => import('../App/RoadmapImportant/RoadmapImportant'));
const ProjectRequest = lazy(() => import('../App/ProjectRequest/ProjectRequest'));
const CashFlow = lazy(() => import('../App/CashFlow/CashFlow'));
const MasterPlanB = lazy(() => import('../App/MasterPlanB/MasterPlanB'));
const ProjectRequestSignoff = lazy(() => import('../App/ProjectRequestSignoff/ProjectRequestSignoff'));
const ProjectImportantSignoff = lazy(() => import('../App/ProjectImportantSignoff/ProjectImportantSignoff'));
const ProjectImportantMap = lazy(() => import('../App/MapProjectImportant/Map'));
const ProjectRequestMap = lazy(() => import('../App/MapProjectRequest/Map'));

const ProjectRequestPersonManage = lazy(() => import('../App/ProjectRequestPersonManage/PersonManage'));
const GroupImportantManage = lazy(() => import('../App/ProjectImportantGroupManage/GroupManage'));

// const RestrictedRoute = ({ component: Component, isAuth, ...rest }) => {
// 	console.log(isAuth);
// 	return (
// 		<Route
// 			{...rest}
// 			render={props =>
// 				isAuth ? (
// 					<Component {...props} />
// 				) : (
// 					<Redirect
// 						to={{
// 							pathname: "/landingpage",
// 							state: { from: props.location }
// 						}}
// 					/>
// 				)}
// 		/>
// 	);
// };

const Redirect404 = () => {
	useEffect(() => {
		window.location.replace(`${window.origin}/404`);
	}, []);
	return null;
}

const Routes = (props) => {
	const { isAuth, account, consentID, trainingPlatform } = useSelector(({ isAuth, account, consentID, trainingPlatform }) => ({ isAuth, account, consentID, trainingPlatform }));

	return (
		<Suspense fallback={
			<div style={{ height: '100vh' }}>
				<Box className={"circularProgressRoot"}>
					<CircularProgress />
				</Box>
			</div>
		}
		>
			<Switch>
				<Route key={0} path="/resetpassword/:resetToken" exact component={ResetPassword} />
				{
					trainingPlatform ?
						<Route key={0} path="/landingpage" exact render={(componentProps) => <LandingPageTraining setMasterData={props.setMasterData} history={componentProps.history} />} />
						:
						<Route key={0} path="/landingpage" exact render={(componentProps) => <LandingPage setMasterData={props.setMasterData} history={componentProps.history} />} />
				}
				{
					(consentID && isAuth) ?
						<Route path="/privacy" render={() => (<Redirect to="/home" />)} /> :
						<Route key={0} path="/privacy" exact component={PDPA} />
				}
				{
					isAuth ? <Route path="/" exact render={() => (<Redirect to="/home" />)} />
						: <Route path="/" render={() => (<Redirect to="/landingpage" />)} />
				}
				{/* {
					isAuth ? <Route path="/" exact render={() => (<Redirect to="/home" />)} />
						: null
				} */}
				{
					account?.AccountID && !consentID ?
						<Route path="/" render={() => (<Redirect to="/privacy" />)} />
						: null
				}
				<Route key={0} path="/releasedversions" exact component={ReleasedVersions} />
				{
					(account?.Permissions) ?
						<Route key={0} path="/home" exact component={Home} />
						: null
				}
				{
					(account?.Permissions?.ProjectData) ?
						[
							<Route key={0} path="/project" exact component={Project} />,
							<Route key={0} path="/project/projectlist" exact component={Project} />,
							<Route key={0} path="/project/projectdetail" exact render={props => <Redirect to={{ pathname: "/project/projectlist", state: { from: props.location } }} />} />,
							<Route key={0} path="/project/projectdetail/:projectid" exact component={Project} />,
							<Route key={0} path="/project/projectgui/001/add" exact component={Project} />,
							<Route key={0} path="/project/projectgui/004/add" exact component={Project} />,
							<Route key={0} path="/project/projectgui/001/:projectid" exact component={Project} />,
							<Route key={0} path="/project/projectgui/004/:projectid" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/001/add" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/001cashflow/add" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/004/add" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/001/edit" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/001cashflow/edit" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/004/edit" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/001/:projectid" exact component={Project} />,
							<Route key={0} path="/project/spreadsheet/004/:projectid" exact component={Project} />,

							<Route key={0} path="/masterplan/masterplanlist" exact component={MasterPlan} />,
							<Route key={0} path="/masterplan/detail/:masterplanid" exact component={MasterPlan} />,
							<Route key={0} path="/masterplan/detail/important/add/:projectid" exact render={(props) => <MasterPlan {...props} type="important" />} />,
							<Route key={0} path="/masterplan/detail/:masterplanid/:projectid" exact component={MasterPlan} />,

							<Route key={0} path="/cashflow/cashflowdetail/:projectid" exact component={CashFlow} />,

							<Route key={0} path="/signoff/duplicate" exact component={SignoffDuplicate} />,
							<Route key={0} path="/signoff/duplicate/list" exact component={SignoffDuplicate} />,
							<Route key={0} path="/signoff/duplicate/compare" exact component={SignoffDuplicate} />,
							<Route key={0} path="/signoff/duplicate/compare/:signoffid" exact component={SignoffDuplicate} />,
							<Route key={0} path="/signoff/status" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/list" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/detail" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/detail/:signoffid" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/orderedit" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/scoreweight" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/priority" exact component={SignoffStatusCheck} />,
							<Route key={0} path="/signoff/status/importpriority" exact component={SignoffStatusCheck} />,
						]
						: null
				}
				{
					(account?.Permissions?.ProjectImportant) ?
						[
							<Route key={0} path="/projectimportant/projectlist" exact component={ProjectImportant} />,
							<Route key={0} path="/projectimportant/projectdetail" exact component={ProjectImportant} />,
							<Route key={0} path="/projectimportant/projectdetail/:projectid" exact component={ProjectImportant} />,
							<Route key={0} path="/projectimportant/projectgui/001/add" exact component={ProjectImportant} />,
							<Route key={0} path="/projectimportant/projectgui/001/:projectid" exact component={ProjectImportant} />,
							<Route key={0} path="/projectimportant/roadmap" exact component={RoadmapImportant} />,
							<Route key={0} path="/projectimportant/map" exact component={ProjectImportantMap} />,
						]
						:
						null
				}
				{
					(account?.Permissions?.ProjectImportantSignoff) ?
						[
							<Route key={0} path="/projectimportant/signoff" exact component={ProjectImportantSignoff} />,
						]
						:
						null
				}
				{
					(account?.Permissions?.ProjectRequest) ?
						[
							<Route key={0} path="/projectrequest/projectlist" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectdetail" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectdetail/:projectid" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectgui/001/add" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectgui/001/add/:projectid" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectgui/001/duplicate/:projectid" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/projectgui/001/:projectid" exact component={ProjectRequest} />,
							<Route key={0} path="/projectrequest/map" exact component={ProjectRequestMap} />,

							<Route key={0} path="/masterplanb/masterplanblist" exact component={MasterPlanB} />,
							<Route key={0} path="/masterplanb/detail/:masterplanid" exact component={MasterPlanB} />,
							<Route key={0} path="/masterplanb/detail/projectrequest/:projectrequestCode" exact component={MasterPlanB} />,
							<Route key={0} path="/masterplanb/detail/add/:projectid" exact component={MasterPlanB} />,
							<Route key={0} path="/masterplanb/detail/:masterplanid/:projectid" exact component={MasterPlanB} />,
						]
						:
						null
				}
				{
					(account?.Permissions?.ProjectRequestSignoff || account?.Permissions?.ProjectRequestSignoff1 || account?.Permissions?.['ProjectRequestSignoff-1'] ||
						account?.Permissions?.['ProjectRequestSignoff-2'] || account?.Permissions?.['ProjectRequestSignoff-3'] || account?.Permissions?.['ProjectRequestSignoff7']) ?
						[
							<Route key={0} path="/projectrequest/signoff" exact component={ProjectRequestSignoff} />,
						]
						:
						null
				}
				{
					(account?.Permissions?.Signoff) ?
						[
							<Route key={0} path="/signoff" exact component={Signoff} />,
							<Route key={0} path="/signoff/detail/:signoffid" exact component={Signoff} />,
							<Route key={0} path="/signoffmanage" exact component={SignoffManage} />,
							<Route key={0} path="/signoffmanage/add" exact component={SignoffManage} />,
							<Route key={0} path="/signoffmanage/detail/:signoffid" exact component={SignoffManage} />,
							<Route key={0} path="/signoffmanage/detail/member/:signoffid/:signoffstepid" exact component={SignoffManage} />,

							<Route key={0} path="/settings/orderprofile/list" exact component={OrderProfile} />,
							<Route key={0} path="/settings/orderprofile/detail" exact render={props => <Redirect to={{ pathname: "/settings/orderprofile/list", state: { from: props.location } }} />} />,
							<Route key={0} path="/settings/orderprofile/detail/:orderid" exact component={OrderProfile} />,
							<Route key={0} path="/settings/orderprofile/gui/:action/:orderid" exact component={OrderProfile} />,
						]
						: null
				}
				{
					(account?.Permissions?.Map) ?
						<Route key={0} path="/map" exact component={Map} />
						: null
				}
				{/* {
					(account?.Permissions?.Export) ?
						[
							<Route key={0} path="/report" exact component={Report} />,
							<Route key={0} path="/reportprojectrequest" exact component={ReportProjectRequest} />,
							<Route key={0} path="/reportprojectimportant" exact component={ReportProjectImportant} />,
							<Route key={0} path="/export" exact render={() => <div>export</div>} />
						]
						: null
				} */}
				{
					(account?.Permissions?.Export) ?
						[
							<Route key={0} path="/report" exact component={Report} />
						]
						: null
				}
				{
					(account?.Permissions?.ProjectRequestExport) ?
						[
							<Route key={0} path="/reportprojectrequest" exact component={ReportProjectRequest} />
						]
						: null
				}
				{
					(account?.Permissions?.ProjectImportantExport) ?
						[
							<Route key={0} path="/reportprojectimportant" exact component={ReportProjectImportant} />
						]
						: null
				}
				{
					(account?.Permissions?.Export) ?
						[
							<Route key={0} path="/export" exact render={() => <div>export</div>} />
						]
						: null
				}
				{
					(account?.Permissions?.ReportMinistry) ?
						[
							<Route key={0} path="/reportministry" exact component={ReportMinistry} />,
							<Route key={0} path="/reportministry/:reportid" exact component={ReportMinistry} />,
						]
						: null
				}
				{
					(account?.Permissions?.Dashboard) ?
						<Route key={0} path="/dashboard" exact render={() => <div>dashboard</div>} />
						: null
				}
				{
					(
						account?.Permissions?.MasterData ||
						account?.Permissions?.Account ||
						account?.Permissions?.Organization
					) ?
						<Route key={0} path="/settings" exact component={Settings} />
						: null
				}
				{
					(account?.Permissions?.Account) ?
						<Route key={0} path="/settings/user" exact component={UserManage} />
						: null
				}
				{
					(account?.Permissions?.Organization) ?
						<Route key={0} path="/settings/organization" exact component={OrgManage} />
						: null
				}
				{
					(account?.Permissions?.MasterData) ?
						[
							<Route key={0} path="/incident" exact component={IncidentManage} />,
							<Route key={0} path="/settings/kpi" exact component={KPIManage} />,
							<Route key={0} path="/settings/kpiunit" exact component={KPIUnitManage} />,
							<Route key={0} path="/settings/permission" exact render={() => <div>permission</div>} />,
							<Route key={0} path="/settings/strategy" exact component={StrategyManage} />,
							<Route key={0} path="/settings/projecttype" exact component={ProjectTypeManage} />,
							<Route key={0} path="/settings/provincegroup" exact component={ProvinceGroupManage} />,
							<Route key={0} path="/settings/incidenttype" exact component={IncidenttypeManage} />,
							<Route key={0} path="/settings/incidentcategory" exact component={IncidentCategoryManage} />,
							<Route key={0} path="/settings/tag" exact component={TagManage} />,
							<Route key={0} path="/settings/activity" exact component={ActivityManage} />,
							<Route key={0} path="/settings/permissiondate" exact component={PermissionDate} />,
							<Route key={0} path="/settings/projectrequestperson" exact component={ProjectRequestPersonManage} />,
							<Route key={0} path="/settings/organizationinternal" exact component={OrgInternalManage} />,
							<Route key={0} path="/settings/importantgroup" exact component={GroupImportantManage} />
						]
						: null
				}
				{
					account?.Permissions?.AccountLog ?
						[
							<Route key={0} path="/settings/accountlog" exact component={AccountLog} />
						] : null
				}
				{
					(account?.Permissions?.EmailCheck) ?
						[
							<Route key={0} path="/settings/emailcheck" exact component={EmailChecker} />
						]
						: null
				}
				{
					(account?.Permissions?.MasterDataPerson) ?
						[
							<Route key={0} path="/settings/projectrequestperson" exact component={ProjectRequestPersonManage} />
						]
						: null
				}
				{
					(account?.Permissions?.FileRecovery) ?
						[<Route key={0} path="/settings/filerecovery" exact component={FileRecovery} />]
						: null
				}
				{
					(account?.Permissions?.TrainingRequest) ?
						[<Route key={0} path="/settings/TrainingRequest" exact component={TrainingRequest} />]
						: null
				}
				{
					(account?.Permissions?.ProjectTransfer) ?
						[<Route key={0} path="/settings/projecttransfer" exact component={ProjectTransfer} />]
						: null
				}

				<Route key={0} path="/wresource/wresourcelist" exact component={Wresource} />,
				<Route key={0} path="/wresource/wresourceverify" exact component={Wresource} />,
				<Route key={0} path="/wresource/wresourcedetail/:wresourceid" exact component={Wresource} />,

				<Route key={0} path="/settings/history" exact component={History} />,
				<Route key={0} path="/settings/profile" exact component={ProfileManage} />,
				{/* <Route key={0} path="/" component={Redirect404} /> */}
				{
					(account?.Permissions) ?
						<Route key={0} path="/" component={Redirect404} />
						: null
				}
			</Switch>
		</Suspense>
	);
};

export default Routes;
