import React from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    Box,
    Typography
} from '@material-ui/core';
import {
    Close,
    Book,
    Save
} from '@material-ui/icons';
//import Swal from 'sweetalert2';
import TextFieldMemo from '../../Util/TextFieldMemo';

class PresetModal extends React.Component {
    state = {
        canUpdate: false,
        PresetCollectionName: "",
        PresetData: {}
    }

    resetState = () => {
        this.setState({
            canUpdate: true,
            PresetCollectionName: "",
            PresetData: {}
        })
    }

    onEnterModal = () => {
        let data = {
            canUpdate: true,
            PresetCollectionName: "",
            PresetData: {}
        };
        if (this.props.filter?.PresetCollectionID) {
            data.PresetCollectionName = this.props.preset.find(x => x.PresetCollectionID === this.props.filter.PresetCollectionID)?.PresetCollectionName;
        }

        ///// Section 1 /////

        data.PresetData.ProjectName = this.props.filter.ProjectName;
        data.PresetData.ProjectCode = this.props.filter.ProjectCode;
        data.PresetData.BudgetYear = this.props.filter.BudgetYear?.map(x => x).sort((a, b) => a - b).join(", ");
        if (this.props.filter.OrganizationID?.length) {
            data.PresetData.OrganizationName = this.props.masterData.GetAllOrganization.filter(x => this.props.filter.OrganizationID.includes(x.OrganizationID)).map(x => x.OrganizationName).join(", ");
        }

        this.setState(data);
    }

    setData = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <Dialog
                onClose={this.props.setShowPreset}
                aria-labelledby="preset-dialog"
                open={this.props.showPreset}
                fullWidth
                maxWidth='sm'
                TransitionProps={{
                    onEnter: this.onEnterModal,
                    onExited: this.resetState
                }}
            >
                <DialogTitle disableTypography className="d-flex align-items-center">
                    <Book className="mr-05" />
                    <Typography variant="h4">จัดการ Preset Collection</Typography>
                    <IconButton className="ml-auto p-02" onClick={this.props.setShowPreset}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box marginBottom={4}>
                        <Box>
                            <TextFieldMemo
                                label="ชื่อ Preset"
                                variant="outlined"
                                size="small"
                                value={this.state.PresetCollectionName ?? ''}
                                name='PresetCollectionName'
                                onChange={this.setData}
                                fullWidth
                            />
                        </Box>
                        <Box marginTop={3}>
                            <Box className="section-header">
                                <div>รายละเอียด Preset</div><hr />
                            </Box>
                        </Box>
                        <Box marginTop={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h5">ชื่อโครงการ</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box display="flex" flexWrap="wrap">
                                        {this.state.PresetData.ProjectName ?? "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginTop={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h5">รหัสโครงการ</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box display="flex" flexWrap="wrap">
                                        {this.state.PresetData.ProjectCode ?? "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginTop={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h5">ปีงบประมาณ</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box display="flex" flexWrap="wrap">
                                        {this.state.PresetData.BudgetYear ?? "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginTop={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="h5">หน่วยรับงบประมาณ</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box display="flex" flexWrap="wrap">
                                        {this.state.PresetData.OrganizationName ?? "-"}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions className="modal-footer">
                    <Button
                        variant='contained'
                        color='default'
                        className='btn-success'
                        size="small"
                    // onClick={this.saveActivity}
                    >
                        <Save className="mr-03" />
                        บันทึกข้อมูล
                    </Button>
                    <Button
                        variant='contained'
                        onClick={this.props.setShowPreset}
                        size="small"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PresetModal;