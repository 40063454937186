import React, { useState } from 'react';
import {
    Box,
    Button,
    Grow,
    IconButton,
} from '@material-ui/core';
import {
    Close,
    Room,
    Search,
} from "@material-ui/icons";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import TextFieldMemo from '../../Util/TextFieldMemo';
import Swal from 'sweetalert2';

const MapLatLng = (props) => {
    const {
        showLatLngWindow,
        setShowLatLngWindow,
        handleClickHoldMap,
        onChangeAreaCircleVisible
    } = props;
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);

    const searchLatLng = () => {
        if (!handleClickHoldMap) return;
        if (
            (
                (!lat && lat !== 0) ||
                lat > 90
            ) ||
            (
                (!lng && lng !== 0) ||
                lng > 180
            )
        ) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                text: "กรุณาระบุพิกัดให้ถูกต้อง",
                icon: "warning"
            });
            return;
        }
        let data = {
            latLng: {
                lat: () => lat,
                lng: () => lng
            }
        }
        onChangeAreaCircleVisible(true);
        handleClickHoldMap(data, 13);
    }

    return (
        <Grow in={showLatLngWindow} className="map-filter d-flex flex-column" style={{ minHeight: "auto" }}>
            <CmtCard>
                <div className="section-header main d-flex align-items-center py-05 px-10 mb-0">
                    <Room className="mr-05" />
                    <h4>ค้นหาตำแหน่ง</h4>
                    <IconButton className="ml-auto p-02 text-default" onClick={setShowLatLngWindow}>
                        <Close />
                    </IconButton>
                </div>
                <hr className="hr-light" />
                <CmtCardContent className="flex-fill overflowY-auto overflowX-hidden">
                    <div>
                        <TextFieldMemo
                            label={
                                <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
                                    <Room className="mr-03" /> Lat
                                </div>
                            }
                            placeholder="Lat"
                            variant="outlined"
                            size="small"
                            value={lat ?? ''}
                            name='searchLayer'
                            onChange={(e) => setLat(e.target.value)}
                            onBlur={(e) => setLat(parseFloat(e.target.value) || null)}
                            fullWidth
                        />
                    </div>
                    <div className="mt-10">
                        <TextFieldMemo
                            label={
                                <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
                                    <Room className="mr-03" /> Lng
                                </div>
                            }
                            placeholder="Lng"
                            variant="outlined"
                            size="small"
                            value={lng ?? ''}
                            name='searchLayer'
                            onChange={(e) => setLng(e.target.value)}
                            onBlur={(e) => setLng(parseFloat(e.target.value) || null)}
                            fullWidth
                        />
                    </div>
                    <Box display="flex" justifyContent="center" flexWrap="wrap" marginTop={4}>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            className="mr-05"
                            onClick={searchLatLng}
                        >
                            <Search className="mr-03" />
                            ค้นหาพิกัด
                        </Button>
                        <Button
                            size="small"
                            color="default"
                            variant="contained"
                            onClick={() => {
                                setLat(null);
                                setLng(null);
                            }}
                        >
                            <Close className="mr-03" />
                            ล้างค่า
                        </Button>
                    </Box>
                </CmtCardContent>
            </CmtCard>
        </Grow >
    );
}

export default React.memo(MapLatLng);