import React from "react";
import SidebarToggleHandler from "../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import Toolbar from "@material-ui/core/Toolbar";
import { alpha, Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import { alpha, Box, InputBase } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
// import AppsMenu from "./AppsMenu";
// import HeaderNotifications from "./HeaderNotifications";
// import HeaderMessages from "./HeaderMessages";
// import Hidden from "@material-ui/core/Hidden";
import Logo from "../Logo";
// import SearchPopover from "../SearchPopover";
import { useLocation } from 'react-router-dom'

const useStyles = props => makeStyles(theme => ({
	root: {
		width: "100%",
		paddingLeft: 16,
		paddingRight: 16,
		minHeight: 64,
		zIndex: 2,
		[theme.breakpoints.up("md")]: {
			minHeight: 72
		},
		[theme.breakpoints.up("md")]: {
			paddingLeft: 24,
			paddingRight: 24
		},
		backgroundColor: props.color ?? '#007aee'
	},
	searchRoot: {
		position: "relative",
		width: 260,
		[theme.breakpoints.up("md")]: {
			width: 350
		},
		"& .MuiSvgIcon-root": {
			position: "absolute",
			left: 18,
			top: "50%",
			transform: "translateY(-50%)",
			zIndex: 1
		},
		"& .MuiInputBase-root": {
			width: "100%"
		},
		"& .MuiInputBase-input": {
			height: 48,
			borderRadius: 30,
			backgroundColor: alpha(theme.palette.common.dark, 0.38),
			color: alpha(theme.palette.common.white, 0.7),
			boxSizing: "border-box",
			padding: "5px 15px 5px 50px",
			transition: "all 0.3s ease",
			"&:focus": {
				backgroundColor: alpha(theme.palette.common.dark, 0.58),
				color: alpha(theme.palette.common.white, 0.7)
			}
		}
	},
	langRoot: {
		borderLeft: `solid 1px ${alpha(theme.palette.common.dark, 0.15)}`,
		minHeight: 72,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingLeft: 8,
		paddingRight: 8,
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			minHeight: 64
		}
	},
	iconBtn: {
		color: alpha(theme.palette.common.white, 0.38),
		"&:hover, &:focus": {
			color: theme.palette.common.white
		}
	}
}));

function MyToolbar(props) {
	const classes = useStyles(props)();
	return <Toolbar className={classes.root}>
		{props.children}
	</Toolbar>
}

const Header = () => {
	const location = useLocation();
	const [color, setColor] = React.useState(null);
	const [bannerImg, setBannerImg] = React.useState('white');

	React.useEffect(() => {
		let path = location.pathname.split('/');
		switch (path[1]) {
			case "projectimportant":
			case "reportprojectimportant":
			case "masterplan": {
				setColor('#5f5ae0');
				setBannerImg('/images/logo-white-imp.png');
				break;
			}
			case "masterplanb":
			case "reportprojectrequest":
			case "projectrequest": {
				setColor('#436e82');
				setBannerImg('/images/logo-white-req.png');
				break;
			}
			default: {
				setColor(null);
				setBannerImg(null);
			}
		}
	}, [location.pathname])

	return (
		<MyToolbar color={color}>
			<SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
			<Logo ml={2} bannerImg={bannerImg} />
			<Box flex={1} />
			{/* <Hidden smDown>
				<Box pr={3} className={classes.searchRoot}>
					<InputBase
						placeholder={"Search here..."}
						inputProps={{ "aria-label": "search" }}
					/>
					<SearchIcon />
				</Box>
			</Hidden> */}
			{/* <Hidden mdUp>
				<SearchPopover iconClassName={classes.iconBtn} />
			</Hidden> */}
			{/* <AppsMenu />
			<HeaderMessages />
			<HeaderNotifications /> */}
		</MyToolbar>
	);
};

export default Header;
