import React, { useEffect, useState } from 'react';
import { Button, Grid, Dialog, DialogTitle, DialogContent, Typography, Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Image, ArrowBack } from '@material-ui/icons';
import { Edit } from 'react-feather';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../../redux/action';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import Pagination from '@material-ui/lab/Pagination';
import TextFieldMemo from "../../../Util/TextFieldMemo";
import Datepicker from "../../../Util/Datepicker";
import UploadFile from "../LoginModal/UploadFile";
import CmtCardHeader from '../../../@coremat/CmtCard/CmtCardHeader';
import CmtAvatar from "../../../@coremat/CmtAvatar";
import CmtImage from '../../../@coremat/CmtImage';
import CmtCard from '../../../@coremat/CmtCard';
import HeadlinesService from "../../../Service/HeadlinesService";
import NewsService from "../../../Service/NewsService";
import Swal from "sweetalert2";

const useStyles = makeStyles(() => ({
    colorHeadlines: {
        borderRadius: "5px",
        width: "50px",
        height: "40px"
    },
    paginationText: {
        margin: "auto 20px"
    },
    paginationText: {

    }
}));

const ModalNewsAndHeadlines = ({ show, setShow, menu, setNewsAll, setDataHeadlines }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const menuList = [
        {
            name: "เพิ่ม",
            imgSrc: "/images/portal/project/project-add-icon.png"
        },
        {
            name: "แก้ไข",
            imgSrc: "/images/portal/project/project-edit-icon.png"
        }
    ];

    const colorHeadlinesList = [
        {
            name: "Green",
            background: "#C1F7EE",
            tag: "#94DACD"
        },
        {
            name: "Yellow",
            background: "#FFFDD0",
            tag: "#FFD700"
        },
        {
            name: "Pink",
            background: "#FFF0F4",
            tag: "#FCB8E1"
        },
        {
            name: "Blue",
            background: "#D8E9F7",
            tag: "#D1D8FD"
        },
        {
            name: "Brown",
            background: "#FFE5B4",
            tag: "#F1C470"
        },
        {
            name: "Purple",
            background: "#E9CCED",
            tag: "#D6B8E6"
        }
    ];

    const [showNewsModal, setShowNewsModal] = useState(false);
    const [showHeadlinesModal, setShowHeadlinesModal] = useState(false);

    const [stateNews, setStateNews] = useState({
        edit: false,
        news: {
            title: "",
            content: "",
            thumbnail: [],
            createdAt: null,
            reference: "",
            isThumbnailExist: true
        },
        Files: [],
        newsData: [],
        error: {}
    });

    const [stateHeadlines, setStateHeadlines] = useState({
        edit: false,
        headlinesData: [],
        headlines: {
            content: "",
            backgroundColor: "",
            tagColor: ""
        },
        error: {}
    });

    const [stateManageMenu, setStateManageMenu] = useState("");
    const [stateMenu, setStateMenu] = useState("");
    const [page, setPage] = useState(1);
    const [fileLimit, setFileLimit] = useState(10);
    const url = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';

    useEffect(() => {
        if (!menu) {
            setStateMenu(stateMenu);
        } else {
            menu == "news" ? getNews() : getHeadlines()
            setStateMenu(menu);
        }

        if (stateHeadlines.edit) {
            const data = colorHeadlinesList.find(item => item.background == stateHeadlines.headlines.backgroundColor);
            handleBackgroundHeadlines(data, true);
        }
    }, [menu, stateHeadlines.edit])

    const setInit = () => {
        dispatch(hideLoading());
        setStateManageMenu("");
        if (stateMenu == "news") {
            setStateNews({
                edit: false,
                news: {
                    title: "",
                    content: "",
                    thumbnail: [],
                    createdAt: null,
                    reference: "",
                    isThumbnailExist: true
                },
                Files: [],
                newsData: [],
                error: {}
            })
            setShowNewsModal(false);
            getNews();
        } else {
            setStateHeadlines({
                edit: false,
                headlines: {
                    content: "",
                    backgroundColor: "",
                    tagColor: ""
                },
                headlinesData: [],
                error: {}
            })
            setShowHeadlinesModal(false);
            getHeadlines();
        }
    };

    const onClickMenu = (element) => {
        menu == "news" ? setShowNewsModal(!showNewsModal) : setShowHeadlinesModal(!showHeadlinesModal);
        element == "เพิ่ม" ? setStateManageMenu("Add") : setStateManageMenu("Manage");
    };

    const onChange = (value, name) => {
        if (menu == "news") {
            setStateNews((prevState) => ({ ...prevState, news: { ...prevState.news, [name]: value } }));
        } else {
            setStateHeadlines((prevState) => ({ ...prevState, headlines: { ...prevState.headlines, [name]: value } }));
        }
    };

    const checkData = () => {
        let error = {};
        if (menu == "news") {
            if (!stateNews.news.title || stateNews.news.title === "") error.title = true;
            if (!stateNews.news.content || stateNews.news.content === "") error.content = true;
            if (!stateNews.news.createdAt || stateNews.news.createdAt === null) error.createdAt = true;
            if (stateNews.Files.length === 0 && stateNews.news.thumbnail.length == 0) error.AttachedFile = true;
            if (stateNews.news.reference) {
                const patternURL = stateNews.news.reference.split("://")[0];
                if (patternURL !== "http" && patternURL !== "https") error.reference = true;
            }
            if (stateNews.edit) {
                if (stateNews.Files.length + stateNews.news.thumbnail.length > 10) error.fileLimit = true;
            } else {
                if (stateNews.Files.length > 10) error.fileLimit = true;
            }

            setStateNews((prevState) => ({ ...prevState, error: error }));
        } else {
            if (!stateHeadlines.headlines.content || stateHeadlines.headlines.content === "") error.content = true;
            if (!stateHeadlines.headlines.backgroundColor || stateHeadlines.headlines.backgroundColor === "") error.backgroundColor = true;
            if (!stateHeadlines.headlines.tagColor || stateHeadlines.headlines.tagColor === "") error.tagColor = true;

            setStateHeadlines((prevState) => ({ ...prevState, error: error }));
        }

        if (Object.keys(error).length) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                icon: "warning",
            });
            return false;
        } else return true;
    };

    const resetError = () => {
        setStateNews((prevState) => ({ ...prevState, error: {} }));
        setStateHeadlines((prevState) => ({ ...prevState, error: {} }));
    };

    const onSubmit = () => {
        resetError();
        if (!checkData()) return;

        let swaltitle = ""
        if (menu == "news") {
            swaltitle = stateNews.edit ? 'ต้องการแก้ไขข่าวนี้หรือไม่' : 'ต้องการเพิ่มข่าวนี้หรือไม่'
        } else {
            swaltitle = stateHeadlines.edit ? 'ต้องการแก้ไขหัวเรื่องนี้หรือไม่' : 'ต้องการเพิ่มหัวเรื่องนี้หรือไม่'
        }

        let isThumbnailExist = true;
        if (stateNews.edit) {
            const checkThumbnail = stateNews.news.thumbnail.some(item => (item.split("/").pop()).split(".")[0] === "thumbnail");
            if (stateNews.Files.length === 0 && !checkThumbnail) {
                isThumbnailExist = false;
            }
        }

        Swal.fire({
            title: swaltitle,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(event => {
            if (!event.isConfirmed) return
            dispatch(showLoading());
            if (menu == "news") {
                if (stateNews.edit) {
                    NewsService.updateNews(stateNews.news, isThumbnailExist).then(res => {
                        if (res?.errors) {
                            dispatch(hideLoading());
                            return Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: res?.errors?.[0]?.Message || "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                                icon: 'error'
                            });
                        }
                        if (stateNews.Files.length == 0) {
                            dispatch(hideLoading());
                            return Swal.fire({
                                title: 'บันทึกข้อมูลสำเร็จ',
                                text: 'แก้ไขข่าวสำเร็จ',
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            }).then(() => {
                                setInit();
                                setNewsAll();
                            });
                        } else {
                            uploadNewsImage(res.data.UpdateNews);
                        }
                    }).catch(() => {
                        dispatch(hideLoading());
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            icon: 'error'
                        });
                    })

                } else {
                    NewsService.insertNews(stateNews.news).then(res => {
                        if (res?.errors) {
                            throw new Error(res?.errors?.[0]?.Message);
                        }

                        return res.data.InsertNews;
                    }).then((res) => {
                        let data = {
                            Files: stateNews.Files,
                            NewsID: res.ID

                        };
                        return NewsService.uploadFileNewsImage(data);
                    }).then((res) => {
                        if (res?.errors) {
                            return Swal.fire({
                                title: res?.errors?.[0]?.Message,
                                type: "error",
                            });
                        }

                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            text: 'เพิ่มข่าวสำเร็จ',
                            icon: 'success',
                            confirmButtonText: 'ตกลง'
                        }).then(() => {
                            setInit();
                            setNewsAll();
                        });

                    }).catch(() => {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            icon: 'error'
                        });
                    })
                }

            } else {
                if (stateHeadlines.edit) {
                    HeadlinesService.updateHeadlines(stateHeadlines.headlines).then(res => {
                        if (res?.errors) {
                            return Swal.fire({
                                title: res?.errors?.[0]?.Message,
                                type: "error",
                            });
                        }

                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            text: 'แก้ไขหัวเรื่องสำเร็จ',
                            icon: 'success',
                            confirmButtonText: 'ตกลง'
                        }).then(() => {
                            setInit();
                            setDataHeadlines();
                        });

                    }).catch(() => {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            icon: 'error'
                        });
                    })
                } else {
                    HeadlinesService.insertHeadlines(stateHeadlines.headlines).then(res => {
                        if (res?.errors) {
                            return Swal.fire({
                                title: res?.errors?.[0]?.Message,
                                type: "error",
                            });
                        }

                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            text: 'เพิ่มหัวเรื่องสำเร็จ',
                            icon: 'success',
                            confirmButtonText: 'ตกลง'
                        }).then(() => {
                            setInit();
                            setDataHeadlines();
                        });

                    }).catch(() => {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            icon: 'error'
                        });
                    })
                }
            }
        })
    };

    const handleFileChoose = (e) => {
        let files = e.target.files;
        let fileList = [];
        let maxSize = 50 * 1024 * 1024;

        if (stateNews.edit) {
            if (10 - stateNews.news.thumbnail.length - stateNews.Files.length > 0) {
                setFileLimit(10 - stateNews.news.thumbnail.length - stateNews.Files.length);
            } else {
                setFileLimit(0);
            }
        }

        if (stateNews.edit) {
            if (stateNews.news.thumbnail.length + stateNews.Files.length + files.length > fileLimit) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "เลือกภาพข่าวสูงสุด 10 ภาพ",
                    icon: "warning",
                });
                return;
            }
        } else {
            if (stateNews.Files.length + files.length > fileLimit) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "เลือกภาพข่าวสูงสุด 10 ภาพ",
                    icon: "warning",
                });
                return;
            }
        }

        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            if (files[i].size > maxSize) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ขนาดไฟล์ใหญ่เกิน 50MB ต่อไฟล์",
                    icon: "warning",
                });
                return;
            }
            if (!checkFileExtension(files[i])) return;
            fileList.push(addFile(files[i]));
        }

        setStateNews((prevState) => ({
            ...prevState,
            Files: [...prevState.Files, ...fileList]
        }));
    };

    const handleDrop = (files) => {
        let fileList = [...stateNews.Files];
        let maxSize = 100 * 1024 * 1024;
        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            if (files[i].size > maxSize) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ขนาดไฟล์ใหญ่เกิน 50MB ต่อไฟล์",
                    icon: "warning",
                });
                return;
            }
            if (!checkFileExtension(files[i])) return;
            fileList.push(addFile(files[i]));
        }
        setStateNews((prevState) => ({
            ...prevState,
            Files: [...prevState.Files, ...fileList],
        }));
    };

    const handleRemoveFile = (index) => {
        let fileList = [...stateNews.Files];
        fileList.splice(index, 1);
        setStateNews((prevState) => ({
            ...prevState,
            Files: prevState.Files.filter((r, num) => index !== num),
        }));
    };

    const handleRemoveOldFile = (value) => {
        setStateNews((prevState) => ({ ...prevState, news: { ...prevState.news, "thumbnail": stateNews.news.thumbnail.filter(item => item !== value) } }));
    }

    const checkFileExtension = (file) => {
        if (!file.name) return false;
        let ext = [".jpg", ".jpeg", ".png"];
        let fileExt = file.name.match(/\.[0-9a-z]+$/i)?.[0]?.toLowerCase();
        let inSupportedExt = ext.includes(fileExt);
        if (!inSupportedExt) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                html: `
                <div class="d-flex align-items-center justify-content-center">
                    <div>ระบบไม่รองรับสกุลไฟล์นี้<div>
                    <div>กรุณาเลือกไฟล์ที่มีสกุลไฟล์ดังนี้</div>
                    <div>${ext.join(", ")}<div>
                </div>
                `,
                icon: "warning",
            });
        }
        return inSupportedExt;
    };

    const addFile = (file) => {
        return {
            FileName: file.name,
            FileData: file,
        };
    };
    
    const updateSection = (element) => {
        if (menu == "news") {
            setStateNews((prevState) => ({
                ...prevState,
                edit: true,
                news: {
                    title: element.Title,
                    content: element.Content.replace(/&emsp;/g, '  ').replace(/<br *\/?>/gi, '\n'),
                    thumbnail: element.Thumbnail,
                    createdAt: element.CreatedAt,
                    reference: element.Reference || "",
                    id: element.ID
                }
            }));
        } else {
            setStateHeadlines((prevState) => ({
                ...prevState,
                edit: true,
                headlines: {
                    content: element.Content,
                    backgroundColor: element.BackgroundColor,
                    tagColor: element.TagColor,
                    id: element.ID
                }
            }));
        }
    };

    const deleteHeadlines = (element) => {
        Swal.fire({
            title: 'ต้องการลบหัวเรื่องนี้หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(event => {
            if (!event.isConfirmed) return;
            dispatch(showLoading());
            HeadlinesService.deleteHeadlines(element.ID).then(res => {
                dispatch(hideLoading());
                if (res?.errors) {
                    return Swal.fire({
                        title: res.errors[0]?.Message,
                        type: "error",
                    });
                }

                Swal.fire({
                    title: "ลบหัวเรื่องสำเร็จ",
                    icon: "success"
                }).then(() => {
                    getHeadlines();
                    setDataHeadlines();
                });

            }).catch(() => {
                dispatch(hideLoading());
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    icon: 'error'
                });
            })
        })
    };

    const deleteNews = (element) => {
        Swal.fire({
            title: 'ต้องการลบข่าวนี้หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(event => {
            if (!event.isConfirmed) return
            dispatch(showLoading());
            NewsService.deleteNews(element.ID).then(res => {
                if (res?.errors) {
                    return Swal.fire({
                        title: res.errors[0]?.Message,
                        type: "error",
                    });
                }

                Swal.fire({
                    title: "ลบข่าวสำเร็จ",
                    icon: "success"
                }).then(() => {
                    getNews();
                    setNewsAll();
                });
            }).catch(() => {
                dispatch(hideLoading());
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    icon: 'error'
                });
            })
        })
    };

    const getHeadlines = () => {
        dispatch(hideLoading());
        fetch(`${url}/twp/v2/upload/news/headlines.json`)
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                setStateHeadlines((prevState) => ({ ...prevState, headlinesData: data }));
            })
            .catch(function () {
                setStateHeadlines((prevState) => ({ ...prevState, headlinesData: [] }));
            });
    };

    const getNews = () => {
        dispatch(hideLoading());
        fetch(`${url}/twp/v2/upload/news/news.json`)
            .then(function (res) {
                return res.json();
            }).then(function (data) {
                data.sort((date1, date2) => {
                    date1 = date1['CreatedAt']?.split('-').reverse().join('') ?? "";
                    date2 = date2['CreatedAt']?.split('-').reverse().join('') ?? "";
                    return date2.localeCompare(date1);
                });
                setStateNews((prevState) => ({ ...prevState, newsData: data }));
            }).catch(function () {
                setStateNews((prevState) => ({ ...prevState, newsData: [] }));
            });
    };

    const uploadNewsImage = (item) => {
        const data = {
            Files: stateNews.Files,
            NewsID: item.ID
        };

        NewsService.uploadFileNewsImage(data).then((res) => {
            dispatch(hideLoading());
            if (res?.errors) {
                return Swal.fire({
                    title: res.errors[0]?.Message,
                    type: "error",
                });
            }
            Swal.fire({
                title: "แก้ไขข่าวสำเร็จ",
                icon: "success"
            }).then(() => {
                setInit();
                setNewsAll();
            });

        }).catch(() => {
            dispatch(hideLoading());
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                icon: 'error'
            });
        })
    }

    const handleBackgroundHeadlines = (item, isUpdate) => {
        if (!isUpdate) setStateHeadlines((prevState) => ({ ...prevState, headlines: { ...prevState.headlines, backgroundColor: item.background, tagColor: item.tag } }));
        colorHeadlinesList.forEach(element => {
            if (element.name == item.name) {
                document.getElementById(item.name).style.border = "2px solid grey"
            } else {
                document.getElementById(element.name).style.border = "none"
            }
        })
    }

    const setCurrentData = (data) => {
        let newData = [];
        if (!data?.length) return newData;
        let start = (page - 1) * 10;
        for (let i = start; i < start + 10; i++) {
            if (!data[i]) break;
            newData.push(data[i]);
        }
        return newData;
    }

    const renderDataPosition = () => {
        let start = (page - 1) * 10;
        let end = (page * 10 > stateNews.newsData.length) ? stateNews.newsData.length : page * 10;
        return (" " + (start + (stateNews.newsData.length > 0)) + " - " + end + " ");
    }

    return (
        <Dialog
            onClose={setShow}
            TransitionProps={{
                onExit: () =>
                    setTimeout(() => {
                        setShowHeadlinesModal(false);
                        setShowNewsModal(false);
                        setInit();
                    }, 300)
            }}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth={stateManageMenu !== "" ? "md" : "sm"}
        >
            <DialogTitle style={{ position: "relative" }}>
                {showNewsModal || showHeadlinesModal ? (
                    <IconButton aria-label="close" className='p-0 mr-03'>
                        <ArrowBack onClick={() => { setInit() }} />
                    </IconButton>
                ) : null}
                {stateMenu == "news" ?
                    stateNews.edit ? "แก้ไขข่าวสาร" : stateManageMenu == "Add" ? "เพิ่มข่าวสาร" : "จัดการข่าวสาร"
                    : stateHeadlines.edit ? "แก้ไขหัวเรื่อง" : stateManageMenu == "Add" ? "เพิ่มหัวเรื่อง" : "จัดการหัวเรื่อง"
                }
            </DialogTitle>

            <DialogContent style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <Grow in={!showNewsModal && !showHeadlinesModal} timeout={400}>
                    <div style={{ display: !showNewsModal && !showHeadlinesModal ? "" : "none" }}>
                        <Grid container>
                            {menuList.map((element, i) => {
                                return (
                                    <Grid item className="m-10" key={"news" + (element.ID || i)}>
                                        <Grow in={show} timeout={400} className="height-100">
                                            <div>
                                                <CmtCard onClick={() => onClickMenu(element.name)} className="hover-zoom height-100">
                                                    <CmtCardHeader className="p-10">
                                                        <CmtImage
                                                            src={element.imgSrc} alt={element.name} style={{ maxWidth: '50px', marginRight: '10px' }}
                                                        />
                                                        <Typography variant="h6" style={{ fontWeight: 500 }}>{stateMenu == "news" ? element.name + "ข่าว" : element.name + "หัวเรื่อง"}</Typography>
                                                    </CmtCardHeader>
                                                </CmtCard>
                                            </div>
                                        </Grow>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Grow>

                <Grow in={showNewsModal} timeout={400}>
                    <div style={{ display: showNewsModal ? "" : "none" }}>
                        {stateManageMenu == "Add" || stateNews.edit ? (
                            <Grid container style={{ minHeight: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    หัวข้อข่าว <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic1"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="title"
                                        value={stateNews.news.title}
                                        onChange={(e) => onChange(e.target.value, e.target.name)}
                                        error={stateNews.error.title}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3} className="d-flex align-items-center mt-10">
                                    วันที่ประกาศข่าว <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10">
                                    <Datepicker
                                        format="DD-MM-YYYY"
                                        name="createdAt"
                                        value={stateNews.news.createdAt ?? ""}
                                        setvalue={onChange}
                                        size="small"
                                        index={1 ?? ""}
                                    />
                                </Grid>
                                {stateNews.error.createdAt ?
                                    <>
                                        <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                        <Grid item xs={9} className="d-flex align-items-center">
                                            <div style={{ color: "red", fontSize: "12px" }}>* กรุณาเลือกวันที่ประกาศข่าว</div>
                                        </Grid>
                                    </> : null}
                                <Grid item xs={3} className="d-flex align-items-center mt-10">
                                    เนื้อหาข่าว <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10">
                                    <TextFieldMemo
                                        id="outlined-basic2"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="content"
                                        multiline
                                        rows={13}
                                        value={stateNews.news.content}
                                        onChange={(e) => onChange(e.target.value, e.target.name)}
                                        error={stateNews.error.content}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3} className="d-flex align-items-center mt-10">
                                    ที่มาข่าว :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10">
                                    <TextFieldMemo
                                        id="outlined-basic2"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="reference"
                                        value={stateNews.news.reference}
                                        onChange={(e) => onChange(e.target.value, e.target.name)}
                                        error={stateNews.error.reference}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <div style={{ color: "#6495ED", fontSize: "12px" }}>ที่มาข่าวต้องขึ้นต้นด้วย http:// หรือ https:// เท่านั้น</div>
                                </Grid>
                                {(stateNews.error.reference) ?
                                    <>
                                        <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                        <Grid item xs={9} className="d-flex align-items-center">
                                            <div style={{ color: "red", fontSize: "12px" }}>* กรุณาระบุที่มาข่าวให้ถูกต้อง</div>
                                        </Grid>
                                    </> : null}
                                <Grid item xs={3} className="d-flex align-items-center mt-10">
                                    แนบภาพข่าว <span style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10">
                                    <UploadFile
                                        handleDrop={handleDrop}
                                        handleFileChoose={handleFileChoose}
                                        handleRemoveFile={handleRemoveFile}
                                        files={stateNews.Files}
                                        section={"news"}
                                        fileLimit={fileLimit}
                                    />
                                </Grid>
                                {stateNews.edit ?
                                    <>
                                        {stateNews.news.thumbnail.map((item, i) => {
                                            return (
                                                <React.Fragment key={"i" + (i)}>
                                                    <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                                    <Grid item xs={9} className="d-flex align-items-center">
                                                        <div
                                                            className={`d-flex ${i > 0 ? "mt-05" : ""}`}
                                                            style={{
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <div className="d-flex">
                                                                <Image className="mr-03" />
                                                                <span style={{ wordBreak: "break-all" }}>{item.split?.('/').pop()}</span>
                                                            </div>
                                                            <IconButton
                                                                variant="contained"
                                                                color="default"
                                                                size="small"
                                                                className="btn-danger ml-05"
                                                                onClick={() => {
                                                                    handleRemoveOldFile(item);
                                                                }}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </> : null}
                                {(stateNews.Files.length == 0 && stateNews.error.AttachedFile) ?
                                    <>
                                        <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                        <Grid item xs={9} className="d-flex align-items-center">
                                            <div style={{ color: "red", fontSize: "12px" }}>* กรุณาแนบภาพข่าว</div>
                                        </Grid>
                                    </> : stateNews.error.fileLimit ?
                                        <>
                                            <Grid item xs={3} className="d-flex align-items-center"></Grid>
                                            <Grid item xs={9} className="d-flex align-items-center">
                                                <div style={{ color: "red", fontSize: "12px" }}>* กรุณาเลือกภาพข่าวสูงสุด 10 ภาพ</div>
                                            </Grid>
                                        </> : null
                                }
                                <br />
                                <Grid item xs={12} className="d-flex justify-content-flex-end mt-10">
                                    <Button variant="contained" color="primary" component="span" onClick={onSubmit}>
                                        บันทึก
                                    </Button>
                                </Grid>
                            </Grid>) : (
                            <Grid container>
                                {stateNews.newsData.length > 0 ?
                                    <>
                                        {setCurrentData(stateNews.newsData).map((element, i) => {
                                            return (
                                                <div className="mb-10" style={{ width: "100%" }} key={"curr" + (element.ID || i)}>
                                                    <Grow in={stateManageMenu == "Manage"} timeout={400}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', boxShadow: "0px 0px 12px #D3D3D3" }} className="p-10">
                                                            <div>
                                                                <CmtAvatar size={56} src={((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : "") + element.Thumbnail.find(item => (item.split?.('/').pop())?.split('.')?.[0] == 'thumbnail')} className="mr-10" />
                                                            </div>
                                                            <div style={{ fontSize: "14px", width: "90%", wordWrap: "break-word", wordWrap: "break-word" }}>{element.Title}</div>
                                                            <div>
                                                                <IconButton aria-label="close" style={{ backgroundColor: 'transparent' }}>
                                                                    <Edit color="#2AAA8A" onClick={() => updateSection(element)} style={{ marginRight: "10px" }} />
                                                                    <Delete style={{ color: "#EC5800" }} onClick={() => deleteNews(element)} />
                                                                </IconButton>
                                                            </div>
                                                        </Box>
                                                    </Grow>
                                                </div>
                                            )
                                        })}

                                        <div className="mt-10 d-flex" style={{ width: "100%" }}>
                                            <div className={classes.paginationText}>แสดงข้อมูล
                                                <span>
                                                    {renderDataPosition()}
                                                </span>
                                                จากทั้งหมด <span>{stateNews.newsData.length ?? '0'}</span> ข่าว
                                            </div>
                                            <Pagination
                                                className="ml-auto"
                                                color="primary"
                                                count={stateNews.newsData.length ? Math.floor(stateNews.newsData.length / 10) + (stateNews.newsData.length % 10 > 0) : 1}
                                                page={page}
                                                onChange={(obj, page) => setPage(page)}
                                            />
                                        </div>
                                    </> : <div style={{ textAlign: "center", fontSize: "16px", flex: 1 }}>ไม่มีข้อมูล...</div>}
                            </Grid>
                        )}
                    </div>
                </Grow>

                <Grow in={showHeadlinesModal} timeout={400}>
                    <div style={{ display: showHeadlinesModal ? "" : "none" }}>
                        {stateManageMenu == "Add" || stateHeadlines.edit ? (
                            <Grid container style={{ minHeight: "75px" }}>
                                <Grid item xs={2} className="d-flex align-items-center">
                                    หัวเรื่อง <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={10} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic3"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="content"
                                        rows={5}
                                        value={stateHeadlines.headlines.content}
                                        onChange={(e) => onChange(e.target.value, e.target.name)}
                                        error={stateHeadlines.error.content}
                                        inputProps={{ maxLength: 400 }}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={2} className="d-flex align-items-center mt-10">
                                    สีหัวเรื่อง <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={10} className="d-flex align-items-center">
                                    {colorHeadlinesList.map((color, i) => {
                                        return <Grid item xs={2} className="d-flex align-items-center mt-10" key={"c" + (i)}>
                                            <Button onClick={() => { handleBackgroundHeadlines(color) }}>
                                                <div style={{ backgroundColor: color.background }} className={classes.colorHeadlines} id={color.name} name={color.name}></div>
                                            </Button>
                                        </Grid>
                                    })}
                                </Grid>

                                {stateHeadlines.error.backgroundColor ?
                                    <>
                                        <Grid item xs={2} className="d-flex align-items-center"></Grid>
                                        <Grid item xs={10} className="d-flex align-items-center">
                                            <div style={{ color: "red", fontSize: "12px" }}>* กรุณาเลือกสีหัวเรื่อง</div>
                                        </Grid>
                                    </> : null}

                                <Grid item xs={12} className="d-flex justify-content-flex-end mt-10">
                                    <Button variant="contained" color="primary" component="span" onClick={onSubmit}>
                                        บันทึก
                                    </Button>
                                </Grid>
                            </Grid>) : (
                            <Grid container>
                                {stateHeadlines.headlinesData.length > 0 ? <>
                                    {stateHeadlines.headlinesData.map((element, i) => {
                                        return (
                                            <Grid item xs={12} className="mb-10" key={"h" + (i)}>
                                                <Grow in={stateManageMenu == "Manage"} timeout={400} className="height-100">
                                                    <div style={{ backgroundColor: element.BackgroundColor }} className="hover-zoom height-100">
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', p: 1, m: 1 }}>
                                                            <div style={{ borderRadius: "50%", marginRight: "8px", width: "20px", height: "20px", backgroundColor: element.TagColor }}></div>
                                                            <div style={{ fontSize: "14px", width: "90%", wordBreak: "break-word" }}>{element.Content}</div>
                                                            <div>
                                                                <IconButton aria-label="close" style={{ backgroundColor: 'transparent' }}>
                                                                    <Edit color="#2AAA8A" onClick={() => updateSection(element)} style={{ marginRight: "10px" }} />
                                                                    <Delete style={{ color: "#EC5800" }} onClick={() => deleteHeadlines(element)} />
                                                                </IconButton>
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </Grow>
                                            </Grid>
                                        )
                                    })}
                                </> : <div style={{ textAlign: "center", fontSize: "16px", flex: 1 }}>ไม่มีข้อมูล...</div>}
                            </Grid>
                        )}
                    </div>
                </Grow>
            </DialogContent>
        </Dialog >
    )
}

export default ModalNewsAndHeadlines;