import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";

const Logo = ({ bannerImg, ...props }) => {
	const logoUrl = bannerImg ?? "/images/logo-white.png";
	const logoSymbolUrl = "/images/logo_en_big_white.png";

	return (
		<Box className="pointer" {...props}>
			<Hidden xsDown>
				<NavLink to="/">
					<CmtImage src={logoUrl} alt="logo" style={{ height: '45px', marginTop: '5px' }} />
				</NavLink>
			</Hidden>
			<Hidden smUp>
				<NavLink to="/">
					<CmtImage src={logoSymbolUrl} alt="logo" style={{ height: '45px', marginTop: '5px' }} />
				</NavLink>
			</Hidden>
		</Box>
	);
};

export default Logo;
