import React, { useState } from "react";
import CmtMediaObject from "../../../@coremat/CmtMediaObject";
import CmtAvatar from "../../../@coremat/CmtAvatar";
import Box from "@material-ui/core/Box";
import useStyles from "./BaseItem.style";
import ModalItem from "./ModalItem";

const BaseItem = ({ title, item }) => {
	const classes = useStyles();
	const [showLoginModal, setShowLoginModal] = useState(false);

	const month = {
		"01": "มกราคม",
		"02": "กุมภาพันธ์",
		"03": "มีนาคม",
		"04": "เมษายน",
		"05": "พฤษภาคม",
		"06": "มิถุนายน",
		"07": "กรกฎาคม",
		"08": "สิงหาคม",
		"09": "กันยายน",
		"10": "ตุลาคม",
		"11": "พฤศจิกายน",
		"12": "ธันวาคม"
	}

	const thumbnail = item.Thumbnail?.find?.(element => (element.split?.('/').pop())?.split('.')?.[0] == 'thumbnail');

	return (
		<>
			<Box className={classes.feedItemRoot} style={{ cursor: "pointer" }} onClick={() => setShowLoginModal(!showLoginModal)}>
				<CmtMediaObject
					avatarPos="center"
					avatar={
						<CmtAvatar
							size={56}
							src={((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : "") + thumbnail}
						/>
					}
					title={title}
					subTitle={(item.CreatedAt?.split?.('-')?.[0] || "") + " " + month[item.CreatedAt?.split?.('-')?.[1]] + " " + (item.CreatedAt?.split?.('-')?.[2] || "")}
					subTitleProps={{
						className: classes.subTitleRoot,
						component: "div",
						variant: "inherit",
						gutterBottom: false
					}}
				/>
			</Box>
			<ModalItem show={showLoginModal} setShow={() => setShowLoginModal(!showLoginModal)} item={item} />
		</>
	);
};

export default BaseItem;
