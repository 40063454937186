import React, { useState, useRef, useEffect } from 'react';
import {
    // TextField,
    // Grid,
    // Button,
    Dialog,
    DialogTitle,
    // DialogActions,
    DialogContent,
    // CircularProgress,
    // Slide,
    IconButton,
    Grow,
    Typography,
    Box,
} from '@material-ui/core';
import {
    Close,
    // Tune,
    // NotificationImportant,
    // MailOutline
} from "@material-ui/icons";
import Skeleton from '@material-ui/lab/Skeleton';


const header = `ขยายระยะเวลา Sign-Off 1 สำหรับ "แผนปฏิบัติการด้านน้ำเพื่อรองรับภัยแล้งและฝนทิ้งช่วง ปี 2565"`;
const content = `
<div>เนื่องจากสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) เพื่ออำนวยความสะดวกให้ทางหน่วยงานและพื้นที่ที่เกี่ยวข้องมีเวลาตรวจสอบแผนปฏิบัติการเพิ่มเติม ทางกวค.สทนช.จึงดำเนินการขยายเวลาการดำเนินงานผ่านระบบ Thai Water Plan สำหรับ <b>"แผนปฏิบัติการด้านน้ำเพื่อรองรับภัยแล้งและฝนทิ้งช่วง ปี 2565"</b> ดังต่อไปนี้</div>
<ul class="pl-10 mt-05">
<li>ขยายเวลาเพิ่ม/แก้ไขแผนงานและ Sign-Off1: จากเดิมวันที่ 6 ม.ค. 2565 24:00 >> เป็นวันที่ 10 ม.ค. 2565 24:00</li>
<li>ขยายเวลาแนบเอกสารและการปรับสถานะการเตรียมความพร้อม: จากเดิมวันที่ 6 ม.ค. 2565 24:00 >> เป็นวันที่ 21 ม.ค. 2565 24:00</li>
</ul>
`;

// let headerTimeout = null;
// let contentTimeout = null;
// let headerIterations = 0;

const options = {
    offset: 0,
    timeout: 5,
    iterations: 10,
    characters: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'x', 'y', 'x', '#', '%', '&', '-', '+', '_', '?', '/', '\\', '='],
    element: document.querySelector('[data-target-resolver]')
}

const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomCharacter = (characters) => {
    return characters[getRandomInteger(0, characters.length - 1)];
};

const getRandomText = (text, allText, iterations) => {
    iterations.current += 1;
    let iteration = iterations.current;
    if (iterations.current === options.iterations) {
        iterations.current = 0;
    }
    let newText = (
        allText.substr(0, text.length - ((iteration !== options.iterations) * 1)) +
        (
            (text.substr(0, text.length - 1) === allText) ?
                ""
                : (
                    (text.length % allText.length > options.iterations) ?
                        getRandomInteger(0, 9) : randomCharacter(options.characters)
                ))
    );
    if (text === newText) newText = newText.substr(0, newText.length - 1) + ".";
    return newText;
};

const LoginModal = ({ show, setShow }) => {
    const [showContent, setShowContent] = useState(false);
    const [loadContent, setLoadContent] = useState(false);
    const [headerText, setHeaderText] = useState("");
    const timerref = useRef(null);
    const iterations = useRef(0);
    // const [contentText, setContentText] = useState("");

    useEffect(() => {
        setTimeout(() => setShowContent(true), 350);
        setTimeout(() => setLoadContent(true), 1250);
    }, []);

    useEffect(() => {
        if (headerText !== header)
            timerref.current = setTimeout(() => {
                setHeaderText(getRandomText(headerText, header, iterations));
            }, options.timeout);
    }, [headerText]);

    useEffect(() => {
        return () => clearTimeout(timerref.current);
    }, [])
    // if (loadContent && contentText !== content) {
    //     clearTimeout(contentTimeout);
    //     contentTimeout = setTimeout(() => {
    //         setContentText(getRandomText(contentText, content, "content"));
    //     }, options.timeout);
    // }

    return (
        <Dialog
            onClose={setShow}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle disableTypography>
                <Box display="flex" alignItems="center">
                    {/* <MailOutline /> */}
                    <Typography variant="h4" className="ml-05">{headerText}</Typography>
                    <IconButton className="ml-auto p-02" onClick={setShow}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grow in={showContent}>
                    <Box
                        paddingTop={0} paddingBottom={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box display="flex">
                            {
                                loadContent ?
                                    <Grow in={true}>
                                        <Box>
                                            <img src="/images/twp_news_header.png" style={{ width: "100%" }} alt="twp news header" />
                                        </Box>
                                    </Grow>
                                    :
                                    <Skeleton variant="rect" width={150} height={150} />
                            }
                        </Box>
                        <Box marginTop={4} width="100%">
                            {
                                loadContent ?
                                    <Grow in={true} timeout={600}>
                                        <Box>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: content }}
                                            />
                                        </Box>
                                    </Grow> :
                                    <>
                                        <Skeleton animation="wave" width="100%" />
                                        <Skeleton animation="wave" width="100%" />
                                        <Skeleton animation="wave" width="100%" />
                                        <Skeleton animation="wave" width="100%" />
                                    </>
                            }
                        </Box>
                    </Box>
                </Grow>
            </DialogContent>
        </Dialog >
    )
}

export default LoginModal;