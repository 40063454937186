import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PDPAService from "../../Service/PDPAService";
import {
    setLocalStorage,
    removeLocalStorage,
    getLocalStorage,
    removeUserToken,
} from "../../Service/_LocalStorageService";
import { removeAllUsersCookies } from "../../Service/_CookieService";
import { setAccountLogin, setAccountLogout, setMasterData } from "../../redux/action";
import AccountService from "../../Service/AccountService";
import Utilities from "../../Util/Utilities";

const PDPA = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [state, setState] = useState({
        privacyP: false,
        cookieP: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleSubmit = () => {
        PDPAService.insertPDPAConsent()
            .then((res) => {
                let isConsent = res.data.InsertPDPAConsent;

                if (!isConsent) {
                    Swal.fire("เกิดข้อผิดพลาด", "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล", "error");
                    removeLocalStorage("masterData");
                    removeUserToken();
                    Utilities.clearNavigationMenu();
                    dispatch(setAccountLogout());
                    AccountService.Logout();
                    history.push("/landingpage");
                }

                return isConsent;
            })
            .then((res) => {
                if (!res) return;

                let SessionToken = getLocalStorage("SessionToken");
                if (SessionToken) {
                    dispatch(setAccountLogin({ SessionToken: SessionToken }));
                    AccountService.getAccountToken(SessionToken)
                        .then((res) => {
                            setIsDataLoaded(true);
                            let data = res?.data?.GetAccountByToken;
                            if (!data || res.errors) {
                                removeUserToken();
                                Utilities.clearNavigationMenu();
                                dispatch(setAccountLogout());
                                AccountService.Logout();
                                return;
                            }
                            removeAllUsersCookies();
                            setLocalStorage("SessionToken", data.SessionToken);
                            dispatch(setAccountLogin(data));
                            history.push("/home");
                        })
                        .catch((e) => {
                            console.log(e);
                            setIsDataLoaded(true);
                            removeUserToken();
                            Utilities.clearNavigationMenu();
                            dispatch(setAccountLogout());
                            AccountService.Logout();
                            history.push("/landingpage");
                        });
                } else {
                    if (!history.location.pathname.includes("/resetpassword")) {
                        history.push("/landingpage");
                    }
                    setTimeout(() => {
                        setIsDataLoaded(true);
                    }, 750);
                }
            })
            .catch((err) => console.log(err));
    };

    const openPolicy = (link) => {
        window.open(link, "_blank");
        return;
    };

    const handleLogout = () => {
		dispatch(setMasterData());
		removeUserToken();
		dispatch(setAccountLogout());
		removeLocalStorage("masterData");
		Utilities.clearNavigationMenu();
		AccountService.Logout();
		history.push("/landingpage");
	};

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="term-of-agreement-dialog-title"
                aria-describedby="term-of-agreement-dialog-description"
            >
                <DialogTitle id="term-of-agreement-dialog-title">นโยบายการใช้งานและการจัดการข้อมูลส่วนบุคคล</DialogTitle>
                <DialogContent>
                    <DialogContentText id="term-of-agreement-dialog-description">
                        <>
                            <div className="py-10" style={{ width: "100%", textAlign: "center" }}>
                                <img height="300" src="/about/images/pdpa.png" />
                            </div>
                            <div>
                                TWP Platform มีการเก็บข้อมูล Session และ Cookies กรุณายอมรับข้อตกลงเพื่อดำเนินการเข้าใช้ระบบต่อไป
                            </div>
                            <div className="mt-10 px-05">
                                <FormGroup>
                                    <div style={{ alignItems: "center" }} className="d-flex">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.privacyP}
                                                    onChange={handleChange}
                                                    name="privacyP"
                                                    color="primary"
                                                />
                                            }
                                            label="นโยบายการคุ้มครองข้อมูลส่วนบุคคล"
                                        />
                                        (
                                        <h5
                                            onClick={() => openPolicy("/about/privacypolicyth.html")}
                                            style={{ fontWeight: 400, cursor: "pointer", textDecoration: "underline" }}
                                        >
                                            รายละเอียดเพิ่มเติม
                                        </h5>
                                        )
                                    </div>
                                    <div style={{ alignItems: "center" }} className="d-flex">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.cookieP}
                                                    onChange={handleChange}
                                                    name="cookieP"
                                                    color="primary"
                                                />
                                            }
                                            label="นโยบายคุกกี้"
                                        />
                                        (
                                        <h5
                                            onClick={() => openPolicy("/about/cookiespolicyth.html")}
                                            style={{ fontWeight: 400, cursor: "pointer", textDecoration: "underline" }}
                                        >
                                            รายละเอียดเพิ่มเติม
                                        </h5>
                                        )
                                    </div>
                                </FormGroup>
                            </div>
                        </>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="py-10 px-10" style={{ justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        onClick={() => handleLogout()}
                        color="default"
                        fullWidth
                    >
                        ออกจากระบบ
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!state.cookieP || !state.privacyP}
                        onClick={() => handleSubmit()}
                        color="primary"
                        fullWidth
                    >
                        ยอมรับ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PDPA;
