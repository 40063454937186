import React from 'react';
import {
    Button,
    Grow,
    IconButton,
    Grid,
    Tooltip,
    Chip,
    Card,
    Typography,
    Box,
} from '@material-ui/core';
import {
    List,
    Close,
    Info,
    Room,
    AccountTree,
    MonetizationOn,
    Search,
    Star
} from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import TextFieldMemo from '../../Util/TextFieldMemo';

const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
}

const getAmount = (StatusID) => {
    if (StatusID === 1) return "RequestAmount";
    if (StatusID === 2) return "DraftAmount";
    if (StatusID === 3) return "AllocateAmount";
}

class MapFilter extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.showProject === false && this.props.showProject === false)
            return false;
        return !(
            nextProps.showProject === this.props.showProject &&
            // nextProps.showLayer === this.props.showLayer &&
            nextProps.project === this.props.project &&
            nextProps.searchProject === this.props.searchProject &&
            nextProps.projectCount === this.props.projectCount &&
            nextProps.page === this.props.page
        )
    }
    searchProjectLabel = (
        <div className="d-flex align-items-center" style={{ marginTop: "-4px", pointerEvents: "none" }}>
            <Search className="mr-03" /> ค้นหาโครงการ
        </div>
    )

    render() {
        const {
            showProject,
            //layers,
            setShowProject,
            project,
            projectCount,
            limit,
            page,
            onChangePage,
            onClickProjectLocation,
            searchProject,
            setSearchProject
        } = this.props;

        return (
            <Grow in={showProject} className="map-filter d-flex flex-column">
                <div>
                    <CmtCard className="p-0 d-flex flex-column">
                        <div className="section-header main d-flex align-items-center py-05 px-10 mb-0">
                            <List className="mr-05" />
                            <h4 className="mr-05">รายชื่อโครงการ</h4>
                            <Chip size="small" label={projectCount ?? 0} color="primary" />
                            <IconButton className="ml-auto p-02 text-default" onClick={setShowProject}>
                                <Close />
                            </IconButton>
                        </div>
                        <CmtCardContent className="flex-fill overflowY-auto overflowX-hidden" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
                            <Grid
                                container
                                spacing={6}
                                className="mt-0"
                                justifyContent="center"
                            >
                                <TextFieldMemo
                                    label={this.searchProjectLabel}
                                    placeholder="ค้นหาโครงการ"
                                    variant="outlined"
                                    size="small"
                                    value={searchProject ?? ''}
                                    name='searchProject'
                                    onChange={setSearchProject}
                                    fullWidth
                                    className="mb-05"
                                />
                                {
                                    project?.length ?
                                        project?.map((obj, index) => (
                                            <Card key={"project-" + index} className="width-100 mb-05">
                                                <div className="p-10">
                                                    <Tooltip title={obj.ProjectName || ""}>
                                                        <Box display="flex" alignItems="center">
                                                            {
                                                                obj.MYProject ?
                                                                    <Tooltip title="โครงการของฉัน">
                                                                        <Star className="star-icon" />
                                                                    </Tooltip>
                                                                    :
                                                                    null
                                                            }
                                                            <Typography className="text-ellipsis" variant="h5" color="primary">
                                                                {
                                                                    "#" + (((page - 1) * limit) + 1 + index) +
                                                                    " " + obj.ProjectName
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Tooltip>
                                                    <small className="text-ellipsis">
                                                        {
                                                            ((obj.ProvinceName) ? ("จ." + obj.ProvinceName + " ") : "") +
                                                            ((obj.DistrictName) ? ("อ." + obj.DistrictName + " ") : "") +
                                                            ((obj.SubdistrictName) ? ("ต." + obj.SubdistrictName + " ") : "") +
                                                            ((obj.Village) ? (obj.Village + " ") : "") +
                                                            ((obj.Moo) ? (obj.Moo) : "")
                                                        }
                                                    </small>
                                                    <div className="d-flex align-items-center mt-02">
                                                        <Chip
                                                            label={"สถานะ: " + (obj.ProjectStatusName ?? "-")}
                                                            variant="outlined"
                                                            size="small"
                                                            color="secondary"
                                                        />
                                                        <span className="ml-auto">
                                                            <Button
                                                                className="btn-small"
                                                                color="secondary"
                                                                variant="contained"
                                                                onClick={() => openInNewTab(`/twp/v2/project/projectdetail/${obj.ProjectID}`)}
                                                            >
                                                                <Info />
                                                            </Button>
                                                            <Button
                                                                className="ml-03 btn-small"
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => onClickProjectLocation(obj.ProjectID)}
                                                            >
                                                                <Room />
                                                            </Button>
                                                        </span>
                                                    </div>
                                                    <div className="mt-05 d-flex flex-wrap">
                                                        <Box className="mr-auto text-ellipsis" display="flex" alignItems="center" component="small">
                                                            <AccountTree className="mr-03 text-secondary" style={{ fontSize: "1rem" }} />
                                                            {obj.OrganizationName}
                                                        </Box>
                                                        <Box display="flex" alignItems="center" component="small">
                                                            <MonetizationOn className="mr-03 text-secondary" style={{ fontSize: "1rem" }} />
                                                            วงเงินงบฯ: {obj[getAmount(obj.ProjectStatusID)] ?? "-"} ลบ.
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))
                                        :
                                        <Card className="width-100 mb-05">
                                            <div className="p-10" style={{ textAlign: "center" }}>
                                                ไม่มีข้อมูล...
                                            </div>
                                        </Card>
                                }
                            </Grid>
                        </CmtCardContent>
                        <Pagination
                            count={(projectCount) ? Math.floor(projectCount / limit) + (projectCount % limit > 0) : 1}
                            page={page}
                            onChange={(obj, page) => onChangePage(page)}
                            color="primary"
                            className="my-05 pagination-center"
                        />
                    </CmtCard>
                </div>
            </Grow >
        );
    }
}

export default MapFilter;