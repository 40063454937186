import React, { useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Chip from "@material-ui/core/Chip";
import {
    ArrowDropDown,
    ArrowDropUp,
    Cancel
} from "@material-ui/icons"
import { Box } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    inputRoot: {
        width: "100%",
        lineHeight: "1.1876em",
        fontSize: "1.1876em",
        position: "relative",
        "& .customMS-inputBase": {
            width: "100%",
            height: "100%",
            padding: "5px 12px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            "& > div": {
                display: "flex",
                flexWrap: "wrap",
                flex: "1 1 auto",
                overflow: "hidden"
            },
        },
        "&.focus": {
            "& fieldset": {
                borderColor: theme.palette.primary.main,
                borderWidth: "2px",
                transitionDelay: "0.2s",
                "& legend": {
                    left: "-1px",
                    color: theme.palette.primary.main
                }
            }
        },
        "& fieldset": {
            top: "-5px",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "0",
            padding: "0 8px",
            overflow: "hidden",
            position: "absolute",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "4px",
            pointerEvents: "none",
            transition: "border-color 0.2s",
            "& legend": {
                width: "auto",
                height: "11px",
                display: "block",
                padding: "0",
                fontSize: "0.75em",
                maxWidth: "1000px",
                textAlign: "left",
                position: "relative",
                transition: "color 0.05s, max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                "& span": {
                    display: "inline-block",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    top: "-5px",
                    position: "relative"
                }
            }
        },
        "&:hover .customMS-inputBase": {
            borderColor: "rgba(0, 0, 0, 0.75)",
            // borderColor: theme.palette.primary.main
        },
        "& input": {
            font: "inherit",
            color: "currentColor",
            width: "100%",
            border: "0",
            height: "1.1876em",
            margin: "0",
            display: "block",
            padding: "5px 0 5px",
            minWidth: "0",
            background: "none",
            boxSizing: "content-box",
            flex: "1 1 auto",
            outline: "none",
            lineHeight: "inherit",
            "&::-webkit-input-placeholder": {
                color: "rgba(0, 0, 0, 0.55)"
            },
            "&:-ms-input-placeholder": {
                color: "rgba(0, 0, 0, 0.55)"
            },
            "&::placeholder": {
                color: "rgba(0, 0, 0, 0.55)"
            },
        },
        "& svg": {
            fill: "rgba(0, 0, 0, 0.55)"
        },
        "& .customMS-dropdownArrow": {
            paddingLeft: "2px",
            "& button": {
                padding: "2px"
            }
        },
    },
    popperRoot: {
        zIndex: 1000,
        overflow: "hidden",
        userSelect: "none",
        "& .popper-contentRoot": {
            overflowX: "hidden",
            overflowY: "auto",
            maxHeight: "350px"
        }
    },
    paperContent: {
        // padding: theme.spacing(4),
    },
    "customMS-list": {
        "& > div": {
            padding: "10px 16px",
            cursor: "pointer"
        },
        "& >div.disabled": {
            cursor: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        },
        "& > div:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)"
        },
        "& >div.disabled:hover": {
            cursor: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.10)"
        },
    },
    "customMS-chip": {
        height: '30px',
        padding: "4px",
        overflow: "hidden",
        margin: "3px 0",
        marginRight: "6px",
        "& > span": {
            padding: "2px 0px 2px 7px",
            overflow: "hidden",
        },
        "& div": {
            display: "flex",
            alignItems: "center",
        },
        "& div > span": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        "& button": {
            marginLeft: "4px",
            padding: "2px",
            "& svg": {
                fontSize: "22px",
                fill: "rgba(0, 0, 0, 0.50)"
            }
        }
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes myEffectExit": {
        "0%": {
            opacity: 1,
            transform: "translateY(0)"
        },
        "100%": {
            opacity: 0,
            transform: "translateY(-200%)"
        }
    }
}))

const checkInclude = (data, child, key) => {
    if (!data?.length) return false;
    for (let i = 0; i < data.length; i++) {
        if (data[i][key] === child[key]) {
            return true;
        }
    }
    return false;
}

const getTypeName = (type) => {
    switch (type) {
        case 1: return "จังหวัด";
        case 2: return "อำเภอ";
        case 3: return "ตำบล";
        case 4: return "ลุ่มน้ำหลัก";
        case 5: return "ลุ่มน้ำสาขา";
        default: return;
    }
}

const renderTypeName = (type) => {
    let typeName = getTypeName(type);
    if (!typeName) return null;
    return (
        <div>
            <Chip
                color="secondary"
                size="small"
                label={typeName}
            />
        </div>
    )
}

const MultiSelect = (props) => {
    const [input, setInput] = useState(null);
    const [isFocus, setFocus] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyle();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    // let ref = null;
    let inputRef = null;
    let timeout = null;

    const handleClick = (event) => {
        clearTimeout(timeout);
        setAnchorEl(inputRef);
        inputRef.focus();
        setFocus(true);
    };
    const handleClickOut = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setInput(null);
            setAnchorEl(null);
            setFocus(false);
        }, 175);
    };
    const handleClickOption = (index) => {
        if (inputRef) {
            inputRef.focus();
            setFocus(true);
        }
        clearTimeout(timeout);
        let val = props.selected;
        if (!val) {
            if (props.onChange) props.onChange([props.options[index]], props.name);
            return;
        }
        let selected = props.options[index];
        let repeated = false;
        for (let i = 0; i < props.selected.length; i++) {
            if (selected.Value === props.selected[i].Value) {
                repeated = true;
                break;
            }
        }
        if (repeated) return;
        val = [...val, props.options[index]];
        if (props.onChange) props.onChange(val, props.name);
        setAnchorEl(null);
        setInput(null);
    }
    const onClickRemove = (index) => {
        clearTimeout(timeout);
        if (!props.selected) return;
        let val = [...props.selected];
        val.splice(index, 1);
        if (props.onChange) props.onChange(val, props.name);
    }
    const handleChangeInput = (val) => {
        setInput(val);
        if (props.onChangeInput) props.onChangeInput(val);
    }

    return (
        <div>
            <div
                aria-describedby={id}
                className={classes.inputRoot + ((isFocus) ? " focus" : "")}
            >
                <div className={"customMS-inputBase"}>
                    <div>
                        {
                            props.selected?.length ?
                                props.selected.map((obj, index) => (
                                    <Chip
                                        key={"selected" + index}
                                        className={classes["customMS-chip"]}
                                        label={
                                            <div>
                                                <span>{obj.Label}</span>
                                                <IconButton onClick={() => onClickRemove(index)}>
                                                    <Cancel />
                                                </IconButton>
                                            </div>
                                        }
                                    />
                                ))
                                : null
                        }
                        <input
                            value={input ?? ""}
                            ref={el => inputRef = el}
                            placeholder={props.placeholder}
                            onChange={(e) => handleChangeInput(e.target.value)}
                            onClick={handleClick}
                            onBlur={handleClickOut}
                        />
                    </div>
                    <span className="customMS-dropdownArrow" onClick={(anchorEl) ? handleClickOut : handleClick}>
                        <IconButton>
                            {
                                (anchorEl) ?
                                    <ArrowDropUp />
                                    : <ArrowDropDown />
                            }
                        </IconButton>
                    </span>
                </div>
                <fieldset>
                    <legend><span>{props.label ?? ""}</span> </legend>
                </fieldset>
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={classes.popperRoot}
                modifiers={{
                    offset: {
                        offset: "15px,10px"
                    }
                }}
                placement="bottom"
            >
                <Paper className="popper-contentRoot">
                    <div className={classes.paperContent} style={{ width: anchorEl ? (anchorEl.clientWidth + 40) : "auto" }}>
                        <div className={classes["customMS-list"]}>
                            {
                                props.options?.length ?
                                    props.options.map((obj, index) => (
                                        <React.Fragment key={"options" + index}>
                                            {index === 0 ? null : <hr className="hr-light" />}
                                            <Box
                                                display="flex"
                                                onClick={() => handleClickOption(index)}
                                                className={checkInclude(props.selected, obj, "Value") ? "disabled" : ""}
                                            >
                                                <div className="flex-fill text-ellipsis">{obj.Label}</div>
                                                {renderTypeName(obj.Type)}
                                            </Box>
                                        </React.Fragment>
                                    ))
                                    :
                                    <div>ไม่พบข้อมูล...</div>
                            }
                        </div>
                    </div>
                </Paper>
            </Popper>
        </div>
    )
}

export default React.memo(MultiSelect);