import React from 'react';
import PageContainer from "../../@jumbo/components/PageComponents/layouts/PageContainer";
import CmtCard from '../../@coremat/CmtCard';
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtImage from '../../@coremat/CmtImage';
import Grow from '@material-ui/core/Grow';
import {
    Grid,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Card,
    Badge
} from '@material-ui/core';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Check,
    Tune,
    Close
} from '@material-ui/icons';
import Select from '../../Util/Select';
import AutoComplete from '../../Util/Autocomplete';
import Utilities from '../../Util/Utilities';
import { connect } from "react-redux";
import { setFilterData } from '../../redux/action';
import CoreService from '../../Service/CoreService';
import Swal from 'sweetalert2';
import Datepicker from '../../Util/Datepicker';
import moment from 'moment';
import { removeLocalStorage } from '../../Service/_LocalStorageService';
import TrainingRequestService from '../../Service/TrainingRequest';
import ApproveAccountLogService from '../../Service/ApproveAccountLogService';

class Home extends React.Component {
    // navigationMenus = Utilities.getAllNavigationMenu(this.props.account);
    state = {
        show: [],
        menus: [],
        menusLevel: 1,
        showFilters: false,
        optionFilters: [],
        showButtonBack: false,
        showButtonConfirm: false,
        showModal: false,
        filter: {},
        selectedElement: null,
        preset: [],
        disabledForm: false,
        disabledBudgetSource: false,
        notificationTraining: 0,
        notificationUser: 0
    }
    timeout = [];
    menusLayer1 = [];
    menusLayer2 = [];
    menusLayer3 = [];

    formOptions = [
        { FormID: "001", FormName: "แบบฟอร์มสทนช. 001" },
        // { FormID: "004", FormName: "แบบฟอร์มสทนช. 004" },
        { FormID: "001cashflow", FormName: "แบบฟอร์มสทนช. 001 พร้อม Cashflow" }
    ];

    setShowTimeout = () => {
        for (let i = 0; i < this.state.show.length; i++) {
            // const element = this.state.show[i];
            this.timeout[i] = setTimeout(() => {
                let newShow = [...this.state.show];
                newShow[i] = true;
                this.setState({
                    show: newShow
                })
            }, (i + 1) * 62);
        }
    }

    setNavigationMenus = async () => {
        return new Promise(async (resolve) => {
            const navMenus = await Utilities.getAllNavigationMenu(this.props.account, true, this.props.trainingPlatform);
            this.setState({
                show: navMenus.map(() => false),
                menus: navMenus
            }, () => resolve());
        });
    }

    clearShowTimeout = () => {
        for (let i = 0; i < this.timeout.length; i++) {
            const element = this.timeout[i];
            if (element) clearTimeout(element);
        }
    }

    getPresetCollection = () => {
        return CoreService.getPresetCollection()
            .then(res => {
                return new Promise((resolve, reject) => {
                    if (!res.data?.GetPresetCollection || res.errors) {
                        reject();
                        return;
                    }
                    this.setState({
                        preset: res.data.GetPresetCollection
                    }, () => {
                        resolve();
                    })
                })
            })
    }

    componentDidMount() {
        removeLocalStorage('currentSignoff');
        this.props.setFilter(null);
        this.getTrainingRequestCount();
        this.getApproveAccountCount();
        this.setNavigationMenus()
            // .then(this.getPresetCollection)
            .then(this.setShowTimeout)
            .catch(e => {
                this.setState({
                    preset: []
                }, () => {
                    this.setShowTimeout();
                })
                console.log(e);
            });
    }

    componentWillUnmount() {
        this.clearShowTimeout();
    }

    showModal = () => {
        this.setState({ showModal: true });
    }

    hideModal = () => {
        this.setState({ showModal: false, filter: {} }, () => {
            this.state.selectedElement?.OnExit?.(this);
        });
    }

    setSearch = (value, prop) => {
        return new Promise((resolve) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [prop]: value
                }
            }, async () => {
                if (this.state.selectedElement?.onChangeValue) {
                    await this.state.selectedElement.onChangeValue(this, prop);
                }
                resolve();
            });
        })
    }

    setProjectYear = (value, prop) => {
        if (prop === "ProjectStartYear" && value > this.state.filter?.ProjectEndYear)
            return new Promise((resolve) => {
                this.setState({
                    filter: {
                        ...this.state.filter,
                        [prop]: value,
                        ProjectEndYear: null
                    }
                }, async () => {
                    if (this.state.selectedElement?.onChangeValue) {
                        await this.state.selectedElement.onChangeValue(this, prop);
                    }
                    resolve();
                });
            })

        return new Promise((resolve) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [prop]: value,
                }
            }, async () => {
                if (this.state.selectedElement?.onChangeValue) {
                    await this.state.selectedElement.onChangeValue(this, prop);
                }
                resolve();
            });
        })
    }

    onClickMenu = (element) => {
        let subMenu = element.subMenu ?? [];
        let selectOption = element?.selectOption ?? false;
        let options = element?.options ?? [];
        let link = element?.link ?? '';
        let externalLink = element?.externalLink ?? false;
        if (externalLink) {
            window.open(link, '_blank')
            return;
        }

        if (this.state.menusLevel === 1) {
            this.menusLayer1 = this.state.menus;

        } else if (this.state.menusLevel === 2) {
            this.menusLayer2 = this.state.menus;

        } else if (this.state.menusLevel === 3) {
            this.menusLayer3 = this.state.menus;
        }
        this.clearShowTimeout();
        if (subMenu.length > 0) {
            this.timeout = [];
            this.setState({
                show: subMenu.map(x => false),
                showFilters: false,
                showButtonBack: (this.state.menusLevel >= 1),
                showButtonConfirm: false,
                selectedElement: null
            }, () => {
                setTimeout(() => {
                    this.setState({
                        menus: subMenu,
                        menusLevel: this.state.menusLevel + 1
                    }, () => {
                        this.setShowTimeout();
                    })
                }, 250)
            })
        }
        else if (selectOption && options.length > 0) {
            this.setState({
                // ...this.state,
                // showButtonBack: true,
                // showButtonConfirm: true,
                showFilters: true,
                optionFilters: [...options],
                showModal: true,
                selectedElement: element
                // menusLevel: this.state.menusLevel + 1
            }, () => {
                let filter = null;
                if (element.options?.includes("BudgetYear")) {
                    if (!filter) filter = {};
                    filter.BudgetYear = Utilities.getDefaultBudgetYear();
                    // this.setState({ filter: { BudgetYear: Utilities.getDefaultBudgetYear() } });
                }
                if (element.options?.includes("Preset")) {
                    if (!filter) filter = {};
                    filter.PresetCollectionID = this.props.account?.DefaultPresetCollectionID;
                    filter.BudgetYear = undefined;
                }
                if (filter) {
                    this.setState({ filter: filter });
                }
                if (element.onClick && typeof element.onClick === "function")
                    element.onClick(this, filter, "filter");
            })
        }
        else if (link) {
            this.props.history.push(link);
        }
    }

    onClickBack = () => {
        if (this.state.menusLevel === 1) return;
        this.clearShowTimeout();
        let newMenus = this["menusLayer" + (this.state.menusLevel - 1)];
        this.setState({
            show: newMenus.map(x => false),
            showButtonConfirm: false,
            showFilters: false,
            selectedElement: null
        }, () => {
            setTimeout(() => {
                this.setState({
                    menus: [...newMenus],
                    menusLevel: this.state.menusLevel - 1,
                    showButtonBack: ((this.state.menusLevel - 1) > 1),
                }, () => {
                    this.setShowTimeout();
                })
            }, 250)
        })
        if (this.state.menusLevel === 2) {
            this.menusLayer2 = [];
            this.menusLayer3 = [];
        }
        else if (this.state.menusLevel === 3) {
            this.menusLayer3 = [];
        }
    }

    getTrainingRequestCount = () => {
        if (this.props.account?.Permissions?.TrainingRequest) {
            TrainingRequestService.getTrainingRequestCount().then((res) => {
                this.setState({
                    notificationTraining: res.data.SearchTrainingRequest?.Count
                });
            }).catch((e) => {
                console.log(e);
            });
        }
    };

    getApproveAccountCount = () => {
        if (this.props.account?.Permissions?.AccountLog) {
            ApproveAccountLogService.getApproveAccountLogCount().then((res) => {
                this.setState({
                    notificationUser: res.data.SearchApproveAccountLog?.Count
                });
            }).catch((e) => {
                console.log(e);
            });
        }
    };

    renderMenu = () => {
        return this.state.menus.map((element, index) => {
            if (element.groupHeader)
                return (
                    <Grid item xs={12} key={"menu-" + index}>
                        <Grow in={this.state.show[index]} timeout={400} className="height-100">
                            <div>
                                <Box className={"section-header main w-100" + (index === 0 ? "mt-15" : " mt-30")}>
                                    <Typography
                                        variant="h3"
                                        className="d-flex align-items-center"
                                    >
                                        {element.icon}
                                        <span className={element.icon ? "ml-05" : ""}>{element.name}</span>
                                    </Typography>
                                    <small className="mt-03">{element.subText ?? ''}</small>
                                    <hr className='hr-light mt-05' />
                                </Box>
                            </div>
                        </Grow>
                    </Grid >
                )
            else
                return (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"menu-" + index} >
                        <Grow in={this.state.show[index]} timeout={400} className="height-100">
                            <div>
                                {element.name === 'ข้อมูลผู้ลงทะเบียนสมัคร Training' || element.name === 'ประวัติการเปลี่ยนแปลงผู้ใช้งาน' ?
                                    <Badge
                                        badgeContent={element.name === 'ข้อมูลผู้ลงทะเบียนสมัคร Training' ? this.state.notificationTraining : this.state.notificationUser}
                                        color="error"
                                        style={{width: '100%'}}
                                        >
                                        <Card
                                            className={"hover-zoom height-100" + (element.highlight ? " menu-highlight" : "") + (element.highlightClass || "")}
                                            onClick={() => this.onClickMenu(element)}
                                            style={{ overflow: "visible",width: '100%' }}
                                        >
                                            <CmtCardHeader justifyContent="left" className="p-10">
                                                <CmtImage
                                                    src={element.imgSrc}
                                                    alt={element.name}
                                                    style={{ maxWidth: '80px', marginRight: '15px' }}
                                                    className={element.imgClass}
                                                />
                                                <Box>
                                                    <Typography variant="h5" style={{ fontWeight: 800 }}>{element.name}</Typography>
                                                    <small className="mt-03">{element.subText ?? ''}</small>
                                                </Box>
                                            </CmtCardHeader>
                                        </Card>
                                    </Badge>
                                    :
                                    <Card
                                        className={"hover-zoom height-100" + (element.highlight ? " menu-highlight" : "") + (element.highlightClass || "")}
                                        onClick={() => this.onClickMenu(element)}
                                        style={{ overflow: "visible" }}
                                    >
                                        <CmtCardHeader justifyContent="left" className="p-10">
                                            <CmtImage
                                                src={element.imgSrc}
                                                alt={element.name}
                                                style={{ maxWidth: '80px', marginRight: '15px' }}
                                                className={element.imgClass}
                                            />
                                            <Box>
                                                <Typography variant="h5" style={{ fontWeight: 800 }}>{element.name}</Typography>
                                                <small className="mt-03">{element.subText ?? ''}</small>
                                            </Box>
                                        </CmtCardHeader>
                                    </Card>}

                            </div>
                        </Grow>
                    </Grid>
                )
        });
    }

    renderFilters = () => {
        let require = this.state.selectedElement?.getRequired?.(this.state.filter) ?? [];
        return this.state.optionFilters.map((element, index) => {
            switch (element) {
                case "BudgetYear":
                    if (this.state.filter.FormID === "001cashflow") return null;
                    if (this.state.selectedElement?.menuName === "ProjectAdd" || this.state.selectedElement?.projectMap) {
                        return (
                            <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                                <Grid item xs={12} sm={9}>
                                    <Datepicker
                                        format="YYYY"
                                        label="ปีงบประมาณ"
                                        name="BudgetYear"
                                        value={this.state.filter.BudgetYear ?? new Date().getFullYear() + 543}
                                        setvalue={this.setSearch}
                                        size="small"
                                        // mindate="4"
                                        // maxdate="16"
                                        maxYear={this.state.selectedElement?.componentData?.EndYear ? moment(this.state.selectedElement.componentData.EndYear.toString()) : undefined}
                                        minYear={this.state.selectedElement?.componentData?.StartYear ? moment(this.state.selectedElement.componentData.StartYear.toString()) : undefined}
                                    />
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                                <Grid item xs={12} sm={9}>
                                    <Datepicker
                                        format="YYYY"
                                        label="ปีงบประมาณ"
                                        name="BudgetYear"
                                        value={this.state.filter.BudgetYear ?? new Date().getFullYear() + 543}
                                        setvalue={this.setSearch}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                case "BudgetStatus":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="สถานะงบประมาณ"
                                    dataname="ProjectStatus"
                                    name="ProjectStatusID"
                                    dataobjectname="ProjectStatusID"
                                    value={this.state.filter.ProjectStatusID ?? ""}
                                    setvalue={this.setSearch}
                                    data={this.props.masterData?.GetProjectStatus ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                case "BudgetSource": {
                    if (this.state.filter.FormID !== "001") return null;
                    let methodAdd =
                        (
                            this.state.selectedElement?.method === "add" &&
                            (
                                this.state.selectedElement?.subMethod === "projectgui" ||
                                this.state.selectedElement?.subMethod === "spreadsheet"
                            )
                        );
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="แหล่งที่มางบประมาณ"
                                    dataname='BudgetSource'
                                    name='BudgetSourceID'
                                    dataobjectname="BudgetSourceID"
                                    value={this.state.filter.BudgetSourceID ?? ""}
                                    setvalue={this.setSearch}
                                    data={
                                        this.state.selectedElement?.getOptions?.BudgetSource?.()
                                        ?? this.props.masterData?.GetBudgetSource
                                    }
                                    disabled={this.state.disabledBudgetSource}
                                    noDefault={methodAdd}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>

                    );
                }
                case "Organization":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="หน่วยรับงบประมาณ (กรม/จังหวัด)"
                                    value={this.state.filter.OrganizationID ?? ''}
                                    dataname='Organization'
                                    name='OrganizationID'
                                    dataobjectname="OrganizationID"
                                    setvalue={this.setSearch}
                                    data={this.props.masterData?.FilterOrganizationOption ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>

                    )
                case "Province":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="จังหวัด"
                                    value={this.state.filter.ProvinceID ?? ''}
                                    dataname='Province'
                                    name='ProvinceID'
                                    dataobjectname="ProvinceID"
                                    setvalue={this.setSearch}
                                    data={this.props.masterData?.GetProvince ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                case "Basin":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="ลุ่มน้ำหลัก"
                                    value={this.state.filter.BasinID ?? ''}
                                    dataname='Basin'
                                    name='BasinID'
                                    dataobjectname="BasinID"
                                    setvalue={this.setSearch}
                                    data={this.props.masterData?.GetBasin ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                case "Preset":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="Collection"
                                    value={this.state.filter.PresetCollectionID ?? ''}
                                    dataname='PresetCollection'
                                    name='PresetCollectionID'
                                    dataobjectname="PresetCollectionID"
                                    setvalue={this.setSearch}
                                    data={this.state.preset ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                case "BudgetType": {
                    if (this.state.filter.FormID !== "001") return null;
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="ประเภทงบประมาณ"
                                    value={this.state.filter.BudgetTypeID ?? ''}
                                    dataname='BudgetType'
                                    name='BudgetTypeID'
                                    dataobjectname="BudgetTypeID"
                                    setvalue={this.setSearch}
                                    data={this.props.masterData?.GetBudgetType ?? []}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                case "BudgetDimension": {
                    if (this.state.filter.FormID !== "001" && this.state.filter.FormID !== "001cashflow") return null;
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <AutoComplete
                                    label="มิติงบประมาณ"
                                    value={this.state.filter.BudgetDimensionID ?? ''}
                                    dataname='BudgetDimension'
                                    name='BudgetDimensionID'
                                    dataobjectname="BudgetDimensionID"
                                    setvalue={this.setSearch}
                                    data={this.state.selectedElement?.name === "หลายรายการ" ?
                                        this.props.masterData.GetBudgetDimension
                                            .filter(x => [1, 3, 5].includes(x.BudgetDimensionID))
                                            .map(x => {
                                                if (x.BudgetDimensionID === 1) {
                                                    let newObj = { ...x }
                                                    newObj.BudgetDimensionName = "บูรฯน้ำ / Function / อื่นๆ"
                                                    return newObj
                                                }

                                                return x;
                                            }) :
                                        this.props.masterData.GetBudgetDimension
                                    }
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                case "ProjectStartYear": {
                    if (this.state.selectedElement?.menuName === "ProjectAdd" && this.state.filter.FormID === "001cashflow") {
                        return (
                            <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                                <Grid item xs={12} sm={9}>
                                    <Datepicker
                                        format="YYYY"
                                        label="ปีเริ่ม Cashflow"
                                        name="ProjectStartYear"
                                        value={this.state.filter.ProjectStartYear}
                                        setvalue={this.setProjectYear}
                                        size="small"
                                        maxYear={moment("2037")}
                                        minYear={moment("2017")}
                                    />
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return null;
                    }
                }
                case "ProjectEndYear": {
                    if (this.state.selectedElement?.menuName === "ProjectAdd" && this.state.filter.FormID === "001cashflow") {
                        return (
                            <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                                <Grid item xs={12} sm={9}>
                                    <Datepicker
                                        format="YYYY"
                                        label="ปีสิ้นสุด Cashflow"
                                        name="ProjectEndYear"
                                        value={this.state.filter.ProjectEndYear}
                                        setvalue={this.setProjectYear}
                                        size="small"
                                        maxYear={moment("2037")}
                                        minYear={moment(this.state.filter?.ProjectStartYear ? (this.state.filter?.ProjectStartYear - 543).toString() : new Date().getFullYear().toString())}
                                    />
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return null;
                    }
                }
                case "Form":
                    return (
                        <Grid container direction="row" key={`filter-${index}`} spacing={6} justifyContent="center">
                            <Grid item xs={12} sm={9}>
                                <Select
                                    label="แบบฟอร์ม"
                                    value={this.state.filter.FormID ?? ''}
                                    dataname='Form'
                                    name='FormID'
                                    dataobjectname="FormID"
                                    setvalue={(value, prop) => {
                                        this.setSearch(value, prop)
                                    }}
                                    data={this.state.selectedElement?.name === "หลายรายการ" ? [
                                        { FormID: "001", FormName: "แบบฟอร์มสทนช. 001" },
                                        // { FormID: "004", FormName: "แบบฟอร์มสทนช. 004" },
                                        { FormID: "001cashflow", FormName: "แบบฟอร์มสทนช. 001 พร้อม Cashflow" }
                                    ] : [
                                        { FormID: "001", FormName: "แบบฟอร์มสทนช. 001" },
                                    ]}
                                    noDefault
                                    disabled={this.state.disabledForm}
                                    required={require.includes(index)}
                                />
                            </Grid>
                        </Grid>
                    )
                default: return (<Box key={`filter-${index}`}></Box>);
            }
        })

    }

    onClickLink = () => {
        if (!this.state.selectedElement?.link) return;
        let newFilter = {
            ...this.state.filter,
            method: this.state.selectedElement.method ?? undefined,
            subMethod: this.state.selectedElement.subMethod ?? undefined
        };
        // if (this.state.selectedElement.componentData?.TagID) {
        //     newFilter.TagID = this.state.selectedElement.componentData?.TagID;
        // }
        // if (this.state.selectedElement.componentData?.SignoffID) {
        //     newFilter.SignoffID = this.state.selectedElement.componentData?.SignoffID;
        // }
        if (newFilter.BudgetSourceID) {
            newFilter.BudgetSourceName = this.props.masterData?.GetBudgetSource?.find(x => x.BudgetSourceID === newFilter.BudgetSourceID)?.BudgetSourceName;
        }
        if (newFilter.BudgetTypeID) {
            newFilter.BudgetTypeName = this.props.masterData?.GetBudgetType?.find(x => x.BudgetTypeID === newFilter.BudgetTypeID)?.BudgetTypeName;
        }
        if (this.state.selectedElement.getOptions?.BudgetSource) {
            newFilter.BudgetSourceOption = this.state.selectedElement.getOptions.BudgetSource(this.props.masterData.GetBudgetSource);
        }
        let requireData = this.state.selectedElement.getRequiredData?.(newFilter) ?? [];
        if (requireData.length) {
            for (let i = 0; i < requireData.length; i++) {
                const element = requireData[i];
                if (!newFilter[element]) {
                    Swal.fire({
                        title: "กรุณาตรวจสอบข้อมูล",
                        text: "กรุณากรอกข้อมูลให้ครบถ้วน",
                        icon: "warning"
                    });
                    return;
                }
            }
        }
        this.props.setFilter(newFilter);
        if (
            this.state.selectedElement.method === "add" &&
            this.state.selectedElement.subMethod === "projectgui"
        ) {
            if (newFilter.FormID) {
                this.props.history.push(this.state.selectedElement.link + "/" + newFilter.FormID + "/add");
            }
            else {
                this.props.history.push(this.state.selectedElement.altLink);
            }
        }
        else if (
            this.state.selectedElement.method === "add" &&
            this.state.selectedElement.subMethod === "spreadsheet"
        ) {
            if (newFilter.FormID) {
                this.props.history.push(this.state.selectedElement.link + "/" + newFilter.FormID + "/add");
            }
            else {
                this.props.history.push(this.state.selectedElement.altLink);
            }
        }
        else {
            this.props.history.push(this.state.selectedElement.link);
        }
    }

    render() {
        if (!this.props.account?.Permissions) return null;
        return (
            <PageContainer>
                <Grow
                    in={this.state.showButtonBack}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ height: this.state.showButtonBack ? "" : "0px" }}
                    >
                        <Grid item>
                            <Box visibility={this.state.showButtonBack ? 'visible' : 'hidden'} className="mb-10">
                                <Button color="default" className="btn-danger" onClick={this.onClickBack}>
                                    <KeyboardArrowLeft />
                                    กลับ
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box visibility={this.state.showButtonConfirm ? 'visible' : 'hidden'} className="mb-10">
                                <Button color="primary" variant="contained" onClick={this.onClickConfirm}>
                                    ยืนยัน
                                    <KeyboardArrowRight />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grow>

                <Grid container spacing={5}>
                    {this.renderMenu()}
                </Grid>

                <Dialog
                    onClose={this.hideModal}
                    aria-labelledby="option-dialog"
                    open={this.state.showModal}
                    fullWidth
                    maxWidth='sm'
                >
                    <DialogTitle disableTypography className="d-flex align-items-center">
                        <Tune className="mr-05" />
                        <Typography variant="h4">{this.state?.selectedElement?.modalTitle ?? "คัดกรองข้อมูล"}</Typography>
                        <IconButton className="ml-auto p-02" onClick={this.hideModal}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ padding: '12px 24px' }}>
                        {this.renderFilters()}
                    </DialogContent>
                    <DialogActions className="modal-footer">
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onClickLink}
                        >
                            <Check className="mr-03" />
                            ยืนยัน
                        </Button>
                        <Button
                            variant='contained'
                            onClick={this.hideModal}
                        >
                            <Close className="mr-03" />
                            ยกเลิก
                        </Button>
                    </DialogActions>
                </Dialog >
            </PageContainer >
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        masterData: state.masterData,
        account: state.account,
        trainingPlatform: state.trainingPlatform
    }
}

const mapDispatchtoProps = (dispatch) => {
    return {
        setFilter: (filterData) => { dispatch(setFilterData(filterData)); }
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);