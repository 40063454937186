import Axios from './_BaseService';

const ApproveAccountLogService = {
    updateApproveAccountLog: (data) => {
        const UPDATEAPPROVEACCOUNTLOG = `
        mutation UpdateApproveAccountLog(
            $LogID: Int!
            $Status: Int!
            $AccountID: Int
            $LogRemark: String
        ) {
            UpdateApproveAccountLog(
                LogID: $LogID
                Status: $Status
                AccountID: $AccountID
                LogRemark: $LogRemark
            ) 
        }`;

        return Axios.post("/twpservice", {
            query: UPDATEAPPROVEACCOUNTLOG,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 90 * 1000
        });
    },

    updateApproveAccountLogDetail: (data) => {
        const UPDATEAPPROVEACCOUNTLOGDETAIL = `
        mutation UpdateApproveAccountLogDetail(
            $LogID: Int!
            $Username: String!
        ) {
            UpdateApproveAccountLogDetail(
                LogID: $LogID
                Username: $Username
            ) 
        }`;

        return Axios.post("/twpservice", {
            query: UPDATEAPPROVEACCOUNTLOGDETAIL,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 90 * 1000
        });
    },

    CreateChangeOwnerRequestByOther: (data) => {
        const CREATECHOWNREQUEST = `
        mutation CreateChangeOwnerRequestByOther(
            $Username: String
            $OldFirstName: String
            $OldLastName: String
            $FirstName: String!
            $LastName: String!
            $Email: String!
            $OrganizationInternal: String!
            $Position: String!
            $PhoneNumber: String!
            $OfficeNumber: String!
            $Remark: String
        ) {
            CreateChangeOwnerRequestByOther(
                Username: $Username
                OldFirstName: $OldFirstName
                OldLastName: $OldLastName
                FirstName: $FirstName
                LastName: $LastName
                Email: $Email
                OrganizationInternal: $OrganizationInternal
                Position: $Position
                PhoneNumber: $PhoneNumber
                OfficeNumber: $OfficeNumber
                Remark: $Remark
            ) {
                LogID
            }
        }`;
        let validateOwnerName = data.OwnerName?.split(" ").filter(n => n !== "");
        let payload = { ...data, OldFirstName: validateOwnerName[0], OldLastName: validateOwnerName[1] }

        return Axios.post("/twpservice", {
            query: CREATECHOWNREQUEST,
            variables: payload,
            errorPolicy: "all",
        });
    },

    searchApproveAccountLog: (data, getCount = false) => {
        data = {
            LogID: data.LogID?.length ? data.LogID : undefined,
            Username: data.Username || undefined,
            OldFirstName: data.OldFirstName || undefined,
            OldLastName: data.OldLastName || undefined,
            Status: data.Status || undefined,
            Offset: data.Offset || undefined,
            Limit: data.Limit || undefined,
            OrganizationID: data.OrganizationID || undefined,
            FirstName: data.FirstName || undefined,
            LastName: data.LastName || undefined,
            Email: data.Email || undefined,
            ProvinceID: data.ProvinceID?.length ? data.ProvinceID : undefined,
            GetCount: getCount
        };

        const SEARCHAPPROVEACCOUNTLOG = `
            query SearchApproveAccountLog(
                $LogID: [Int]
                $Username: String
                $OldFirstName: String
                $OldLastName: String
                $OrganizationID: Int
                $FirstName: String
                $LastName: String
                $Email: String
                $Status: [Int]
                $GetCount: Boolean
                $ProvinceID: [Int]
                $Offset: Int
                $Limit: Int
            ) {
                SearchApproveAccountLog (
                    LogID: $LogID
                    Username: $Username
                    OldFirstName: $OldFirstName
                    OldLastName: $OldLastName
                    OrganizationID: $OrganizationID
                    FirstName: $FirstName
                    LastName: $LastName
                    Email: $Email
                    Status: $Status
                    GetCount: $GetCount
                    ProvinceID: $ProvinceID
                    Offset: $Offset
                    Limit: $Limit
                ) {
                    ApproveAccountLog {
                        LogID
                        AccountID
                        Username
                        OldFirstName
                        OldLastName
                        FirstName
                        LastName
                        Email
                        OrganizationInternal
                        Position
                        PhoneNumber
                        OfficeNumber
                        Remark
                        Status
                        OwnerRequest
                        EmailStatus
                        ApprovedDateTime
                        ApproverUsername
                        Files
                        LogRemark
                    }
                    Count
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: SEARCHAPPROVEACCOUNTLOG,
            variables: data,
            errorPolicy: "all",
        }, {
            timeout: 90 * 1000
        });
    },

    getApproveAccountLogDetail: (LogID) => {
        const GETAPPROVEACCOUNTLOGDETAIL = `
            query GetApproveAccountLogDetail(
                $LogID: Int!
            ) {
                GetApproveAccountLogDetail (
                    LogID: $LogID
                ) {
                    ApproveAccountLog {
                        LogID
                        AccountID
                        Username
                        OldFirstName
                        OldLastName
                        FirstName
                        LastName
                        Email
                        OrganizationInternal
                        Position
                        PhoneNumber
                        OfficeNumber
                        Remark
                        Status
                        OwnerRequest
                        ApprovedDateTime
                        ApproverUsername
                        Files
                        ProvinceID
                        ProvinceName
                    }
                    Account {
                        AccountID
                        Username
                        FirstName
                        LastName
                        Email
                        OrganizationInternal
                        Position
                        PhoneNumber
                        OfficeNumber
                        Remark
                        OrganizationName
                        ProvinceName
                    }
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: GETAPPROVEACCOUNTLOGDETAIL,
            variables: { LogID },
            errorPolicy: "all",
        }, {
            timeout: 90 * 1000
        });
    },

    uploadFileChangeOwner: (data) => {
        data = {
            Files: data.Files?.length ? data.Files : [],
            LogID: data.LogID
        }
        let formData = new FormData();
        for (let i = 0; i < data.Files.length; i++) {
            const element = data.Files[i];
            formData.append("Files", element.FileData);
        }
        formData.append("LogID", data.LogID);

        return Axios({
            method: "post",
            url: "/upload/changeowner",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 120000
        });
    },

    exportAccountLog: (data) => {
        data = {
            Username: data.Username || undefined,
            OldFirstName: data.OldFirstName || undefined,
            OldLastName: data.OldLastName || undefined,
            FirstName: data.FirstName || undefined,
            LastName: data.LastName || undefined,
            Email: data.Email || undefined,
            Status: data.Status || undefined,
            OrganizationID: data.OrganizationID || undefined,
            LogID: data.selected || [],
            ProvinceID: data.ProvinceID || []
        };

        const EXPORTACCOUNTLOG = `
            query ExportAccountLog(
                $Username: String
                $OldFirstName: String
                $OldLastName: String
                $OrganizationID: Int
                $FirstName: String
                $LastName: String
                $Email: String
                $Status: [Int]
                $LogID: [Int]
                $ProvinceID: [Int]
            ) {
                ExportAccountLog (
                    Username: $Username
                    OldFirstName: $OldFirstName
                    OldLastName: $OldLastName
                    OrganizationID: $OrganizationID
                    FirstName: $FirstName
                    LastName: $LastName
                    Email: $Email
                    Status: $Status
                    LogID: $LogID
                    ProvinceID: $ProvinceID,
                ) {
                    FileName
                    Link
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: EXPORTACCOUNTLOG,
            variables: data,
            errorPolicy: "all",
        });
    },

    getApproveAccountLogCount: () => {
        const SEARCHAPPROVEACCOUNTLOG = `
            query SearchApproveAccountLog(
                $Status: [Int]
                $GetCount: Boolean
            ) {
                SearchApproveAccountLog (
                    Status: $Status
                    GetCount: $GetCount
                ) {
                    Count
                }
            } 
        `;

        return Axios.post("/twpservice", {
            query: SEARCHAPPROVEACCOUNTLOG,
            variables: { Status: [1], GetCount: true },
            errorPolicy: "all",
        }, {
            timeout: 90 * 1000
        });
    }
}

export default ApproveAccountLogService;