export const showMenu = () => ({
    type: "SHOWMENU"
});

export const hideMenu = () => ({
    type: "HIDEMENU"
});

export const openMapMode = () => ({
    type: "OPENMAPMODE"
});

export const closeMapMode = () => ({
    type: "CLOSEMAPMODE"
});

export const showLoading = () => ({
    type: "SHOWLOADING"
});

export const hideLoading = () => ({
    type: "HIDELOADING"
});

export const setAccountLogin = (Account) => ({
    type: "LOGIN",
    payload: {
        Account: Account
    }
});

export const setImageChanged = () => ({
    type: "IMAGE_CHANGE",
})

export const setAccountLogout = () => ({
    type: "LOGOUT"
});

export const setMasterData = (MasterData) => ({
    type: "SETMASTERDATA",
    payload: {
        MasterData: MasterData
    }
});

export const setFilterData = (filter) => ({
    type: "SETFILTERDATA",
    payload: {
        FilterData: filter
    }
});

export const setTrainingData = (data, days) => ({
    type: "SETTRAINING",
    payload: {
        training: data,
        trainingDays: days
    }
});
