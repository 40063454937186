import work from './worker_1';
export default async (data) => {
    if (typeof Worker === "undefined") return Promise.resolve(null);

    const blob = new Blob([work]);
    const worker = new Worker(URL.createObjectURL(blob));

    return new Promise((r) => {
        worker.onmessage = (m) => {
            worker.terminate();
            r(m.data);
        }
        worker.onerror = (e) => console.log(e);
        worker.postMessage(JSON.stringify(data));
    })
}