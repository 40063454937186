import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import AppWrapper from "../@jumbo/components/AppWrapper";
import AppContextProvider from "../@jumbo/components/contextProvider/AppContextProvider";
import Routes from "../routes";
import store from '../redux';

const App = () => (
    <AppContextProvider>
        <Provider store={store}>
            <BrowserRouter basename="/twp/v2/">
                <AppWrapper Children={Routes.Routes}>
                </AppWrapper>
            </BrowserRouter>
        </Provider>
    </AppContextProvider>
);

export default App;