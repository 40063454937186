import React, { useState, useEffect } from "react";
import { alpha, Tooltip } from "@material-ui/core";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../@coremat/CmtList";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DefaultItem from "./DefaultItem";
import DescriptionIcon from '@material-ui/icons/Description';
import DnsIcon from '@material-ui/icons/Dns';
import IconButton from '@material-ui/core/IconButton';
import ModalNewsAndHeadlines from "./ModalNewsAndHeadlines";
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
	cardRoot: {
		position: "relative",
		"& .Cmt-card-content": {
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	scrollbarRoot: {
		maxHeight: 330,
		overflowY: 'auto'
	},
	chipRoot: {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		color: theme.palette.primary.main,
		letterSpacing: 0.25,
		fontSize: 14
	},
	toolTip: {
		padding: "unset",
		background: "transparent"
	},
	pagination: {
		margin: "auto 20px"
	}
}));

function GetDataNews(setFeed) {
	const url = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';
	fetch(`${url}/twp/v2/upload/news/news.json`)
		.then(function (res) {
			return res.json();
		})
		.then(function (data) {
			data.sort((date1, date2) => {
				date1 = date1['CreatedAt']?.split('-').reverse().join('') ?? "";
				date2 = date2['CreatedAt']?.split('-').reverse().join('') ?? "";
				return date2.localeCompare(date1);
			});
			setFeed(data);
		})
		.catch(function () {
			setFeed([]);
		});
}

const DailyFeed = (user) => {
	const [feed, setFeed] = useState([]);
	const classes = useStyles();
	const [state, setState] = useState({
		showModal: false,
		menu: ""
	});

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const setNewsAll = () => {
		GetDataNews(setFeed);
		renderNews(feed);
		setPage(1);
		setLimit(10);
	}

	useEffect(() => {
		setNewsAll();
	}, []);


	const renderItem = (item, index) => {
		return <DefaultItem key={index} item={item} />;
	};

	const setInit = (menu) => {
		setState((prevState) => ({
			...prevState,
			showModal: !state.showModal,
			menu: menu
		}));
	};

	const setCurrentData = (data) => {
		let newData = [];
		if (!data?.length) return newData;
		let start = (page - 1) * limit;
		for (let i = start; i < start + limit; i++) {
			if (!data[i]) break;
			newData.push(data[i]);
		}
		return newData;
	};

	const renderNews = (data) => {
		const newsData = setCurrentData(data)
		return <CmtList data={newsData} renderRow={renderItem} />
	};

	const renderDataPosition = () => {
		let start = (page - 1) * limit;
		let end = (page * limit > feed.length) ? feed.length : page * limit;
		return (" " + (start + (feed.length > 0)) + " - " + end + " ");
	};

	return (
		<>
			<CmtCard className={classes.cardRoot}>
				<CmtCardHeader title="ข่าวสารล่าสุด">
					{user.permissions?.NewsManage ?
						<>
							<Tooltip title="จัดการหัวเรื่อง">
								<IconButton className={classes.toolTip}>
									<DnsIcon color="primary" style={{ marginRight: "7px" }} onClick={() => setInit("headlines")} />
								</IconButton>
							</Tooltip>

							<Tooltip title="จัดการข่าว">
								<IconButton className={classes.toolTip}>
									<DescriptionIcon fontSize="medium" color="primary" onClick={() => setInit("news")} />
								</IconButton>
							</Tooltip>
						</>
						: null}
				</CmtCardHeader>

				<CmtCardContent className={classes.scrollbarRoot}>
					{feed.length == 0 ? <div style={{ textAlign: "center", fontSize: "16px", flex: 1 }}>ไม่มีข้อมูล...</div>
						: <PerfectScrollbar>
							{renderNews(feed)}
						</PerfectScrollbar>
					}

				</CmtCardContent>

				{feed.length == 0 ? null :
					<CmtCardContent>
						<div className="mt-10 d-flex">
							<div className={classes.pagination}>แสดงข้อมูล
								<span>
									{renderDataPosition()}
								</span>
								จากทั้งหมด <span>{feed.length ?? '0'}</span> ข่าว
							</div>
							<Pagination
								className="ml-auto"
								color="primary"
								count={feed.length ? Math.floor(feed.length / limit) + (feed.length % limit > 0) : 1}
								page={page}
								onChange={(obj, page) => setPage(page)}
							/>
						</div>
					</CmtCardContent>}
			</CmtCard>
			<ModalNewsAndHeadlines setNewsAll={setNewsAll} setDataHeadlines={user.setHeadlines} show={state.showModal} setShow={() => setState({ showModal: !state.showModal })} menu={state.menu} />
		</>
	);
};

export default DailyFeed;
