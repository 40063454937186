import React from 'react';
import GoogleMap from '../../Util/Maps';
import { connect } from 'react-redux';
import { showLoading, hideLoading, openMapMode, closeMapMode, setMasterData, setFilterData } from '../../redux/action';
import {
    IconButton,
    Grid,
    Badge,
    Collapse,
    Tooltip,
    Box,
    Typography,
    Chip,
    Snackbar
} from '@material-ui/core';
import {
    Tune,
    People,
    Room,
    Layers,
    Info,
    MoreHoriz,
    PieChart,
    TrackChanges,
    GetApp,
    Assignment,
    ContactSupport,
    Close,
    Mouse,
} from "@material-ui/icons";
import { MousePointer } from 'react-feather';
import GISService from '../../Service/GISService';
import CoreService from '../../Service/CoreService';
import Swal from "sweetalert2";
import MapFilter from './Map_filter';
import MapLayers from './Map_layers';
import MapProject from './Map_project';
import MapDescription from './Map_description';
import MapArea from './Map_area';
import MapLatLng from './Map_latlng';
import MapReport from './Map_report';
import MapInfo from './Map_info';
import MapPreset from './Map_preset';
import ProjectService from '../../Service/ProjectService';
import TagService from '../../Service/TagService';
import ReportService from '../../Service/ReportService';
import { Marker, InfoWindow } from "@react-google-maps/api";
import Utilities from "../../Util/Utilities";
import html2canvas from 'html2canvas';
import CmtCard from '../../@coremat/CmtCard';
import WresourceService from '../../Service/WresourceService'
import { getLocalStorageMaster } from '../../Service/_LocalStorageService';
import {
    checkMasterProjectExist,
    checkDistrictMasterDataExist,
    serviceWrapper,
    checkMasterDataLoaded
} from '../../Util/_ManageMasterData';
import SignoffService from '../../Service/SignoffService';
import { getLocalStorage, setLocalStorage } from '../../Service/_LocalStorageService';

// const ServerPath = "https://ssl.klickerlab.com/geoserver/twp/";
const baseURL = ((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin) + "/twp/v2";
const maxRadius = 100;
const maxMarker = 100;

let HTMLMarker;

class Map extends React.Component {
    state = {
        showHelpMenu: true,
        showSpeedDial: false,
        showFilter: false,
        filterPage: 1,
        showLayerWindow: false,
        showProject: false,
        showDescription: false,
        showReport: false,
        showMore: false,
        showInfo: false,
        showAreaWindow: false,
        showLatLngWindow: false,
        showProjectLayer: true,
        showPreset: false,
        signoffList: [],
        masterLoaded: checkMasterDataLoaded(
            this.props,
            [
                "layerPath",
                "waterResourceType",
                "masterProject",
                "districtMasterData"
            ]
        ) ? false : true,
        tag: [],
        preset: [],
        basicSearchData: {

        },
        filter: {

        },
        placesOptions: [],
        description: {

        },
        descriptionList: [],
        showLayer: {

        },
        areaFilter: {
            Radius: 10,
            Invisible: true
        },
        reportData: {

        },
        criticalArea: [],
        projectArea: [],
        waterResourceArea: [],
        searchLayer: null,
        searchProject: null,
        searchOrganization: null,
        searchProvince: null,
        searchLayerFilter: null,
        searchOrganizationFilter: null,
        searchProvinceFilter: null,
        mapType: 0,
        Project: [],
        ProjectCount: 0,
        Limit: 5,
        Page: 1,
        areaMarker: null,
        areaInfoWindow: null,
        selectedProjectInfoWindow: null,
    }
    searchPlacesTimeout = null;
    searchLayerTimeout = null;
    searchProvinceTimeout = null;
    searchOrganizationTimeout = null;
    searchProjectTimeout = null;
    searchAreaProjectTimeout = null;
    mapLayerProjectTimeout = null;
    stateTimeout = null;
    mapWrapper = null;
    map = null;
    maps = null;
    mapMouseDowntimeout = null;
    areaInfoObject = null;
    areaInfoData = null;
    areaCircle = null;
    startHold = null;
    layers = {};
    layersStyle = {};
    currentLatLng = {};
    nearProjectMarkers = [];
    nearProjectInfoWindow = null;
    selectedProjectMarker = null;
    selectedProjectInfoWindowObject = null;
    budgetYearOptions = Utilities.genBudgetYearOptions();
    budgetYearOptionsObject = this.budgetYearOptions.map(obj => ({ BudgetYearID: obj, BudgetYearName: "" + obj }));
    projectCQL = "";
    optionalCQL = {};

    OrganizationActionComponent = ({ number }) => (
        <div className="d-flex align-items-center text-bold">
            <People className="mr-05" />
            <span>หน่วยรับงบประมาณ {number ?? ""}</span>
        </div>
    );
    ProvinceActionComponent = ({ number }) => (
        <div className="d-flex align-items-center text-bold">
            <Room className="mr-05" />
            <span>จังหวัด {number ?? ""}</span>
        </div>
    );
    LayerActionComponent = ({ number }) => (
        <div className="d-flex align-items-center text-bold width-100">
            <Layers className="mr-05" />
            <span>ชั้นข้อมูลพื้นที่ {number ?? ""}</span>
            {/* <span className="ml-auto">
                <Button size="small" variant="contained">
                    <Refresh className="mr-02" />
                    clear
                </Button>
            </span> */}
        </div>
    );

    /////////////////////// Life Cycle ///////////////////////

    componentDidMount() {
        this.getSignoff();
        if (!this.props.mapMode) {
            this.props.setOpenMapMode();
        }
        if (!this.state.masterLoaded) {
            this.setMasterData();
        }
    }

    componentWillUnmount() {
        if (this.props.mapMode) {
            this.props.setCloseMapMode();
        }
    }

    ///////////////////////// Data ////////////////////////////

    checkFilterProps = () => {
        let filterData = {};
        let propData = this.props.filterData;
        if (!propData) return Promise.resolve(false);
        if (propData.PresetCollectionID) {
            for (let i = 0; i < this.state.preset.length; i++) {
                if (this.state.preset[i].PresetCollectionID === propData.PresetCollectionID) {
                    filterData = {
                        ...this.state.preset[i].PresetCollectionJSON,
                        PresetCollectionID: propData.PresetCollectionID
                    }
                    break;
                }
            }
        }
        if (propData.BudgetYear) {
            let data = filterData.BudgetYear ?? [];
            if (data.findIndex(x => x === propData.BudgetYear) === -1) {
                data.push(propData.BudgetYear);
            }
            filterData.BudgetYear = data;
        }
        if (propData.ProjectStatusID) {
            let data = filterData.ProjectStatusID ?? [];
            if (data.findIndex(x => x === propData.ProjectStatusID) === -1) {
                data.push(propData.ProjectStatusID);
            }
            filterData.ProjectStatusID = data;
        }
        if (propData.OrganizationID) {
            let data = filterData.OrganizationID ?? [];
            if (data.findIndex(x => x === propData.OrganizationID) === -1) {
                data.push(propData.OrganizationID);
            }
            filterData.OrganizationID = data;
        }
        if (propData.TagID) {
            let data = filterData.TagID ?? [];
            if (data.findIndex(x => x === propData.TagID) === -1) {
                data.push(propData.TagID);
            }
            filterData.TagID = data;
        }
        if (propData.SignoffID) {
            let data = filterData.SignoffID ?? null;
            filterData.SignoffID = data || propData.SignoffID;
            filterData.disabledSignoff = true;
        }

        if (propData.ProvinceID && this.props.masterData?.GetAllProvince?.length) {
            for (let i = 0; i < this.props.masterData.GetAllProvince.length; i++) {
                const element = this.props.masterData.GetAllProvince[i];
                if (element.ProvinceID === propData.ProvinceID) {
                    let data = filterData.SelectedPlace ?? [];
                    if (data.findIndex(x => x.Value === propData.ProvinceID + "-1") === -1) {
                        data.push({
                            Type: 1,
                            ProvinceID: element.ProvinceID,
                            Value: element.ProvinceID + "-1",
                            Label: element.ProvinceName ?? "",
                        });
                        filterData.SelectedPlace = data;
                        break;
                    }
                }
            }
        }
        if (propData.BasinID && this.props.masterData?.GetAllBasin?.length) {
            for (let i = 0; i < this.props.masterData.GetAllBasin.length; i++) {
                const element = this.props.masterData.GetAllBasin[i];
                if (element.BasinID === propData.BasinID) {
                    let data = filterData.SelectedPlace ?? [];
                    if (data.findIndex(x => x.Value === propData.BasinID + "-4") === -1) {
                        data.push({
                            Type: 4,
                            BasinID: element.BasinID,
                            Value: element.BasinID + "-1",
                            Label: element.BasinName ?? "",
                        });
                        filterData.SelectedPlace = data;
                        break;
                    }
                }
            }
        }
        return new Promise(resolve => {
            this.setState({ filter: filterData },
                () => {
                    this.props.setFilterData(null);
                    resolve(true);
                });
        })
    }

    getSignoff = () => {
        SignoffService.searchSignoff({})
            .then(res => {
                if (!res.data?.SearchSignoff || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                this.setState({
                    signoffList: res.data.SearchSignoff
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
    }

    setDefaultData = () => {
        this.getTag();
        this.getPresetCollection()
            .then(() => {
                this.checkFilterProps();
                this.checkCurrentSignoff();
                // .then((res) => this.getDefaultPreset(res))
            })
            .then(() => this.searchProject(null, true))
            .then(() => {
                this.toggleMapLayerProject();
                this.getBasicSearchData();
                this.searchPlaces();
                this.searchBudgetSummary();
            })
            .catch(e => {
                console.log(e);
            })
    }

    checkCurrentSignoff() {
        if (this.state.filter?.SignoffID && this.state.filter?.disabledSignoff) {
            setLocalStorage('currentSignoff', this.state.filter?.SignoffID);
        } else {
            let currentSignoff = getLocalStorage('currentSignoff')
            if (!currentSignoff) return;

            this.setState({
                filter: {
                    ...this.state.filter,
                    SignoffID: parseInt(currentSignoff),
                    disabledSignoff: true
                }
            });
        }
    }

    setMasterData = () => {
        this.props.showLoading();
        let dataPromise = [];
        let districtMasterData = getLocalStorageMaster("districtMasterData");
        let masterProject = getLocalStorageMaster("masterProject");

        if (checkDistrictMasterDataExist(this.props) && !districtMasterData) {
            dataPromise.push(serviceWrapper("districtMasterData", CoreService.getDistrictMasterData()));
        }
        if (checkMasterProjectExist(this.props) && !masterProject) {
            dataPromise.push(serviceWrapper("masterProject", CoreService.getMasterProject()));
        }
        if (
            !this.props.masterData.GetLayerPath ||
            !this.props.masterData.GetGEOServerProjectLayer
        ) {
            dataPromise.push(GISService.getLayerPath());
        }
        if (!this.props.masterData.GetWaterResourceType) {
            dataPromise.push(CoreService.getWaterResourceType());
        }

        Promise.all(dataPromise)
            .then((res) => {
                if (
                    (!res[0]?.data || res[0]?.errors) ||
                    (res[1] && (!res[1].data || res[1]?.errors)) ||
                    (res[2] && (!res[2].data || res[2]?.errors)) ||
                    (res[3] && (!res[3].data || res[3]?.errors))
                ) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                let data = {}
                for (let i = 0; i < res.length; i++) {
                    data = { ...data, ...res[i].data };
                }

                if (districtMasterData) data = { ...data, ...districtMasterData };
                if (masterProject) data = { ...data, ...masterProject };
                let GetAllStrategyWorkplan = this.setMasterWorkPlan(data.GetAllStrategyKPI);
                if (GetAllStrategyWorkplan)
                    data.GetAllStrategyWorkplan = GetAllStrategyWorkplan;
                let GetAllStrategyKPI = this.setMasterDataWorkPlan(data.GetAllStrategyKPI);
                if (GetAllStrategyKPI)
                    data.GetAllStrategyKPI = GetAllStrategyKPI;
                if (data.GetStrategy) {
                    data.StrategyOptions = this.setMasterStrategy(data.GetStrategy);
                    data.PolicyOptions = this.setMasterPolicy(data.GetStrategy);
                }
                // if (data.GetLayerPath) {
                //     data.GetLayerPath.splice(0, 0, {
                //         LayerID: 0,
                //         LayerGroupName: "แผนงานด้านทรัพยากรน้ำ",
                //         LayerGroupID: 0,
                //         LayerName: "ตำแหน่งแผนงาน/โครงการ",
                //         LayerPath: "twp%3Atwp_project"
                //     });
                // }
                this.props.setMaster(data);
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
                this.setState({ masterLoaded: true });
            })
    }

    setMasterDataWorkPlan = (strategy) => {
        if (!strategy) return;
        let workPlanData = [];
        strategy.forEach(obj => {
            if (obj.Policy?.length) {
                obj.Policy.forEach(object => {
                    if (object.WorkPlan?.length) {
                        for (let i = 0; i < object.WorkPlan.length; i++) {
                            const element = object.WorkPlan[i];
                            if (element.StrategyCode)
                                element.StrategyName = element.StrategyCode + ". " + element.StrategyName;
                        }
                        workPlanData = [...workPlanData, ...object.WorkPlan];
                    }
                })
            }
        });
        return workPlanData;
    }

    setMasterWorkPlan = (strategy) => {
        if (!strategy) return;
        let workPlanData = [];
        strategy.forEach(obj => {
            if (obj.Policy?.length) {
                obj.Policy.forEach(object => {
                    if (object.WorkPlan?.length) {
                        object.WorkPlan.forEach(workplan => {
                            workPlanData.push(workplan);
                        });
                    }
                });
            }
        });
        return workPlanData;
    }

    setMasterStrategy = (strategy) => {
        let Strategy = strategy.filter(x => x.Level === 1);
        // for (let i = 0; i < Strategy.length; i++) {
        //     const element = Strategy[i];
        // }
        return Strategy;
    }

    setMasterPolicy = (strategy) => {
        let Strategy = strategy.filter(x => x.Level === 2);
        for (let i = 0; i < Strategy.length; i++) {
            const element = Strategy[i];
            element.StrategyName = (element.StrategyCode || "") + " " + element.StrategyName;
        }
        return Strategy;
    }

    getCriticalArea = (lat, lng) => {
        if ((!lat && lat !== 0) && (!lng && lng !== 0)) return;
        this.props.showLoading();
        GISService.getGISCriticalAreaInfoByLatLng(lat, lng)
            .then(res => {
                if (!res?.data?.GetGISCriticalAreaInfoByLatLng || res?.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                this.setState({
                    criticalArea: res.data.GetGISCriticalAreaInfoByLatLng
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
            })
    }

    searchProject = (optionalData, setLayerStyle) => {
        this.props.showLoading();
        let filterData = this.getSearchJSON();
        if (optionalData) {
            filterData = { ...filterData, ...optionalData };
        }
        return ProjectService.searchProject(filterData)
            .then(res => {
                let data = res.data?.SearchProject;
                if (!data?.Projects || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                this.projectCQL = data.CQLFilter;
                this.setState({
                    Project: data.Projects,
                    ProjectCount: data.Count
                }, () => {
                    if (setLayerStyle) {
                        this.clearMapLayerStyle();
                        if (!data.LayerStyle?.length) return;
                        for (let i = 0; i < data.LayerStyle.length; i++) {
                            this.toggleMapLayerStyle(data.LayerStyle[i], true);
                        }
                    }
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
            })
    }

    searchPlaces = (placeName) => {
        clearTimeout(this.searchPlacesTimeout);
        this.searchPlacesTimeout = setTimeout(() => {
            CoreService.searchPlace(placeName)
                .then(res => {
                    if (!res?.data?.SearchPlace || res?.errors) {
                        return;
                    }
                    this.setState({
                        placesOptions: res.data.SearchPlace
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        }, 750);
    }

    getSearchJSONPlace = (data) => {
        if (!data || !data.length) return {};
        let cqlData = {
            ProvinceID: [],
            DistrictID: [],
            SubdistrictID: [],
            BasinID: [],
            SubbasinID: []
        };
        for (let i = 0; i < data.length; i++) {
            switch (data[i].Type) {
                case 1: {
                    cqlData.ProvinceID.push(data[i].ProvinceID);
                    break;
                }
                case 2: {
                    cqlData.DistrictID.push(data[i].DistrictID);
                    break;
                }
                case 3: {
                    cqlData.SubdistrictID.push(data[i].SubdistrictID);
                    break;
                }
                case 4: {
                    cqlData.BasinID.push(data[i].BasinID);
                    break;
                }
                case 5: {
                    cqlData.SubbasinID.push(data[i].SubbasinID);
                    break;
                }
                default: break;
            }
        }
        return cqlData;
    }

    getSearchJSONStrategy = () => {
        let strat = [];

        // let PolicyID = [];
        // this.props.masterData.GetStrategy.forEach(obj => {
        //     if (this.state.filter[key].includes(obj.ParentID)) {
        //         PolicyID.push(obj.StrategyID);
        //     }
        // });
        // let WorkPlanID = [];
        // this.props.masterData.GetStrategy.forEach(obj => {
        //     if (PolicyID.includes(obj.ParentID)) {
        //         WorkPlanID.push(obj.StrategyID);
        //     }
        // });

        // //////////////////////////

        // if (!this.state.filter[key]?.length) break;
        // let WorkPlanID = [];
        // this.props.masterData.GetStrategy.forEach(obj => {
        //     if (this.state.filter[key].includes(obj.ParentID)) {
        //         WorkPlanID.push(obj.StrategyID);
        //     }
        // });

        if (this.state.filter?.StrategyID?.length) {
            for (let i = 0; i < this.state.filter.StrategyID.length; i++) {
                const element = this.state.filter.StrategyID[i];
                strat.push(element);
            }
        }
        if (this.state.filter?.PolicyID?.length) {
            for (let i = 0; i < this.state.filter.PolicyID.length; i++) {
                const element = this.state.filter.PolicyID[i];
                strat.push(element);
            }
        }
        return strat.length ? strat : undefined;
    }
    getSearchJSON = () => {
        return {
            BudgetYear: this.state.filter?.BudgetYear || undefined,
            ProjectName: this.state.filter?.ProjectName || undefined,
            ProjectCode: this.state.filter?.ProjectCode || undefined,
            OrganizationID: this.state.filter?.OrganizationID?.length ? this.state.filter.OrganizationID : undefined,
            DLAOrganizationID: this.state.filter?.DLAOrganizationID?.length ? this.state.filter.DLAOrganizationID : undefined,
            OperatorOrganizationID: this.state.filter?.OperatorOrganizationID?.length ? this.state.filter.OperatorOrganizationID : undefined,
            // ProvinceID: this.state.filter?.ProvinceID?.length ? this.state.filter.ProvinceID : undefined,
            // DistrictID: this.state.filter?.DistrictID?.length ? this.state.filter.DistrictID : undefined,
            // SubdistrictID: this.state.filter?.SubdistrictID?.length ? this.state.filter.SubdistrictID : undefined,
            // BasinID: this.state.filter?.BasinID?.length ? this.state.filter.BasinID : undefined,
            // SubbasinID: this.state.filter?.SubbasinID?.length ? this.state.filter.SubbasinID : undefined,
            ...this.getSearchJSONPlace(this.state.filter.SelectedPlace),
            BudgetSourceID: this.state.filter?.BudgetSourceID?.length ? this.state.filter.BudgetSourceID : undefined,
            BudgetTypeID: this.state.filter?.BudgetTypeID?.length ? this.state.filter.BudgetTypeID : undefined,
            ActivityID: this.state.filter?.ActivityID?.length ? this.state.filter.ActivityID : undefined,
            ProjectTypeID: this.state.filter?.ProjectTypeID?.length ? this.state.filter.ProjectTypeID : undefined,
            AreaBaseID: this.state.filter?.AreaBaseID?.length ? this.state.filter.AreaBaseID : undefined,
            BudgetDimensionID: this.state.filter?.BudgetDimensionID?.length ? this.state.filter.BudgetDimensionID : undefined,
            StrategyID: this.getSearchJSONStrategy(),
            TagID: this.state.filter?.TagID?.length ? this.state.filter?.TagID : undefined,
            ProjectStatusID: this.state.filter?.ProjectStatusID?.length ? this.state.filter?.ProjectStatusID : undefined,
            ProjectTypeAmount: this.state.filter?.ProjectTypeAmountID || undefined,
            SignoffID: this.state.filter?.SignoffID || undefined,
            Keyword: this.state.searchProject || undefined,
            BudgetRangeStart: this.state.filter?.BudgetRangeStart ?? undefined,
            BudgetRangeEnd: this.state.filter?.BudgetRangeEnd ?? undefined,
            MYProject: this.state.filter?.MYProject ?? undefined,
            Offset: ((this.state.Page - 1) * this.state.Limit),
            Limit: this.state.Limit,
            GetMap: true,
        }
    }

    GetGISProjectByLatLngMultiSelect = (lat, lng, zoom, data) => {
        if ((!lat && lat !== 0) || (!lng && !lng === 0) || !zoom) return;
        return GISService.GetGISProjectByLatLngMultiSelect(lat, lng, zoom, data);
    }

    getAreaInfoByLatLng = (lat, lng) => {
        this.props.showLoading();
        return GISService.getGISAreaInfoByLatLng(lat, lng)
            .then(res => {
                if (!res?.data?.GetGISAreaInfoByLatLng || res?.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                let data = res.data.GetGISAreaInfoByLatLng;
                let newState = {
                    Province: {},
                    District: {},
                    Subdistrict: {},
                    Basin: {},
                    Subbasin: {},
                    AreaBase: {}
                };
                if (!data.length) {
                    Swal.fire({
                        title: 'ไม่พบข้อมูลพื้นที่',
                        text: 'ไม่พบข้อมูลในระบบ GIS',
                        icon: 'warning'
                    });
                    return null;
                }
                for (let i = 0; i < data.length; i++) {
                    const obj = data[i];
                    switch (obj.ShapeType) {
                        case "province": {
                            let data = this.props.masterData.GetAllProvince?.find(x => x.ProvinceCode === obj.ShapeCode);
                            let newData = {
                                ProvinceID: data?.ProvinceID ?? null,
                                ProvinceName: data?.ProvinceName ?? null
                            }
                            newState.Province = newData;
                            break;
                        }
                        case "district": {
                            let data = (newState.Province?.ProvinceID) ?
                                this.props.masterData?.GetDistrictObject?.[newState.Province?.ProvinceID] ?? this.props.masterData?.GetAllDistrict
                                : this.props.masterData?.GetAllDistrict;
                            data = data?.find(x => x.DistrictCode === obj.ShapeCode);
                            let newData = {
                                DistrictID: data?.DistrictID ?? null,
                                DistrictName: data?.DistrictName ?? null
                            }
                            newState.District = newData;
                            break;
                        }
                        case "subdistrict": {
                            let data = (newState.District?.DistrictID) ?
                                this.props.masterData?.GetSubdistrictObject?.[newState.District?.DistrictID] ?? this.props.masterData?.GetAllSubdistrict
                                : this.props.masterData?.GetAllSubdistrict;
                            data = data?.find(x => x.SubdistrictCode === obj.ShapeCode);
                            let newData = {
                                SubdistrictID: data?.SubdistrictID ?? null,
                                SubdistrictName: data?.SubdistrictName ?? null
                            }
                            newState.Subdistrict = newData;
                            break;
                        }
                        case "basin": {
                            let data = this.props.masterData.GetAllBasin?.find(x => x.BasinCode === obj.ShapeCode);
                            let newData = {
                                BasinID: data?.BasinID ?? null,
                                BasinName: data?.BasinName ?? null
                            }
                            newState.Basin = newData;
                            break;
                        }
                        case "subbasin": {
                            let data = this.props.masterData.GetAllSubbasin?.find(x => x.SubbasinCode === obj.ShapeCode);
                            let newData = {
                                SubbasinID: data?.SubbasinID ?? null,
                                SubbasinName: data?.SubbasinName ?? null
                            }
                            newState.Subbasin = newData;
                            break;
                        }
                        case "areabase": {
                            let data = this.props.masterData.GetAreaBase?.find(x => x.AreaBaseCode === obj.ShapeCode);
                            let newData = {
                                AreaBaseID: data?.AreaBaseID ?? null,
                                AreaBaseName: data?.AreaBaseName ?? null,
                                AreaBaseCode: data?.AreaBaseCode ?? null,
                                LayerID: data ? obj.LayerID ?? null : null
                            }
                            newState.AreaBase = newData;
                            break;
                        }
                        default: break;
                    }
                }
                return newState;
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
            })
    }

    getProjectDetail = (projectID) => {
        if (!projectID) return;
        return ProjectService.getProjectDetail(projectID);
    }

    getWresourceDetail = (WaterResourceID) => {
        if (!WaterResourceID) return;
        return WresourceService.getWaterResourceDetail(WaterResourceID);
    }

    getProjectNearLatLng = () => {
        if (
            this.currentLatLng?.lat === undefined ||
            !this.currentLatLng.lng === undefined
        ) {
            Swal.fire({
                title: "กรุณาปักหมุด",
                text: "กรุณาปักหมุดก่อนค้นหาโครงการใกล้เคียง",
                icon: "warning"
            })
            return;
        }
        if (!this.state.areaFilter?.Radius) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                text: "กรุณาระบุรัศมี",
                icon: "warning"
            })
            return;
        }
        this.props.showLoading();
        let data = {
            Lat: this.currentLatLng.lat,
            Lng: this.currentLatLng.lng,
            RadiusKM: this.state.areaFilter.Radius,
            ...this.state.areaFilter
        }
        return GISService.GetGISNearProjectsMultiSelect(data)
            .then(async (res) => {
                if (!res.data?.GetGISNearProjectsMultiSelect || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                await this.getWaterResourceNearLatLng(data);
                this.clearNearProjectMarker();
                this.setNearProjectMarker(res.data.GetGISNearProjectsMultiSelect);
                this.setState({
                    projectArea: res.data.GetGISNearProjectsMultiSelect
                })
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
            })
    }

    setWaterResourceCQL = (CQL) => {
        let layer = this.props.masterData?.GetLayerPath?.find?.(x => x.LayerName === "ข้อมูลแหล่งน้ำจากระบบ Thai Water Resource")?.LayerID;
        if (!layer) return;
        this.optionalCQL[layer] = CQL ?? "";
        if (!this.state.showLayer[layer]) return;
        this.toggleMapLayer(layer, false);
        this.toggleMapLayer(layer, true);
    }

    getWaterResourceNearLatLng = (data) => {
        return GISService.getGISNearWaterResourceMultiSelect(data)
            .then((res) => {
                return new Promise((r) => {
                    let data = res?.data?.GetGISNearWaterResourceMultiSelect;
                    this.waterResourceCQL = this.setWaterResourceCQL(data.CQL || "");

                    this.setState({
                        waterResourceArea: data.WaterResource || []
                    }, r);
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    getTag = () => {
        TagService.searchTag({})
            .then(res => {
                if (!res.data?.SearchTag || res.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                    return;
                }
                this.setState({
                    tag: res.data.SearchTag
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
    }

    searchBudgetSummary = () => {
        let data = {
            ProjectTypeSummaryData: [],
            StrategySummaryData: []
        }
        let filterData = this.getSearchJSON();
        this.setState({ Page: 1, loadingData: true, selected: [] }, () => {
            ReportService.getBudgetSummary({ ...filterData, Type: 10 })
                .then(res => {
                    if (res.error || !res.data?.GetBudgetSummary) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    data.ProjectTypeSummaryData = res.data.GetBudgetSummary;
                    // let typeName = "ลักษณะงาน";
                    // data.ProjectTypeSummaryData = [
                    //     {
                    //         "type": "column",
                    //         "name": typeName,
                    //         "data": res.data.GetBudgetSummary.map((element) => [element.Name, element.ProjectCount]),
                    //         "color": "#1776c3"
                    //     }
                    // ];
                })
                .then(() => ReportService.getBudgetSummary({ ...filterData, Type: 4 }))
                .then(res => {
                    if (res.error || !res.data?.GetBudgetSummary) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                            icon: 'error'
                        });
                        return;
                    }
                    data.StrategySummaryData = res.data.GetBudgetSummary;
                    // let typeName = "แผนแม่บทฯน้ำ 20 ปี";
                    // data.StrategySummaryData = [
                    //     {
                    //         "type": "pie",
                    //         "name": typeName,
                    //         "data": res.data.GetBudgetSummary.map((element) => [element.Name, element.SumRequestAmount]),
                    //         "color": "#1776c3"
                    //     }
                    // ];
                })
                .then(() => {
                    this.setState({
                        reportData: data
                    });
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        icon: 'error'
                    });
                })
        })
    }

    getPresetCollection = () => {
        return CoreService.getPresetCollection()
            .then(res => {
                return new Promise((resolve, reject) => {
                    if (!res.data?.GetPresetCollection || res.errors) {
                        reject();
                        return;
                    }
                    this.setState({
                        preset: res.data.GetPresetCollection
                    }, () => {
                        resolve();
                    })
                })
            })
    }

    getDefaultPreset = (PresetCollectionID) => {
        if (PresetCollectionID === true)
            return Promise.resolve();
        else if (this.props.account?.DefaultPresetCollectionID) {
            this.setSearchPreset(this.props.account.DefaultPresetCollectionID, "PresetCollectionID", true);
        }
    }

    getBasicSearchData = () => {
        let data = {};
        let filterData = this.getSearchJSON();
        return ReportService.getBudgetSummary({ ...filterData, Type: 1 })
            .then(res => {
                if (res.error || !res.data?.GetBudgetSummary) {
                    throw new Error();
                }
                data.provinceData = res.data.GetBudgetSummary;
            })
            .then(() => ReportService.getBudgetSummary({ ...filterData, Type: 3 }))
            .then(res => {
                if (res.error || !res.data?.GetBudgetSummary) {
                    throw new Error();
                }
                data.organizationData = res.data.GetBudgetSummary;
            })
            .then(() => {
                this.setState({
                    basicSearchData: data
                });
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
    }

    ///////////////////////// Map ///////////////////////////

    setMap = (map) => {
        this.map = map;
        this.maps = window.google.maps;
        // this.toggleMapLayerProject();
        this.setDefaultData();
        this.toggleMapLayer(4, true);
        this.setState({
            showLayer: { ...this.state.Layer, 4: true }
        });
        this.map.addListener('mousedown', (e) => {
            this.startHold = new Date().getTime();
            clearTimeout(this.mapMouseDowntimeout);
            this.mapMouseDowntimeout = setTimeout(() => {
                if (this.startHold !== null)
                    this.handleClickHoldMap(e, (this.state.areaFilter.Radius > 50) ? 9 : 11);
            }, 1000)
        });
        this.map.addListener('dragstart', (e) => {
            this.startHold = null;
            clearTimeout(this.mapMouseDowntimeout);
        });
        this.map.addListener('click', (e) => {
            let end = new Date().getTime();
            let longpress = (end - this.startHold > 1000);
            this.startHold = null;
            clearTimeout(this.mapMouseDowntimeout);
            if (!longpress) {
                this.handleClickMap(e);
            }
        });

        class HTMLMapMarker extends this.maps.OverlayView {
            constructor(args) {
                super();
                this.latlng = args.position;
                this.html = args.html;
                this.setMap(args.map);
                this.onClick = args.onClick;
            }

            createDiv() {
                this.div = document.createElement('div');
                this.div.style.position = 'absolute';
                // this.div.style.width = "25px";
                // this.div.style.height = "25px";
                // this.div.style.zIndex = "10000";
                if (this.html) {
                    this.div.innerHTML = this.html;
                }
                window.google.maps.event.addDomListener(this.div, 'click', event => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (this.onClick) this.onClick();
                });
            }

            appendDivToOverlay() {
                const panes = this.getPanes();
                panes.overlayMouseTarget.appendChild(this.div);
            }

            positionDiv() {
                const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                if (point) {
                    this.div.style.left = `${point.x}px`;
                    this.div.style.top = `${point.y}px`;
                }
            }

            draw() {
                if (!this.div) {
                    this.createDiv();
                    this.appendDivToOverlay();
                }
                this.positionDiv();
            }

            remove() {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    this.div = null;
                }
            }

            getPosition() {
                return this.latlng;
            }

            getDraggable() {
                return false;
            }
        }

        HTMLMarker = HTMLMapMarker;
        // this.map.addListener('mouseup', (e) => {
        //     let end = new Date().getTime();
        //     this.longpress = (end - this.startHold > 400);
        //     this.startHold = null;
        //     if (this.longpress) {
        //         this.handleClickHoldMap(e);
        //     }
        //     else {
        //         this.handleClickMap(e);
        //     }
        // });
        // this.map.addListener("click", (e) => {
        //     this.handleClickMap(e);
        // });
    }

    handleClickMap = async (e) => {
        this.props.showLoading();
        this.removeSelectedProjectMarker();
        this.GetGISProjectByLatLngMultiSelect(e.latLng.lat(), e.latLng.lng(), this.map.getZoom(), this.getSearchJSON())
            .then(res => {
                let data = res.data.GetGISProjectByLatLngMultiSelect;
                if (!data || !data[0] || res?.errors) {
                    // throw new Error();
                    this.setState({
                        description: {},
                        descriptionList: [],
                        showDescription: false
                    });
                    return;
                }
                this.setState({
                    showDescription: true,
                    showAreaWindow: false,
                    description: data[0],
                    descriptionList: data.map(x => ({ ProjectID: x.ProjectID, ProjectName: x.ProjectName }))
                }, () => {
                    if (
                        (this.state.description?.Lat || this.state.description?.Lat === 0) &&
                        (this.state.description?.Lng || this.state.description?.Lng === 0)) {
                        let latlng = new this.maps.LatLng(this.state.description.Lat, this.state.description.Lng);
                        this.map.panTo(latlng);
                        this.setSelectedProjectMarker(this.state.description);
                    }
                })
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    description: {},
                    descriptionList: [],
                    showDescription: false
                });
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    icon: 'error'
                });
            })
            .finally(() => {
                this.props.hideLoading();
            })
    }

    handleClickHoldMap = async (e, zoom) => {
        this.areaInfoData = await this.getAreaInfoByLatLng(e.latLng.lat(), e.latLng.lng());
        this.setAreaMarker(e.latLng.lat(), e.latLng.lng(), zoom);
        this.clearNearProjectMarker();
    }

    setAreaMarker = (lat, lng, zoom) => {
        if (this.state.areaMarker) {
            this.setState({
                areaMarker: null,
            }, () => {
                if ((lat || lat === 0) && (lng || lng === 0)) {
                    let latLng = new this.maps.LatLng(lat, lng);
                    this.setState({
                        projectArea: [],
                        waterResourceArea: [],
                        areaInfoWindow: null,
                        areaMarker:
                            <Marker
                                position={latLng}
                                // animation={this.maps.Animation.DROP}
                                onClick={this.openAreaInfoWindow}
                                onDragEnd={(e) => this.handleClickHoldMap(e, this.map.getZoom())}
                                draggable={true}
                            />
                    }, () => {
                        this.waterResourceCQL = this.setWaterResourceCQL("");
                        this.currentLatLng = { lat, lng };
                        this.map.panTo(latLng);
                        this.map.setZoom(zoom ?? 9);
                        this.setMarkerInfoWindow(latLng);
                        this.setAreaCircle(latLng);
                        this.getCriticalArea(lat, lng);
                        this.clearSelectedProjectMarker();
                    });
                }
            })
        }
        else {
            if ((lat || lat === 0) && (lng || lng === 0)) {
                let latLng = new this.maps.LatLng(lat, lng);
                this.setState({
                    projectArea: [],
                    waterResourceArea: [],
                    areaInfoWindow: null,
                    areaMarker:
                        <Marker
                            position={latLng}
                            // animation={this.maps.Animation.DROP}
                            onClick={this.openAreaInfoWindow}
                            onDragEnd={(e) => this.handleClickHoldMap(e, this.map.getZoom())}
                            draggable={true}
                        />
                }, () => {
                    this.waterResourceCQL = this.setWaterResourceCQL("");
                    this.currentLatLng = { lat, lng };
                    this.map.panTo(latLng);
                    this.map.setZoom(zoom ?? 9);
                    this.setMarkerInfoWindow(latLng);
                    this.setAreaCircle(latLng);
                    this.getCriticalArea(lat, lng);
                    this.clearSelectedProjectMarker();
                })
            }
        }
    }

    onClickInfoWindowButton = (Type) => {
        let newPlaces = [];
        let newData = {};
        if (Type === 6) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    AreaBaseID: this.areaInfoData.AreaBase?.AreaBaseID ?
                        [this.areaInfoData.AreaBase.AreaBaseID] : undefined,
                    SelectedPlace: []
                }
            },
                () => {
                    this.searchProject(null, true)
                        .then(() => {
                            this.getBasicSearchData();
                            this.toggleMapLayerProject();
                            this.searchBudgetSummary();
                        })
                });
        }
        else {
            switch (Type) {
                case 1: {
                    newData.Type = Type;
                    newData.ProvinceID = this.areaInfoData.Province?.ProvinceID;
                    newData.Value = this.areaInfoData.Province?.ProvinceID + "-1";
                    newData.Label = this.areaInfoData.Province?.ProvinceName ?? "";
                    break;
                }
                case 2: {
                    newData.Type = Type;
                    newData.DistrictID = this.areaInfoData.District?.DistrictID;
                    newData.Value = this.areaInfoData.District?.DistrictID + "-2";
                    newData.Label = "";
                    newData.Label += (this.areaInfoData.Province?.ProvinceName ?? "");
                    newData.Label += ((newData.Label) ? ", " : "") + (this.areaInfoData.District?.DistrictName ?? "");
                    break;
                }
                case 3: {
                    newData.Type = Type;
                    newData.SubdistrictID = this.areaInfoData.Subdistrict?.SubdistrictID;
                    newData.Value = this.areaInfoData.Subdistrict?.SubdistrictID + "-3";
                    newData.Label = "";
                    newData.Label += (this.areaInfoData.Province?.ProvinceName ?? "");
                    newData.Label += ((newData.Label) ? ", " : "") + (this.areaInfoData.District?.DistrictName ?? "");
                    newData.Label += ((newData.Label) ? ", " : "") + (this.areaInfoData.Subdistrict?.SubdistrictName ?? "");
                    break;
                }
                case 4: {
                    newData.Type = Type;
                    newData.BasinID = this.areaInfoData.Basin?.BasinID;
                    newData.Value = this.areaInfoData.Basin?.BasinID + "-4";
                    newData.Label = this.areaInfoData.Basin?.BasinName ?? "";
                    break;
                }
                case 5: {
                    newData.Type = Type;
                    newData.SubbasinID = this.areaInfoData.Subbasin?.SubbasinID;
                    newData.Value = this.areaInfoData.Subbasin?.SubbasinID + "-5";
                    break;
                }
                default: break;
            }
            newPlaces.push(newData);
            this.setState({
                filter: {
                    ...this.state.filter,
                    SelectedPlace: newPlaces,
                    AreaBaseID: []
                }
            },
                () => {
                    this.searchProject(null, true)
                        .then(() => {
                            this.getBasicSearchData();
                            this.toggleMapLayerProject();
                            this.searchBudgetSummary();
                        })
                });
        }
    }

    setMarkerInfoWindow = (latLng) => {
        if (!this.state.areaMarker) return;
        if (!this.areaInfoData) {
            this.setState({ areaInfoWindow: null });
            return;
        }
        this.setState({
            areaInfoWindow:
                <InfoWindow
                    position={latLng}
                    onLoad={(window) => {
                        this.areaInfoObject = window;
                        window.close();
                    }}
                    onUnmount={() => {
                        this.setState({ areaInfoWindow: null });
                    }}
                    options={{
                        pixelOffset: new this.maps.Size(0, -45),
                    }}
                >
                    <div style={{ minWidth: "200px", maxWidth: "250px" }} className="px-03 pb-10 pt-03">
                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                            <Box display="flex" alignItems="center">
                                <Info color="primary" className="mr-05" />
                                <Typography variant="h4">ข้อมูลเชิงพื้นที่</Typography>
                            </Box>
                            <div style={{ width: '100%' }} />
                            <small>กดเพื่อคัดกรองโครงการในพื้นที่</small>
                        </Box>
                        <Grid container spacing={2} className="section-border p-05 mt-05">
                            {
                                this.currentLatLng?.lat ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="default"
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {`Lat ${this.currentLatLng.lat?.toFixed(6)}`}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                            {
                                this.currentLatLng?.lng ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="default"
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {`Lng ${this.currentLatLng.lng?.toFixed(6)}`}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                        </Grid>
                        <Grid container spacing={2} className="section-border p-05 mt-05">
                            {
                                this.areaInfoData.Province?.ProvinceName ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="primary"
                                            clickable
                                            onClick={() => this.onClickInfoWindowButton(1)}
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {"จ." + this.areaInfoData.Province.ProvinceName}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                            {
                                this.areaInfoData.District?.DistrictName ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="primary"
                                            clickable
                                            onClick={() => this.onClickInfoWindowButton(2)}
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {"อ." + this.areaInfoData.District.DistrictName}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                            {
                                this.areaInfoData.Subdistrict?.SubdistrictName ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="primary"
                                            clickable
                                            onClick={() => this.onClickInfoWindowButton(3)}
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {"ต." + this.areaInfoData.Subdistrict.SubdistrictName}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                        </Grid>
                        <Grid container spacing={2} className="mt-10 section-border p-05">
                            {
                                this.areaInfoData.Basin?.BasinName ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="default"
                                            clickable
                                            onClick={() => this.onClickInfoWindowButton(4)}
                                            className="width-100"
                                            label={
                                                <Typography variant="h5">
                                                    {this.areaInfoData.Basin.BasinName}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                            {
                                this.areaInfoData.AreaBase?.AreaBaseName ?
                                    <Grid item xs={12} className="d-flex">
                                        <Chip
                                            color="default"
                                            clickable
                                            onClick={() => this.onClickInfoWindowButton(6)}
                                            className="width-100"
                                            label={
                                                <Tooltip title={this.areaInfoData.AreaBase.AreaBaseName}>
                                                    <Typography variant="h5">{this.areaInfoData.AreaBase?.AreaBaseCode ? (this.areaInfoData.AreaBase.AreaBaseCode) : ""}</Typography>
                                                </Tooltip>
                                            }
                                        />
                                    </Grid>
                                    : null
                            }
                        </Grid>
                        <Box className="mt-10">
                            <Chip
                                color="default"
                                clickable
                                onClick={this.removeAreaMarker}
                                className="width-100 btn-danger"
                                label={
                                    <Typography variant="h5">ลบหมุด</Typography>
                                }
                            />
                        </Box>
                    </div>
                </InfoWindow>
        })
    }

    setAreaCircle = (latLng) => {
        if (this.areaCircle) {
            this.areaCircle.setRadius((this.state.areaFilter?.Radius ?? 0) * 1000);
            this.areaCircle.setCenter(latLng);
            return;
        }
        this.areaCircle = new this.maps.Circle({
            strokeColor: "#00009F",
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: "#00009F",
            fillOpacity: 0.25,
            map: this.map,
            center: latLng,
            editable: false,
            draggable: false,
            clickable: false,
            radius: (this.state.areaFilter?.Radius ?? 0) * 1000,
            visible: this.state.areaFilter?.Invisible !== undefined ? !(this.state.areaFilter.Invisible) : true
        });
    }

    openAreaInfoWindow = () => {
        if (this.areaInfoObject)
            this.areaInfoObject.open(this.map);
    }

    removeAreaMarker = () => {
        this.areaInfoData = null;
        this.areaInfoObject = null;
        if (this.areaCircle) {
            this.areaCircle.setMap(null);
        }
        this.areaCircle = null;
        this.setState({
            areaMarker: null,
            areaInfoWindow: null,
            criticalArea: [],
            projectArea: [],
            waterResourceArea: []
        }, () => {
            this.waterResourceCQL = this.setWaterResourceCQL("");
            this.currentLatLng = {};
            this.clearNearProjectMarker();
        });
    }

    setMapType = (index) => {
        switch (index) {
            case 0: {
                this.map.setMapTypeId('roadmap');
                break;
            }
            case 1: {
                this.map.setMapTypeId('satellite');
                break;
            }
            case 2: {
                this.map.setMapTypeId('terrain');
                break;
            }
            default: return;
        }
        this.setState({ mapType: index });
    }

    setShowProjectLayer = (show) => {
        this.setState({ showProjectLayer: show ? true : false }, () => {
            this.toggleMapLayerProject();
        });
    }

    setShowLayer = (e) => {
        this.setState({
            showLayer: {
                ...this.state.showLayer,
                [e.target.name]: e.target.checked
            }
        }, () => {
            this.toggleMapLayer(e.target.name, e.target.checked)
        });
    }

    clearShowLayer = (e) => {
        let newShowLayer = {};
        this.props.masterData.GetLayerPath.forEach(obj => {
            newShowLayer[obj.LayerID] = false;
            this.toggleMapLayer(obj.LayerID, false);
        });
        this.setShowProjectLayer(false);
        this.setState({
            showLayer: newShowLayer
        });
    }

    getLayerData = (layer) => {
        if (!this.props.masterData?.GetLayerPath) return null;
        return this.props.masterData.GetLayerPath.find(x => x.LayerID == layer) ?? null;
    }

    toggleMapLayerProject = () => {
        if (!this.map) return;
        if (this.layers.Project) {
            let index = this.map.overlayMapTypes.indexOf(this.layers.Project.imageMap);
            if (index !== -1)
                this.map.overlayMapTypes.removeAt(index);
            this.layers.Project = null;
        }
        clearTimeout(this.mapLayerProjectTimeout);
        if (!this.state.showProjectLayer) return;
        this.mapLayerProjectTimeout = setTimeout(this.createMapLayerProject, 500);
    }

    createMapLayerProject = () => {
        let geoObj = {
            url: "",
            layerPath: "",
            imageMap: null
        };
        if (!this.props.masterData.GetGEOServerProjectLayer) return;
        geoObj.url = this.props.masterData.GetGEOServerProjectLayer.ServerPath;
        geoObj.layerPath = this.props.masterData.GetGEOServerProjectLayer.LayerPath;
        let deltaX = 0.0;
        let deltaY = 0.0;
        let areaGEO = new this.maps.ImageMapType({
            getTileUrl: (coord, zoom) => {
                let proj = this.map.getProjection();
                let zfactor = Math.pow(2, zoom);
                let top = proj.fromPointToLatLng(new this.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
                let bot = proj.fromPointToLatLng(new this.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor));
                let bbox = "&minx=" + (top.lng() + deltaX) + "&miny=" + (bot.lat() + deltaY) + "&maxx=" + (bot.lng() + deltaX) + "&maxy=" + (top.lat() + deltaY);
                let url = baseURL + "/getmap?";
                url += "layer=" + geoObj.layerPath;
                url += bbox;
                url += (this.projectCQL) ? "&cql=" + this.projectCQL.replace("&cql_filter=", "") : "";
                url += "&token=" + (this.props.account.SessionToken);
                if (this.map?.getZoom() < 5) {
                    url += "&env=IconSize:3;IconBorder:1";
                }
                else if (this.map?.getZoom() < 7) {
                    url += "&env=IconSize:5;IconBorder:1";
                }
                return url;
            },
            tileSize: new this.maps.Size(256, 256),
            opacity: 0.7,
            isPng: true
        });
        geoObj.imageMap = areaGEO;
        this.layers.Project = geoObj;
        this.map.overlayMapTypes.push(areaGEO);
    }

    checkOptionalCQL = (layer) => {
        return this.optionalCQL[layer] ?? "";
    }

    toggleMapLayer = (layer, checked) => {
        if (checked) {
            let layerData = this.getLayerData(layer);
            let optionalCQL = this.checkOptionalCQL(layer);
            if (!layerData) return;
            let geoObj = {
                url: "",
                layerPath: "",
                layerName: "",
                groupName: "",
                groupID: "",
                imageMap: null
            };
            geoObj.url = layerData.ServerPath;
            geoObj.layerPath = layerData.LayerPath;
            geoObj.layerName = layerData.LayerName;
            geoObj.groupName = layerData.LayerGroupName;
            geoObj.groupID = layerData.LayerGroupID;
            let deltaX = 0.0;
            let deltaY = 0.0;
            let areaGEO = new this.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    let proj = this.map.getProjection();
                    let zfactor = Math.pow(2, zoom);
                    let top = proj.fromPointToLatLng(new this.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
                    let bot = proj.fromPointToLatLng(new this.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor));
                    let bbox = "&minx=" + (top.lng() + deltaX) + "&miny=" + (bot.lat() + deltaY) + "&maxx=" + (bot.lng() + deltaX) + "&maxy=" + (top.lat() + deltaY);
                    let url = baseURL + "/getmap?";
                    url += "layer=" + geoObj.layerPath;
                    url += bbox;
                    url += (optionalCQL) ? ("&cql=" + optionalCQL) : "";
                    url += "&token=" + (this.props.account.SessionToken);
                    return url;
                },
                tileSize: new this.maps.Size(256, 256),
                opacity: 0.7,
                isPng: true
            });
            geoObj.imageMap = areaGEO;
            this.layers[layer] = geoObj;
            this.map.overlayMapTypes.push(areaGEO);
            this.toggleMapLayerProject();
        } else {
            if (!this.layers[layer]) return;
            let index = this.map.overlayMapTypes.indexOf(this.layers[layer].imageMap);
            if (index !== -1)
                this.map.overlayMapTypes.removeAt(index);
            this.layers[layer] = null;
        }
    }

    clearMapLayerStyle = () => {
        if (!this.layersStyle) return;
        for (const key in this.layersStyle) {
            if (Object.hasOwnProperty.call(this.layersStyle, key) && this.layersStyle[key]) {
                let index = this.map.overlayMapTypes.indexOf(this.layersStyle[key].imageMap);
                if (index !== -1)
                    this.map.overlayMapTypes.removeAt(index);
                this.layersStyle[key] = null;
            }
        }
    }


    toggleMapLayerStyle = (data, checked) => {
        if (!data) return;
        let layer = data.LayerID;
        if (checked) {
            let layerData = this.getLayerData(layer);
            if (!layerData) return;
            let geoObj = {
                url: "",
                layerPath: "",
                layerName: "",
                style: "",
                cqlFilter: "",
                groupName: "",
                groupID: "",
                imageMap: null
            };
            geoObj.url = layerData.ServerPath;
            geoObj.layerPath = layerData.LayerPath;
            geoObj.layerName = layerData.LayerName;
            geoObj.style = data.Style;
            geoObj.cqlFilter = data.CQLFilter;
            geoObj.groupName = layerData.LayerGroupName;
            geoObj.groupID = layerData.LayerGroupID;
            let deltaX = 0.0;
            let deltaY = 0.0;
            let areaGEO = new this.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    let proj = this.map.getProjection();
                    let zfactor = Math.pow(2, zoom);
                    let top = proj.fromPointToLatLng(new this.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
                    let bot = proj.fromPointToLatLng(new this.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor));
                    let bbox = "&minx=" + (top.lng() + deltaX) + "&miny=" + (bot.lat() + deltaY) + "&maxx=" + (bot.lng() + deltaX) + "&maxy=" + (top.lat() + deltaY);
                    let url = baseURL + "/getmap?";
                    url += "layer=" + geoObj.layerPath;
                    url += "&style=" + geoObj.style;
                    url += bbox;
                    url += "&cql=" + geoObj.cqlFilter;
                    url += "&token=" + (this.props.account.SessionToken);
                    return url;
                },
                tileSize: new this.maps.Size(256, 256),
                opacity: 0.7,
                isPng: true
            });
            geoObj.imageMap = areaGEO;
            this.layersStyle[layer] = geoObj;
            this.map.overlayMapTypes.push(areaGEO);
            this.toggleMapLayerProject();
        } else {
            if (!this.layersStyle[layer]) return;
            let index = this.map.overlayMapTypes.indexOf(this.layersStyle[layer].imageMap);
            if (index !== -1)
                this.map.overlayMapTypes.removeAt(index);
            this.layersStyle[layer] = null;
        }
    }

    saveMapToDataUrl = async () => {
        this.props.showLoading();
        this.map.set("zoomControl", false);
        this.map.set("fullscreenControl", false);
        // let setAreaCircle = false;
        // let setAreaInfoWindow = false;
        // let areaMarker = this.state.areaMarker ?? null;
        let el = document.querySelector(".gm-bundled-control.gm-bundled-control-on-bottom");
        if (el) {
            el.style.display = "none";
        }
        // if (this.areaCircle && this.areaCircle.visible) {
        //     setAreaCircle = true;
        //     this.areaCircle.setVisible(false);
        // }
        // if (this.areaInfoObject && this.areaInfoObject.getMap()) {
        //     setAreaInfoWindow = true;
        //     this.areaInfoObject.close();
        // }
        // if (areaMarker) {
        //     await new Promise((res) => { this.setState({ areaMarker: null }, () => { res(); }) });
        // }
        if (this.selectedProjectMarker) {
            let pin = document.getElementById("map-project-pin");
            pin.classList.add("pin-noanimation")
        }
        html2canvas(this.mapWrapper, {
            useCORS: false,
            allowTaint: true,
            logging: false,
            proxy: ((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin) + "/canvas"
        })
            .then(canvas => {
                var dataUrl = canvas.toDataURL("image/png");
                var a = document.createElement('a');
                a.setAttribute('download', "map");
                a.setAttribute('href', dataUrl);
                a.setAttribute('target', '_blank');
                a.click();
                a.remove();
                canvas = undefined;
            })
            .catch(e => {
                console.log(e)
            })
            .finally(async () => {
                this.map.set("zoomControl", true);
                this.map.set("fullscreenControl", true);
                if (el) {
                    el.style.display = "";
                }
                // if (setAreaCircle) {
                //     this.areaCircle.setVisible(true);
                // }
                // if (setAreaInfoWindow) {
                //     this.areaInfoObject.open(this.map);
                // }
                // if (areaMarker) {
                //     await new Promise((res) => { this.setState({ areaMarker: areaMarker }, () => { res(); }) });
                // }
                if (this.selectedProjectMarker) {
                    let pin = document.getElementById("map-project-pin");
                    pin.classList.remove("pin-noanimation")
                }
                this.props.hideLoading();
            })
    }

    setNearProjectMarker = (list) => {
        if (!list?.length) return;
        for (let i = 0; i < maxMarker; i++) {
            if (!list[i]) break;
            if (
                (!list[i].Lat && list[i].Lat !== 0) ||
                (!list[i].Lng && list[i].Lng !== 0)
            ) continue;
            const marker = new HTMLMarker({
                position: new this.maps.LatLng(list[i].Lat, list[i].Lng),
                title: list[i].ProjectName,
                html: `<div class="custom-marker">${i + 1}</div>`,
                map: this.map,
                onClick: () => this.showNearProjectInfoWindow(list[i])
                // html: `<div class="custom-marker">${i}</div>`,
                // animation: this.maps.Animation.DROP
            });
            // marker.addListener("click", (e) => {
            //     this.showNearProjectInfoWindow(list[i]);
            // });
            marker.setMap(this.map)
            this.nearProjectMarkers.push(marker);
        }
    }

    clearNearProjectMarker = () => {
        for (let i = 0; i < this.nearProjectMarkers.length; i++) {
            this.nearProjectMarkers[i].setMap(null);
        }
        this.nearProjectMarkers = [];
        if (this.nearProjectInfoWindow) {
            this.nearProjectInfoWindow.close();
            this.nearProjectInfoWindow.setMap(null);
        }
    }

    showNearProjectInfoWindow = (data) => {
        let latLng = new this.maps.LatLng(data.Lat, data.Lng);
        if (!this.nearProjectInfoWindow) {
            this.nearProjectInfoWindow = new this.maps.InfoWindow({
                position: latLng,
                content: data.ProjectName,
                pixelOffset: new this.maps.Size(0, -15)
            });
            this.nearProjectInfoWindow.open(this.map);
        }
        else {
            this.nearProjectInfoWindow.setContent(data.ProjectName);
            this.nearProjectInfoWindow.setPosition(latLng);
            this.nearProjectInfoWindow.open(this.map);
        }
    }

    setSelectedProjectMarker = (data) => {
        this.removeSelectedProjectMarker();
        const marker = new HTMLMarker({
            position: new this.maps.LatLng(data.Lat, data.Lng),
            title: data.ProjectName,
            html: `
            <div class="pin-wrapper">
                <div class='pin' id='map-project-pin'></div>
                <div class='pulse'></div>
            </div>
            `,
            map: this.map,
            onClick: () => this.showSelectedProjectInfoWindow(data)
        });
        marker.setMap(this.map);
        this.selectedProjectMarker = marker;
        this.setState({
            selectedProjectInfoWindow: null
        }, () => {
            this.selectedProjectInfoWindowObject = null;
        })
    }

    showSelectedProjectInfoWindow = (data) => {
        if (!data?.lat && !data?.Lng) return;
        if (this.state.selectedProjectInfoWindow) {
            this.selectedProjectInfoWindowObject.open(this.map)
        }
        else
            this.setState({
                selectedProjectInfoWindow:
                    <InfoWindow
                        position={new this.maps.LatLng(data.Lat, data.Lng)}
                        onLoad={(window) => {
                            this.selectedProjectInfoWindowObject = window;
                        }}
                        onUnmount={() => {
                            this.setState({ areaInfoWindow: null }, () => {
                                this.selectedProjectInfoWindowObject = null;
                                this.removeSelectedProjectMarker();
                            });
                        }}
                        options={{
                            pixelOffset: new this.maps.Size(0, -35),
                        }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            {
                                this.state.description?.ProjectName ?
                                    <Tooltip title={this.state.description.ProjectName}>
                                        <Box maxWidth="300px" display="flex" marginBottom={3} marginTop={1}>
                                            <Typography variant="h4" className="text-ellipsis" style={{ width: "100%" }}>
                                                {this.state.description.ProjectName}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                    : null
                            }
                            <Chip
                                color="default"
                                clickable
                                onClick={this.clearSelectedProjectMarker}
                                className="btn-danger"
                                label={
                                    <Typography variant="h5">ลบหมุด</Typography>
                                }
                            />
                        </Box>
                    </InfoWindow>
            });
    }

    clearSelectedProjectMarker = () => {
        this.setState({
            selectedProjectInfoWindow: null,
            description: {},
            descriptionList: [],
            showDescription: false
        }, () => {
            this.selectedProjectInfoWindowObject = null;
            this.removeSelectedProjectMarker();
        })
    }

    removeSelectedProjectMarker = () => {
        if (this.selectedProjectMarker) {
            this.selectedProjectMarker.setMap(null);
            this.selectedProjectMarker = null;
        }
    }

    ///////////////////// Map Menu //////////////////////

    setShowSpeedDial = (open) => {
        this.setState({ showSpeedDial: open })
    }

    setShowFilter = () => {
        if (
            this.state.showLayerWindow ||
            this.state.showProject ||
            this.state.showReport ||
            this.state.showLatLngWindow ||
            this.state.showInfo
        )
            this.setState({
                showLayerWindow: false,
                showProject: false,
                showReport: false,
                showLatLngWindow: false,
                showInfo: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showFilter: !this.state.showFilter
                    })
                }, 150)
            });
        else {
            this.setState({
                showFilter: !this.state.showFilter
            })
        }
    }

    setShowLayerWindow = () => {
        if (
            this.state.showFilter ||
            this.state.showProject ||
            this.state.showReport ||
            this.state.showLatLngWindow ||
            this.state.showInfo
        )
            this.setState({
                showFilter: false,
                showProject: false,
                showReport: false,
                showLatLngWindow: false,
                showInfo: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showLayerWindow: !this.state.showLayerWindow
                    })
                }, 150)
            });
        else {
            this.setState({
                showLayerWindow: !this.state.showLayerWindow
            })
        }
    }

    setShowProject = () => {
        if (
            this.state.showFilter ||
            this.state.showLayerWindow ||
            this.state.showReport ||
            this.state.showLatLngWindow ||
            this.state.showInfo
        )
            this.setState({
                showFilter: false,
                showLayerWindow: false,
                showReport: false,
                showLatLngWindow: false,
                showInfo: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showProject: !this.state.showProject
                    })
                }, 150)
            });
        else {
            this.setState({
                showProject: !this.state.showProject
            })
        }
    }

    setShowDescription = () => {
        if (this.state.showAreaWindow) {
            this.setState({
                showAreaWindow: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showDescription: !this.state.showDescription,
                    });
                }, 150)
            });
        }
        else {
            this.setState({
                showDescription: !this.state.showDescription,
            });
        }
    }

    setShowReport = () => {
        if (
            this.state.showFilter ||
            this.state.showLayerWindow ||
            this.state.showLatLngWindow ||
            this.state.showProject ||
            this.state.showInfo
        )
            this.setState({
                showFilter: false,
                showLayerWindow: false,
                showLatLngWindow: false,
                showProject: false,
                showInfo: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showReport: !this.state.showReport
                    })
                }, 150)
            });
        else {
            this.setState({
                showReport: !this.state.showReport
            })
        }
    }

    setShowInfo = () => {
        if (
            this.state.showFilter ||
            this.state.showLayerWindow ||
            this.state.showLatLngWindow ||
            this.state.showProject ||
            this.state.showReport
        )
            this.setState({
                showFilter: false,
                showLayerWindow: false,
                showLatLngWindow: false,
                showProject: false,
                showReport: false,
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showInfo: !this.state.showInfo
                    })
                }, 150)
            });
        else {
            this.setState({
                showInfo: !this.state.showInfo
            })
        }
    }

    setShowAreaWindow = () => {
        if (this.state.showDescription) {
            this.setState({
                showDescription: false,
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showAreaWindow: !this.state.showAreaWindow,
                    });
                }, 150)
            });
        }
        else {
            this.setState({
                showAreaWindow: !this.state.showAreaWindow,
            });
        }
    }

    setShowLatLngWindow = () => {
        if (
            this.state.showFilter ||
            this.state.showLayerWindow ||
            this.state.showReport ||
            this.state.showInfo ||
            this.state.showProject
        )
            this.setState({
                showFilter: false,
                showLayerWindow: false,
                showReport: false,
                showProject: false,
                showInfo: false
            }, () => {
                clearTimeout(this.stateTimeout)
                this.stateTimeout = setTimeout(() => {
                    this.setState({
                        showLatLngWindow: !this.state.showLatLngWindow
                    })
                }, 150)
            });
        else {
            this.setState({
                showLatLngWindow: !this.state.showLatLngWindow
            })
        }
    }

    setShowPreset = () => {
        this.setState({ showPreset: !this.state.showPreset });
    }

    /////////////////////// Filter //////////////////////////

    setFilterPage = () => {
        this.setState({
            filterPage: (this.state.filterPage === 0) * 1,
        });
    }

    setSearch = (value, prop) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [prop]: value
            }
        });
    }

    setData = (value, prop) => {
        var error = { ...this.state.error };
        error[prop] = false;
        this.setState({ [prop]: value, error: error });
    }

    setSearchPreset = (value, prop, skipSetData) => {
        if (!value) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    [prop]: ""
                }
            });
            return;
        }
        for (let i = 0; i < this.state.preset.length; i++) {
            if (this.state.preset[i].PresetCollectionID === value) {
                this.setState({
                    filter: {
                        ...this.state.preset[i].PresetCollectionJSON,
                        [prop]: value
                    },
                    searchProject: null
                }, () => {
                    if (skipSetData) return;
                    this.searchProject(null, true);
                    this.getBasicSearchData();
                    this.searchPlaces();
                    this.searchBudgetSummary();
                    this.toggleMapLayerProject();
                });
                return;
            }
        }
    }

    setSearchPlacesInput = (value) => {
        this.searchPlaces(value);
    }

    setSearchPlaces = (value, prop) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [prop]: value
            }
        }, () => {

        });
    }

    setSearchBudgetDimension = (value, prop) => {
        let newBudgetDimension = this.state.filter?.BudgetDimensionID ? [...this.state.filter?.BudgetDimensionID] : [];
        let index = newBudgetDimension.findIndex(x => x === value);
        if (index > -1)
            newBudgetDimension.splice(index, 1);
        else
            newBudgetDimension.push(value);
        this.setState({
            filter: {
                ...this.state.filter,
                BudgetDimensionID: newBudgetDimension
            }
        });
    }

    setSearchProvince = (value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                ProvinceID: value,
                DistrictID: null,
                SubdistrictID: null
            }
        });
    }

    setSearchDistrict = (value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                DistrictID: value,
                SubdistrictID: null
            }
        });
    }

    /////////////////////// Filter //////////////////////////

    setSearchLayer = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value }, () => {
            clearTimeout(this.searchLayerTimeout);
            this.searchLayerTimeout = setTimeout(() => {
                this.setState({
                    searchLayerFilter: this.props.masterData?.GetLayerPath?.filter(obj => (obj.LayerGroupName?.includes(value) || obj.LayerName?.includes(value))) ?? []
                });
            }, 750);
        });
    }

    setSearchProject = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value, Page: 1 }, () => {
            clearTimeout(this.searchProjectTimeout);
            this.searchProjectTimeout = setTimeout(() => {
                this.searchProject();
            }, 750);
            // clearTimeout(this.searchLayerTimeout);
            // this.searchLayerTimeout = setTimeout(() => {
            //     this.setState({
            //         searchLayerFilter: this.props.masterData?.GetLayerPath?.filter(obj => (obj.LayerGroupName?.includes(value) || obj.LayerName?.includes(value))) ?? []
            //     });
            // }, 500);
        });
    }

    setSearchOrganizationOptions = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value }, () => {
            clearTimeout(this.searchOrganizationTimeout);
            if (!value)
                this.setState({
                    searchOrganizationFilter: null
                });
            else
                this.searchOrganizationTimeout = setTimeout(() => {
                    this.setState({
                        searchOrganizationFilter: this.state.basicSearchData?.organizationData.filter(obj => (obj.Name.includes(value))) ?? []
                    });
                }, 500);
        });
    }

    setSearchProvinceOptions = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value }, () => {
            clearTimeout(this.searchProvinceTimeout);
            if (!value)
                this.setState({
                    searchProvinceFilter: null
                });
            else
                this.searchProvinceTimeout = setTimeout(() => {
                    this.setState({
                        searchProvinceFilter: this.state.basicSearchData?.provinceData.filter(obj => (obj.Name.includes(value))) ?? []
                    });
                }, 500);
        });
    }

    setSearchAreaProject = (e) => {
        let value = e.target.value;
        this.setState({
            areaFilter: {
                ...this.state.areaFilter,
                [e.target.name]: value,
                Page: 1
            }
        }, () => {
            clearTimeout(this.searchAreaProjectTimeout);
            this.searchAreaProjectTimeout = setTimeout(async () => {
                let isChecked = this.getProjectNearLatLng();
                if (!isChecked) return;
                else await isChecked;
            }, 750);
            // clearTimeout(this.searchLayerTimeout);
            // this.searchLayerTimeout = setTimeout(() => {
            //     this.setState({
            //         searchLayerFilter: this.props.masterData?.GetLayerPath?.filter(obj => (obj.LayerGroupName?.includes(value) || obj.LayerName?.includes(value))) ?? []
            //     });
            // }, 500);
        });
    }

    /////////////////////// Project ////////////////////////

    onChangePage = (page) => {
        this.setState({
            Page: page
        }, () => {
            this.searchProject();
        })
    }

    onClickProjectInfo = (ProjectID) => {

    }

    onClickProjectLocation = (id, notSetMarker) => {
        this.getProjectDetail(id)
            .then(res => {
                let data = res?.data?.GetProjectDetail;
                if (!data || res?.errors) {
                    throw new Error();
                }
                else {
                    this.setState({
                        showAreaWindow: false,
                        showDescription: true,
                        description: data
                    }, () => {
                        if ((data.Lat || data.Lat === 0) && (data.Lng || data.Lng === 0)) {
                            let latlng = new this.maps.LatLng(data.Lat, data.Lng);
                            if (!notSetMarker)
                                this.setSelectedProjectMarker(data)
                            this.map.panTo(latlng);
                            this.map.setZoom(13);
                        }
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    onClickWaterResourceLocation = (id, notSetMarker) => {
        this.getWresourceDetail(id)
            .then(res => {
                let data = res?.data?.GetWaterResourceDetail;
                console.log(data)
                if (!data || res?.errors) {
                    throw new Error();
                }
                else {
                    if ((data.Lat || data.Lat === 0) && (data.Lng || data.Lng === 0)) {
                        let latlng = new this.maps.LatLng(data.Lat, data.Lng);
                        this.map.panTo(latlng);
                        this.map.setZoom(13);
                    }
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    onClickProjectLocationLatLng = (lat, lng) => {
        if ((!lat && lat !== 0) || (!lng && lng !== 0)) return;
        let latlng = new this.maps.LatLng(lat, lng);
        this.map.panTo(latlng);
        this.map.setZoom(12);
    }

    /////////////////////// Description /////////////////////

    setDescription = (id) => {
        if (id === this.state.description?.ProjectID) return;
        this.getProjectDetail(id)
            .then(res => {
                let data = res?.data?.GetProjectDetail;
                if (!data || res?.errors) {
                    throw new Error();
                }
                else {
                    this.setState({
                        description: data
                    }, () => {
                        if (
                            (this.state.description?.Lat || this.state.description?.Lat === 0) &&
                            (this.state.description?.Lng || this.state.description?.Lng === 0)) {
                            let latlng = new this.maps.LatLng(this.state.description.Lat, this.state.description.Lng);
                            this.map.panTo(latlng);
                            this.setSelectedProjectMarker(this.state.description);
                        }
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    ////////////////////// Area /////////////////////////////

    setSearchAreaFilter = (value, prop) => {
        this.setState({
            areaFilter: {
                ...this.state.areaFilter,
                [prop]: value
            }
        });
    }

    clearSearchAreaFilter = () => {
        this.setState({
            areaFilter: { invisible: this.state.areaFilter?.invisible }
        });
    }

    onChangeRadius = (value, prop) => {
        if (value > maxRadius) value = maxRadius;
        this.setState({
            areaFilter: {
                ...this.state.areaFilter,
                Radius: value
            }
        }, () => {
            if (!value || !this.areaCircle) return;
            this.areaCircle.setRadius(value * 1000);
        });
    }

    onChangeAreaCircleVisible = (value) => {
        this.setState({
            areaFilter: {
                ...this.state.areaFilter,
                Invisible: value
            }
        }, () => {
            if (!this.areaCircle) return;
            this.areaCircle.setVisible(!value);
        });
    }

    ////////////////////// Event, Render ////////////////////

    onClickSearchProject = () => {
        this.setState({
            Page: 1,
            searchProject: null
        }, () => {
            this.clearSelectedProjectMarker();
            this.searchProject(null, true)
                .then(() => {
                    this.clearSelectedProjectMarker();
                    this.getBasicSearchData();
                    this.toggleMapLayerProject();
                    this.searchBudgetSummary();
                })
        })
    }

    onClickClearFilter = () => {
        this.setState({
            filter: {},
            Page: 1,
            searchLayer: null,
            searchProject: null,
            searcjOrganization: null,
            searchProvince: null,
            searchLayerFilter: null,
            searcjOrganizationFilter: null,
            searchProvinceFilter: null,
        }, () => {
            this.searchProject(null, true)
                .then(() => {
                    this.clearSelectedProjectMarker();
                    this.getBasicSearchData();
                    this.toggleMapLayerProject();
                    this.searchBudgetSummary();
                })
        })
    }

    checkInvisibleBadgeMenu = (data) => {
        if (!data) return true;
        let invisible = true;
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                if (
                    data[key] &&
                    (
                        typeof data[key] !== "object" ||
                        (typeof data[key] === "object" &&
                            data[key].length)
                    )
                ) invisible = false;
            }
        }
        return invisible
    }

    render() {
        if (!this.state.masterLoaded || !this.props.mapMode) return null;
        return (
            <>
                <div className="map-wrapper-map">
                    <div className="width-100 height-100" ref={el => this.mapWrapper = el}>
                        <GoogleMap
                            setMap={this.setMap}
                        >
                            {this.state.areaMarker}
                            {this.state.areaInfoWindow}
                            {this.state.selectedProjectInfoWindow}
                        </GoogleMap>
                    </div>
                    <div className="map-speeddial-filter">
                        <Grid
                            container
                            className="map-menu"
                            direction="column"
                            spacing={4}
                            style={{ width: "auto" }}
                        >
                            <Grid>
                                <div>
                                    <Tooltip title="รูปแบบแผนที่" placement="right" enterDelay={500} enterNextDelay={500}>
                                        <Badge color="error" invisible={this.checkInvisibleBadgeMenu(this.state.showLayer)} variant="dot">
                                            <IconButton
                                                onClick={this.setShowLayerWindow}
                                                className="icon-btn-default"
                                            >
                                                <Layers />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid>
                                <div className="mt-05">
                                    <Tooltip title="การคัดกรองข้อมูลโครงการ" placement="right" enterDelay={500} enterNextDelay={500}>
                                        <Badge color="error" invisible={this.checkInvisibleBadgeMenu(this.state.filter)} variant="dot">
                                            <IconButton
                                                onClick={this.setShowFilter}
                                                className="icon-btn-default "
                                            >
                                                <Tune />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid>
                                <div className="mt-05">
                                    <Tooltip title="รายการโครงการ" placement="right" enterDelay={500} enterNextDelay={500}>
                                        <Badge color="error" invisible={this.state.Project?.length ? false : true} variant="dot">
                                            <IconButton
                                                onClick={this.setShowProject}
                                                className="icon-btn-default"
                                            >
                                                <Info />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid>
                                <div className="mt-05">
                                    <Tooltip title="สรุปรายงาน" placement="right" enterDelay={500} enterNextDelay={500}>
                                        <IconButton
                                            onClick={this.setShowReport}
                                            className="icon-btn-default"
                                        >
                                            <PieChart />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Collapse in={this.state.showMore}>
                                <Grid>
                                    <div className="mt-05">
                                        <Tooltip title="ข้อมูล" placement="right" enterDelay={500} enterNextDelay={500}>
                                            <Badge color="error" invisible={this.checkInvisibleBadgeMenu(this.state.showLayer)} variant="dot">
                                                <IconButton
                                                    onClick={this.setShowInfo}
                                                    className="icon-btn-default"
                                                >
                                                    <ContactSupport />
                                                </IconButton>
                                            </Badge>
                                        </Tooltip>
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="mt-05">
                                        <Tooltip title="ข้อมูลพื้นที่" placement="right" enterDelay={500} enterNextDelay={500}>
                                            <Badge
                                                color="error"
                                                invisible={
                                                    (this.state.projectArea?.length ||
                                                        this.state.criticalArea?.length) ?
                                                        false : true
                                                }
                                                variant="dot"
                                            >
                                                <IconButton
                                                    onClick={this.setShowAreaWindow}
                                                    className="icon-btn-default"
                                                >
                                                    <TrackChanges />
                                                </IconButton>
                                            </Badge>
                                        </Tooltip>
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="mt-05">
                                        <Badge color="error" invisible={this.checkInvisibleBadgeMenu(this.state.description)} variant="dot">
                                            <Tooltip title="ข้อมูลโครงการ" placement="right" enterDelay={500} enterNextDelay={500}>
                                                <IconButton
                                                    onClick={this.setShowDescription}
                                                    className="icon-btn-default"
                                                >
                                                    <Assignment />
                                                </IconButton>
                                            </Tooltip>
                                        </Badge>
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="mt-05">
                                        <Tooltip title="ค้นหาตำแหน่ง" placement="right" enterDelay={500} enterNextDelay={500}>
                                            <IconButton
                                                onClick={this.setShowLatLngWindow}
                                                className="icon-btn-default"
                                            >
                                                <Room />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="mt-05">
                                        <Tooltip title="ส่งออกแผนที่" placement="right" enterDelay={500} enterNextDelay={500}>
                                            <IconButton
                                                onClick={this.saveMapToDataUrl}
                                                className="icon-btn-default"
                                            >
                                                <GetApp />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Collapse>
                            <Grid>
                                <div className="mt-05">
                                    <Tooltip title="เพิ่มเติม" placement="right" enterDelay={500} enterNextDelay={500}>
                                        <IconButton
                                            onClick={() => this.setState({ showMore: !this.state.showMore })}
                                            className="icon-btn-default"
                                        >
                                            <MoreHoriz />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                        <MapFilter
                            masterData={this.props.masterData}
                            OrganizationActionComponent={this.OrganizationActionComponent}
                            ProvinceActionComponent={this.ProvinceActionComponent}
                            setSearch={this.setSearch}
                            setSearchPlaces={this.setSearchPlaces}
                            setSearchPlacesInput={this.setSearchPlacesInput}
                            setSearchProvince={this.setSearchProvince}
                            setSearchDistrict={this.setSearchDistrict}
                            setFilterPage={this.setFilterPage}
                            setShowFilter={this.setShowFilter}
                            setSearchLayer={this.setSearchLayer}
                            setSearchBudgetDimension={this.setSearchBudgetDimension}
                            onClickSearchProject={this.onClickSearchProject}
                            onClickClearFilter={this.onClickClearFilter}
                            setSearchPreset={this.setSearchPreset}
                            setSearchOrganizationOptions={this.setSearchOrganizationOptions}
                            setSearchProvinceOptions={this.setSearchProvinceOptions}
                            setShowPreset={this.setShowPreset}
                            signoffList={this.state.signoffList}
                            filter={this.state.filter}
                            placesOptions={this.state.placesOptions}
                            showFilter={this.state.showFilter}
                            filterPage={this.state.filterPage}
                            budgetYearOptions={this.budgetYearOptionsObject}
                            basicSearchData={this.state.basicSearchData}
                            searchOrganization={this.state.searchOrganization}
                            searchOrganizationFilter={this.state.searchOrganizationFilter}
                            searchProvince={this.state.searchProvince}
                            searchProvinceFilter={this.state.searchProvinceFilter}
                            tag={this.state.tag}
                            preset={this.state.preset}
                        />
                        <MapLayers
                            setMapType={this.setMapType}
                            mapType={this.state.mapType}
                            showLayerWindow={this.state.showLayerWindow}
                            setShowLayerWindow={this.setShowLayerWindow}
                            setShowLayer={this.setShowLayer}
                            clearShowLayer={this.clearShowLayer}
                            masterData={this.props.masterData}
                            LayerActionComponent={this.LayerActionComponent}
                            showLayer={this.state.showLayer}
                            searchLayer={this.state.searchLayer}
                            setSearchLayer={this.setSearchLayer}
                            searchLayerFilter={this.state.searchLayerFilter}
                            showProjectLayer={this.state.showProjectLayer}
                            setShowProjectLayer={this.setShowProjectLayer}
                        />
                        <MapProject
                            showProject={this.state.showProject}
                            layers={this.layers}
                            setShowProject={this.setShowProject}
                            project={this.state.Project ?? []}
                            projectCount={this.state.ProjectCount}
                            limit={this.state.Limit}
                            page={this.state.Page}
                            onChangePage={this.onChangePage}
                            onClickProjectLocation={this.onClickProjectLocation}
                            searchProject={this.state.searchProject}
                            setSearchProject={this.setSearchProject}
                        />
                        <MapInfo
                            showInfo={this.state.showInfo}
                            setShowInfo={this.setShowInfo}
                            layers={this.layers}
                            baseURL={baseURL}
                            account={this.props.account}
                        />
                        <MapDescription
                            setShowDescription={this.setShowDescription}
                            setDescription={this.setDescription}
                            onClickProjectLocationLatLng={this.onClickProjectLocationLatLng}
                            showDescription={this.state.showDescription}
                            description={this.state.description}
                            descriptionList={this.state.descriptionList}
                        />
                        <MapArea
                            masterData={this.props.masterData}
                            setShowAreaWindow={this.setShowAreaWindow}
                            setSearchAreaFilter={this.setSearchAreaFilter}
                            clearSearchAreaFilter={this.clearSearchAreaFilter}
                            setSearchAreaProject={this.setSearchAreaProject}
                            onChangeRadius={this.onChangeRadius}
                            onChangeAreaCircleVisible={this.onChangeAreaCircleVisible}
                            onClickProjectLocation={this.onClickProjectLocation}
                            onClickWaterResourceLocation={this.onClickWaterResourceLocation}
                            getProjectNearLatLng={this.getProjectNearLatLng}
                            setShowLayer={this.setShowLayer}
                            showLayer={this.state.showLayer}
                            showAreaWindow={this.state.showAreaWindow}
                            areaFilter={this.state.areaFilter}
                            criticalArea={this.state.criticalArea}
                            projectArea={this.state.projectArea}
                            waterResourceArea={this.state.waterResourceArea}
                            limit={this.state.Limit}
                            budgetYearOptions={this.budgetYearOptionsObject}
                        />
                        <MapLatLng
                            setShowLatLngWindow={this.setShowLatLngWindow}
                            showLatLngWindow={this.state.showLatLngWindow}
                            handleClickHoldMap={this.handleClickHoldMap}
                            onChangeAreaCircleVisible={this.onChangeAreaCircleVisible}
                        />
                        <MapReport
                            showReport={this.state.showReport}
                            setShowReport={this.setShowReport}
                            reportData={this.state.reportData}
                            masterData={this.props.masterData}
                        />
                    </div>

                    <Snackbar
                        open={this.state.showHelpMenu}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <CmtCard className="padding-Menu">
                            <Box padding={2}>
                                <Box display="flex">
                                    <MousePointer style={{ width: "1rem" }} />
                                    <Box marginBottom={2} marginLeft={2} display="block" position="relative">
                                        กดคลิกซ้ายที่จุดตำแหน่งเพื่อแสดงรายละเอียดโครงการ
                                    </Box>
                                    <IconButton
                                        size="small"
                                        onClick={() => this.setState({ showHelpMenu: false })}
                                        className="ml-05"
                                    >
                                        <Close style={{ fontSize: "1rem" }} />
                                    </IconButton>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Mouse style={{ fontSize: "1rem" }} />
                                    <Box marginLeft={2}>กดคลิกซ้ายค้างในแผนที่ 2 วินาทีเพื่อแสดงข้อมูลพื้นที่</Box>
                                </Box>
                            </Box>
                        </CmtCard>
                    </Snackbar>
                </div>
                <MapPreset
                    masterData={this.props.masterData}
                    showPreset={this.state.showPreset}
                    setShowPreset={this.setShowPreset}
                    filter={this.state.filter}
                    placesOptions={this.state.placesOptions}
                    showFilter={this.state.showFilter}
                    filterPage={this.state.filterPage}
                    budgetYearOptions={this.budgetYearOptionsObject}
                    basicSearchData={this.state.basicSearchData}
                    searchOrganization={this.state.searchOrganization}
                    searchOrganizationFilter={this.state.searchOrganizationFilter}
                    searchProvince={this.state.searchProvince}
                    searchProvinceFilter={this.state.searchProvinceFilter}
                    tag={this.state.tag}
                    preset={this.state.preset}
                />
            </>
        )
    }
}

const mapStatetoProps = (state) => ({
    masterData: state.masterData,
    mapMode: state.mapMode,
    filterData: state.filterData,
    account: state.account
});

const mapDispatchtoProps = (dispatch) => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    setOpenMapMode: () => dispatch(openMapMode()),
    setCloseMapMode: () => dispatch(closeMapMode()),
    setMaster: (data) => dispatch(setMasterData(data)),
    setFilterData: (data) => dispatch(setFilterData(data))
})

export default connect(mapStatetoProps, mapDispatchtoProps)(Map);