import React, { useState } from "react";
import {
    TextField,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    Grow,
    Container,
    Card,
    Typography,
    Box,
} from "@material-ui/core";
import AccountService from "../../../Service/AccountService";
import { setAccountLogin } from "../../../redux/action";
import { ArrowBack, Clear } from "@material-ui/icons";
import { setLocalStorage } from "../../../Service/_LocalStorageService";
import ApproveAccountLogService from "../../../Service/ApproveAccountLogService";
import Swal from "sweetalert2";
import TextFieldMemo from "../../../Util/TextFieldMemo";
import UploadFile from "./UploadFile";
import styles from "./ChangeOwner.module.css";
import "./ChangeOwner.css";

const checkLogin = (username, password) => {
    if (!username && !password) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Username และ Password", "error");
        return false;
    } else if (!username) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Username", "error");
        return false;
    } else if (!password) {
        Swal.fire("กรุณาตรวจสอบข้อมูล", "กรุณากรอก Password", "error");
        return false;
    } else return true;
};

const login = (username, password, dispatch, history, setMasterData, masterData) => {
    if (!checkLogin(username, password)) return;
    AccountService.Login({ Username: username, Password: password })
        .then((res) => {
            if (res.errors || !res.data) {
                let error = res.errors && res.errors[0] ? res.errors[0].Message : "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล";
                return Swal.fire("กรุณาตรวจสอบข้อมูล", error, "error");
            }
            // setUsersCookies('sessionToken', res.data.Login.SessionToken);
            setLocalStorage('SessionToken', res.data.Login.SessionToken);
            dispatch(setAccountLogin(res.data.Login));
            setMasterData()
                .then(() => {
                    if (!res.data?.Login?.PDPAConsentID) {
                        history.push("/privacy");
                    } else {
                        history.push("/home");
                    }
                });
        })
        .catch((e) => {
            console.log(e);
            Swal.fire("เกิดข้อผิดพลาด", "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล", "error");
        });
};

const LoginModal = ({ show, setShow, dispatch, history, setMasterData, masterData, showLoading, hideLoading }) => {
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [changeOwner, setChangeOwner] = useState(false);
    const [username, setUsername] = useState("");
    const [usernameReset, setUsernameReset] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setError] = useState({});
    const [state, setState] = useState({
        edit: false,
        account: {
            Username: "",
            OwnerName: "",
            FirstName: "",
            LastName: "",
            Email: "",
            OrganizationInternal: "",
            Position: "",
            PhoneNumber: "",
            OfficeNumber: "",
            Remark: "",
            AttachedFile: "",
        },
        Files: [],
        error: {},
    });

    const disabledDownload =
        ((!state.account.Username || state.account.Username === "") &&
            (!state.account.OwnerName || state.account.OwnerName === "")) ||
        !state.account.Email ||
        state.account.Email === "" ||
        !state.account.FirstName ||
        state.account.FirstName === "" ||
        !state.account.LastName ||
        state.account.LastName === "" ||
        !state.account.OrganizationInternal ||
        state.account.OrganizationInternal === "" ||
        !state.account.Position ||
        state.account.Position === "" ||
        !state.account.PhoneNumber ||
        state.account.PhoneNumber === "" ||
        !state.account.OfficeNumber ||
        state.account.OfficeNumber === "";

    const setInit = () =>
        setState({
            edit: false,
            account: {
                Username: "",
                OwnerName: "",
                FirstName: "",
                LastName: "",
                Email: "",
                OrganizationInternal: "",
                Position: "",
                PhoneNumber: "",
                OfficeNumber: "",
                Remark: "",
                AttachedFile: "",
            },
            Files: [],
            error: {},
        });

    const checkDataResetPassword = () => {
        var error = {};
        if (!usernameReset) error.usernameReset = true;
        if (!email) error.Email = true;
        setError(error);

        if (Object.keys(error).length) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                icon: "warning",
            });
            return false;
        } else return true;
    };

    const checkFileExtension = (file) => {
        if (!file.name) return false;
        let ext = [".pdf"];
        let fileExt = file.name.match(/\.[0-9a-z]+$/i)?.[0]?.toLowerCase();
        let inSupportedExt = ext.includes(fileExt);
        if (!inSupportedExt) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                html: `
                <div class="d-flex align-items-center justify-content-center">
                    <div>ระบบไม่รองรับสกุลไฟล์นี้<div>
                    <div>กรุณาเลือกไฟล์ที่มีสกุลไฟล์ดังนี้</div>
                    <div>${ext.join(", ")}<div>
                </div>
                `,
                icon: "warning",
            });
        }
        return inSupportedExt;
    };

    const onEnter = (
        e,
        username,
        password,
        dispatch,
        history,
        setMasterData,
        masterData,
        showResetPassword,
        setShowResetPassword,
        email
    ) => {
        if (e.keyCode === 13) {
            if (!showResetPassword && !changeOwner) login(username, password, dispatch, history, setMasterData, masterData);
            else if (showResetPassword) {
                if (!checkDataResetPassword()) return;
                showLoading();
                return AccountService.forgetpassword(email, usernameReset)
                    .then((res) => {
                        if (res.errors || !res.data) {
                            let error =
                                res.errors && res.errors[0]
                                    ? res.errors[0].Message
                                    : "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล";
                            return Swal.fire("เกิดข้อผิดพลาด", error, "error");
                        }
                        Swal.fire({
                            title: "ทำรายการสำเร็จ",
                            text: "ระบบได้ทำการส่ง Email เรียบร้อยแล้ว กรุณาตรวจสอบกล่องข้อความของท่าน",
                            icon: "success",
                        });
                        setUsernameReset("");
                        setEmail("");
                        setShowResetPassword(false);
                    })
                    .catch((e) => {
                        console.log(e);
                        Swal.fire({
                            title: "เกิดข้อผิดพลาด",
                            icon: "error",
                        });
                    })
                    .then(() => {
                        hideLoading()
                    })
            }
            else return false;
        }
    };

    const addFile = (file) => {
        return {
            FileName: file.name,
            FileData: file,
        };
    };

    const onPrintModal = () => {
        document.body.style.width = "100%";
        document.body.style.position = "fixed";
        window.print();
        document.body.style.width = "";
        document.body.style.position = "";
    };

    const handleFileChoose = (e) => {
        let files = e.target.files;
        let fileList = [];
        let maxSize = 50 * 1024 * 1024;
        let textEncoder = new TextEncoder();
        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            let name = files[i].name.replace(/\.[^/.]+$/, "");
            if (name.length > 60 || textEncoder.encode(name).length > 245) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ชื่อไฟล์แนบมีความยาวเกิน 60 ตัวอักษร กรุณาแก้ไขชื่อไฟล์และลองใหม่อีกครั้ง",
                    icon: "warning",
                });
                return;
            }
            if (files[i].size > maxSize) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ขนาดไฟล์ใหญ่เกิน 50MB ต่อไฟล์",
                    icon: "warning",
                });
                return;
            }
            if (!checkFileExtension(files[i])) return;
            fileList.push(addFile(files[i]));
        }
        setState((prevState) => ({
            ...prevState,
            Files: [...prevState.Files, ...fileList],
        }));
    };

    const handleDrop = (files) => {
        let fileList = [...state.Files];
        let maxSize = 100 * 1024 * 1024;
        let textEncoder = new TextEncoder();
        for (let i = 0; i < files.length; i++) {
            if (!files[i].name) return;
            let name = files[i].name.replace(/\.[^/.]+$/, "");
            if (name.length > 60 || textEncoder.encode(name).length > 245) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ชื่อไฟล์แนบมีความยาวเกิน 60 ตัวอักษร กรุณาแก้ไขชื่อไฟล์และลองใหม่อีกครั้ง",
                    icon: "warning",
                });
                return;
            }
            if (files[i].size > maxSize) {
                Swal.fire({
                    title: "กรุณาตรวจสอบข้อมูล",
                    text: "ขนาดไฟล์ใหญ่เกิน 50MB ต่อไฟล์",
                    icon: "warning",
                });
                return;
            }
            if (!checkFileExtension(files[i])) return;
            fileList.push(addFile(files[i]));
        }
        setState((prevState) => ({
            ...prevState,
            Files: [...prevState.Files, ...fileList],
        }));
    };

    const handleRemoveFile = (index) => {
        var fileList = [...state.Files];
        fileList.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            Files: prevState.Files.filter((r, num) => index !== num),
        }));
    };

    const onChange = (name, value) => {
        setState((prevState) => ({ ...prevState, account: { ...prevState.account, [name]: value } }));
    };

    const checkData = () => {
        var error = {};
        if (
            (!state.account.Username || state.account.Username === "") &&
            (!state.account.OwnerName || state.account.OwnerName === "")
        ) {
            error.Username = true;
            error.OwnerName = true;
        }
        if (!state.account.Email || state.account.Email === "") error.Email = true;
        if (!state.account.FirstName || state.account.FirstName === "") error.FirstName = true;
        if (!state.account.LastName || state.account.LastName === "") error.LastName = true;
        if (!state.account.OrganizationInternal || state.account.OrganizationInternal === "")
            error.OrganizationInternal = true;
        if (!state.account.Position || state.account.Position === "") error.Position = true;
        if (!state.account.PhoneNumber || state.account.PhoneNumber === "") error.PhoneNumber = true;
        if (!state.account.OfficeNumber || state.account.OfficeNumber === "") error.OfficeNumber = true;
        if (state.Files.length === 0) error.AttachedFile = true;

        setState((prevState) => ({ ...prevState, error: error }));
        if (Object.keys(error).length) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                icon: "warning",
            });
            return false;
        } else return true;
    };

    const resetError = () => setState((prevState) => ({ ...prevState, error: {} }));

    const onSave = () => {
        resetError();
        if (!checkData()) return;
        showLoading();

        ApproveAccountLogService.CreateChangeOwnerRequestByOther(state.account)
            .then((res) => {
                if (res?.errors) {
                    throw new Error(res?.errors?.[0].Message);
                    // return Swal.fire({
                    //     title: "เกิดข้อผิดพลาด",
                    //     text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                    //     icon: "error",
                    // });
                }

                return res.data;
            })
            .then((res) => {
                let logID = res.CreateChangeOwnerRequestByOther?.LogID;

                if (!logID) {
                    throw new Error("ไม่พบเลขที่คำขอ");
                }

                let data = {
                    Files: state.Files,
                    LogID: logID,
                };
                return ApproveAccountLogService.uploadFileChangeOwner(data);
            })
            .then((res) => {
                if (res?.errors) {
                    return Swal.fire({
                        title: res.errors[0]?.Message,
                        type: "error",
                    });
                }

                Swal.fire({
                    title: "ส่งคำขอสำเร็จ",
                    text: `คำขอของท่านอยู่ระหว่างรอตรวจสอบ
                        หลังคำขอได้รับการพิจารณาและตรวจสอบเสร็จสิ้น
                        ท่านจะได้รับแจ้งผลการพิจารณาผ่านทาง E-Mail ที่ลงทะเบียน`,
                    icon: "success",
                });

                setInit();
                return setChangeOwner(false);
            })
            .catch((err) => {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: err?.message ?? "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                    icon: "error",
                });
            })
            .finally(() => {
                hideLoading();
            })
    };

    return (
        <Dialog
            onClose={setShow}
            TransitionProps={{
                onExit: () =>
                    setTimeout(() => {
                        setEmail("");
                        setShowResetPassword(false);
                        setChangeOwner(false);
                    }, 300)
            }}
            aria-labelledby="year-dialog"
            open={show}
            fullWidth
            maxWidth={changeOwner ? "md" : "xs"}
        >
            <DialogTitle style={{ position: "relative" }}>
                {!showResetPassword && !changeOwner
                    ? "เข้าสู่ระบบ"
                    : showResetPassword
                        ? "ลืมรหัสผ่าน"
                        : "ยื่นคำขอเปลี่ยนผู้รับผิดชอบ"}
                {changeOwner ? (
                    <div style={{ position: "absolute", right: 12, top: 8 }}>
                        <div className={styles.buttonContainer}>
                            <span
                                onClick={() => {
                                    setChangeOwner(false);
                                    setInit();
                                }}
                            >
                                <Clear id="clear-button" className={styles.in} />
                            </span>
                        </div>
                    </div>
                ) : null}
            </DialogTitle>
            <DialogContent
                style={{ paddingTop: !showResetPassword ? "1rem" : "0", paddingBottom: "1rem", overflowX: "hidden" }}
                onKeyDown={(e) =>
                    onEnter(
                        e,
                        username,
                        password,
                        dispatch,
                        history,
                        setMasterData,
                        masterData,
                        showResetPassword,
                        setShowResetPassword,
                        email
                    )
                }
            >
                <Grow in={!showResetPassword && !changeOwner} timeout={400}>
                    <div style={{ display: !showResetPassword && !changeOwner ? "" : "none" }}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    size="small"
                                    value={username ?? ""}
                                    onChange={(e) => setUsername(e.target.value)}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="รหัสผ่าน"
                                    variant="outlined"
                                    size="small"
                                    value={password ?? ""}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grow>
                <Grow in={showResetPassword} timeout={400}>
                    <div style={{ display: showResetPassword ? "" : "none" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <IconButton
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    // className="btn-danger"
                                    onClick={() => {
                                        setUsernameReset("");
                                        setEmail("");
                                        setShowResetPassword(false);
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    value={email ?? ""}
                                    onChange={(e) => {
                                        if (errors.Email) setError((prevErr) => ({ ...prevErr, Email: false }));
                                        setEmail(e.target.value);
                                    }}
                                    error={errors.Email}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid className="mt-10" item xs={12}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    size="small"
                                    value={usernameReset ?? ""}
                                    onChange={(e) => {
                                        if (errors.Username) setError((prevErr) => ({ ...prevErr, Username: false }));
                                        setUsernameReset(e.target.value);
                                    }}
                                    error={errors.usernameReset}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grow>
                <Grow in={changeOwner} timeout={400}>
                    <div id="Change-Owner-Modal" style={{ display: changeOwner ? "" : "none" }}>
                        <div className="for-print mb-10">
                            <Grid
                                container
                                justifyContent="center"
                                style={{ maxHeight: "75px", marginBottom: "1rem" }}
                            >
                                <Grid item className="d-flex align-items-center">
                                    <img src="/images/twp-header.png" />
                                </Grid>
                            </Grid>
                            <div style={{ fontSize: "1rem", fontWeight: 500 }}>ยื่นคำขอเปลี่ยนแปลงผู้รับผิดชอบ</div>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ minHeight: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    Username <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic1"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="Username"
                                        value={state.account.Username}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.Username}
                                        // disabled={state.account.OwnerName?.length > 0}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3} className="d-flex align-items-center mt-10 no-print">
                                    หรือ
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10 no-print">
                                </Grid>
                                <Grid item xs={3} className="d-flex align-items-center mt-10">
                                    ชื่อ-นามสกุลผู้รับผิดชอบเดิม{" "}
                                    <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center mt-10">
                                    <TextFieldMemo
                                        id="outlined-basic10"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="OwnerName"
                                        value={state.account.OwnerName}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.OwnerName}
                                        // disabled={state.account.Username?.length > 0}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    ชื่อผู้รับผิดชอบใหม่ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic2"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="FirstName"
                                        value={state.account.FirstName}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.FirstName}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    นามสกุลผู้รับผิดชอบใหม่ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic3"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="LastName"
                                        value={state.account.LastName}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.LastName}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    Email ผู้รับผิดชอบใหม่ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic4"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="Email"
                                        value={state.account.Email}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.Email}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    หน่วยงาน <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic5"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="OrganizationInternal"
                                        value={state.account.OrganizationInternal}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.OrganizationInternal}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    ตำแหน่ง <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic6"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="Position"
                                        value={state.account.Position}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.Position}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    เบอร์โทรติดต่อ <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic7"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="PhoneNumber"
                                        value={state.account.PhoneNumber}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.PhoneNumber}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    เบอร์โทรสำนักงาน <span className="no-print" style={{ color: "red", padding: "0 8px" }}>*</span> :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic8"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="OfficeNumber"
                                        value={state.account.OfficeNumber}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.OfficeNumber}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.section}>
                            <Grid container style={{ height: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    หมายเหตุ :{" "}
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <TextFieldMemo
                                        id="outlined-basic9"
                                        variant="outlined"
                                        className="form-control"
                                        type="text"
                                        size="small"
                                        name="Remark"
                                        value={state.account.Remark}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        error={state.error.Remark}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Grid item xs={12} className="for-print mb-10">
                            <Box mb={2}>SIGN HERE</Box>
                            <Box className="box-sign-here">
                                <Box>ลงชื่อ ......................................................</Box>
                                <Box>(..............................................................)</Box>
                                <Box>ตำแหน่ง .................................................</Box>
                                <Box>(หัวหน้าส่วนราชการ)</Box>
                            </Box>
                        </Grid>
                        <div className={`${styles.section} no-print`}>
                            <Grid container style={{ minHeight: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    พิมพ์เอกสารลงนาม :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <Button
                                        onClick={onPrintModal}
                                        disabled={disabledDownload}
                                        variant="contained"
                                        color="primary"
                                    >
                                        พิมพ์เอกสารลงนาม
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={`${styles.section} no-print`}>
                            <Grid container style={{ minHeight: "75px" }}>
                                <Grid item xs={3} className="d-flex align-items-center">
                                    แนบเอกสารลงนาม <span style={{ color: "red", padding: "0 8px" }}>*</span> :
                                </Grid>
                                <Grid item xs={9} className="d-flex align-items-center">
                                    <UploadFile
                                        handleDrop={handleDrop}
                                        handleFileChoose={handleFileChoose}
                                        handleRemoveFile={handleRemoveFile}
                                        files={state.Files}
                                        section={"changeOwner"}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mr-10 no-print" style={{ textAlign: "right" }}>
                            <Button variant="contained" color="primary" className="mt-10" onClick={onSave}>
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </Grow>
            </DialogContent>
            {!changeOwner ? (
                <DialogActions style={{ display: "block", textAlign: "center", paddingBottom: "1.2rem" }}>
                    {!showResetPassword ? (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => login(username, password, dispatch, history, setMasterData, masterData)}
                            >
                                เข้าสู่ระบบ
                            </Button>
                            <div className="d-flex mt-10" style={{ justifyContent: "center", alignItems: "center" }}>
                                <small
                                    style={{ cursor: "pointer", textAlign: "center", marginTop: "0.4rem" }}
                                    onClick={() => {
                                        setUsernameReset("");
                                        setEmail("");
                                        setShowResetPassword(true);
                                    }}
                                >
                                    ลืมรหัสผ่าน
                                </small>
                                <div className="px-10">|</div>
                                <small
                                    style={{ cursor: "pointer", textAlign: "center", marginTop: "0.4rem" }}
                                    onClick={() => setChangeOwner(true)}
                                >
                                    ยื่นคำขอเปลี่ยนผู้รับผิดชอบ
                                </small>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (!checkDataResetPassword()) return;
                                    showLoading();
                                    return AccountService.forgetpassword(email, usernameReset)
                                        .then((res) => {
                                            if (res.errors || !res.data) {
                                                let error =
                                                    res.errors && res.errors[0]
                                                        ? res.errors[0].Message
                                                        : "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล";
                                                return Swal.fire("เกิดข้อผิดพลาด", error, "error");
                                            }
                                            Swal.fire({
                                                title: "ทำรายการสำเร็จ",
                                                text: "ระบบได้ทำการส่ง Email เรียบร้อยแล้ว กรุณาตรวจสอบกล่องข้อความของท่าน",
                                                icon: "success",
                                            });
                                            setUsernameReset("");
                                            setEmail("");
                                            setShowResetPassword(false);
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                            Swal.fire({
                                                title: "เกิดข้อผิดพลาด",
                                                icon: "error",
                                            });
                                        })
                                        .then(() => {
                                            hideLoading()
                                        })
                                }}
                            >
                                ยืนยัน
                            </Button>
                        </>
                    )}
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

export default LoginModal;
